import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Application } from 'src/app/shared/model/application.model';
import { IncompleteApp } from 'src/app/shared/model/applyLUApiObjects/incomplete-app.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { FormService } from 'src/app/shared/service/form.service';
import { IncompleteAppService } from 'src/app/shared/service/incomplete-app.service';
import {DomService} from '../../../shared/service/dom.service';

@Component({
  selector: 'app-email-phone-search',
  templateUrl: './app-email-phone-search.component.html',
  styleUrls: ['./app-email-phone-search.component.scss']
})

export class AppEmailPhoneSearchComponent implements OnInit {
  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Output() prefillEvent = new EventEmitter<boolean>();

  searchSuccess = false;
  searched = false;
  searching = false;
  preloaded = false;
  nonSelected = false;
  errorMessage = '';
  applicationsFormGroup = new FormGroup({ prefill_radio: new FormControl<string>('') });
  theApps = new Array<IncompleteApp>();

  constructor(
    private applicationService: ApplicationService,
    private incompleteAppService: IncompleteAppService,
    private formService: FormService,
    private domService: DomService
  ) {
  }

  ngOnInit(): void {
    this.formGroup.addControl('email_search', new FormControl<string>(''));
    this.formGroup.addControl('phone_search', new FormControl<string>(''));
  }

  getIncompleteAppPhoneOrEmail(event?) {
    if (event) {
      event.preventDefault();
    }
    this.domService.selectElementAsRoot('#email_search_field').blur();
    this.domService.selectElementAsRoot('#phone_search_field').blur();
    const email = this.formGroup.get('email_search').value;
    const phone = (this.formGroup.get('phone_search').value as string).replace(/[^0-9]/g, '');
    this.preloaded = false;
    this.theApps = new Array<IncompleteApp>();
    this.applicationsFormGroup.get('prefill_radio').setValue('');
    if (email === '' && phone === '') {
      return false;
    }
    this.searchSuccess = false;
    this.nonSelected = false;
    this.searching = true;
    this.searched = false;
    this.incompleteAppService.getAppByEmailPhone({ email, phone }).subscribe((response: Array<IncompleteApp>) => {
      if (response.length <= 0) {
        if (email === '' && phone !== '') {
          this.errorMessage = `No application found with a phone of ${phone}`;
        } else if (phone === '' && email !== '') {
          this.errorMessage = `No application found with a email of ${email}`;
        } else {
          this.errorMessage = `No application found with a phone of ${phone} and email of ${email}`;
        }
      } else {
        // even if an IncompleteApp is found & returned, sometimes the appJson is null
        response.forEach((app) => {
          this.theApps.push(app);
        });
        this.searchSuccess = true;
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 400) {
        this.errorMessage = `No application found`;
      } else if (error.status === 401 || error.status === 403) {
        this.errorMessage = 'Unauthorized';
      } else if (error.status === 503) {
        this.errorMessage = 'Sorry, the Application Service is unavailable';
      } else {
        this.errorMessage = 'Sorry, there was an error with this request, please reach out to support';
      }
      this.searchSuccess = false;
    }, () => {
      this.searching = false;
      this.searched = true;
    });
  }

  prefillForm() {
    const selectedApp = this.applicationsFormGroup.get('prefill_radio').value;

    const application = this.theApps.find((app) => app.id.toString() === selectedApp.toString());

    if (application) {
      const app = Object.assign(new Application(), JSON.parse(application.appJson));
      const prefillApp = this.formService.prefillFromApplication(app);

      this.applicationService.application = prefillApp;

      this.applicationService.prefillApplication.next(prefillApp);

      this.formService.prefillForm.next({formData: JSON.parse(application.formObject), activeSectionName: ''});

      this.preloaded = true;

      if (application.pidm) {
        this.applicationService.pidm = application.pidm;
      }
    } else {
      this.nonSelected = true;
    }
  }

  getProgramCode = (incompleteApp: IncompleteApp) => {
    let result: string;
    const appObject = new Application(JSON.parse(incompleteApp.appJson));
    result = appObject.majorFoses[0]?.programCode;
    return result;
  }

  getName(incompleteApp: IncompleteApp) {
    let result: string;
    const appObject = new Application(JSON.parse(incompleteApp.appJson));
    result = (appObject.firstName) ? appObject.firstName + ' ' : '';
    result += (appObject.lastName) ? appObject.lastName : '';
    if (result === '') {
      result = 'N/A';
    }
    return result;
  }

  getBirthDate(incompleteApp: IncompleteApp) {
    let result: string;
    const appObject = new Application(JSON.parse(incompleteApp.appJson));
    result = appObject.birthDate;
    return result;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {tap, timeout, retry, finalize} from 'rxjs/operators';
import { FlightSchool } from '../model/flight-school.model';

@Injectable({
  providedIn: 'root'
})
export class FlightschoolService {
  private static readonly FLIGHTSCHOOL_BASE_URL = '/rest/flightschools';

  private allFlightSchools = new Array<FlightSchool>();
  private isGeneratingAllFlightSchools = false;

  constructor(private http: HttpClient) { }

  public generateFlightschools(callback?) {
    if (this.allFlightSchools.length) {
      return of<Array<FlightSchool>>(this.allFlightSchools);
    }
    if (!this.isGeneratingAllFlightSchools) {
      this.isGeneratingAllFlightSchools = true;
      this.http.get<Array<FlightSchool>>(FlightschoolService.FLIGHTSCHOOL_BASE_URL)
        .pipe(
          timeout(8000),
          retry(1),
          tap<Array<FlightSchool>>(response => {

            this.allFlightSchools = response;
            this.isGeneratingAllFlightSchools = false;
          }),
          finalize(() => this.isGeneratingAllFlightSchools = false)
        ).subscribe({
        next: callback
      });
    }
  }


  public returnFlightschools(): Array<FlightSchool> {
    return this.allFlightSchools;
  }

}

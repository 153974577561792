import {Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {NavigationService} from 'src/app/shared/service/navigation.service';
import {MenuItem} from './menu-item.model';
import {environment} from 'src/environments/environment';
import {EIconType} from 'src/app/shared/component/icon/icon.component';
import {NgIfSlideInOutAnimation} from 'src/app/animations';
import {FormService} from 'src/app/shared/service/form.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DomService} from '../../shared/service/dom.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  animations: [NgIfSlideInOutAnimation]
})
export class SidebarMenuComponent implements OnChanges, OnDestroy {

  @Input() menuItems: MenuItem[] = this.navigationService.possibleSections;

  environment = environment;

  public eIconTypes: typeof EIconType = EIconType;

  mobileIconsHide = false;

  endSubscriptions = new Subject<void>();

  constructor(
    public navigationService: NavigationService,
    public applicationService: ApplicationService,
    private formService: FormService,
    private domService: DomService
  ) {
    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(prefillObj => {

      this.menuItems.forEach(section => section.active = false);

      if (prefillObj.activeSectionName) {
        const activeSection = this.menuItems.find(section => section.name === prefillObj.activeSectionName);
        activeSection.active = true;
      }
    });
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    this.mobileIconsHide = window.pageYOffset > 0 && window.innerWidth <= 1160;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuItems) {
      this.menuItems = changes.menuItems.currentValue;
    }
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  public activateSection(section: MenuItem) {
    this.navigationService.emitActiveSectionNext(section.name);
  }

  public focusNextNav(event, thisMenuItem: MenuItem) {
    event.preventDefault();

    const nextItem = this.navigationService.getNextAvailableNavItem(thisMenuItem);
    this.domService.selectElementAsRoot(`#${nextItem.name}`).focus();
    this.navigationService.maybeActivateSection(nextItem.name);
  }

  public focusPrevNav(event, thisMenuItem: MenuItem) {
    event.preventDefault();

    const prevItem = this.navigationService.getPrevAvailableNavItem(thisMenuItem);
    this.domService.selectElementAsRoot(`#${prevItem.name}`).focus();
    this.navigationService.maybeActivateSection(prevItem.name);

  }


}

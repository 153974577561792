import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputModule } from 'src/app/form/input/input.module';
import { StudentResumeModalComponent } from './student-resume-modal.component';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { FullAddressModule } from 'src/app/form/input/personal-info/full-address/full-address.module';
import { MiniAlertModule } from 'src/app/alert/mini-alert/mini-alert.module';

@NgModule({
  declarations: [
    StudentResumeModalComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputModule,
        MiniAlertModule,
        IconModule,
        FullAddressModule,
    ],
  exports: [
    StudentResumeModalComponent
  ]
})
export class StudentResumeModalModule {
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CheckboxOpts } from 'src/app/shared/model/checkbox-opts.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-refund-terms-radio',
  templateUrl: './refund-terms-radio.component.html',
  styleUrls: ['./refund-terms-radio.component.scss']
})
export class RefundTermsRadioComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() refundTermsAnswer = new EventEmitter<string>();

  refundCheckboxOpts: CheckboxOpts[] = [
    {
      name: 'refund_check',
      required: true,
      disabled: false,
      defaultVal: false,
      label: `<b>I have read, understand, and agree to the terms and conditions of the <a href="https://www.liberty.edu/residential/undergraduate/confirm-now/#refund" rel="noopener noreferrer nofollow" target="_blank">Liberty University Refund Policy.</a></b>`,
      errorMessage: 'You must read and agree with the refund policy'
    }
  ];

  constructor(
    public formService: FormService
    ) { }

  ngOnInit(): void {
    this.formGroup.addControl('refund_terms_radio', new FormGroup({}));
    this.getYesCheckControl().valueChanges.subscribe(answer => {
      this.refundTermsAnswer.emit(answer.refund_check);
    });
  }

  ngOnDestroy(): void {
    this.formGroup.removeControl('refund_terms_radio');
  }

  getYesCheckControl(): AbstractControl {
    return this.formGroup.get('refund_terms_radio');
  }

}

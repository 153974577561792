import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  public startNewAppSubject = new Subject<boolean>();
  public vwoCookieRegex = new RegExp(`^VWOID\[[a-z,A-Z,0-9]*\].*$`);

  GDPRCookiesObtained = new Subject<boolean>();
  userIsEu: boolean;

  // tslint:disable-next-line:variable-name
  public _vis_test_id = ''; // VWO test ID, provided by Mark or someone from design team
  // tslint:disable-next-line:variable-name
  public _vis_opt_preview_combination = '2'; // determines the test variant, variant is almost always '2' but on occasion a test has more than one variation and the val can be 3 or more
  cookieEnum = {
    RESUME_KEY: 'applylu_rk',
    APP_ID: 'applylu_ai',
    GDPR_CONSENT: 'gdpr-consent',
    GDPR_GRANT_TYPE: 'gdpr-grant-type'
  };
  private hasBadCookie = false;

  constructor() {
    const findCookies = setInterval(() => {
      this.checkCookiesForEU();
    }, 500);
    this.GDPRCookiesObtained.subscribe(response => {
      if (response === true || response === false) {
        this.userIsEu = response;
        clearInterval(findCookies);
      }
    });

  }

  getBadCookieBoolean(): boolean {
    return this.hasBadCookie;
  }

  // will overwrite cookie if it already exists
  addCookie(name: string, value: string, numOfDays = 1, path = ''): void {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (numOfDays * 24 * 60 * 60 * 1000));
    document.cookie = name + '=' + value + '; expires=' + expireDate + ';path=/' + path;
  }

  deleteCookie(name: string) {
    document.cookie = `${name}=; expires = Thu, 01 Jan 1970 00:00:00 GMT; domain=.liberty.edu;`;
  }

  sanitizeCookies() {
    const badCookieCharactersExpression = /[^\x00-\x7F]|[\x00-\x1F\x7F-\x9F]/g;
    const cookies = document.cookie.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25').split(';');

    for (const cookie of cookies) {
      if (badCookieCharactersExpression.test(cookie)) {
        this.deleteCookie(cookie.split('=')[0].trim());
        this.hasBadCookie = true;
      }
    }
  }

  getCookie(name: string): string {
    const cookieName = name + '=';

    // dont try to decode the entire document.cookie string because sometimes a different cookie that we dont care about will be malformwqed and cause all sripts to crash, we will decode the cookie we want further down
    const decodedCookie: string = document.cookie.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25');

    const cookieElements = decodedCookie.split(';');
    for (let cookieElement of cookieElements) {
      while (cookieElement.charAt(0) === ' ') {
        cookieElement = cookieElement.substring(1);
      }
      if (cookieElement.indexOf(cookieName) === 0) {
        try {
          // the replace attempts a safe decode in case certain characters are present that would cause a crash
          return decodeURIComponent(cookieElement.substring(cookieName.length, cookieElement.length));
        } catch (error) {
          // if this happens just return empty string as if the cookie didn't exist
          return '';
        }
      }
    }
    return '';
  }

  checkCookie(name: string): boolean {
    return (this.getCookie(name) !== '' && this.getCookie(name) !== 'undefined' && this.getCookie(name) !== 'null');
  }

  checkCookiesForEU() {
    const gdprGrantType = this.getCookie(this.cookieEnum.GDPR_GRANT_TYPE);
    if (gdprGrantType === 'explicit') {
      this.GDPRCookiesObtained.next(true);
    } else if (gdprGrantType === 'implicit') {
      this.GDPRCookiesObtained.next(false);
    }
  }

  getAllCookies(): string[] {
    // dont try to decode the entire document.cookie string because sometimes a different cookie that we dont care about will be malformed and cause all sripts to crash, we will decode the cookie we want further down
    const decodedCookie: string = document.cookie.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25');
    return decodedCookie.split(';');
  }

  getVWOCookies(): string {
    const decodedCookie: string = document.cookie.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25');
    const arrayOfCookies: string[] = decodedCookie.split(/[;=]+/g);
    const arrayOfCookiesTrimmed = [];
    // In this block I'm getting the cookie key (VWOID[blah]) (they will be seperated in the array), and then returning the value
    for (let i = 0; i < arrayOfCookies.length; i++) {
      // Get rid of all the spaces in the key so regex will work
      const trimmedValue = arrayOfCookies[i].toString().replace(/ /, '');
      if (this.vwoCookieRegex.test(trimmedValue)) {
        // The next index (+1) is the value of the key that passed the regex
        arrayOfCookiesTrimmed.push(arrayOfCookies[i + 1]);
      }
    }
    // Needed to re-work this so ADS could get an array of objects so banner can process.
    return arrayOfCookiesTrimmed.length !== 0 ? `[{vwo_ids: [${arrayOfCookiesTrimmed}]}]` : '';
  }

  // the testID is provided in the name of the cookie, the value of the cookie specifies the variation (usually 2 but sometimes 3)
  // assumes the variation is 2, but can specify a different one if desired
  isVwoTest(testId: string, variationNum?: string): boolean {
    if (!variationNum) {
      variationNum = '2';
    }

    // return true if the url param for testID matches. If the url param for variation number is present then it also must match
    return this.getCookie(`_vis_opt_exp_${testId}_combi`) === variationNum
      || (
        this._vis_test_id === testId
        && (
          this._vis_opt_preview_combination === ''
          || (this._vis_opt_preview_combination !== '' && this._vis_opt_preview_combination === variationNum)));
  }
}

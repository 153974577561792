import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { ThankYouComponent } from './thank-you.component';
import { SharedModule } from '../shared/shared.module';
import { ReferralFormComponent } from './referral-form/referral-form.component';
import { TranscriptUploadComponent } from './transcript-upload/transcript-upload.component';
import { EssayUploadComponent } from './essay-upload/essay-upload.component';
import { InputModule } from '../form/input/input.module';
import { GenericInputModule } from '../form/input/generic/generic-input.module';
import { SidebarModule } from '../sidebar/sidebar.module';
import { MultiFileUploadComponent } from './multi-file-upload/multi-file-upload.component';


@NgModule({
  declarations: [
    ThankYouComponent,
    ReferralFormComponent,
    TranscriptUploadComponent,
    EssayUploadComponent,
    MultiFileUploadComponent
  ],
  imports: [
    CommonModule,
    GenericInputModule,
    ReactiveFormsModule,
    SharedModule,
    InputModule,
    SidebarModule,
  ],
  exports: [
    ThankYouComponent,
  ]
})
export class ThankYouModule { }

<ng-container [formGroup]="formGroup">

  <app-radio
    [ngClass]="{'hidden': formGroup.get('active_military').disabled}"
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('active_military')"
    [label]="'Have you or your spouse served in the U.S. Armed Forces, or are you the child of someone who served in the U.S. Armed Forces?'"
  >
  </app-radio>

  <div [ngClass]="{'hidden': formGroup.get('military_status').disabled}" class="military-input form-control">
    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('military_status'))"></div>
    <label for="military_status">
      <span class="input-label-text">Military Status</span>
    </label>
    <div class="select-input-wrap">
      <select
        name="military_status"
        id="military_status"
        formControlName="military_status"
        [attr.aria-invalid]="formGroup.get('military_status').errors ? 'true': false"
        [attr.aria-describedby]="'military-status-error'"
      >
        <option value="" disabled="true" selected="true">Select</option>
        <option *ngFor="let status of militaryStatuses" value="{{status.value}}">{{status.name}}</option>
      </select>
      <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>

    </div>
    <span class="error" id="military-status-error" role="alert" *ngIf="formService.hasErrors(formGroup.get('military_status'))">Indicate military status</span>
  </div>

  <div [ngClass]="{'hidden': formGroup.get('military_branch').disabled}" class="military-input form-control">
    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('military_branch'))"></div>
    <label for="military_branch">
      <span class="input-label-text">Military Branch</span>
    </label>
    <div class="select-input-wrap">
      <select
        name="military_branch"
        id="military_branch"
        formControlName="military_branch"
        [attr.aria-invalid]="formGroup.get('military_branch').errors ? 'true': false"
        [attr.aria-describedby]="'military-branch-error'"
      >
        <option value="" disabled="true" selected="true">Select</option>
        <option *ngFor="let branch of militaryBranches" value="{{branch.value}}">{{branch.name}}</option>
      </select>
      <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
    </div>
    <span class="error" id="military-branch-error" role="alert" *ngIf="formService.hasErrors(formGroup.get('military_branch'))">Select military branch</span>
  </div>

  <div [ngClass]="{'hidden': formGroup.get('military_paygrade').disabled}" class="military-input form-control">
    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('military_paygrade'))"></div>
    <label for="military_paygrade">
      <span class="input-label-text">Military Paygrade</span>
    </label>
    <div class="select-input-wrap">
      <select
        name="military_paygrade"
        id="military_paygrade"
        formControlName="military_paygrade"
        [attr.aria-invalid]="formGroup.get('military_paygrade').errors ? 'true': false"
        [attr.aria-describedby]="'military-paygrade-error'"
      >
        <option value="" disabled="true" selected="true">Select</option>
        <option *ngFor="let pay of payGrades" value="{{ pay.value }}">{{ pay.name }}</option>
      </select>
      <app-icon class="icon" id="military-paygrade-error" role="alert" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
    </div>
  </div>

  <app-radio
    [ngClass]="{'hidden': formGroup.get('military_tuition').disabled}"
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('military_tuition')"
    [label]="'Are you eligible to receive Military Tuition Assistance?'"
    [errorMessage]="'Indicate if you are eligible to receive Military Tuition Assistance'"
  >
  </app-radio>

  <app-radio
    [ngClass]="{'hidden': formGroup.get('veterans_affairs_benefits').disabled}"
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('veterans_affairs_benefits')"
    [label]="'Are you eligible to receive Department of Veterans Affairs educational benefits, or Dependents Education Assistance?'"
    [errorMessage]="'Indicate your Department of Veterans Affairs educational benefits, or Dependents Education Assistance eligibility'"
  >
  </app-radio>

  <hr [ngClass]="{'hidden': formGroup.get('active_military').disabled}">

</ng-container>

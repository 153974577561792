<ng-container [formGroup]="formGroup">
  <div class="form-control">

    <div class="seeking-pla-container">

      <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('seeking_pla'))"></div>

      <label for="seeking_pla">
        <span class="input-label-text">Seeking EPlus</span>
      </label>

      <div class="select-input-wrap">
        <select
          name="seeking_pla"
          id="seeking_pla"
          formControlName="seeking_pla"
          [attr.aria-invalid]="formService.hasErrors(formGroup.get('seeking_pla')) ? 'true' : null"
          [attr.aria-describedby]="'pla-error'"
        >
          <option value="" disabled="true" selected="true">Select</option>
          <option *ngFor="let plaReason of plaReasons" value="{{plaReason.value}}">{{plaReason.name}}</option>
        </select>

        <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
      </div>

      <span class="error" id="pla-error" role="alert" *ngIf="formService.hasErrors(formGroup.get('seeking_pla'))">This field is required</span>
    </div>

  </div>


</ng-container>

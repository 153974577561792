import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FileUploadComponent} from 'src/app/form/input/generic/file-upload/file-upload.component';
import {EmailMessage, EmailType} from 'src/app/shared/model/email-message.model';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {EmailService} from 'src/app/shared/service/email.service';


@Component({
  selector: 'app-transcript-upload',
  templateUrl: './transcript-upload.component.html',
  styleUrls: ['./transcript-upload.component.scss']
})
export class TranscriptUploadComponent implements OnInit {
  @Input() replyToAddress = '';
  @Input() fullName: string;

  @Input() campus = '';
  @Input() toAddress = EmailMessage.SAT;

  @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;

  approvedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
  approvedFileTypesNames = [' .pdf', ' .docx', ' .doc'];
  fileListLength = 0;
  sentToAPI = false;
  failedSendToAPI = false;

  constructor(
    private emailService: EmailService,
    private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
  }

  sendTranscripts(fileList: File[]) {
    this.fileListLength = fileList.length;
    const webId = this.applicationService.webId;
    this.fileUploadComponent.showLoadingAnimation = true;

    // Set emailMessage Properties
    const messageBody = 'Transcript: ' + this.fullName + ((webId && webId.length > 0) ? ', ' + webId : '') + '\nEmail: ' + this.replyToAddress;
    const emailMessage: EmailMessage = new EmailMessage(EmailMessage.NO_REPLY, this.toAddress, this.replyToAddress,
      `Transcript: ${this.campus} Applicant`, messageBody, EmailType.TRANSCRIPT);

    this.emailService.sendEmailWithAttachment(fileList, emailMessage).subscribe(() => {
      this.fileUploadComponent.showLoadingAnimation = false;
      if (this.emailService.successfulUpload) {
        this.sentToAPI = true;
      } else {
      this.failedSendToAPI = true;
      }
    }, () => {
      this.fileUploadComponent.showLoadingAnimation = false;
      this.failedSendToAPI = true;
    }
    );
  }

}

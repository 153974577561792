import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { PaymentService } from 'src/app/shared/service/payment.service';

@Component({
  selector: 'app-enrollment-deposit-radio',
  templateUrl: './enrollment-deposit-radio.component.html'
})
export class EnrollmentDepositRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() depositAnswer = new EventEmitter<string>();

  questionText = 'Would you like to do that now?';
  depositTooltipText = 'Like with most schools, the Enrollment Deposit is your next step in joining the Liberty University community. Unlike most schools, the deposit unlocks thousands of dollars to help you fund your education and the earlier you place it the more you’re likely to receive.';
  cfawTooltipText = 'Do you want to really KNOW if Liberty is for you? Spend four days and three nights on campus living like a student at College For A Weekend (CFAW) to get the full experience as you attend classes, stay in a dorm, and participate in student activities. You’ll qualify for a free CFAW when you submit your Enrollment Deposit and save another $50.';
  edAmount = 250;

  acodeSubscription = new Subscription();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor(
    public paymentService: PaymentService,
    private applicationService: ApplicationService
    ) { }

  ngOnInit(): void {

    this.formGroup.addControl('enrollment_deposit_radio', new FormControl<string>('No', [] ) );

    this.formGroup.get('enrollment_deposit_radio').valueChanges.subscribe(answer => this.depositAnswer.emit(answer));

    this.acodeSubscription = this.applicationService.acodesUpdated.subscribe(async () => {
      this.edAmount = this.paymentService.getPaymentAmount();
    });

  }

  ngOnDestroy(): void {

    this.formGroup.removeControl('enrollment_deposit_radio');

    this.acodeSubscription.unsubscribe();

  }
}

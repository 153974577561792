<ng-container *ngIf="isModalDisplayed">
  <div class="modal-container" #modalContainer>
    <a class="modalClose" (click)="closeModal()" (keyup.enter)="closeModal()" tabindex="0">
    </a>
    <div class="modalContent">
      <div [innerHTML]='content'></div>

      <div class="text-center" *ngIf="modalService.displayButton">
        <button class="ia btn" (click)="acceptAndClose()">{{ modalService.closeButtonText }}</button>
      </div>

    </div>
  </div>
  <div class="modalOverlay" (click)="closeModal()" (keyup.enter)="closeModal()" tabindex="-1"></div>
</ng-container>


import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {asapScheduler, asyncScheduler, Subject, timeInterval} from 'rxjs';
import { distinctUntilChanged, takeUntil} from 'rxjs/operators';
import { Address } from 'src/app/shared/model/application/address.model';
import { ProgramStateDisclosure } from 'src/app/shared/model/applyLUApiObjects/program-state-disclosure.model';
import { Program } from 'src/app/shared/model/program.model';
import { State } from 'src/app/shared/model/state.model';
import { FormService } from 'src/app/shared/service/form.service';
import { ProgramStateDisclosureService } from 'src/app/shared/service/program-state-disclosure.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { StateService } from 'src/app/shared/service/state.service';
import {NavigationService} from '../../../../shared/service/navigation.service';
import {DomService} from '../../../../shared/service/dom.service';

@Component({
  selector: 'app-program-state-disclosure',
  templateUrl: './program-state-disclosure.component.html'
})
export class ProgramStateDisclosureComponent implements OnInit {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  activeProgram: Program;
  currentProgramCode = '';
  currentState = new State();
  currentCountry = '';

  disclosureCode: string;
  disclosureState: string;
  disclosureRadioOpts = [];

  responseMessage: string;
  matchFound = false;
  alertType: string;
  showRadio: any;

  endSubscriptions = new Subject<void>();

  constructor(
    private programService: ProgramService,
    private programStateDisclosureService: ProgramStateDisclosureService,
    public formService: FormService,
    private stateService: StateService,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private domService: DomService
  ) { }

  ngOnInit(): void {

    // to test one, select Masters, search Addiction and select 'Addictions Counseling'. Then go to address fields and select 'Arkansas'

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe((program: Program) => {
      if (program.programCode !== this.currentProgramCode) {
        this.currentProgramCode = program.programCode;
        this.maybeDisplayDisclosure();

        if (this.currentProgramCode === '') {
          this.formGroup.get('state_disclosure_radio').setValue('');
        }
      }
    });

    this.formService.updatedState.pipe(takeUntil(this.endSubscriptions)).subscribe((state: State) => {
      if (state && state.name && state.name !== '') {
        this.currentState = this.stateService.getStateByName(state.name);
      } else {
        this.currentState = new State();
      }
      this.maybeDisplayDisclosure();
    });

    this.formService.UpdatedAddress.pipe(distinctUntilChanged(), takeUntil(this.endSubscriptions)).subscribe((address: Address) => {
      this.currentCountry = address.nationCode;
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  navigateToDegreePicker() {
    this.navigationService.emitActiveSectionNext('yourDegree');

    requestAnimationFrame(() => { // ensures the scroll happens after the new DOM is painted
      this.domService.selectElementAsRoot('#degree-picker-anchor').scrollIntoView({behavior: 'smooth'});
    });
  }

  async maybeDisplayDisclosure() {
    this.formGroup.get('state_disclosure_radio').clearValidators();
    this.formGroup.get('state_disclosure').clearValidators();
    this.matchFound = false;

    const runDisclosureCheck = this.currentProgramCode && this.currentProgramCode !== '' && this.currentState && this.currentState.code && this.currentState.code !== '' && this.currentCountry === 'US';

    if (runDisclosureCheck) {

      const returnedDisclosure: ProgramStateDisclosure = await this.programStateDisclosureService.getProgramStateDisclosure(this.currentProgramCode, this.currentState.code);
      if (!returnedDisclosure) {
        return;
      }
      this.showRadio = returnedDisclosure.allowComplete;
      this.showRadio === 'N' ? this.formGroup.get('state_disclosure_radio').disable() : this.formGroup.get('state_disclosure_radio').enable();
      if (returnedDisclosure) {
        this.disclosureCode = returnedDisclosure.textCode;
        this.disclosureState = returnedDisclosure.stateCode;
        this.disclosureRadioOpts = [
          { value: this.disclosureCode + '-' + this.disclosureState + '-Yes', label: 'Yes' },
          { value: this.disclosureCode + '-' + this.disclosureState + '-No', label: 'No' }
        ];

        this.formGroup.get('state_disclosure_radio').setValidators([Validators.required] );
        this.matchFound = true;
        this.alertType = 'generic';
        this.responseMessage = returnedDisclosure.ruleDisplay;
        if (returnedDisclosure.allowComplete === 'N') {
          this.alertType = 'danger';
          this.formGroup.get('state_disclosure').setValidators([Validators.required]);
        }
      } else {
        this.formGroup.get('state_disclosure_radio').clearValidators();
        this.formGroup.get('state_disclosure').clearValidators();
        this.matchFound = false;
      }

    }

    this.formGroup.get('state_disclosure_radio').updateValueAndValidity();
    this.formGroup.get('state_disclosure').updateValueAndValidity();

    if (this.currentState && !this.currentState.code) {
      this.matchFound = false;
    }
    this.cdr.detectChanges();

  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() { }

  public findNext(items: Array<any>, currentItem?: any) {
    if (currentItem === undefined || currentItem === null) {
      return items[0];
    }
    const nextIndex = items.findIndex(thisThing => thisThing === currentItem) + 1;
    if (nextIndex === items.length) {
      return items[0];
    }
    return items[nextIndex];
  }

  public findPrev(items: Array<any>, currentItem?: any) {
    if (currentItem === undefined || currentItem === null) {
      return items[items.length - 1];
    }
    const prevIndex = items.findIndex(thisThing => thisThing === currentItem) - 1;
    if (prevIndex === -1) {
      return items[items.length - 1];
    }
    return items[prevIndex];
  }

}

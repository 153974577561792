import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private findNextTabStop(
    el: HTMLElement,
    plusMinus: number,
    inElement?: HTMLElement
  ) {
    let universe: HTMLElement[] = [];

    if (inElement) {
      universe = Array.from(
        inElement.querySelectorAll('[tabindex="0"]')
      ) as HTMLElement[];
    } else {
      universe = Array.from(
        this.renderer.selectRootElement('html', true).querySelectorAll('[tabindex="0"]')
      ) as HTMLElement[];
    }

    const index = universe.indexOf(el);
    return universe[index + plusMinus] || universe[0];
  }

  public scrollIt(element: HTMLElement, to: number, duration: number) {
    let userScrolled = false;

    window.addEventListener(
      'wheel',
      () => {
        userScrolled = true;
      },
      { passive: true }
    );

    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      if (userScrolled) {
        return true;
      }
      currentTime += increment;
      this.renderer.setProperty(
        element,
        'scrollTop',
        this.easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  getOffset(el: HTMLElement): { top: number; left: number } {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
      window.scrollX || document.documentElement.scrollLeft;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  public scrollIntoView(element: HTMLElement, additionalOffset?: number) {
    const theHtml = this.renderer.selectRootElement('html', true) as HTMLElement;
    let elementOffset = this.getOffset(element).top;
    if (additionalOffset) {
      elementOffset -= additionalOffset;
    }
    if (window.innerWidth < 1161) {
      elementOffset -= 158;
    }

    this.scrollIt(theHtml, elementOffset, 500);
  }

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  private easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) { return c / 2 * t * t + b; }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  public tabIndex(event, direction: string, inElement = null) {
    event.preventDefault(); // prevents default scrolling behavior for up/down arrow keys

    const plusMinus = direction === 'previous' ? -1 : 1;

    const nextEl = this.findNextTabStop(event.target, plusMinus, inElement);

    if (nextEl) {
      nextEl.focus();
    }

  }

  public arrowIndex(event, direction: string = '', inElement) {
    event.preventDefault();

    const currentRadio: Element = event.target;


    const radios = inElement.parentElement.querySelectorAll('[role="radio"]');
    const list = Array.prototype.filter.call(radios, (item) => item);
    const index = list.indexOf(currentRadio);


    if (direction === 'previous' && index >= 1) {

      // set previous radio to tab index 0
      list[index - 1].tabIndex = 0;

      // set current to tab index -1
      list[index].tabIndex = -1;

      // focus on previous element
      list[index - 1].focus();

    } else if (direction === 'next' && index < list.length) {

      // set next radio to tab index 0
      list[index + 1].tabIndex = 0;

      // set current to tab index -1
      list[index].tabIndex = -1;

      // focus on next element
      list[index + 1].focus();
    }

  }

  getSiblings = (elem) => {

    // Setup siblings array and get the first sibling
    const siblings = [];
    let sibling = elem.parentNode.firstChild;

    // Loop through each sibling and push to the array
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }

    return siblings;

  }

  getNextSiblings(elem) {
    const sibs = [];
    while (elem === elem.nextSibling) {
      if (elem.nodeType === 1) { sibs.push(elem); }
    }
    return sibs;
  }

  selectElementAsRoot(selector: string, preserveContent = true): HTMLElement {
    try {
      return this.renderer.selectRootElement(selector, preserveContent) as HTMLElement;
    }
    catch {
      return;
    }
  }

  selectAllElementsAsRoot(selector: string, preserveContent = true): HTMLElement[] {
    try {
      const root = this.renderer.selectRootElement('app-root', preserveContent) as HTMLElement;
      const elements = root.querySelectorAll(selector);
      return Array.from(elements) as HTMLElement[];
    }
    catch {
      return;
    }
  }
}

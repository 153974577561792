import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { CheckboxOpts } from 'src/app/shared/model/checkbox-opts.model';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() public itemFormGroup: FormGroup<any>; // can provide a group of new FormControlCustom<string>() to specify the label for each, check Institutional Agreement component for an example
  @Input() public options: CheckboxOpts[] = [];
  @Input() public style = 'alert';

  @ViewChild('checkElement') checkElement: ElementRef;

  @Output() outputtedVals = new EventEmitter();
  @Output() clickListener = new EventEmitter<HTMLElement>();

  allLinks: ElementRef[] = [];

  @HostListener('click', ['$event']) onClick(event) {
    const element = (event.target as HTMLElement);
    if (element.classList.contains('link')) {
      event.preventDefault();
    }
    this.clickListener.emit(element);
  }

  constructor() {}

  ngOnInit() {

    this.options.forEach(checkboxOpt => {
      const newControl = new FormControlCustom<string>({}, checkboxOpt.defaultVal);
      newControl.label = checkboxOpt.label;
      if (checkboxOpt.errorMessage) {
        newControl.errorMessages.required = checkboxOpt.errorMessage;
      }
      if (checkboxOpt.required) {
        newControl.setValidators([Validators.requiredTrue]);
      }
      if (checkboxOpt.disabled) {
        newControl.disable();
      }
      this.itemFormGroup.addControl(checkboxOpt.name, newControl);
    });

  }

  onCheckboxChange(control) {
    control.value.markAsTouched();
    // formControl.markAsTouched();
    this.outputtedVals.emit(this.itemFormGroup.value);
  }

  getRequiredErrorMessage(formControlCustom: AbstractControl): any {
    if (formControlCustom instanceof FormControlCustom) {
      if (!(formControlCustom as FormControlCustom<any>).errorMessages) {
        return {};
      } else {
        return (formControlCustom as FormControlCustom<any>).errorMessages;
      }
    } else {
      return false;
    }
  }

}

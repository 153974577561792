import {Injectable} from '@angular/core';
import {Application} from '../model/application.model';
import {Address} from '../model/application/address.model';
import {Code} from '../model/application/code.model';
import {College} from '../model/application/college.model';
import {HighSchool} from '../model/application/highschool.model';
import {MajorFoses} from '../model/application/major-foses.model';
import {Phone} from '../model/application/phone.model';
import {Term} from '../model/term.model';
import {Visa} from '../model/visa.model';
import {SchoolService} from './school.service';

import {AustraliaStates, CanadaStates, States} from 'src/app/shared/model/states.model';
import {FormService, intlDisclaimerProgramCodes} from './form.service';
import {StateService} from './state.service';
import {Program} from '../model/program.model';
import {ProgramService} from './program.service';
import {ELevelCategory} from '../model/prog-level.model';
import {State} from '../model/state.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationTransformerService {
  private readonly DELIMITER = ' | ';

  constructor(
    private schoolService: SchoolService,
    private formService: FormService,
    private stateService: StateService,
    private programService: ProgramService
  ) {
  }

  // takes the application form and maps out the values to where they need to go
  public transformFormToApp = (formVals: any) => new Promise<Application>(resolve => {
    const currentCampus: string = this.formService.getCurrentCampus();
    const currentLevel: string = this.formService.getCurrentLevel();
    const currentLevelCode: string = formVals.yourDegree.level_code;
    const currentProgram: Program = this.programService.getCurrentProgram();
    const levelCategory = this.programService.getCurrentLevelCategory();
    let returnedApp = new Application();

    /* * * * * * AGENT QUESTIONS SECTION * * * * * */
    if (formVals.agentQuestions) {
      const formSection = formVals.agentQuestions;

      /***Acodes***/
      const acodesForm = formSection.agent_acode_search.acodes;

      if (acodesForm && acodesForm.length > 0) {
        acodesForm.forEach((acode: Code) => {
          const tempCode = new Code(acode.code);
          returnedApp.addAcode(tempCode.code);
        });
      }

      /*** If LUID search is used ***/
      const luidPrefillHappened = formSection.luid_prefill_happened;
      if (luidPrefillHappened && luidPrefillHappened === 'Yes') {
        returnedApp.setCustomAnswer(luidPrefillHappened, '317');
      }

      /***Applicant Goals***/
      const applicangGoalsForm = formSection.applicant_goals_group.goals_search_selected_options;
      const applicantGoalsQuestionNumber = '214';
      let goalsString = '';

      if (applicangGoalsForm && applicangGoalsForm.length > 0) {
        applicangGoalsForm.forEach(goal => {
          goalsString = goalsString.concat(this.DELIMITER + goal.value);
        });
        goalsString = goalsString.substring(3);     // Remove the first Delimiter
      }
      returnedApp.setCustomAnswer(goalsString, applicantGoalsQuestionNumber);

      /***Application Origin***/
      let applicationOriginVal = formSection.application_origin;
      const applicantOriginQuestionNumber = '61';

      if (applicationOriginVal === 'Visitor') {
        applicationOriginVal = 'Visit';
      }
      returnedApp.setCustomAnswer(applicationOriginVal, applicantOriginQuestionNumber);

      /***Seeking PLA***/
      const seekingPlaVal = formSection.seeking_pla;
      returnedApp.addAttribute(seekingPlaVal);

    }

    /* * * * * * YOUR DEGREE SECTION * * * * * */
    if (formVals.yourDegree) {
      const formSection = formVals.yourDegree;
      const powerPlantCertQuestionNumber = '232';
      const professionalPilotQuestionNumber = '230';
      const additionalFlightTrainingQuestionNumber = '231';
      const rnLicenseQuestionNumber = '307';

      /*** Degree Picker ***/
      if (formSection.program && formSection.program.length > 0) {
        const majorFosesForm = new MajorFoses();
        majorFosesForm.etrySequenceNumber = '1';
        majorFosesForm.programCode = formSection.program;
        majorFosesForm.sareFosSequenceNumber = '';
        returnedApp.majorFoses.push(majorFosesForm);
      }

      if (formSection.program === 'INLS-LLM-D') {
        returnedApp.addAcode('D85193');
      }

      if (formSection.start_term) {
        returnedApp.term = formSection.start_term;
      }

      /*** Custom Program Questions ***/
      const customProgQs = formSection.custom_program_questions;
      returnedApp.setCustomAnswer(customProgQs.faa_powerplant_certificate, powerPlantCertQuestionNumber);

      // Aviation Origin
      returnedApp.setCustomAnswer(customProgQs.professional_pilot_program, professionalPilotQuestionNumber);
      returnedApp.setCustomAnswer(customProgQs.additional_flight_training, additionalFlightTrainingQuestionNumber);

      if (customProgQs.professional_pilot_program !== '' && customProgQs.additional_flight_training !== 'No') {
        if (customProgQs.flight_school_selection?.attributeCode?.length > 0) {
          returnedApp.addAttribute(customProgQs.flight_school_selection.attributeCode);
        }

        if (customProgQs.flight_school_selection?.institutionCode?.length > 0) {
          returnedApp.addAcode(customProgQs.flight_school_selection.institutionCode);
        }
      }


      // Nursing
      const rnCheck = customProgQs.rn_license.rn_license_check;
      if (rnCheck === true) {
        returnedApp.setCustomAnswer('Yes', rnLicenseQuestionNumber);

        const rnNumber = customProgQs.nursing_license_number;
        if (rnNumber) {
          returnedApp.setCustomAnswer(rnNumber, '324');
        }

        const rnState = customProgQs.nursing_license_state;
        if (rnState) {
          returnedApp.setCustomAnswer(rnState, '325');
        }
      }

      // APRN disclosure
      if (currentProgram.programCode && currentProgram.programCode === 'CTMH-CTG-D') {
        const aprnCheck = customProgQs.aprn_license.aprn_license_check;
        if (aprnCheck === true) {
          returnedApp.setCustomAnswer('Yes', '321');
        }
      }

      // Master Interdisciplinary
      if (customProgQs.mais_12_hour_select && customProgQs.mais_12_hour_select.length > 0) {
        returnedApp.addAttribute(customProgQs.mais_12_hour_select);
      }
      if (customProgQs.mais_9_hour_select && customProgQs.mais_9_hour_select.length > 0) {
        returnedApp.addAttribute(customProgQs.mais_9_hour_select);
      }

      // Master Interdisciplinary Research
      if (customProgQs.indr_12_hour_select && customProgQs.indr_12_hour_select.length > 0) {
        returnedApp.addAttribute(customProgQs.indr_12_hour_select);
      }
      if (customProgQs.indr_18_hour_select && customProgQs.indr_18_hour_select.length > 0) {
        returnedApp.addAttribute(customProgQs.indr_18_hour_select);
      }

      // Administration & Supervision: Education Leadership License
      if (customProgQs.education_leadership_license.asel_check === true) {
        returnedApp.setCustomAnswer('Yes', '332');
      }
    }


    /* * * * * * PREVIOUS EDUCATION SECTION * * * * * */
    if (formVals.previousEducation) {
      const schoolInfoGroup = formVals.previousEducation;

      /*** High School/Home School ***/
      returnedApp.setCustomAnswer(schoolInfoGroup.hs_diploma_type, '220');

      // HS search selection
      if (schoolInfoGroup && schoolInfoGroup.highschoolSearch && schoolInfoGroup.highschoolSearch.selected_schools && schoolInfoGroup.highschoolSearch.selected_schools.length > 0) {
        const hsFields = schoolInfoGroup.highschoolSearch.selected_schools[0];
        const hsData = hsFields.selected_school;

        if (
          hsData
          && hsFields.last_month_attended
          && hsFields.grad_year
          && (hsData.highSchoolName || hsData.ceebCode)
        ) {
          const theHighschool = Object.assign(new HighSchool(), hsData);
          if (hsFields.state_search_input && hsFields.state_search_input !== '') {
            theHighschool.state = States.find(state => state.name === hsFields.state_search_input).code;
          }
          theHighschool.setDate(`${hsFields.last_month_attended}/15/${hsFields.grad_year.substr(0, 4)}`);
          returnedApp.addHighSchool(theHighschool);
        }

      }

      // GED highschool selection
      const gedFields = schoolInfoGroup.ged_fields;
      const gedHS = new HighSchool();
      if (gedFields) {
        if (gedFields.ged_ceeb_code) {
          gedHS.ceebCode = gedFields.ged_ceeb_code;
        }
        if (gedFields.ged_diploma_month && gedFields.ged_diploma_year) {
          gedHS.setDate(`${gedFields.ged_diploma_month}/15/${gedFields.ged_diploma_year.substr(0, 4)}`);
        }
        if (gedFields.ged_state) {
          gedHS.state = this.stateService.getStateByName(gedFields.ged_state).code;
        }
        if (gedFields.ged_hs_name) {
          gedHS.setName(gedFields.ged_hs_name);
        }
        if (gedHS.highSchoolName || gedHS.ceebCode) {
          returnedApp.addHighSchool(gedHS);
        }
      }

      returnedApp.setCustomAnswer(schoolInfoGroup.certify_degree_radio, '206');
      if (schoolInfoGroup.assert_gpa) {
        returnedApp.setCustomAnswer(schoolInfoGroup.assert_gpa, '259');
      }
      returnedApp.setCustomAnswer(schoolInfoGroup.previously_attended_college, '311');

      // make sure these go after the highschool stuff, should only trigger if there are any highschools
      if (returnedApp.highSchools && returnedApp.highSchools.length > 0) {
        if (schoolInfoGroup.hs_diploma_firstName !== undefined) {
          returnedApp.setCustomAnswer(schoolInfoGroup.hs_diploma_firstName.slice(0, 60), '242');
        }
        if (schoolInfoGroup.hs_diploma_lastName !== undefined) {
          returnedApp.setCustomAnswer(schoolInfoGroup.hs_diploma_lastName.slice(0, 60), '243');
        }
      }


      /*** College Searches ***/
      const collegeSearchGroups = [schoolInfoGroup.multiCollegeSearch, schoolInfoGroup.bachelorsSearch, schoolInfoGroup.mastersSearch, schoolInfoGroup.doctoralSearch];

      collegeSearchGroups.forEach(thisGroup => {

        const selected_schools = thisGroup.selected_schools;

        if (thisGroup && selected_schools && selected_schools.length > 0) {
          selected_schools.forEach((collegeFields) => {
            const thisCollege = collegeFields.selected_school;
            const theCollege: College = Object.assign(new College(), thisCollege);
            if (
              (theCollege.institutionName || theCollege.ceebCode)
              && collegeFields.grad_year && collegeFields.degree_level_received && collegeFields.last_month_attended
            ) {
              if (collegeFields.state_search_input && collegeFields.state_search_input !== '') {
                theCollege.state = States.find(state => state.name === collegeFields.state_search_input).code;
              }
              theCollege.degree = collegeFields.degree_level_received;
              theCollege.setDate(`${collegeFields.last_month_attended}/15/${collegeFields.grad_year.substr(0, 4)}`);
              returnedApp.addCollege(theCollege);
            }
          });
        }

        // if any of them have this radio and is set to Yes
        if (thisGroup && thisGroup.done_entering_multi_school && thisGroup.done_entering_multi_school.checkbox_values.done_entering_multi_school === true) {
          returnedApp.setCustomAnswer('Yes', '312');
        }

      });

      // ensures that all schools are actually instances of Highschools or Colleges
      returnedApp.convertSchools();

      // check to see if any colleges should enable VA Community college acode
      if (returnedApp.priorColleges && returnedApp.priorColleges.length > 0) {
        if (this.checkForApplicationFeeWaivers(returnedApp.priorColleges)) {
          returnedApp.addAcode('D85194');
        }
      }

      const expelledGroup = schoolInfoGroup.expelled_group;
      const dismissedQuestionNumber = '49';
      if (expelledGroup.expelled_radio_question === 'Yes') {
        returnedApp.setCustomAnswer('Yes_SchoolName: ' + expelledGroup.expelled_school, dismissedQuestionNumber);
      } else {
        returnedApp.setCustomAnswer(expelledGroup.expelled_radio_question, dismissedQuestionNumber);
      }

      returnedApp.setCustomAnswer(schoolInfoGroup.previous_balance, '216');

    }

    const phoneInput = formVals.personalInformation.phone_fields.phoneNumberInput as string;
    /* * * * * * PERSONAL INFO SECTION * * * * * */
    const hasValidPhone = formVals.personalInformation.phone_fields.isPossible
      || (formVals.personalInformation.phone_fields.isInternational
        && phoneInput !== '+' && phoneInput.length <= 25);

    if (formVals.personalInformation) {
      const formSection = formVals.personalInformation;

      // custom questions
      const activeMilitaryQuestionNumber = '308';
      const militaryTuitionQuestionNumber = '89';
      const veteransAffairsQuestionNumber = '87';
      const greenCardsQuestionNumber = '310';
      const cityOfBirthQuestionNumber = '256';

      if (formSection.email && formSection.email !== '') {
        returnedApp.setApplicationEmail(formSection.email);
      }

      /* *** Phone stuff *** */
      if (hasValidPhone) {
        returnedApp.setApplicationPhone(formSection.phone_fields.completePhone);
      }
      if (formSection.phone_fields.cellPhoneRadio === 'Yes' && hasValidPhone) {
        const clPhone: Phone = new Phone();
        Object.assign(clPhone, formSection.phone_fields.completePhone);
        clPhone.type = 'CL';
        returnedApp.setApplicationPhone(clPhone);
      }

      // state disclosure
      const disclosureGroup = formSection.state_disclosure_group;
      if (disclosureGroup && disclosureGroup.state_disclosure_radio !== '') {
        returnedApp.setCustomAnswer(disclosureGroup.state_disclosure_radio, '228');
      }

      /* *** Name Fields *** */
      const lastNameChangeQuestionNumber = '304';
      const nameFields = formSection.name_fields;

      if (nameFields.lastNameChange && nameFields.lastNameChange !== '') {
        returnedApp.setCustomAnswer(nameFields.lastNameChange, lastNameChangeQuestionNumber);
      }
      if (nameFields.firstName) {
        returnedApp.firstName = nameFields.firstName.slice(0, 60) || '';
      }
      if (nameFields.lastName) {
        returnedApp.lastName = nameFields.lastName.slice(0, 60) || '';
      }
      if (nameFields.suffix) {
        returnedApp.suffix = nameFields.suffix.slice(0, 60) || '';
      }
      if (nameFields.middleName) {
        returnedApp.middleName = nameFields.middleName.slice(0, 60) || '';
      }
      if (nameFields.lastNameChange === 'Yes' && nameFields.multiLastName_selected_options && nameFields.multiLastName_selected_options.length > 0) {
        returnedApp.formerName = nameFields.multiLastName_selected_options.map(name => name.value).join(',').slice(0, 60);
      }


      /* *** Address Fields *** */
      const address_fields = formSection.address_fields;
      returnedApp.setCustomAnswer(address_fields.us_resident_radio, '303');

      const findState = (states: State[], stateName: string) => {
        return states.find(
          thisState => thisState.name.trim().toLowerCase() === stateName.trim().toLowerCase())
                    ?? states.find(
                      thisState => thisState.code.trim().toLowerCase() === stateName.trim().toLowerCase());
      };

      const countryCode: string = address_fields?.personal_info_country_code;
      const personalInfoState: string = address_fields?.personal_info_state?.toString();
      let userState = new State();

      if (countryCode?.trim().toUpperCase() === 'US' && personalInfoState) {
        userState = findState(States, personalInfoState);
      } else if (countryCode?.trim().toUpperCase() === 'AU' && personalInfoState) {
        userState = findState(AustraliaStates, personalInfoState);
      } else if (countryCode?.trim().toUpperCase() === 'CA' && personalInfoState) {
        userState = findState(CanadaStates, personalInfoState);
      }

      const theAddress = new Address();
      theAddress.sequenceNumber = '1';
      theAddress.type = 'AP';
      theAddress.address1 = address_fields?.street_address;
      theAddress.city = address_fields?.city;
      theAddress.state = userState?.code;
      theAddress.zip = address_fields?.zip_code?.substring(0, 30);
      theAddress.nationCode = countryCode;

      returnedApp.addresses = [theAddress];

      if (formSection.genderRadio && formSection.genderRadio !== '') {
        returnedApp.gender = formSection.genderRadio;
      }

      /* *** Parent guardian Fields *** */
      // if is Resident undergrad
      if (currentCampus === 'R' && levelCategory === ELevelCategory.Undergrad) {
        const guardianFields = formSection.relative_info;
        if (guardianFields) {
          if (guardianFields.guardian_relationship) {
            returnedApp.setCustomAnswer(guardianFields.guardian_relationship, '236');
          }
          if (guardianFields.first_name) {
            returnedApp.setCustomAnswer(guardianFields.first_name, '233');
          }
          if (guardianFields.last_name) {
            returnedApp.setCustomAnswer(guardianFields.last_name, '234');
          }
          if (guardianFields.email) {
            returnedApp.setCustomAnswer(guardianFields.email, '235');
          }
        }
      }


      // MM/DD/YYYY
      const birthDateFields = formSection.birth_date;
      if (birthDateFields.birth_day && birthDateFields.birth_day !== '' && birthDateFields.birth_month !== '' && birthDateFields.birth_year !== '') {
        const birthDayNumber: number = parseInt(birthDateFields.birth_day, 10);
        const birthDayString: string = birthDateFields.birth_day.toString();
        if (birthDayNumber && birthDayNumber < 10 && birthDayNumber > 0 && birthDayString.length < 2) {
          returnedApp.birthDate = birthDateFields.birth_month + '/0' + birthDayString + '/' + birthDateFields.birth_year;
        } else {
          returnedApp.birthDate = birthDateFields.birth_month + '/' + birthDayString + '/' + birthDateFields.birth_year;
        }
      }

      const raceArray = [];
      if (formSection.race_select_selected_options && formSection.race_select_selected_options.length > 0) {
        formSection.race_select_selected_options.forEach((race: Term) => {
          raceArray.push(race.value);
        });
        returnedApp.race = raceArray;
      }

      let ethnicityCategory: string | boolean;
      if (raceArray.includes('RF')) { // opt out
        ethnicityCategory = false;
      } else if (raceArray.includes('HO')) {
        ethnicityCategory = '2';
      } else {
        ethnicityCategory = '1';
      }
      if (ethnicityCategory) {
        returnedApp.ethnicityCategory = ethnicityCategory;
      }

      if (currentCampus === 'R' && formSection.maritalStatus && formSection.maritalStatus.length > 0) {
        returnedApp.maritalStatus = formSection.maritalStatus;
      }

      if (currentCampus === 'R' && formSection.religion_search && formSection.religion_search.religion_code && formSection.religion_search.religion_code.length > 0) {
        returnedApp.religion = formSection.religion_search.religion_code;
      }


      /* *** US Citizen Questions *** */
      // US Citizen
      const citizenshipFields = formSection.citizenshipGroup;

      if (citizenshipFields.citizenship) {
        returnedApp.citizenship = citizenshipFields.citizenship;
      }
      if (citizenshipFields.us_citizen_radio === 'Yes') {
        returnedApp.nation = 'US';
      } else if (citizenshipFields.nation_search_code && citizenshipFields.nation_search_code !== '') {
        returnedApp.nation = citizenshipFields.nation_search_code;
      }
      returnedApp.setCustomAnswer(citizenshipFields.green_card_radio, greenCardsQuestionNumber);

      if (citizenshipFields.us_citizen_radio === 'Yes' || citizenshipFields.green_card_radio === 'Yes') {
        returnedApp.setCustomAnswer(formSection.financial_aid, '305');
      }

      if (
        (citizenshipFields.us_citizen_radio === 'Yes'
          || citizenshipFields.us_citizen_radio === 'No' || citizenshipFields.green_card_radio === 'Yes')
        && formSection.ssn && formSection.ssn.length === 11
      ) {
        const strippedSsn = formSection.ssn.replace(/\D/g, '');
        if (strippedSsn.length === 9) {
          returnedApp.ssn = strippedSsn;
        }
      }

      if (
        citizenshipFields.green_card_radio === 'No' && currentProgram.programCode &&
        intlDisclaimerProgramCodes.some(programCodeEnding => currentProgram.programCode.includes(programCodeEnding))
      ) {
        // if true then capture value of international_matd_disclosure
        const matdDisclosureCheck = citizenshipFields.international_matd_disclosure_checkbox.international_matd_disclosure;
        const qVal = matdDisclosureCheck ? 'Yes' : 'No';
        returnedApp.setCustomAnswer(qVal, '320');
      }

      /*Resi Specific*/
      if (citizenshipFields.country_of_birth_search_code && citizenshipFields.country_of_birth_search_code !== '') {
        if (citizenshipFields.country_of_birth_search_code !== 'decline') {
          returnedApp.birthCountry = citizenshipFields.country_of_birth_search_code;
        }
      }
      if (citizenshipFields.city_of_birth && citizenshipFields.city_of_birth !== '') {
        returnedApp.setCustomAnswer(citizenshipFields.city_of_birth, cityOfBirthQuestionNumber);
      }

      if (citizenshipFields.visa_search_code && citizenshipFields.visa_search_code !== '' && citizenshipFields.visa_search_code.length > 0) {
        returnedApp.visa = new Visa(citizenshipFields.visa_search_code);
      }

      /* *** Military Questions *** */
      const militaryFields = formSection.military;

      if (militaryFields.active_military !== '') {
        returnedApp.setCustomAnswer(militaryFields.active_military, activeMilitaryQuestionNumber);
        if (militaryFields.active_military === 'Yes') {
          returnedApp.addAttribute('MAEL');
        }
      }
      if (militaryFields.military_status !== '') {
        returnedApp.addAcode('D85195');
        returnedApp.addAttribute(militaryFields.military_status);
      }
      if (militaryFields.military_branch !== '') {
        returnedApp.addAttribute(militaryFields.military_branch);
      }
      if (militaryFields.military_paygrade !== '') {
        returnedApp.addAttribute(militaryFields.military_paygrade);
      }
      if (militaryFields.military_tuition !== '') {
        returnedApp.setCustomAnswer(militaryFields.military_tuition, militaryTuitionQuestionNumber);
      }
      if (militaryFields.veterans_affairs_benefits !== '') {
        if (militaryFields.veterans_affairs_benefits === 'Yes') {
          returnedApp.addAttribute('VAEL');
        }
        returnedApp.setCustomAnswer(militaryFields.veterans_affairs_benefits, veteransAffairsQuestionNumber);
      } else {
        returnedApp.removeAttribute('VAEL');
      }

      /* *** College Credits *** */
      const collegeCreditsRadioNumber = '218';
      const collegeCertsNumber = '217';

      if (currentCampus === 'D' && (currentLevel === 'Bachelor' || currentLevel === 'Associate')) {
        returnedApp.setCustomAnswer(formSection.college_credits_radio, collegeCreditsRadioNumber);

        if (
          formSection.college_certs_group.college_certs_select
          && formSection.college_certs_group.college_certs_select.certs_selected_options
          && formSection.college_certs_group.college_certs_select.certs_selected_options.length > 0) {
          const certNames = [];

          formSection.college_certs_group.college_certs_select.certs_selected_options.forEach(cert => {
            certNames.push(cert.name);
          });

          returnedApp.setCustomAnswer(JSON.stringify(certNames).substr(0, 1999), collegeCertsNumber);

        } else {
          returnedApp.setCustomAnswer('', collegeCertsNumber);
        }
      } else {
        returnedApp.setCustomAnswer('', collegeCreditsRadioNumber);
        returnedApp.setCustomAnswer('', collegeCertsNumber);
      }


    }

    /* * * * * * AGREE AND SUBMIT SECTION * * * * * */
    if (formVals.agreeSubmit) {
      const formSection = formVals.agreeSubmit;

      const alcoholQuestionNumber = '4';
      const felonyQuestionNumber = '212';
      const dismissedQuestionNumber = '335';
      const probationQuestionNumber = '306';
      const alcoholTextQuestionNumber = '315';
      const felonyTextQuestionNumber = '316';
      const dismissedTextQuestionNumber = '336';
      const probationTextQuestionNumber = '314';

      const tShirtQuestionNumber = '20';
      const institutionalAgreementQuestionNumber1 = '202';
      const institutionalAgreementQuestionNumber2 = '203';
      const tcpaAgreementQuestionNumber = '223';
      const hearAboutUsQuestionNumber = '29';

      /***Personal History***/
      // Alcohol
      returnedApp.setCustomAnswer(formSection.alcoholRadio, alcoholQuestionNumber);
      if (formSection.textbox_alcohol) {
        returnedApp.setCustomAnswer(formSection.textbox_alcohol, alcoholTextQuestionNumber);
      }

      // Felon
      returnedApp.setCustomAnswer(formSection.felonRadio, felonyQuestionNumber);
      if (formSection.textbox_felon) {
        returnedApp.setCustomAnswer(formSection.textbox_felon, felonyTextQuestionNumber);
      }

      // Dismissal
      if (currentCampus === 'R' && formSection.dismissalRadio === 'Yes') {
        returnedApp.setCustomAnswer('Yes_SchoolName: ' + formSection.dismissal_school, dismissedQuestionNumber);
        returnedApp.setCustomAnswer(formSection.dismissal_textbox, dismissedTextQuestionNumber);
      } else {
        returnedApp.setCustomAnswer(formSection.dismissalRadio, dismissedQuestionNumber);
      }

      // Probation
      returnedApp.setCustomAnswer(formSection.probationRadio, probationQuestionNumber);
      if (formSection.textbox_probation) {
        returnedApp.setCustomAnswer(formSection.textbox_probation, probationTextQuestionNumber);
      }

      // Title IX
      returnedApp.setCustomAnswer(formSection.titleIXRadio, '327');

      /***Agreements and Additional Questions***/

      // T-Shirt Select
      returnedApp.setCustomAnswer(formSection.tshirtSelect, tShirtQuestionNumber);
      // Hear About Us
      returnedApp.setCustomAnswer(formSection.hear_about_us_select.hear_about_us_dropdown, hearAboutUsQuestionNumber);

      // Institutional Agreement
      if (formSection.institutional_agreement) {
        const iaCheckboxBool = formSection.institutional_agreement.institutional_agreement_check;
        const iaVal: string = iaCheckboxBool ? 'Yes' : 'No';
        // TODO: Do we need 2 custom questions for this?
        returnedApp.setCustomAnswer(iaVal, institutionalAgreementQuestionNumber1);
        returnedApp.setCustomAnswer(iaVal, institutionalAgreementQuestionNumber2);
      }

      // TCPA
      // const tcpaAgreementQuestionNumber_2 = '223'; // Can be NOEU
      if (formSection.tcpa_question.tcpa_radio_isEU === true && formSection.tcpa_question.tcpa_radio === 'No') {
        returnedApp.setCustomAnswer('NOEU', tcpaAgreementQuestionNumber);
      } else {
        returnedApp.setCustomAnswer(formSection.tcpa_question.tcpa_radio, tcpaAgreementQuestionNumber);
        // should add TX phone?
        if (formSection.tcpa_question.tcpa_radio === 'Yes' && hasValidPhone) {
          const txPhone: Phone = new Phone();
          Object.assign(txPhone, formVals.personalInformation.phone_fields.completePhone);
          txPhone.type = 'TX';
          returnedApp.setApplicationPhone(txPhone);
        }
      }

    }

    /* * * * * * DataFields * * * * * * */
    const vwoidCookieQuestionNumber = '329';
    if (formVals.data_fields) {
      const dataFields = formVals.data_fields;
      const vwoValues = dataFields.vwoCookies;
      returnedApp.setCustomAnswer(vwoValues.replace('\\', ''), vwoidCookieQuestionNumber);

      // capture if student has logged in
      const stuHasLoggedIn = dataFields.studentHasLoggedIn;
      if (stuHasLoggedIn && stuHasLoggedIn === true) {
        returnedApp.setCustomAnswer('Yes', '317')
      }

    }

    // Determine wapp code
    let wapp = formVals.yourDegree.wappCode;
    const degreeLevel = formVals.yourDegree.degree_level && formVals.yourDegree.degree_level.length > 0 ? formVals.yourDegreedegree_level : null;

    if (returnedApp.priorColleges.length > 0) {
      wapp = this.programService.gatherWappCode(currentLevelCode, currentLevel, degreeLevel, currentCampus, returnedApp.priorColleges, wapp);
    }

    if (wapp){
      returnedApp.wapp = wapp;
    }

    /* * * * * * OPTIONAL SECTION * * * * * */
    if (formVals.optional) {
      const essayQuestions = formVals.optional.optional_essay_questions;

      if (essayQuestions && currentCampus === 'R' && levelCategory === ELevelCategory.Undergrad) {
        const faithBeliefEssay = essayQuestions.beliefsEssay;
        if (faithBeliefEssay && faithBeliefEssay !== '') {
          returnedApp.setCustomAnswer(faithBeliefEssay, '247');
        }

        const missionEssay = essayQuestions.missionEssay;
        if (missionEssay && missionEssay !== '') {
          returnedApp.setCustomAnswer(missionEssay, '248');
        }
      }
    }

    /* * * * * * ENROLLMENT DEPOSIT SECTION * * * * * */
    if (formVals.enrollmentDeposit) {


    }

    if (formVals.agent_notepad) {
      returnedApp.setCustomAnswer(formVals.agent_notepad, '60');
    }

    returnedApp = this.truncateSchoolNames(returnedApp);

    resolve(returnedApp);
  })


  public checkForApplicationFeeWaivers(colleges: College[]): boolean {
    let hasWaiver = false;
    for (const college of colleges as College[]) {
      if (this.schoolService.isVaCommunityCollege(college)) {
        hasWaiver = true;
      }
    }
    return hasWaiver;
  }


  public truncateSchoolNames(application: Application) {

    // sometimes when prefill happens the colleges aren't actually saved as colleges
    const theColleges = application.priorColleges;
    for (let i = 0; i < theColleges.length; i++) {
      const college = theColleges[i];
      if (!(college instanceof College)) {
        const makeItCollegeAgain = new College();
        Object.assign(makeItCollegeAgain, college);
        theColleges[i] = makeItCollegeAgain;
      }
    }

    theColleges.forEach(college => {
      const collegeName = college.getName();
      if (collegeName) {
        college.setName(collegeName.substr(0, 80));
      }
      if (college.description) {
        delete college.description;
      }
      if (college.schoolSearchString) {
        delete college.schoolSearchString;
      }
    });
    application.highSchools.forEach(highSchool => {
      const hsName = highSchool.getName();
      if (hsName) {
        highSchool.setName(hsName.substr(0, 35));
      }
      if (highSchool.description) {
        delete highSchool.description;
      }
      if (highSchool.schoolSearchString) {
        delete highSchool.schoolSearchString;
      }
    });

    return application;
  }


}

<ng-container *ngIf="formGroup.get('probationRadio').enabled" [formGroup]="formGroup">

  <div class="form-control">

    <app-radio
      [radioOpts]="yesNoRadioOpts"
      [radioFormControl]="formGroup.get('probationRadio')"
      [label]="'Have you ever been dismissed or placed on disciplinary probation?'"
      [errorMessage]="'Indicate if you have been dismissed or placed on probation'">
    </app-radio>

    <app-textbox
      *ngIf="formGroup.get('probationRadio').value === 'Yes'"
      [formGroup]="formGroup"
      [nameForControl]="'textbox_probation'"
      [required]="true"
      [requiredErrorMessage]="'Provide a reason for your probation'"
      [textboxHeader] ="textboxHeader"
      [textboxError]="'Provide the details asked for above regarding dismissal of probation'"
    >
    </app-textbox>

  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousBalanceComponent } from './previous-balance.component';
import { AlertModule } from 'src/app/alert/alert.module';
import { RadioModule } from '../generic/radio/radio.module';

@NgModule({
  declarations: [
    PreviousBalanceComponent
  ],
  imports: [
    CommonModule,
    AlertModule,
    RadioModule
  ],
  exports: [
    PreviousBalanceComponent
  ]
})
export class PreviousBalanceModule { }

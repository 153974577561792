<div [formGroup]="formGroup" [ngClass]="{'hidden': formGroup.get('college_credits_radio').disabled}">

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('college_credits_radio')"
    [label]="'Have you taken any exams where college credit was awarded (CLEP, DANTES, etc..)?'"
  >
  </app-radio>

</div>

<app-radio
  [label]="'Do you owe a balance that could prevent you from obtaining your official transcripts?'"
  [radioOpts]="balanceRadioOptions"
  [radioFormControl]="formGroup.get('previous_balance')"
  [errorMessage]="'Indicate if you have a previous balance'"
></app-radio>

<app-alert class="mb-3 mt-3" type="generic" *ngIf="formGroup.get('previous_balance').value === 'Yes'" role="alert" aria-live="assertive" aria-describedby="alert-need-official-transcript">
  {{ALERT_NEED_OFFICIAL_TRANSCRIPT}}</app-alert>
<span hidden="true" id="alert-need-official-transcript">{{ALERT_NEED_OFFICIAL_TRANSCRIPT}}</span>

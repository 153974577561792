import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html',
  styleUrls: ['./sidebar-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarLogoComponent {

  constructor() {
  }

}

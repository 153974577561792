import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, OperatorFunction, Subject} from 'rxjs';
import {map, timeout} from 'rxjs/operators';
import {College} from '../model/application/college.model';
import {HighSchool} from '../model/application/highschool.model';
import {School} from '../model/application/school';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  public isHomeSchool = new Subject<boolean>();
  private readonly VA_COMMUNITY_COLLEGE_CEEB_CODES = new Array<string>(
    '005083', '005141', '005139', '005163', '005844', '005276', '005676', '005342', '005381', '005451',
    '005513', '005510', '005515', '005517', '005774', '005775', '005549', '005557', '005561', '005590',
    '005660', '005659', '005793', '005787', '005032', '005226', '005707', '005927', '005868', '050917'
  );
  private allHighSchools: HighSchool[] = [];
  private allColleges: College[] = [];
  private allSchools: (College | HighSchool)[] = [];

  public collegeGroups = ['bachelorsSearch', 'mastersSearch', 'doctoralSearch', 'multiCollegeSearch'];

  constructor(
    private http: HttpClient
  ) {
  }

  public generateSchools() {

    if (this.allHighSchools.length === 0) {
      this.http.get<School[]>(`/rest/schools?type=HIGH_SCHOOL`)
        .pipe(
          timeout(10000),
          this.convertToObjects(HighSchool)
        )
        .subscribe((highschools: HighSchool[]) => {

          this.allHighSchools = highschools;
          this.tryCompileAllSchools();

        }, () => {
          // load backup json file
          this.http.get('assets/cache/highschools.json').pipe(
            this.convertToObjects(HighSchool)
          ).subscribe((highschools: HighSchool[]) => {

            this.allHighSchools = highschools;
            this.tryCompileAllSchools();

          });
        });
    }

    if (this.allColleges.length === 0) {
      this.http.get<School[]>(`/rest/schools?type=COLLEGE`)
        .pipe(
          timeout(10000),
          this.convertToObjects(College)
        )
        .subscribe((colleges: College[]) => {

          this.allColleges = colleges;
          this.tryCompileAllSchools();

        }, () => {
          // load backup json file
          this.http.get('assets/cache/colleges.json').pipe(
            this.convertToObjects(College)
          ).subscribe((colleges: College[]) => {

            this.allColleges = colleges;
            this.tryCompileAllSchools();

          });
        });
    }

  }

  tryCompileAllSchools() {
    if (this.allColleges.length > 0 && this.allHighSchools.length > 0 && this.allSchools.length === 0) {
      this.allSchools = [...this.allColleges, ...this.allHighSchools];
    }
  }

  public returnHighSchools(): HighSchool[] {
    return this.allHighSchools;
  }

  public returnColleges(): College[] {
    return this.allColleges;
  }

  public getSchoolByCeebCode(ceebCode: string): (College | HighSchool) {
    return this.allSchools.find((thisSchool: College | HighSchool) => thisSchool.ceebCode === ceebCode);
  }

  public isVaCommunityCollege(college: College): boolean {
    return college
      ? this.VA_COMMUNITY_COLLEGE_CEEB_CODES.includes(college.ceebCode)
      : false;
  }

  public containsVaCommunityCollege(colleges: Array<College>): boolean {
    if (!colleges) {
      return false;
    }

    let isVaCommunityCollege = false;
    colleges.forEach(college => {
      if (this.isVaCommunityCollege(college)) {
        isVaCommunityCollege = true;
      }
    });

    return isVaCommunityCollege;
  }

  private convertToObjects(type: any, byType: boolean = false): OperatorFunction<any[], any[]> {
    return map((response) => {
      if (!response || response.length === 0) {
        throw Error();
      }
      return response.map((object: any) => {
        if (byType) {
          if (object.type === 'COLLEGE') {
            return new College(object);
          } else if (object.type === 'HIGH_SCHOOL') {
            return new HighSchool(object);
          }
        } else {
          return new type(object);
        }
      });
    });
  }

}

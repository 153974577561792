import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InitService} from './init.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private http: HttpClient,
    private initService: InitService,
    private router: Router
  ) {
  }

  async getToken() {
    const getTokenRequest = this.http.get<void>('/rest/token');
    await getTokenRequest
        .toPromise()
        .then(async () => {
          await this.initService.init();
        })
        .catch(() => {
            this.router.navigateByUrl('/maintenance');
          });
  }

}

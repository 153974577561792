import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { Application } from 'src/app/shared/model/application.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-object-toggle',
  templateUrl: './app-object-toggle.component.html',
  styleUrls: ['./app-object-toggle.component.scss']
})
export class AppObjectToggleComponent implements OnInit {

  isProd = environment.envType === 'prod';
  isActive = false;
  application = new Application();

  @Input() passedForm = new FormGroup({});
  parsedForm = '';

  constructor(
    public applicationService: ApplicationService
  ) { }

  ngOnInit() {

    this.passedForm.valueChanges
      .pipe(
        distinctUntilChanged(), // this will prevent it if values aren't changed
        debounceTime(1000), // also give a pause between valueChanges before we send the update to prevent this from being spammed
        switchMap((theForm) => {
          return of(theForm);
        })
      )
     .subscribe(() => {
      this.parsedForm = '<ul class="no-bullets">';
      this.iterateOverGroup(this.passedForm.getRawValue(), {});
      this.parsedForm += '</ul>';
    });

    this.applicationService.updateSent.subscribe(application => {
      this.application = application;
    });

  }

  isObject(object): boolean {
    return typeof object === 'object' && !Array.isArray(object);
  }

  isArray(object): boolean {
    return !!(typeof object === 'object' && Array.isArray(object));
  }

  getTypeof(val): string { return typeof val; }

  iterateOverGroup(group: { [key: string]: any }, returnedObject: {}) {
    Object.keys(group).forEach((key: string) => {

      const subGroup = group[key];

      this.parsedForm += `<li data-group="${key}">${key}: {`;

      if (typeof subGroup === 'object' && subGroup !== null) {
        // recursively calls itself to get to the bottom level FormControl nested inside as many FormGroup as needed
        this.parsedForm += '<ul style="padding-left: 15px;">';
        this.iterateOverGroup(subGroup, returnedObject);
        this.parsedForm += '</ul>';
      } else {
        if (subGroup ===  null) {
          this.parsedForm += `<span data-key="${key}" data-value="">" "</span>`;
        } else {
          this.parsedForm += `"<span data-key="${key}" data-value="${subGroup}">${subGroup === '' ? ' ' : subGroup}</span>"`;
        }
      }

      this.parsedForm += `},</li>`;
    });
    return returnedObject;
  }


}

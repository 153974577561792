<ng-container [formGroup]="formGroup">
  <div class="row">
    <div class="col form-control relationship-wrapper">
      <label for="guardian_relationship">
        <span class="input-label-text">Relationship to applicant</span>
      </label>
      <div class="select-input-wrap">
        <select name="guardian_relationship" id="guardian_relationship" formControlName="guardian_relationship">
          <option value="" disabled="true" selected="true">Select</option>
          <option *ngFor="let relationship of relationships " value="{{relationship.value}}">{{relationship.name}}</option>
        </select>
        <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
      </div>

    </div>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Student } from '../model/student.model';
import { AuthenticationService } from './authentication.service';
import { AppIdService } from '../provider/app-id.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private restDest = '/rest';

  public emitStudent = new Subject<Student>();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private appIdService: AppIdService
  ) { }

  public luidSearch(luid: string): Observable<Student> {
    const agentUsername = this.authService.getUsername();

    return this.http.post<Student>(this.restDest + '/students/' + luid, agentUsername);
  }

  public studentLuidSearch(): Observable<Student> {
    return this.http.post<Student>(this.restDest + '/student/' + this.appIdService.getAppId(), {});
  }

}

// tslint:disable: variable-name
export class FlightSchool {
  public institutionCode: string;
  public attributeCode: string;
  public combinedDescription: string;
  public approved: string;

  constructor(
  ) {
  }
}

<div class="prefill" [ngClass]="{'show': prefilling}"><h2>Loading ...</h2></div>

<app-environment-warning></app-environment-warning>

<form [formGroup]="applicationForm" class="mb-5">

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'agentQuestions',
      'hide' : activeSectionName !== 'agentQuestions'
    }"
    class="col form-group form-section"
    id="agentQuestions"
    formGroupName="agentQuestions"
    role="tabpanel"
    id="agentQuestions-tab"
    [attr.aria-labelledby]="'agentQuestions'"
  >

    <!-- <app-form-error [formSection]="'agentQuestions'" [formGroup]="applicationForm.get('agentQuestions')"></app-form-error> -->

    <h1 tabindex="0" class="agentQuestions screen-reader-only">Agent Questions</h1>

    <h2>Agent Questions</h2>

    <!--suppress TypeScriptValidateTypes -->
    <app-agent-user [formGroup]="applicationForm.get('agentQuestions') //noinspection ValidateTypes"></app-agent-user>

    <hr>

    <app-acode-search [formGroup]="applicationForm.get('agentQuestions').get('agent_acode_search')"></app-acode-search>

    <app-email-dup-checker (emailAddressNuVal)="updateEmailAddress($event)" [formGroup]="applicationForm.get('agentQuestions')"></app-email-dup-checker>

    <app-luid-search class="form-control" [formGroup]="applicationForm.get('agentQuestions')"></app-luid-search>

    <app-applicant-goals [formGroup]="applicationForm.get('agentQuestions').get('applicant_goals_group')"></app-applicant-goals>

    <app-application-origin [formGroup]="applicationForm.get('agentQuestions')" ></app-application-origin>

    <app-seeking-pla [formGroup]="applicationForm.get('agentQuestions')"></app-seeking-pla>

    <app-continue-button (click)="navigationService.emitActiveSectionNext('yourDegree')"></app-continue-button>

  </div>

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'yourDegree',
      'hide' : activeSectionName !== 'yourDegree'
    }"
    class="col form-group form-section col-no-gutter"
    id="yourDegree"
    formGroupName="yourDegree"
    role="tabpanel"
    id="yourDegree-tab"
    [attr.aria-labelledby]="'yourDegree'"
  >

    <h1 tabindex="0" class="yourDegree screen-reader-only">Your Degree</h1>

    <app-decorative-card>
      <h2>Welcome to your Liberty University application</h2>
      <p>You're only a few steps away from pursuing your dreams!</p>
    </app-decorative-card>

    <app-location-radio [formGroup]="applicationForm.get('yourDegree')" class="mt-5"></app-location-radio>

    <app-degree-level-radio [formGroup]="applicationForm.get('yourDegree')"
    [ngClass]="{'hidden' : applicationForm.get('yourDegree').get('campus').value === '' }"></app-degree-level-radio>

    <span id="degree-picker-anchor"></span>

    <app-degree-picker [formGroup]="applicationForm.get('yourDegree')"
    [ngClass]="{'hidden' : applicationForm.get('yourDegree').get('degree_level').value === '' }"></app-degree-picker>

    <app-custom-program-questions [ngClass]="{'hidden' : !programService.getCurrentProgram().programCode}" [formGroup]="applicationForm.get('yourDegree').get('custom_program_questions')" ></app-custom-program-questions>

    <app-start-term-select [ngClass]="{'hidden' : !programService.getCurrentProgram().programCode}" [formGroup]="applicationForm.get('yourDegree')"></app-start-term-select>

    <app-continue-button (click)="navigationService.emitActiveSectionNext('previousEducation')"></app-continue-button>

  </div>

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'previousEducation',
      'hide' : activeSectionName !== 'previousEducation'
    }"
    class="form-group col form-section"
    id="previousEducation"
    formGroupName="previousEducation"
    role="tabpanel"
    id="previousEducation-tab"
    [attr.aria-labelledby]="'previousEducation'"
  >

    <app-previous-education
    (emitHsDiplomaFName)="hsDiplomaFNameUpdated($event)"
    (emitHsDiplomaLName)="hsDiplomaLNameUpdated($event)"
    [formGroup]="applicationForm.get('previousEducation')"
    >
    </app-previous-education>

    <app-continue-button
      *ngIf="programService.getCurrentProgram().programCode !== ''"
      tabindex="0"
      class="mt-3"
      (click)="navigationService.emitActiveSectionNext('personalInformation')"
    ></app-continue-button>

  </div>

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'personalInformation',
      'hide' : activeSectionName !== 'personalInformation'
    }"
    class="col form-group form-section"
    id="personalInformation"
    formGroupName="personalInformation"
    role="tabpanel"
    id="personalInformation-tab"
    [attr.aria-labelledby]="'personalInformation'"
  >

    <h1 tabindex="0" class="personalInformation screen-reader-only">Personal Information</h1>

    <app-personal-information [formGroup]="applicationForm.get('personalInformation')"></app-personal-information>

    <app-continue-button
      class="mt-3"
      (click)="navigationService.emitActiveSectionNext('agreeSubmit')"
      [attr.disabled]="navigationService.sectionIsDisabled('agreeSubmit') ? 'disabled' : null"
    ></app-continue-button>

  </div>

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'agreeSubmit',
      'hide' : activeSectionName !== 'agreeSubmit'
    }"
    class="col form-group form-section"
    id="agreeSubmit"
    formGroupName="agreeSubmit"
    role="tabpanel"
    id="agreeSubmit-tab"
    [attr.aria-labelledby]="'agreeSubmit'"
  >

    <h1 tabindex="0" class="agreeSubmit screen-reader-only">Agree and Submit</h1>

    <app-form-error *ngIf="formService.submitted && activeSectionName === 'agreeSubmit'" [formGroup]="applicationForm"></app-form-error>

    <fieldset>
      <legend>
        <h2 class="large-header d-inline-block">Agree & Submit</h2>
      </legend>
      <app-summary [selectedProgram]="programService.getCurrentProgram()"></app-summary>

      <app-hear-about-us-dropdown [formGroup]="applicationForm.get('agreeSubmit').get('hear_about_us_select')"></app-hear-about-us-dropdown>

      <app-probation-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-probation-radio>

      <app-alcohol-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-alcohol-radio>

      <app-dismissal-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-dismissal-radio>

      <app-felon-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-felon-radio>

      <app-title-ix [formGroup]="applicationForm.get('agreeSubmit')"></app-title-ix>

      <app-tshirt [formGroup]="applicationForm.get('agreeSubmit')"></app-tshirt>

      <app-institutional-agreement [formGroup]="applicationForm.get('agreeSubmit')" [isAgent]="true"></app-institutional-agreement>

      <app-tcparadio [formGroup]="applicationForm.get('agreeSubmit').get('tcpa_question')"></app-tcparadio>
    </fieldset>


    <div class="form-success alert alert-danger mt-3" *ngIf="!applicationService.submitting && applicationService.submitted && !applicationService.submitSuccess">
      Application Submission Error: Unable to reach ApplyLU API (Reach out to Support with AppId #{{appIdService.getAppId()}})
    </div>

    <div style="position: relative;">
      <button
        *ngIf="navigationService.checkForEnrollmentDeposit"
        type="button"
        tabindex="0"
        (click)="navigationService.emitActiveSectionNext('enrollmentDeposit')"
        class="enrollmentDeposit continueButton"
        [attr.disabled]="navigationService.sectionIsDisabled('enrollmentDeposit') ? 'disabled' : null"
      >
      Continue to Enrollment Deposit
      </button>

      <button
        type="button"
        class="continueButton"
        (click)="onSubmit()"
        [attr.disabled]="applicationService.submitting ? 'disabled' : null"
      >
        <span>
          Submit Application
          <app-icon *ngIf="!applicationService.submitting" class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
          <app-loading-ring *ngIf="applicationService.submitting"></app-loading-ring>
        </span>
      </button>

  </div>

</div>

  <app-data-fields [formGroup]="applicationForm.get('data_fields')"></app-data-fields>

  <div
  *ngIf="navigationService.checkForEnrollmentDeposit"
  [ngClass]="{
      'displayed' : activeSectionName === 'enrollmentDeposit',
      'hide' : activeSectionName !== 'enrollmentDeposit'
    }"
  class="col form-group form-section"
  id="enrollmentDeposit"
  formGroupName="enrollmentDeposit"
  role="tabpanel"
  id="enrollmentDeposit-tab"
  [attr.aria-labelledby]="'enrollmentDeposit'"
>

  <h1 tabindex="0" class="enrollmentDeposit screen-reader-only">Enrollment Deposit</h1>

  <app-form-error *ngIf="formService.submitted && activeSectionName === 'enrollmentDeposit'" [formGroup]="applicationForm"></app-form-error>

  <app-enrollment-deposit-radio *ngIf="formService.getCurrentCampus() === 'R' && programService.isUndergrad(programService.getCurrentProgram())"
    [formGroup]="applicationForm.get('enrollmentDeposit').get('enrollment_deposit_question')"
    (depositAnswer)="onDepositAnswer($event)"></app-enrollment-deposit-radio>

  <app-refund-terms-radio *ngIf="formService.getCurrentCampus() === 'R' && programService.isUndergrad(programService.getCurrentProgram()) && isEnrollmentDepositSelected"
    [formGroup]="applicationForm.get('enrollmentDeposit').get('refund_terms_question')"
    (refundTermsAnswer)="onRefundTermsAnswer($event)"></app-refund-terms-radio>

  <button
    type="button"
    class="continueButton"
    (click)="submitED()"
  >
    <span>
      <span *ngIf="isRefundTermsAccepted">Pay ED & Submit App</span>
      <span *ngIf="!isRefundTermsAccepted">Submit Application</span>
      <app-icon *ngIf="!applicationService.submitting" class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
      <app-loading-ring *ngIf="applicationService.submitting"></app-loading-ring>
    </span>
  </button>


</div>

<app-payment-form></app-payment-form>

<app-agent-notepad class="mt-4 d-block" [formGroup]="applicationForm"></app-agent-notepad>

</form>

<app-little-popup></app-little-popup>

<app-app-object-toggle class="col" [passedForm]="applicationForm"></app-app-object-toggle>

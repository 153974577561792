import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IncompleteApp} from '../model/applyLUApiObjects/incomplete-app.model';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IncompleteAppService {

  private applicationBaseUrl = '/rest/applications';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
  }

  getAppById(id: string): Observable<IncompleteApp | HttpErrorResponse> {
    const agentUsername = this.authService.getUsername();

    return this.http.post<IncompleteApp | HttpErrorResponse>(`${this.applicationBaseUrl}/get/${id}`, agentUsername);
  }

  getAppByEmailPhone(emailPhone: { email: string, phone: string }): Observable<Array<IncompleteApp | HttpErrorResponse>> {
    return this.http.post<Array<IncompleteApp | HttpErrorResponse>>(`${this.applicationBaseUrl}/lookup`, emailPhone);
  }

  getIncompleteAppByResumeKey(appId: string, resumeKey: string): Observable<IncompleteApp | HttpErrorResponse> {
    return this.http.post<IncompleteApp | HttpErrorResponse>(`${this.applicationBaseUrl}/resume/${appId}`, resumeKey);
  }

  getThankYouAppByResumeKey(appId: string, resumeKey: string): Observable<IncompleteApp | HttpErrorResponse> {
    return this.http.post<IncompleteApp | HttpErrorResponse>(`${this.applicationBaseUrl}/thank-you/${appId}`, resumeKey);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {StyledRadioComponent} from './styled-radio.component';

@NgModule({
  declarations: [StyledRadioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [
    StyledRadioComponent
  ]
})
export class StyledRadioModule { }

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {RadioOpts} from 'src/app/shared/model/radio-options.model';
import {FormService} from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-previous-balance',
  templateUrl: './previous-balance.component.html',
  styles: ['app-alert { display: block}']
})
export class PreviousBalanceComponent implements OnInit, OnDestroy {

  private static readonly PREVIOUS_BALANCE_CONTROL_NAME = 'previous_balance';

  readonly ALERT_NEED_OFFICIAL_TRANSCRIPT = 'Students must be able to obtain and submit official transcripts from prior institutions or official transcripts must be available for release (no prior balance) to Liberty through the transcript request form before an admission decision will be made.\n';

  @Input() formGroup: FormGroup<any> = new FormGroup({});


  balanceRadioOptions: RadioOpts[] = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
  ];

  constructor(
    public formService: FormService
  ) {
  }


  ngOnInit(): void {
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).enable();
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).setValidators([Validators.required]);
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).updateValueAndValidity();
  }

  ngOnDestroy() {
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).disable();
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).clearValidators();
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).setValue('');
    this.formGroup.get(PreviousBalanceComponent.PREVIOUS_BALANCE_CONTROL_NAME).updateValueAndValidity();
  }
}

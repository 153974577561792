import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import { CompletedBannerApp } from '../model/applyLUApiObjects/completed-banner-app.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CompletedApplicationsService {

  private applicationBaseUrl = '/rest/completedapplications';
  currentEmail = '';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { }


  getCompletedBannerAppByEmail(email: string): Observable<CompletedBannerApp[] | HttpErrorResponse> {

    // Prevent the request from sending if the email hasn't changed.
    if (this.currentEmail !== email) {
      this.currentEmail = email;
      const agentUsername = this.authService.getUsername();
      // the trailing / in the API request path is required, will break if removed
      return this.http.post<CompletedBannerApp[] | HttpErrorResponse>(`${this.applicationBaseUrl}/email/${email}/`, agentUsername);
    } else {
    /*  If we hit this block, it means the email has not changed, thus we don't want to send the request to banner.
        We then return an empty observable that emits a complete notification.*/
      return EMPTY;
    }
  }
}

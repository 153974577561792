<ng-container [formGroup]="getFormControlParent()">

  <div id="{{getFormControlName()}}"></div>

  <input
    [ngClass]="{'hidden': !activateAutocomplete || regressionParam}"
    maxlength="50"
    type="text"
    google-places
    #autocompleteInput
    #placesRef="ngx-google-places"
    formControlName="{{getFormControlName()}}"
    (onGetResults)="displayResults($event)"
    autocomplete="off"
    (focusin)="searchInputIsFocused = true"
    (focusout)="searchInputIsFocused = false; highlightedPrediction = null"
    (change)="highlightedPrediction = null"
    role="combobox"
    [attr.aria-describedby]="formService.hasErrors(inputtedFormControl) ? 'street-address-error' : null"
    (keyup.arrowup)="prevResult()"
    (keyup.arrowdown)="nextResult()"
    (keyup.enter)="selectHighlightedPrediction()"

    [attr.aria-label]="highlightedPrediction ? highlightedPrediction['formatted_address'] : searchResultsString.length > 0 ? searchResultsString : null"
    [attr.aria-owns]="'places-autocomplete-results'"
    [attr.aria-autocomplete]="'both'"
    [attr.aria-activedescendant]="highlightedPrediction ? 'places-autocomplete-prediction-'+highlightedPrediction['formatted_address'] : ''"
  />

  <div class="predictions-container" [hidden]="predictions.length === 0 || searchInputIsFocused === false || searchString.length === 0"       id="places-autocomplete-results"
  >
    <div
      *ngFor="let prediction of predictions; let i = index"
      (mouseenter)="onPredictionHovered(i)"
      class="predictions-item"
      (mousedown)="selectPrediction(prediction)"
      [ngClass]="{'highlight': prediction == highlightedPrediction || isHover === i}"
      role="option"
      tabindex="-1"
      id="places-autocomplete-prediction-{{prediction.formatted_address}}"
    >
      <div class="marker"></div>
      <span [innerHTML]="prediction.formatted_address | highlight:searchString"></span>
    </div>
  </div>


  <input
  [ngClass]="{'hidden': activateAutocomplete && !regressionParam}"
  maxlength="50"
  type="text"
  name="{{getFormControlName()}}"
  formControlName="{{getFormControlName()}}"
  autocomplete="address-line1"
  [attr.aria-describedby]="formService.hasErrors(inputtedFormControl) ? 'street-address-error' : null"
/>

</ng-container>


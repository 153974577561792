<ng-container [formGroup]="formGroup">

  <app-radio
    [radioOpts]="genderRadioOpts"
    [radioFormControl]="formGroup.get('genderRadio')"
    [label]="'What gender are you?'"
    [errorMessage]="'Select a gender'"
  >
  </app-radio>

</ng-container>

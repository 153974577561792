<ng-container *ngIf="open">
<div [ngClass]="{'full-width' : goFullWidth}" class="alert-container" role="alert">
  <div
    [ngClass]="{
      'smaller' : smaller,
      'border-style': alertStyle === 'border',
      'larger': larger,
      'clean': clean
    }"
    class="alert alert-{{type}}"
  >
    <ng-container *ngIf="alertStyle === 'icon'">
      <div *ngIf="type === 'generic'" class="alert-icon" aria-hidden="true"><span>i</span></div>
      <div *ngIf="type === 'primary'" class="alert-icon" aria-hidden="true"><span>i</span></div>
      <div *ngIf="type === 'warning'" class="alert-icon" aria-hidden="true"><span>!</span></div>
      <div *ngIf="type === 'danger'" class="alert-icon" aria-hidden="true"><span>!</span></div>
      <div *ngIf="type === 'notice'" class="alert-icon-triangle" aria-hidden="true"><span>!</span></div>

      <app-icon *ngIf="type === 'success'" class="alert-icon" aria-hidden="true" [icon]="eIconTypes.Check"></app-icon>
    </ng-container>

    <ng-content></ng-content>
    <a *ngIf="hasCloseBtn" class="closeBtn" (click)="closeAlert()" (keyup.enter)="closeAlert()" tabindex="0" aria-label="Click to dismiss this alert">
    </a>
  </div>
</div>
</ng-container>

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Program } from '../model/program.model';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  returnActivatedRoute() {
    return this.route.snapshot.queryParams;
  }

  gatherUrlParams() {
    // gather any URL params from the current URL
    const returnedParams: { [key: string]: any } = {};
    Object.assign(returnedParams, this.returnActivatedRoute());
    return returnedParams;
  }

  getResumeLinkParams(): {appID: string, resumeKey: string} {
    if (
      this.route.snapshot.queryParams.r_k
      && this.route.snapshot.queryParams.r_k !== ''
      && this.route.snapshot.queryParams.a_i
      && this.route.snapshot.queryParams.a_i !== ''
    ) {
      return {appID: this.route.snapshot.queryParams.a_i, resumeKey: this.route.snapshot.queryParams.r_k};
    }
    return null;
  }

  navigate(path: string, programForThankYouParams?: Program, replaceURL = false) {
    if (programForThankYouParams) {

      let degreeVal = '';

      if (programForThankYouParams.displayDescription) {
        degreeVal = programForThankYouParams.displayDescription.replace(/\s/g, '');
      } else if (programForThankYouParams.programCode) {
        degreeVal = programForThankYouParams.programCode.replace(/\s/g, '');
      }

      const queryParams = {
        c: programForThankYouParams.campCode === 'D' ? 'Online' : 'Residential',
        degree: degreeVal
      };

      this.router.navigate([path], {
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: replaceURL // Add this option to replace current URL in history
      });
    } else {
      this.router.navigate([path], {queryParamsHandling: 'merge'});
    }
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-financial-aid',
  templateUrl: './financial-aid.component.html'
})
export class FinancialAidComponent implements OnInit, OnDestroy {

  @Input() formGroup = new FormGroup({});

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  endSubscriptions = new Subject<void>();

  constructor(
    public formService: FormService,
  ) { }

  ngOnInit() {

    this.formService.usCitizenSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

    this.formService.greenCardHolderSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeEnable() {
    // regardless of campus, either needs US Citizen yes or Green Card to be yes
    if (
      this.formService.isUsCitizen
      || (!this.formService.isUsCitizen && this.formService.greenCardHolder)
    ) {
      this.formGroup.get('financial_aid').enable();
    } else {
      this.formGroup.get('financial_aid').disable();
    }

  }

}

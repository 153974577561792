import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { DomService } from 'src/app/shared/service/dom.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-get-help-modal',
  templateUrl: './get-help-modal.component.html',
  styleUrls: ['../modal.component.scss', './get-help-modal.component.scss']
})
export class GetHelpModalComponent extends ModalComponent implements OnInit {

  formGroup: FormGroup<any>;
  currentSelection: string;

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public domService: DomService,
    public appIdService: AppIdService,
    public elm: ElementRef,
    private cdRef: ChangeDetectorRef,
    public renderer: Renderer2,
    public navigationService: NavigationService
  ) {
    super(modalService, applicationService, elm, cdRef, renderer, domService);
  }

  ngOnInit(): void {

    this.formGroup = new FormGroup({
      help_radio: new FormControl<string>('')
    });

    this.modalService.triggerGetHelpModal.pipe(takeUntil(this.endSubscriptions)).subscribe((bool: boolean) => {
      if (bool) {
        this.showModal();
      } else {
        this.closeGetHelpModal();
      }
    });

  }

  onHelpSelectionMade(selection: string) {
    this.currentSelection = selection;
  }

  openOnlineChat() {
    window.open('https://www.liberty.edu/enrollment/botredirect/?dept=luo', '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,width=450,height=700');
  }

  openResidentialChat() {
    window.open('https://www.liberty.edu/enrollment/botredirect/?dept=ResidentUG', '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,width=450,height=700');
  }

  public switchFocusAndEnter(selector: string, keyPressed: string) {
    if (keyPressed === 'ArrowRight') {
      ((selector as unknown as HTMLElement).parentElement.nextElementSibling.firstElementChild as HTMLElement).focus();
      ((selector as unknown as HTMLElement).parentElement.nextElementSibling.firstElementChild as HTMLElement).click();
    } else if (keyPressed === 'ArrowLeft') {
      ((selector as unknown as HTMLElement).parentElement.previousElementSibling.firstElementChild as HTMLElement).focus();
      ((selector as unknown as HTMLElement).parentElement.previousElementSibling.firstElementChild as HTMLElement).click();
    }
  }

  closeGetHelpModal() {
    this.closeModal();
    this.domService.selectElementAsRoot('app-right-sidebar div.sidebar-content app-get-help button.button').focus();
  }
}

<ng-container [formGroup]="formGroup">
  <div class="app-id-search-container mb-2">
    <div class="row">
      <div class="col">
        <label for="app_id_search_field">
          <span class="input-label-text">Previous App</span>
        </label>
      </div>
    </div>
    <div class="app_id_search row">
      <div class="col-sm-auto">
        <input
          type="search"
          name="app_id_search_field"
          id="app_id_search_field"
          formControlName="app_id_search"
          (keyup.enter)="getAppById($event)"
        >
      </div>
      <div class="col-sm-auto">
        <button tabindex="0" (click)="getAppById($event)" (keyup.enter)="getAppById($event)" type="button"
                class="appid btn btn-secondary">Search by AppID
        </button>
      </div>
    </div>
  </div>

  <div class="app-id-results mt-2" *ngIf="!preloaded && theApp && searched && searchSuccess && !isAppSubmitted">
    <p class="mb-2">
      Name: <b>{{theApp.firstName || ''}} {{theApp.middleName || ''}} {{theApp.lastName || ''}}</b><br>
      Email: <b *ngIf="theApp.emails && theApp.emails.length > 0">{{theApp.emails[0].emailAddress || ''}}</b><br>
      Phone: <b
      *ngIf="theApp.phones && theApp.phones.length > 0">{{theApp.phones[0].area || ''}} {{theApp.phones[0].phoneNumber || ''}}</b><br>
      Birthdate: <b>{{theApp.birthDate || ''}}</b><br>
      Gender: <b>{{theApp.gender || ''}}</b><br>
      Address: <b *ngIf="theApp.addresses && theApp.addresses.length > 0">{{theApp.addresses[0].address1 || ''}}<br>
      {{theApp.addresses[0].city || ''}}, {{theApp.addresses[0].state || ''}} {{theApp.addresses[0].zip || ''}}</b>
    </p>
    <button class="btn btn-primary btn-small" id="preload-by-id" (click)="prefillEvent.emit(true); prefillForm(theApp)">
      Preload App With This ID
    </button>
  </div>

  <app-alert type="primary" *ngIf="searching">Searching... <img src="assets/icons/loading-icon.png" class="rotation"
                                                                style="width: 20px;" alt="Loading icon"></app-alert>

  <app-alert type="danger" *ngIf="searched && !searchSuccess && !searching">{{ errorMessage }}</app-alert>

  <app-alert type="danger" *ngIf="searched && searchSuccess && !searching && isAppSubmitted"> This application has
    already successfully posted.
  </app-alert>

  <app-alert type="success" *ngIf="preloaded" text="messageText">Information Preloaded Successfully</app-alert>
</ng-container>

<ng-container *ngIf="isModalDisplayed">
  <dialog class="resume modal-container" aria-modal="true" role='alertdialog' #modalContainer>
    <div class="modalContent">

      <div class="text-center mb-4">
        <h1>Review your information</h1>
        <p class="p-please-review">Please review your info below and make any updates before continuing your application.</p>

        <span class="link mt-2" (click)="triggerLogout()"><h5>Not you? Click here to select an account to log out of.</h5></span>
      </div>

      <form [formGroup]="studentForm">

        <app-name-fields-first-mid-last [formGroup]="studentForm.get('name_fields')"
          [fieldsetLegend]="'Full legal name'"></app-name-fields-first-mid-last>

        <app-mini-alert *ngIf="!env.isAgent && authenticationService.isLoggedIn() && displayNameFieldsMiniAlert">In order to properly update this information, you will be asked to submit two forms of identification after you have submitted your application.</app-mini-alert>

        <app-gender-select [formGroup]="studentForm"></app-gender-select>

        <app-birth-date [formGroup]="studentForm.get('birth_date')"></app-birth-date>

        <app-mini-alert *ngIf="!env.isAgent && authenticationService.isLoggedIn() && displayBdayFieldsMiniAlert">In order to properly update this information, you will be asked to submit two forms of identification after you have submitted your application.</app-mini-alert>

        <div class="row less-padding">

          <app-email-address class="col-sm-7" [formGroup]="studentForm"></app-email-address>

          <app-phone-number class="col-sm-5" [formGroup]="studentForm.get('phone_fields')"></app-phone-number>

        </div>

        <app-full-address [showResideRadio]="false" [activateAutoComplete]="false" [formGroup]="studentForm.get('address_fields')"></app-full-address>

      </form>

      <div class="btn-container text-center">
        <button class="btn" (click)="confirmStudentData()"> Save &amp; Continue
          <app-icon class="continueArrow" aria-hidden="true" [icon]="'side-white-arrow'"></app-icon>
        </button>
      </div>

    </div>
  </dialog>
  <div class="modalOverlay" tabindex="-1"></div>
</ng-container>

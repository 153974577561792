<ng-container [formGroup]="formGroup">
  <div [hidden]="!matchFound" class="row">
    <div class="col">
        <app-alert [type]="alertType">
          <span [innerHTML]="responseMessage"></span>
          <br><br>
          <span *ngIf="showRadio === 'N'">
            Based on this information, please select an
            <a href="#" (click)="navigateToDegreePicker()">alternative program</a>
            before continuing.
          </span>

          <app-radio
            *ngIf="showRadio !== 'N'"
            class="mt-2"
            [radioOpts]="disclosureRadioOpts"
            [radioFormControl]="formGroup.get('state_disclosure_radio')"
            [errorMessage]="'Indicate if you agree to the Program/State disclosure'"
          >
          </app-radio>

        </app-alert>
    </div>
  </div>
  <input type="hidden" formControlName="state_disclosure">
</ng-container>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SchoolSearchFieldsetComponent} from './school-search-fieldset.component';
import {SchoolSearchInputModule} from '../../school-search-input/school-search-input.module';
import {IconModule} from 'src/app/shared/component/icon/icon.module';
import {StateSearchModule} from '../../state-search/state-search.module';
import {SearchableSelectDropDownModule} from '../../searchable-select-drop-down/searchable-select-drop-down.module';
import {GenericInputModule} from '../../generic-input.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    SchoolSearchFieldsetComponent
  ],
  imports: [
    CommonModule,
    SchoolSearchInputModule,
    ReactiveFormsModule,
    IconModule,
    GenericInputModule,
    SearchableSelectDropDownModule,
    StateSearchModule,
  ],
  exports: [
    SchoolSearchFieldsetComponent
  ]
})
export class SchoolSearchFieldsetModule {
}

<ng-container *ngIf="isModalDisplayed">
  <dialog class="modal-container" #modalContainer>
    <div class="modalContent text-center">

      <app-icon class="megaphone-icon" [icon]="'megaphone'"></app-icon>

      <h2>Welcome back!</h2>

      <div class="text-left">
        <p><b>Our application has had a makeover since you were last here.</b></p>
        <p>Please submit a new application.</p>
        <p><b>You're just a couple steps away from applying to Liberty University!</b></p>
      </div>

      <button class="btn mt-3" (click)="closeModal()"> Start Application
        <app-icon class="continueArrow" aria-label="hidden" [icon]="'side-white-arrow'"></app-icon>
      </button>

    </div>

</dialog>
<div class="modalOverlay" (click)="closeModal()" tabindex="-1"></div>
</ng-container>


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG} from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v2-samples/angular11-sample-app/src/app/app.module.ts

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {

  const redirectUri = window.location.origin + (environment.isAgent ? '/agent' : '/login');

  return new PublicClientApplication({
    auth: {
      clientId: environment.azureClientId,
      authority: `https://login.microsoftonline.com/${environment.azureTenantId}`,
      redirectUri: redirectUri,
      postLogoutRedirectUri: redirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      // loggerOptions: {
      //   loggerCallback,
      //   logLevel: LogLevel.Info,
      //   piiLoggingEnabled: false
      // }
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {


  const protectedResourceMap = new Map([
    [environment.api + '/students', [environment.scope]],
    [environment.api + '/student', [environment.scope]],
    [environment.api + '/applications/get', [environment.scope]],
    [environment.api + '/completedapplications/email', [environment.scope]],
    [environment.api + '/applications/lookup', [environment.scope]],
    [environment.api + '/rest/upay-forms', [environment.scope]],
    [environment.api + '/applications/**/agent', [environment.scope]],
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/unauth'
  };
}


@Injectable({
  providedIn: 'root'
})
  @NgModule({
    imports: [
      MsalModule
    ],
    providers: [
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService
    ],
    bootstrap: [MsalRedirectComponent]
  })
export class ApplyluMsalModule { }

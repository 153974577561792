import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TermService {

  constructor() { }

  possibleLevels = ['GR', 'UG'];

  public getStartTermTimestamp(startTerm: string): number {
    if (!startTerm) {
      startTerm = '201920'; // set one in the past
    }
    const startYear = startTerm.substring(4, 0);
    let startMonth = startTerm.substring(4, 6);
    // convert 2 digit startMonth code to actual 2 digit month
    switch (startMonth) {

      // case '10': // winter
      //   startMonth = '12'; // December
      //   break;

      case '20': // spring
        startMonth = '01'; // January
        break;

      case '30': // summer
        startMonth = '05'; // May
        break;

      case '40': // fall
        startMonth = '08'; // August
        break;

      default: // in case its broken
        startMonth = '08'; // August
        break;
    }

    const datum = new Date(Date.UTC(parseInt(startYear, 10), parseInt(startMonth, 10), 15));
    return datum.getTime();
  }

  public getEndTermTimestamp(endTerm: string): number {
    if (!endTerm || endTerm === '999999') {
      return new Date().getTime() * 1000; // set one in the future
    }
    const endYear = endTerm.substring(4, 0);
    let endMonth = endTerm.substring(4, 6);
    // convert 2 digit startMonth code to actual 2 digit month
    switch (endMonth) {

      // case '10': // winter
      //   startMonth = '12'; // December
      //   break;

      case '20': // spring
        endMonth = '01'; // January
        break;

      case '30': // summer
        endMonth = '05'; // May
        break;

      case '40': // fall
        endMonth = '08'; // August
        break;

      default: // in case its broken
        endMonth = '08'; // August
        break;
    }

    const datum = new Date(Date.UTC(parseInt(endYear, 10), parseInt(endMonth, 10), 15));
    return datum.getTime();
  }


  getTermNameByCode = (code: string): string => {
    const termYear = code.substring(4, 0);
    const termCode = code.substring(4, 6);
    // convert 2 digit termCode to semester name
    let semesterName: string;

    switch (termCode) {

      // case '10': // winter
      //   startMonth = '12'; // December
      //   break;

      case '20': // spring
        semesterName = 'Spring'; // January
        break;

      case '30': // summer
        semesterName = 'Summer'; // May
        break;

      case '40': // fall
        semesterName = 'Fall'; // August
        break;

      default: // in case an invalid term code is passed
        semesterName = 'Fall'; // August
        break;
    }

    return semesterName + ' ' + termYear;
  }
}

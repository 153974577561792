import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Student } from '../model/student.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public emitModalContent: Subject<string> = new Subject();
  public triggerGetHelpModal: Subject<boolean> = new Subject();
  public triggerResumeModal: Subject<FormGroup> = new Subject();
  public triggerDegreePickerModal: Subject<boolean> = new Subject();
  public triggerWelcomeBackModal: Subject<boolean> = new Subject();
  public triggerAppSearchModal: Subject<boolean> = new Subject();
  public triggerStudentResumeModal: Subject<Student> = new Subject();

  public triggerOtpModal: Subject<{phone: { label:string, value:string }, email: { label:string, value:string }}> = new Subject();

  public closeButtonText = 'Close';
  public closeButtonCallback: any;
  public displayButton = false;

  constructor() {}

  public displayModal(modalContent: string, closeButtonText = '', closeButtonCallback?) {
    this.emitModalContent.next(modalContent);
    this.closeButtonText = closeButtonText ? closeButtonText : '';
    this.closeButtonCallback = closeButtonCallback ? closeButtonCallback : null;
    if (this.closeButtonText || this.closeButtonCallback) {
      this.displayButton = true;
    }
  }

}

<div [formGroup]="getFormControlParent()" class="form-control">
  <div class="select-input-wrap">
    <select
      formControlName="{{getFormControlName()}}"
      name="{{getFormControlName()}}"
      id="{{getFormControlName()}}"
      (input)="selectionMade($event.target.value)"
    >
      <option *ngIf="placeholder" value="" selected disabled>{{placeholder}}</option>
      <option *ngFor="let item of itemList" [value]="item[optionValueProperty]">{{item[displayProperty]}}</option>
    </select>

    <app-icon class="icon" aria-label="Dropdown arrow icon" [icon]="'dropdown-arrow'"></app-icon>

  </div>
  <span class="error" *ngIf="this.itemFormControl.touched && this.itemFormControl.invalid">This field is required</span>
</div>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Application} from 'src/app/shared/model/application.model';
import {Address} from 'src/app/shared/model/application/address.model';
import {Program} from 'src/app/shared/model/program.model';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {DateService} from 'src/app/shared/service/date.service';
import {NationService} from 'src/app/shared/service/nation.service';
import {NavigationService} from 'src/app/shared/service/navigation.service';
import {ProgramService} from 'src/app/shared/service/program.service';
import {environment} from '../../../environments/environment';
import {FormService} from '../../shared/service/form.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  @Input() selectedProgram = new Program();
  programLocation: string;
  program: string;
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  streetAddress: string;
  zip: string;
  state: string;
  country: string;
  city: string;
  addressSummary: string;

  environment = environment;


  application = new Application();
  appAddress = new Address();

  endSubscriptions = new Subject<void>();

  constructor(
    public applicationService: ApplicationService,
    public formService: FormService,
    public navigationService: NavigationService,
    public dateService: DateService,
    public nationService: NationService,
    private programService: ProgramService
  ) {
    this.application.highSchools = [];
    this.application.priorColleges = [];
    this.application.emails = [];
    this.application.phones = [];
    this.application.customAnswers = [];
  }

  ngOnInit(): void {

    this.applicationService.updateSent.pipe(takeUntil(this.endSubscriptions)).subscribe(application => {
      Object.assign(this.application, application);
    });

    // All of this is for the address coulmn within the personal info summary box
    this.formService.UpdatedAddress.pipe(takeUntil(this.endSubscriptions)).subscribe(address => {
      this.appAddress = address;

      if (this.appAddress.address1) {
        this.streetAddress = this.appAddress.address1;
      } else {
        this.streetAddress = '';
      }
      if (this.appAddress.zip && this.appAddress.nationCode !== 'US') {
        this.zip = ', ' + this.appAddress.zip;
      } else if (this.appAddress.zip) {
        this.zip = ', ' + this.appAddress.zip;
      } else {
        this.zip = '';
      }
      if (this.appAddress.state) {
        this.state = ', ' + this.appAddress.state;
      } else if (this.appAddress.address3) {
        this.state = ', ' + this.appAddress.address3;
      } else {
        this.state = '';
      }
      if (this.appAddress.nationCode && this.appAddress.nationCode !== 'US') {
        this.country = ', ' + this.nationService.findByCode(this.appAddress.nationCode).name;
      } else {
        this.country = '';
      }
      if (this.appAddress.city) {
        this.city = ', ' + this.appAddress.city;
      } else {
        this.city = '';
      }
      if (this.country !== 'United States') {
        this.addressSummary = this.streetAddress + this.city + this.state + this.country + this.zip;
      } else {
        this.addressSummary = this.streetAddress + this.city + this.state + this.zip;
      }

    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.selectedProgram = program;
      this.program = this.selectedProgram.displayDescription;
      if (this.selectedProgram.campCode === 'D') {
        this.programLocation = 'Online';
      } else if (this.selectedProgram.campCode === 'R') {
        this.programLocation = 'Residential';
      }
    });


  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  formatStartTerm(value: string) {
    if (!value) {
      return '';
    }
    let returnedTerm: string;
    const last2 = value.slice(-2);
    const first4 = value.slice(0, 4);
    if (last2 === '40') {
      returnedTerm = 'Fall ' + first4;
    } else if (last2 === '20') {
      returnedTerm = 'Spring ' + first4;
    } else {
      returnedTerm = 'Summer ' + first4;
    }
    return returnedTerm;
  }

}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';

@Component({
  selector: 'app-gender-select',
  templateUrl: './gender-select.component.html',
})
export class GenderSelectComponent {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  genderRadioOpts: RadioOpts[] = [
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
  ];

}

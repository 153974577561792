<ng-container [formGroup]="formGroup">

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('us_citizen_radio')"
    [label]="'Are you a U.S. Citizen?'"
    [errorMessage]="'Indicate if you are a U.S. Citizen'"
  >
  </app-radio>

  <div [ngClass]="{'hidden':this.formGroup.get('nation_search').disabled}">
    <app-searchable-select-drop-down
      class="nation-search"
      [inputLabelText]="'Nation of Citizenship'"
      [placeholder]="'Select'"
      [formGroup]="formGroup"
      [itemFormControl]="formGroup.get('nation_search')"
      [displayProperty]="'name'"
      [optionValueProperty]="'code'"
      [itemList]="allNations"
      (emitedItem)="updateNation($event)"
      [errorMessage]="'Select a nation of citizenship'"
    >
    </app-searchable-select-drop-down>
  </div>

  <div [ngClass]="{'hidden': this.formGroup.get('country_of_birth_search').disabled}">
    <app-searchable-select-drop-down
      class="nation-search"
      [inputLabelText]="'Country of Birth'"
      [placeholder]="'Select'"
      [formGroup]="formGroup"
      [itemFormControl]="formGroup.get('country_of_birth_search')"
      [displayProperty]="'name'"
      [optionValueProperty]="'code'"
      [itemList]="allNationsForEu"
      [errorMessage]="'Select a country of birth'"
      #countryOfBirthDropdown
      (emitedItem)="updateCountryOfBirth($event)"
    ></app-searchable-select-drop-down>
  </div>

  <app-radio
    [ngClass]="{'hidden': this.formGroup.get('green_card_radio').disabled}"
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('green_card_radio')"
    [label]="'Are you a Legal Permanent Resident of the U.S. (green card holder)?'"
    [errorMessage]="'Provide your Legal Permanent Resident status'"
  >
  </app-radio>

  <app-checkbox
    [ngClass]="{'hidden': !showIntMatdDisclosure}"
    [itemFormGroup]="formGroup.get('international_matd_disclosure_checkbox')"
    [options]="intMatdDisclosureOpts"
  >
  </app-checkbox>

  <div [ngClass]="{'hidden': formGroup.get('city_of_birth').disabled}" style="width: 300px;" class="form-control">
    <div style="position: relative">
      <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('city_of_birth'))">
      </div>
      <label for="city_of_birth">
        <span class="input-label-text">City of Birth</span>
        <input
          type="text"
          maxlength="30"
          name="city_of_birth"
          id="city_of_birth"
          formControlName="city_of_birth"
          [attr.aria-invalid]="formService.hasErrors(formGroup.get('city_of_birth')) ? 'true': null"
          [attr.aria-describedby]="'city-of-birth-error'"
        >
      </label>
      <span class="error" id="city-of-birth-error" role="alert" *ngIf="formService.hasErrors(formGroup.get('city_of_birth'))">Enter a city of birth</span>
    </div>
  </div>
  <app-alert *ngIf="showFaaDisclaimer" [type]="'warning'">International students are unable to register for any flight
    courses that are conducted through a Liberty Flight Training Affiliate. The student must submit their FAA
    certificate (or equivalent) and receive Experience Plus credit for the flight courses.</app-alert>

  <app-visa-status-select [formGroup]="formGroup"></app-visa-status-select>

</ng-container>

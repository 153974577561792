<ng-container [formGroup]="formGroup">
  <div class="search_fields row col-sm-auto">
    <div class="form-control mb-0">

      <div class="row">
        <div class="col">
          <label for="phone_search_field">
            <span class="input-label-text">By Phone Number</span>
          </label>

          <input
            type="search"
            name="phone_search_field"
            maxlength="30"
            id="phone_search_field"
            formControlName="phone_search"
            (keyup.enter)="getIncompleteAppPhoneOrEmail()"/>
        </div>

        <div class="col">
          <label for="email_search_field">
            <span class="input-label-text">By Email</span>
          </label>

          <input
            class="form-control"
            maxlength="50"
            type="search"
            name="email_search_field"
            id="email_search_field"
            formControlName="email_search"
            (keyup.enter)="getIncompleteAppPhoneOrEmail()"/>
        </div>
      </div>
    </div>
  </div>

  <button tabindex="0" (click)="getIncompleteAppPhoneOrEmail()"
          (keyup.enter)="getIncompleteAppPhoneOrEmail()" type="button" class="searchbtn btn btn-secondary mb-1">
    Search
  </button>

  <table *ngIf="!preloaded && theApps && theApps.length > 0 && searched && searchSuccess"
         [formGroup]="applicationsFormGroup" class="phone-email-table mt-2 mb-2">
    <tr class="table_header">
      <th>Prefill?</th>
      <th>AppID</th>
      <th>Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Birthdate</th>
      <th>Program</th>
    </tr>
    <ng-container *ngFor="let app of theApps;">
      <tr *ngIf="!preloaded && app && searched && searchSuccess">
        <td><input type="radio" id='prefill_radio_{{app.id}}' name='prefill_radio' formControlName='prefill_radio'
                   [value]="app.id"></td>
        <td>{{app.id}}</td>
        <td>{{this.getName(app)}}</td>
        <td>{{app.email.toLowerCase() ?? 'N/A'}}</td>
        <td>{{app.phone ?? 'N/A'}}</td>
        <td>{{this.getBirthDate(app) ?? 'N/A'}}</td>
        <td>{{this.getProgramCode(app) ?? 'N/A'}}</td>
      </tr>
    </ng-container>
  </table>

  <button *ngIf="!preloaded && theApps && theApps.length > 0 && searched && searchSuccess" tabindex="0"
          (click)="this.prefillForm()"
          (keyup.enter)="getIncompleteAppPhoneOrEmail()" type="button" class="searchbtn btn btn-primary">
    Preload Selected App
  </button>

  <span *ngIf="nonSelected" class="not_selected_error ml-2">Select a application to prefill</span>

  <app-alert type="primary" *ngIf="searching">Searching... <img src="assets/icons/loading-icon.png" class="rotation"
                                                                style="width: 20px;" alt="Loading icon"></app-alert>

  <app-alert type="danger" *ngIf="searched && !searchSuccess && !searching">{{ errorMessage }}</app-alert>

  <app-alert type="success" *ngIf="preloaded" text="messageText">Information Preloaded Successfully</app-alert>
</ng-container>

import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AsYouType} from 'libphonenumber-js';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FullAddressComponent} from 'src/app/form/input/personal-info/full-address/full-address.component';
import {PhoneNumberComponent} from 'src/app/form/input/phone-number/phone-number.component';
import {FormControlCustom} from 'src/app/shared/form-control-custom';
import {Phone} from 'src/app/shared/model/application/phone.model';
import {Nation} from 'src/app/shared/model/nation.model';
import {Student} from 'src/app/shared/model/student.model';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {AuthenticationService} from 'src/app/shared/service/authentication.service';
import {DateService} from 'src/app/shared/service/date.service';
import {FormService, maxiumumBirthYear, minimumBirthYear} from 'src/app/shared/service/form.service';
import {ModalService} from 'src/app/shared/service/modal.service';
import {NationService} from 'src/app/shared/service/nation.service';
import {StateService} from 'src/app/shared/service/state.service';
import {StudentService} from 'src/app/shared/service/student.service';
import {environment} from 'src/environments/environment';
import {ModalComponent} from '../modal.component';
import {DomService} from '../../shared/service/dom.service';

@Component({
  selector: 'app-student-resume-modal',
  templateUrl: './student-resume-modal.component.html',
  styleUrls: ['./student-resume-modal.component.scss']
})
export class StudentResumeModalComponent extends ModalComponent implements OnInit {

  env = environment;
  @ViewChild(FullAddressComponent) fullAddressComponent: FullAddressComponent;
  @ViewChild(PhoneNumberComponent) phoneNumberComponent: PhoneNumberComponent;
  theStudent: Student;
  stopListening = new Subject<void>();
  displayNameFieldsMiniAlert = false;
  displayBdayFieldsMiniAlert = false;
  studentForm = new FormGroup({

    name_fields: new FormGroup({
      firstName: new FormControlCustom({required: 'Enter your first name'}, '', {validators: [Validators.required]}),
      middleName: new FormControl(),
      lastName: new FormControlCustom({required: 'Enter your last name'}, '', {validators: [Validators.required]})
    }),
    genderRadio: new FormControlCustom({required: 'Select a gender'}, '', [Validators.required]),
    birth_date: new FormGroup({
      birth_day: new FormControlCustom({
        required: 'Enter a birth day',
        pattern: 'Enter a valid birth day'
      }, '', {validators: [Validators.required, Validators.pattern(/^(0?[1-9]|[12]\d|3[01])$/)]}),
      birth_month: new FormControlCustom({required: 'Select a birth month'}, '', {validators: [Validators.required]}),
      birth_year: new FormControlCustom({
        required: 'Enter a birth year',
        pattern: 'Enter a valid birth year',
        max: 'Birthdate cannot be more than 100 years ago',
        min: 'Birthdate cannot be in the future'
      }, '', {
        validators: [Validators.required, Validators.min(minimumBirthYear), Validators.max(maxiumumBirthYear)],
        updateOn: 'blur'
      }),
      birth_date_is_valid: new FormControlCustom({pattern: 'Birth date cannot be today or later'}, 'Yes', {
        validators: [Validators.pattern('Yes')],
        updateOn: 'change'
      })
    }),
    email: new FormControl(),
    phone_fields: new FormGroup({
      phoneNumberInput: new FormControlCustom({
        required: 'Enter a phone number',
        pattern: 'Provide a valid phone number'
      }, '', {validators: [Validators.required]}),
      completePhone: new FormGroup({
        area: new FormControl(''),
        phoneNumber: new FormControl(''),
        type: new FormControl(''),
        country: new FormControl('')
      }),
      isPossible: new FormControl(''),
      isInternational: new FormControl(''),
      cellPhoneRadio: new FormControl('Yes')
    }),
    address_fields: new FormGroup({
      street_address: new FormControlCustom({required: 'Provide a street address'}, '', [Validators.required]),
      city: new FormControlCustom({required: 'Enter a city'}, '', [Validators.required]),
      'shipping-state': new FormControl(''),
      personal_info_state: new FormControlCustom({required: 'Select a state or province'}, '', [Validators.required]),
      zip_code: new FormControlCustom({required: 'Provide a zip code'}, ''),
      personal_info_country: new FormControlCustom({required: 'Select a country'}, 'United States'),
      personal_info_country_code: new FormControl('US'),
      international_state: new FormControlCustom({required: 'Provide a state or province'}, {
        value: '',
        disabled: true
      }),
      international_disclosure: new FormControlCustom({required: 'We are sorry. Because of your country of residence and chosen program, you are unable to submit at this time'}, ''),
      us_resident_radio: new FormControl('Yes')
    })

  });

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    private nationService: NationService,
    private stateService: StateService,
    private studentService: StudentService,
    private dateService: DateService,
    private formService: FormService,
    public authenticationService: AuthenticationService,
    public domService: DomService
  ) {
    super(
      modalService,
      applicationService,
      elm,
      cdr,
      renderer,
      domService
    );
  }

  ngOnInit() {

    const bdateFields = this.studentForm.get('birth_date');
    const nameFields = this.studentForm.get('name_fields');

    this.modalService.triggerStudentResumeModal.pipe(takeUntil(this.endSubscriptions)).subscribe(async (student: Student) => {
      this.showModal(); // need to show modal first in order to stand up viewchild components

      this.theStudent = student;

      // prefill form with any student data
      if (student.firstName) {
        nameFields.get('firstName').setValue(student.firstName);
      }
      if (student.lastName) {
        nameFields.get('lastName').setValue(student.lastName);
      }
      if (student.middleName) {
        nameFields.get('middleName').setValue(student.middleName);
      }

      if (student.email) {
        this.studentForm.get('email').setValue(student.email);
      }
      this.updateAddress(student);

      if (student.birthDate) {
        const dateObj = this.dateService.splitDate(student.birthDate);
        bdateFields.get('birth_day').setValue(dateObj.day);
        bdateFields.get('birth_month').setValue(dateObj.month);
        bdateFields.get('birth_year').setValue(dateObj.year);
      }

      if (student.gender) {
        if (student.gender.includes('Male')) {
          student.gender = 'M';
        } else if (student.gender.includes('Female')) {
          student.gender = 'F';
        } else if (student.gender.includes('U') || student.gender.includes('Unknown')) {
          student.gender = '';
        }
        this.studentForm.get('genderRadio').setValue(student.gender);
      }

      if (student.phoneNumber) {
        const formatter = new AsYouType('US');
        formatter.input(student.phoneNumber);
        const getFormatPhone = formatter.getNumber();
        const newPhone = new Phone(formatter.getNumber());

        if (newPhone?.isValid()) {
          newPhone.type = 'AP';
          newPhone.country = getFormatPhone.countryCallingCode.toString();

          this.updatePhone(newPhone);
          this.studentForm.get('genderRadio').setValue(student.gender);
        }
      }

      // now set up subscriptions to fields that if changed will trigger the mini alert and the id upload component on the /thank-you screen
      merge(
        nameFields.get('firstName').valueChanges,
        nameFields.get('lastName').valueChanges,
        nameFields.get('middleName').valueChanges
      ).pipe(takeUntil(this.stopListening)).subscribe(value => {
        this.displayNameFieldsMiniAlert = true;
        this.formService.setShouldDisplayIdUploadSub(true);
        this.triggerStopListening();
      });

      merge(
        bdateFields.get('birth_day').valueChanges,
        bdateFields.get('birth_month').valueChanges,
        bdateFields.get('birth_year').valueChanges
      ).pipe(takeUntil(this.stopListening)).subscribe(value => {
        this.displayBdayFieldsMiniAlert = true;
        this.formService.setShouldDisplayIdUploadSub(true);
        this.triggerStopListening();
      });

      // user has logged in and we've presented their info once

    }); // end modalService.triggerStudentResumeModal

  }

  triggerLogout() {
    this.authenticationService.logoutOfCurrentAccount();
  }

  triggerStopListening() {
    this.stopListening.next();
    this.stopListening.complete();
  }

  confirmStudentData() {
    // this kills their Azure session on the site and they are essentially "silently" logged out
    localStorage.clear();

    this.triggerStopListening();
    // gather data and overwrite if values are present and truthy
    const formData = this.studentForm.getRawValue();

    const birthDateFields = formData.birth_date;
    if (birthDateFields.birth_date_is_valid === 'Yes') {
      // format like "12-12-2000"
      this.theStudent.birthDate = `${birthDateFields.birth_month}-${birthDateFields.birth_day}-${birthDateFields.birth_year}`;
    }
    if (formData.name_fields.firstName) {
      this.theStudent.firstName = formData.name_fields.firstName;
    }
    if (formData.name_fields.middleName) {
      this.theStudent.middleName = formData.name_fields.middleName;
    }
    if (formData.name_fields.lastName) {
      this.theStudent.lastName = formData.name_fields.lastName;
    }
    if (formData.email) {
      this.theStudent.email = formData.email;
    }
    if (formData.address_fields.street_address) {
      this.theStudent.address = formData.address_fields.street_address;
    }
    if (formData.address_fields.city) {
      this.theStudent.city = formData.address_fields.city;
    }
    if (formData.address_fields.personal_info_state) {
      const theState = this.stateService.getStateByName(formData.address_fields.personal_info_state);
      this.theStudent.state = theState.code;
    }
    if (formData.address_fields.zip_code) {
      this.theStudent.zip = formData.address_fields.zip_code;
    }
    if (formData.address_fields.personal_info_country_code) {
      this.theStudent.country = formData.address_fields.personal_info_country_code;
    }
    if (formData.genderRadio) {
      this.theStudent.gender = formData.genderRadio;
    }
    if (formData.phone_fields) {
      this.theStudent.phoneNumber = formData.phone_fields.completePhone.area + formData.phone_fields.completePhone.phoneNumber;
    }

    this.studentService.emitStudent.next(this.theStudent);
    this.formService.setStudentDataImportedSub(true);
    this.closeModal();

  }

  public updatePhone(phone: Phone) {
    let updatedNumber = '';
    if (phone.area) {
      updatedNumber += phone.area;
    }
    updatedNumber += phone.phoneNumber;

    this.studentForm.get('phone_fields').get('phoneNumberInput').setValue(updatedNumber);
    this.phoneNumberComponent.phoneNumberUpdated(updatedNumber);
  }

  updateAddress(student: Student) {
    let findCountry: Nation;

    const addressFields = this.studentForm.get('address_fields');

    if (student.country) {
      findCountry = this.nationService.findByCode(student.country);
      if (!findCountry) {
        findCountry = this.nationService.findByName(student.country);
      }
    }

    if (student.address) {
      addressFields.get('street_address').setValue(student.address);
    }

    if (student.city) {
      addressFields.get('city').setValue(student.city);
    }

    if (student.zip) {
      addressFields.get('zip_code').setValue(student.zip);
    }

    if (findCountry) {
      addressFields.get('personal_info_country').setValue(findCountry.name);
      addressFields.get('personal_info_country_code').setValue(findCountry.code);
    }

    // state. Not sure if we get full name or code from Banner, so convert to code in case
    let studentState = student.state;
    if (studentState && studentState.length > 2) {
      studentState = this.stateService.getStateByName(studentState).code;
    }
    const findState = this.stateService.getStateByCode(studentState);
    if (findState) {
      if (addressFields.get('personal_info_country_code').value === 'US') {
        addressFields.get('personal_info_state').setValue(findState.name ? findState.name : findState);
      } else {
        addressFields.get('international_state').setValue(findState.name ? findState.name : findState);
      }

    }

  }


}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(
    private http: HttpClient
  ) { }

  getRequest(endpoint: string): Observable<any> {
    return this.http.get<any>(endpoint, { headers: this.headers })
      .pipe(
        tap<any>(response => {
          return response;
        }), catchError((error: Observable<HttpErrorResponse>) => {
          return error;
        })
      );
  }

  putRequest(endpoint: string, body: any): Observable<any> {
    return this.http.put<any>(endpoint, body, {headers: this.headers})
      .pipe(
        tap<any>(response => {
          return response;
        }), catchError((error: Observable<HttpErrorResponse>) => {
          return error;
        })
      );
  }

  postRequest(endpoint: string, body: any): Observable<any> {
    return this.http.post<any>(endpoint, body, {headers: this.headers})
      .pipe(
        tap<any>(response => {
          return response;
        }), catchError((error: Observable<HttpErrorResponse>) => {
          return error;
        })
      );
  }


}

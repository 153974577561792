import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "src/app/shared/service/form.service";

@Component({
  selector: 'app-name-fields-first-mid-last',
  templateUrl: './name-fields-first-mid-last.component.html'
})
export class NameFieldsFirstMidLastComponent {
  constructor(
    public formService: FormService
  ) { }


  @Input() formGroup = new FormGroup({});
  @Input() fieldsetLegend = '';

  lastName: string;
  @Output() firstNameNuVal = new EventEmitter<string>();
  @Output() lastNameNuVal = new EventEmitter<string>();


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingRingComponent } from './loading-ring.component';

@NgModule({
  declarations: [
    LoadingRingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingRingComponent
  ]
})
export class LoadingRingModule { }

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/service/authentication.service';
import { RouterService } from '../shared/service/router.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {

    // try waiting until redirect end
    if (this.authenticationService.isLoggedIn()) {
      this.routerService.navigate('');
    }



  }



}

<div class="certs-container" [ngClass]="{'hidden': formGroup.get('college_certs_select').get('certs').disabled}" [formGroup]="formGroup">

  <div class="certs-input-dropdown">
    <app-multi-searchable-select-dropdown
      [inputtedObjectList]="allCerts"
      [itemFormControl]="formGroup.get('college_certs_select').get('certs')"
      [inputLabelText]="'Please add any licenses or certificates (optional)'"
      (outputtedObjectList)="addCerts($event)"
      [smallerLabel]="true"
      [notListedText]="'License or certificate not listed?'"
    ></app-multi-searchable-select-dropdown>
  </div>
</div>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {AgentFormComponent} from './form/agent-form/agent-form.component';
import {StudentFormComponent} from './form/student-form/student-form.component';
import {MainComponent} from './main/main.component';
import {ThankYouComponent} from './thank-you/thank-you.component';
import {UnauthComponent} from './unauth/unauth.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import { ADRoles } from './shared/service/authentication.service';
import {RoleGuard} from './guards/RoleGuard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [
      MsalGuard
    ],
  },
  {
    path: 'unauth',
    component: UnauthComponent
  },
  {path: 'thank-you', redirectTo: 'thank-you/.', pathMatch: 'full'},
  {
    path: 'thank-you/.',
    component: MainComponent,
    children: [
      {
        path: '',
        component: ThankYouComponent,
        outlet: 'form'
      }
    ]
  }, {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  // { path: 'agent', redirectTo: 'agent/.', pathMatch: 'full' },
  {
    path: 'agent',
    component: MainComponent,
    canActivate: [ MsalGuard ],
    canActivateChild: [MsalGuard],
    data: {
      roles: [ ADRoles.SAT_APP_DEV, ADRoles.SAT_LU_AGENT ]
    },
    children: [
      {
        path: '',
        component: AgentFormComponent,
        outlet: 'form',
        canActivate: [RoleGuard],
      }
    ]
  },
  {
    path: 'spc',
    component: MainComponent,
    children: [
      {
        path: '',
        component: StudentFormComponent,
        outlet: 'form'
      }
    ]
  },
  {
    path: 'festivals',
    component: MainComponent,
    children: [
      {
        path: '',
        component: StudentFormComponent,
        outlet: 'form'
      }
    ]
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: StudentFormComponent,
        outlet: 'form'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

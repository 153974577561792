import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {
  SearchableSelectDropDownComponent
} from 'src/app/form/input/generic/searchable-select-drop-down/searchable-select-drop-down.component';
import {CampusLevelProgramArray} from 'src/app/shared/model/campus-level-program-array.model';
import {EmailMessage, EmailType} from 'src/app/shared/model/email-message.model';
import {Program} from 'src/app/shared/model/program.model';
import {RadioOpts} from 'src/app/shared/model/radio-options.model';
import {AppIdService} from 'src/app/shared/provider/app-id.service';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {EmailService} from 'src/app/shared/service/email.service';
import {emailPattern, FormService} from 'src/app/shared/service/form.service';
import {ProgramService} from 'src/app/shared/service/program.service';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-get-help-email-form',
  templateUrl: './get-help-email-form.component.html',
  styleUrls: ['./get-help-email-form.component.scss']
})
export class GetHelpEmailFormComponent implements OnInit, OnDestroy {

  @ViewChild(SearchableSelectDropDownComponent) programDropdown: SearchableSelectDropDownComponent<Program>;

  toAddress = EmailMessage.SAT;
  emailSubject = 'Liberty University Application (ApplyLU) - Applicant Needs Help';
  getHelpEmailForm: FormGroup;
  firstName = '';
  lastName = '';
  messageLengthValidation = false;
  showLoadingAnimation = false;

  levelRadioOpts: RadioOpts[] = [
    { value: 'Bachelor', label: 'Bachelor' },
    { value: 'Master', label: 'Master' },
    { value: 'Doctorate', label: 'Doctorate' },
    { value: 'Associate', label: 'Associate' },
    { value: 'Certificate', label: 'Certificate' }
  ];

  campusRadioOpts: RadioOpts[] = [
    { value: 'R', label: 'Residential' },
    { value: 'D', label: 'Online' }
  ];

  helpReasonOpts: RadioOpts[] = [
    { value: 'Admission', label: 'Admissions Question' },
    { value: 'Technical', label: 'Report a Technical Issue' }
  ];

  programsByCampusLevel = new CampusLevelProgramArray();
  selectedProgram: Program;
  mainFormProgram: Program;
  initProgram: string;

  disableHelpSubmit = false;
  submittedHelp = false;

  degreeLevelSub = new Subscription();
  reasonSub = new Subscription();
  programsLoadedSub = new Subscription();
  isAdmissionQ = false;
  isTechnicalIssue = false;
  sendingError = false;

  constructor(
    private formBuilder: FormBuilder,
    public formService: FormService,
    private applicationService: ApplicationService,
    private emailService: EmailService,
    private _sanitizer: DomSanitizer,
    private appIdService: AppIdService,
    private programService: ProgramService,
    private cdr: ChangeDetectorRef
  ) {
    this.createHelpEmailForm();
    this.programService.initAllPrograms();
  }

  ngOnInit(): void {
    // get any data we can from the main form
    // set name to what is in main form
    this.firstName = this.applicationService.application.firstName;
    this.lastName = this.applicationService.application.lastName;
    this.setFullName();

    if (this.programService.getCurrentProgram()
      && this.programService.getCurrentProgram().programCode
      && this.programService.getCurrentProgram().programCode.length > 0) {
      this.initProgram = this.programService.getCurrentProgram().displayDescription;
    }

    // set email address to what is in main form
    if (this.applicationService.application.emails[0]) {
      this.getHelpEmailForm.get('emailAddress').setValue(this.applicationService.application.emails[0].emailAddress);
    }
    // set campus to what has been selected in main form
    if (this.formService.getCurrentCampus()) {
      this.getHelpEmailForm.get('get_help_campus').setValue(this.formService.getCurrentCampus());
    }
    this.programsByCampusLevel = this.programService.returnProgramsByCampusLevel();

    this.degreeLevelSub = this.getHelpEmailForm.get('degree_level_radio').valueChanges.subscribe(() => {
        this.getHelpEmailForm.get('get_help_campus').markAsTouched();
        this.selectedProgram = null;
    });

    this.reasonSub = this.getHelpEmailForm.get('get_help_reason').valueChanges.subscribe(val => {

      this.isAdmissionQ = val === 'Admission';
      this.isTechnicalIssue = val === 'Technical';
      this.cdr.detectChanges();

      if (this.isTechnicalIssue) {
        // this.getHelpEmailForm.get('get_help_message').setValidators([Validators.required]);
        this.getHelpEmailForm.get('degree_level_radio').clearValidators();
        this.getHelpEmailForm.get('degree_level_radio').updateValueAndValidity();


      } else {
        this.getHelpEmailForm.get('degree_level_radio').setValidators([Validators.required]);

        // set level to what has been selected in main form
        if (this.formService.getCurrentLevel()) {
          this.getHelpEmailForm.get('degree_level_radio').setValue(this.levelRadioOpts.find(level => level.value === this.formService.getCurrentLevel()).value);
          this.cdr.detectChanges();
        }
      }
    });

    if (this.programsByCampusLevel.isEmpty()) {
      this.programsLoadedSub = this.programService.getAllProgramsLoadedSub().subscribe(() => {
        this.programsByCampusLevel = this.programService.returnProgramsByCampusLevel();
      });
    }

  }

  ngOnDestroy() {
    this.degreeLevelSub.unsubscribe();
    this.reasonSub.unsubscribe();
    if (this.programsLoadedSub) {
      this.programsLoadedSub.unsubscribe();
    }
  }

  clearProgramField() {
    if (this.programDropdown) {
      this.programDropdown.clearSelection();
      this.initProgram = null;
    }
  }

  createHelpEmailForm() {
    this.getHelpEmailForm = this.formBuilder.group({
      get_help_campus: ['', Validators.required],
      fullName: ['', Validators.required],
      emailAddress: ['', { validators: [Validators.required, Validators.pattern(emailPattern)], updateOn: 'blur' }],
      get_help_reason: ['', Validators.required],
      get_help_message: ['', Validators.required],
      degree_level_radio: ['', Validators.required],
      degree_search: ['']
    });
  }

  setFullName() {
    if (this.firstName && this.firstName.length > 0 && this.lastName && this.lastName.length > 0) {
      this.getHelpEmailForm.get('fullName').setValue(this.firstName + ' ' + this.lastName);
    } else if ((!this.firstName || this.firstName.length < 0) && this.lastName && this.lastName.length > 0) {
      this.getHelpEmailForm.get('fullName').setValue(this.lastName);
    } else if ((!this.lastName || this.lastName.length < 0) && this.firstName && this.firstName.length > 0) {
      this.getHelpEmailForm.get('fullName').setValue(this.lastName);
    }
  }

  selectProgram(program: Program) {
    this.selectedProgram = program;
    this.cdr.detectChanges();
  }

  submit() {
    // validate form
    this.getHelpEmailForm.get('get_help_campus').markAllAsTouched();
    this.getHelpEmailForm.get('fullName').markAllAsTouched();
    this.getHelpEmailForm.get('emailAddress').markAllAsTouched();
    this.getHelpEmailForm.get('get_help_reason').markAllAsTouched();
    this.getHelpEmailForm.get('get_help_message').markAllAsTouched();
    this.getHelpEmailForm.get('degree_level_radio').markAllAsTouched();

    if (this.getHelpEmailForm.valid && !this.messageLengthValidation) {
      this.sendMessage();
    }
  }

  sendMessage() {

    this.disableHelpSubmit = true;
    this.showLoadingAnimation = true;

    const replyToAddress = this.getHelpEmailForm.get('emailAddress').value;
    const message = this.getHelpEmailForm.get('get_help_message').value;
    const name = this.getHelpEmailForm.get('fullName').value;
    const campus = this.getHelpEmailForm.get('get_help_campus').value;
    const level = this.getHelpEmailForm.get('degree_level_radio').value;
    let body = '';

    this.toAddress = campus === 'R' ? EmailMessage.ADMISSION : EmailMessage.ADMISSION_LUO;
        // If not, default to appDevSat email


    body += '\nAppID: ' + this.appIdService.getAppId();
    body += '\nName: ' + name + '\nCampus: ' + campus + ' ';
    if (this.isAdmissionQ) {
      body += '\nLevel: ' + level;
      if (this.selectedProgram && this.selectedProgram.displayDescription) {
        body += '\nProgram: ';
        if (this.selectedProgram.programCode) {
          body += this.selectedProgram.programCode + ' | ';
        }
        body += this.selectedProgram.displayDescription + '\n';
      }
    }

    body += '\nMessage: ' + this.sanitizeMessage(message) + '\n';
    body += '\n\nReply Email Address: ' + replyToAddress;

    const subject = (campus === 'R' ? 'Residential' : 'Online') + ': ' + this.emailSubject;
    const emailMessage = new EmailMessage(EmailMessage.NO_REPLY, this.toAddress, replyToAddress, subject, body, EmailType.GET_HELP);

    this.emailService.sendEmail(emailMessage).pipe(finalize(() => this.showLoadingAnimation = false))
    .subscribe(
      { next: () => {
          this.submittedHelp = true;
          this.sendingError = false;
        },
        error: () => {
          this.submittedHelp = false;
          this.disableHelpSubmit = false;
          this.sendingError = true;
        }
      }
    );
  }

  checkLength(body: string) {
    this.messageLengthValidation = body.length < 5;
  }

  sanitizeMessage(unsafeMessage: string) {
    return this.sanitizedHTML(unsafeMessage);
  }

  public sanitizedHTML(unsafe): string {
    return (this._sanitizer.sanitize(SecurityContext.HTML, unsafe));
  }
  public sanitizedScript(unsafe): string {
    return (this._sanitizer.sanitize(SecurityContext.SCRIPT, unsafe));
  }

}

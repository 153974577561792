import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { ELevelCategory } from 'src/app/shared/model/prog-level.model';
import { Term } from 'src/app/shared/model/term.model';
import { FormService } from 'src/app/shared/service/form.service';
import { ProgramService } from 'src/app/shared/service/program.service';

@Component({
  selector: 'app-tshirt',
  templateUrl: './tshirt.component.html',
  styles: ['.tshirt-container {max-width: 280px;}']
})
export class TshirtComponent implements OnInit {

  @Input() formGroup: FormGroup = new FormGroup({});
  shirtSizes: Array<Term> = [
    {name: 'Small', value: 'S'},
    {name: 'Medium', value: 'M'},
    {name: 'Large', value: 'L'},
    {name: '1XL', value: 'XL'},
    {name: '2XL', value: 'XXL'}
  ];

  endSubscriptions = new Subject<void>();

  isResidentUndergrad = false;
  isUsCitizen = true;
  isGreencardHolder = true;

  constructor(
    public formService: FormService,
    public programService: ProgramService
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('tshirtSelect', new FormControlCustom<string>({ required: 'Select a shirt size'}, {value: '', disabled: true} ) );

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      if (
        this.programService.getLevelCategoryByProgramCode(program.programCode) === ELevelCategory.Undergrad
        && this.programService.getCampusByProgramCode(program.programCode) === 'R'
        && program.programCode !== 'SPCU-BS-R'
      ) {
        this.isResidentUndergrad = true;
        this.maybeEnableTshirts();
      } else {
        this.isResidentUndergrad = false;
        this.disableTshirts();
      }
    });

    this.formService.usCitizenSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      this.isUsCitizen = val;
      this.maybeEnableTshirts();
    });

    this.formService.greenCardHolderSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      this.isGreencardHolder = val;
      this.maybeEnableTshirts();
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeEnableTshirts() {
    if (
      this.isResidentUndergrad
      && !(!this.isUsCitizen && !this.isGreencardHolder )
    ) {
      this.enableTshirts();
    } else {
      this.disableTshirts();
    }
  }

  enableTshirts() {
    this.formGroup.get('tshirtSelect').enable();
    this.formGroup.get('tshirtSelect').setValidators([Validators.required]);
  }

  disableTshirts() {
    this.formGroup.get('tshirtSelect').setValue('');
    this.formGroup.get('tshirtSelect').clearValidators();
    this.formGroup.get('tshirtSelect').disable();
  }

}

<ng-container [formGroup]="formGroup">

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('us_resident_radio')"
    [label]="'Do you currently reside in the U.S.?'"
    (radioChanged)="emitAnswer($event)"
  >
  </app-radio>

</ng-container>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-marital-status',
  templateUrl: './marital-status.component.html',
  styles: ['#maritalStatus-container {max-width: 340px; padding-left: 0; padding-right: 0;}']
})
export class MaritalStatusComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  maritalStatuses = [
    {name: 'Single', value: 'S'},
    {name: 'Married', value: 'M'},
    {name: 'Divorced', value: 'D'},
    {name: 'Separated', value: 'P'},
    {name: 'Widowed', value: 'W'}
  ];

  constructor(
    public formService: FormService
  ) { }

  ngOnInit(): void {
    this.formGroup.get('maritalStatus').enable();
    this.formGroup.get('maritalStatus').setValidators(Validators.required);
  }

  ngOnDestroy() {
    this.formGroup.get('maritalStatus').disable();
    this.formGroup.get('maritalStatus').setValue('');
    this.formGroup.get('maritalStatus').clearValidators();
  }

}

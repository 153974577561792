import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {College} from 'src/app/shared/model/application/college.model';
import {HighSchool} from 'src/app/shared/model/application/highschool.model';
import {School} from 'src/app/shared/model/application/school';
import {Nation} from 'src/app/shared/model/nation.model';
import {RadioOpts} from 'src/app/shared/model/radio-options.model';
import {DateService} from 'src/app/shared/service/date.service';
import {FormService} from 'src/app/shared/service/form.service';
import {NationService} from 'src/app/shared/service/nation.service';
import {SearchService} from 'src/app/shared/service/search.service';
import {SchoolSearchInputComponent} from '../../school-search-input/school-search-input.component';
import {gradYearMax, gradYearMin} from 'src/app/shared/model/app-form-builder.model';
import {StateSearchComponent} from '../../state-search/state-search.component';
import { Subject, takeUntil } from 'rxjs';

export enum SchoolType {
  Highschool,
  College,
  All
}

@Component({
  selector: 'app-school-search-fieldset',
  templateUrl: './school-search-fieldset.component.html'
})

export class SchoolSearchFieldsetComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() formGroup: FormGroup;
  @Input() schoolType: SchoolType;
  @Input() defaultLevel = '';
  @Input() schoolRequired = true;
  @Input() searchLabelText = '';
  @Input() searchErrorText = '';
  @ViewChild(StateSearchComponent) stateSearchComponent: StateSearchComponent;
  @Input() searchInputName = 'school_search';
  allSchools: School[];
  schoolTypes = SchoolType;
  degreeTypes = {
    CRT: {name: 'Certificate'},
    AA: {name: 'Associate Degree'},
    BS: {name: 'Bachelor\'s Degree'},
    MA: {name: 'Master\'s Degree'},
    D: {name: 'Doctoral Degree'}
  };
  minimumYear = gradYearMin;
  maximumYear = gradYearMax;
  initNationCode = 'US';
  enableSchoolStateNation = false;
  // provided 2 levels up in Previous Education component
  ariaLabelMonth = 'Month Input';
  ariaLabelYear = 'Year Input';
  ariaLabelPrefix = '';
  errorText = '';
  yesNoRadioOpts: RadioOpts[] = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'}
  ];
  endSubs = new Subject();
  @ViewChild(forwardRef(() => SchoolSearchInputComponent))
  private schoolSearchInputComponent: SchoolSearchInputComponent;

  constructor(
    public nationService: NationService,
    public searchService: SearchService,
    public dateService: DateService,
    public formService: FormService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (this.schoolType === SchoolType.Highschool) {
      this.formGroup.get('degree_level_received').disable();
    }

    // capture state search value on init if one is being passed in
    // make sure it isn't lost during init when country init value is filled in, when country init happens it wipes out state value
    const currStateValue = this.formGroup.get('state_search_input').value;

    // set initial country if we init with a school provided and the selected school has something other than US
    const selectedSchool = this.getSelectedSchoolControl().value;
    if (selectedSchool && selectedSchool.description) {
      const nationCode = selectedSchool.nationCode;
      if (nationCode && nationCode !== '') {
        this.initNationCode = nationCode;
      }
    }

    if (this.formGroup.get('did_graduate_radio')) {

      this.formGroup.get('did_graduate_radio').valueChanges.pipe(takeUntil(this.endSubs)).subscribe(value => {
        if (this.maybeEnableDegreeLevelReceived()) {
          this.formGroup.get('degree_level_received').enable();
        } else {
          this.formGroup.get('degree_level_received').disable();
        }
      });
    }

    setTimeout(() => {
      if (currStateValue && currStateValue !== '') {
        const findState = this.stateSearchComponent.allStates.find(state => state.name === currStateValue);
        if (findState) {
          this.stateSearchComponent.searchableSelectDropDownComponent.selectItem(findState);
        }
      }
    }, 300);
  }

  ngAfterViewInit() {
    if (this.schoolType === SchoolType.Highschool) {
      this.formGroup.get('degree_level_received').disable();
    }
    this.checkSelectedSchool();
  }

  ngOnDestroy() {
    this.endSubs.next(undefined);
    this.endSubs.complete();
  }

  checkSelectedSchool() {
    const selectedSchool = this.getSelectedSchoolControl().value as College | HighSchool;

    if (selectedSchool) {
      if (selectedSchool.description && selectedSchool.ceebCode) { // defined school /w ceebCode
        this.schoolSearchInputComponent.addSchool(selectedSchool);
      } else if (selectedSchool.description && !selectedSchool.ceebCode) { // is a custom school
        this.schoolSearchInputComponent.customAddSchool(selectedSchool.description);
      }
    }
  }


  emittedSchool(emittedSchool: (College | HighSchool)) {

    if (this.defaultLevel) {
      emittedSchool.degree = this.defaultLevel;
    }

    if (!emittedSchool.hasName()) { // empty school, reset all fields
      this.resetAllFields();
      this.getSelectedSchoolControl().setValue(emittedSchool);
    } else {
      if (emittedSchool.ceebCode && emittedSchool.ceebCode !== '') {
        this.autoAddSchool(emittedSchool);
      } else {
        this.customAddSchool(emittedSchool);
      }
    }
  }

  autoAddSchool(providedSchool: College | HighSchool) {
    this.disableField('state_search_input');
    this.enableSchoolStateNation = false;
    if (this.nationControl()) {
      this.nationControl().disable();
    }
    this.disableField('city');
    this.getSelectedSchoolControl().setValue(providedSchool);
  }

  customAddSchool(providedSchool: College | HighSchool) {
    this.enableSchoolStateNation = true;
    if (providedSchool instanceof HighSchool) {
      this.enableField('city');
      this.formGroup.get('city').setValidators([Validators.required]);
    }
    if (this.nationControl()) {
      this.nationControl().enable();
      if (this.nationControl().value) {
        if (this.nationControl().value === 'United States') {
          this.formGroup.get('state_search_input').enable();
          this.formGroup.get('state_search_input').setValidators([Validators.required]);
        }
        const findNationByName = this.nationService.findByName(this.nationControl().value);
        if (findNationByName) {
          providedSchool.nationCode = findNationByName.code;
        }
      }
    }
    this.getSelectedSchoolControl().setValue(providedSchool);
  }

  resetAllFields() {

    this.getSchoolSearch().setValue('');
    this.formGroup.get('last_month_attended').setValue('');
    this.formGroup.get('grad_year').setValue('');

    if (this.formGroup.get('degree_level_received') && !this.defaultLevel) {
      this.formGroup.get('degree_level_received').setValue('');
    }
    this.disableField('school_nation');
    this.disableField('state_search_input');
    this.disableField('city');
    this.enableSchoolStateNation = false;
    this.cdr.detectChanges();
  }

  disableField(formControlName: string) {
    this.formGroup.get(formControlName).setValue('');
    this.formGroup.get(formControlName).disable();
    this.formGroup.get(formControlName).markAsPristine();
    this.formGroup.get(formControlName).markAsUntouched();

    this.cdr.detectChanges();


  }

  enableField(formControlName: string) {
    this.formGroup.get(formControlName).enable();
    this.formGroup.get(formControlName).markAsPristine();
    this.formGroup.get(formControlName).markAsUntouched();

    this.cdr.detectChanges();
  }

  didGraduateChange(val: string) {
    this.formGroup.get('degree_level_received').setValue(val === 'No' ? '000000' : '');
  }

  updateSchoolNation(selectedNation: Nation) {

    if (selectedNation.code && selectedNation.code !== '') {
      const selectedSchool = this.getSelectedSchoolControl().value;
      if (selectedSchool && selectedSchool.description) {
        selectedSchool.nationCode = selectedNation.code;
        this.getSelectedSchoolControl().setValue(selectedSchool);
      }
    }

    if (selectedNation) {
      if (selectedNation.code === 'US') {
        if (this.enableSchoolStateNation) {
          this.enableField('state_search_input');
        } else {
          this.disableField('state_search_input');
        }
      } else {
        this.disableField('state_search_input');
      }
    }
  }

  clearRequiredValidators(formGroup?: FormGroup) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }

    this.getSchoolSearch().clearValidators();
    this.getSchoolSearch().updateValueAndValidity({emitEvent: false});
    this.getSchoolSearch().markAsPristine();
    this.getSchoolSearch().markAsUntouched();

    formGroup.get('last_month_attended').clearValidators();
    formGroup.get('last_month_attended').updateValueAndValidity({emitEvent: false});

    formGroup.get('grad_year').clearValidators();
    formGroup.get('grad_year').updateValueAndValidity({emitEvent: false});

    formGroup.get('did_graduate_radio')?.clearValidators();
    formGroup.get('did_graduate_radio')?.updateValueAndValidity({emitEvent: false});

    if (formGroup.get('degree_level_received')) {
      formGroup.get('degree_level_received').clearValidators();
      formGroup.get('degree_level_received').updateValueAndValidity({emitEvent: false});
    }

    formGroup.get('city').clearValidators();
    formGroup.get('city').updateValueAndValidity({emitEvent: false});

    formGroup.get('state_search_input').clearValidators();
    formGroup.get('state_search_input').updateValueAndValidity({emitEvent: false});

    this.nationControl().clearValidators();
    this.nationControl().updateValueAndValidity({emitEvent: false});

    this.cdr.detectChanges();
    formGroup.updateValueAndValidity({emitEvent: false});

  }

  setRequiredValidators(formGroup?: FormGroup) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }

    if (this.getSchoolSearch()) {
      this.getSchoolSearch().setValidators([Validators.required]);
      this.getSchoolSearch().updateValueAndValidity({emitEvent: false});
    }

    formGroup.get('last_month_attended').setValidators([Validators.required]);
    formGroup.get('last_month_attended').updateValueAndValidity({emitEvent: false});

    formGroup.get('grad_year').setValidators([Validators.required, Validators.min(gradYearMin), Validators.max(gradYearMax)]);
    formGroup.get('grad_year').updateValueAndValidity({emitEvent: false});

    if (formGroup.get('city').enabled) {
      formGroup.get('city').setValidators([Validators.required]);
      formGroup.get('city').updateValueAndValidity({emitEvent: false});
    }
    if (formGroup.get('state_search_input').enabled) {
      formGroup.get('state_search_input').setValidators([Validators.required]);
      formGroup.get('state_search_input').updateValueAndValidity({emitEvent: false});
    }

    this.nationControl().setValidators([Validators.required]);
    this.nationControl().updateValueAndValidity({emitEvent: false});

  }

  isDidGraduateEnabled(): boolean {
    return this.formGroup.get('did_graduate_radio')
      && this.schoolType === SchoolType.College
      && this.defaultLevel === '';
  }

  maybeEnableDegreeLevelReceived(): boolean {
    return this.formGroup.get('degree_level_received')
      && this.schoolType === SchoolType.College
      && this.defaultLevel === ''
      && (!this.formGroup.get('did_graduate_radio') || this.formGroup.get('did_graduate_radio').value === 'Yes');
  }

  getSchoolSearch(): FormControl {
    return this.formGroup.get(this.searchInputName) as FormControl;
  }

  public nationControl(): FormControl {
    return this.formGroup?.get('school_nation') as FormControl;
  }

  getSelectedSchoolControl() {
    return this.formGroup?.get('selected_school') as FormControl;
  }

  public keepOriginalOrder = (a) => a.key;

}

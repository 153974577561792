import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { Term } from 'src/app/shared/model/term.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-seeking-pla',
  templateUrl: './seeking-pla.component.html',
  styles: [
    '.seeking-pla-container {width: 270px}'
  ]
})
export class SeekingPlaComponent implements OnInit {

  @Input() formGroup = new FormGroup({});

  plaReasons: Array<Term> = [
    {name: 'Military Experience', value: 'IPME'},
    {name: 'Job Training / Automatic Credit', value: 'IPJT'},
    {name: 'Examination (CLEP, DANTEST, ICE, etc.)', value: 'IPEX'},
    {name: 'Portfolio', value: 'IPPT'},
  ];

  constructor(
    public formService: FormService,
  ) { }

  ngOnInit(): void {

    this.formGroup.addControl('seeking_pla', new FormControl<string>(''));

  }

  ngOnDestroy() {
    this.formGroup.removeControl('seeking_pla');
  }

}

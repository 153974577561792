import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarLogoComponent } from './sidebar-logo/sidebar-logo.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SidebarFooterComponent } from './sidebar-footer/sidebar-footer.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { GetHelpComponent } from './right-sidebar/get-help/get-help.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { IconModule } from '../shared/component/icon/icon.module';

@NgModule({
  declarations: [
    SidebarComponent,
    SidebarLogoComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
    RightSidebarComponent,
    GetHelpComponent,
    MobileMenuComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    IconModule
  ],
  exports: [
    SidebarComponent,
    RightSidebarComponent,
    MobileMenuComponent,
    SidebarMenuComponent
  ]
})
export class SidebarModule { }

<div *ngIf="showPopup" class="popup_container" role="alert" aria-live="assertive">
  <div class="message">
    <a class="close_popup" (click)="closePopup()"></a>
    <div [innerHTML]="message" class="message_text">
    </div>
    <div *ngIf="showButton" class="popup_button_container">
      <button (click)="closePopup()" class="popup_button" type="button">Ok</button>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Term } from 'src/app/shared/model/term.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-applicant-goals',
  templateUrl: './applicant-goals.component.html',
  styles: ['.discovery_goals_container { width: 270px; }']
})
export class ApplicantGoalsComponent {

  @Input() formGroup = new FormGroup({});

  allGoals: Term[] = [
    { value: 'Advancement / Promotion', name: 'Advancement / Promotion' },
    { value: 'Career Switch / Job Opportunity', name: 'Career Switch / Job Opportunity' },
    { value: 'Personal Interest / Increase Knowledge', name: 'Personal Interest / Increase Knowledge' },
    { value: 'Professional Development', name: 'Professional Development' },
    { value: 'Pay Raise', name: 'Pay Raise' },
    { value: 'Other', name: 'Other' }
  ];

  constructor(
    public formService: FormService,
  ) { }

  public addGoals(emittedGoals: Term[]) {

    if (emittedGoals.length > 0) {
      this.formGroup.get('goals_search').clearValidators();
      this.formGroup.get('goals_search').updateValueAndValidity();
    } else {
      this.formGroup.get('goals_search').setValidators([Validators.required]);
      this.formGroup.get('goals_search').updateValueAndValidity();
    }
    this.formGroup.get('goals_search').markAsPristine();
  }

}

import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import {DomService} from '../../../../../shared/service/dom.service';

@Component({
  selector: 'app-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss']
})
export class PlacesAutocompleteComponent implements AfterViewInit, AfterViewChecked {

  @Input() inputtedFormControl: FormControl;
  @Input() activateAutocomplete = false;

  @ViewChild('autocompleteInput', { static: false }) autocompleteInput: ElementRef;

  @Output() emitPlace = new EventEmitter<google.maps.places.PlaceResult>();

  searchInputIsFocused = false;

  searchString = "";
  searchResultsString = '';

  regressionParam = false;

  observer: MutationObserver;

  predictions: google.maps.places.AutocompletePrediction[] = [];
  highlightedPrediction: google.maps.places.AutocompletePrediction = null;

  // Add these properties
  elRef: ElementRef;
  highlightedIndex: number = -1;
  isHover: number = -1;

  constructor(
    public formService: FormService,
    private route: ActivatedRoute,
    private domService: DomService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.elRef = this.elementRef;

    if (this.route.snapshot.queryParams.regression) {
      this.regressionParam = this.route.snapshot.queryParams.regression;
    }

  }

  ngAfterViewInit() {
    this.setupObserver();

    this.autocompleteInput.nativeElement.addEventListener('input', this.handleInputChange.bind(this));

  }

  ngAfterViewChecked() {
    if (this.autocompleteInput) {
      if (this.searchInputIsFocused) {
        this.autocompleteInput.nativeElement.setAttribute('autocomplete', 'off');
      } else {
        this.autocompleteInput.nativeElement.setAttribute('autocomplete', 'address-line1');
      }
    }
  }


  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleInputChange(event: Event) {
    this.searchString = (event.target as HTMLInputElement).value;
  }

  setupObserver() {
    if (!this.observer) {

      const node = this.domService.selectElementAsRoot('.predictions-container');

      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          const numResults = node.querySelectorAll('.predictions-item').length;
          if (numResults > 0) {
            this.searchResultsString = 'There ' + (numResults === 1 ? 'is' : 'are') + ' ' + numResults + ' result' + (numResults > 1 ? 's' : '') + '. Use the up and down arrow keys to highlight a selection';
          } else {
            this.searchResultsString = '';
          }
        });
      });

      observer.observe(node, {
        childList: true,
      });

    }

  }

  // Prediction results received from the directive, display them in the template
  displayResults(predictions: google.maps.places.AutocompletePrediction[]) {
    this.predictions = predictions;
    this.highlightedIndex = -1; // Reset highlighted index when predictions are updated
  }

  onPredictionHovered(passedIndex: number) {
    this.isHover = passedIndex;
    this.highlightedIndex = passedIndex;
  }

  prevResult() {
    if (this.highlightedIndex > 0) {
      this.highlightedIndex--;
    } else {
      // Wrap to the last prediction if already at the first one
      this.highlightedIndex = this.predictions.length - 1;
    }
    this.updateHighlightedPrediction();
  }

  nextResult() {
    if (this.highlightedIndex < this.predictions.length - 1) {
      this.highlightedIndex++;
    } else {
      // Wrap to the first prediction if already at the last one
      this.highlightedIndex = 0;
    }
    this.updateHighlightedPrediction();
  }

  private updateHighlightedPrediction() {
    this.highlightedPrediction = this.predictions[this.highlightedIndex];
    // Remove highlight from all predictions
    const predictionsItems = this.elRef.nativeElement.querySelectorAll('.predictions-item');
    predictionsItems.forEach((item, index) => {
      this.renderer.removeClass(item, 'highlight');
    });

    // Highlight the currently selected prediction
    if (this.highlightedIndex >= 0 && this.highlightedIndex < this.predictions.length) {
      const highlightedItem = predictionsItems[this.highlightedIndex];
      this.renderer.addClass(highlightedItem, 'highlight');
    }
  }

  selectHighlightedPrediction() {
    if (this.highlightedIndex >= 0 && this.highlightedIndex < this.predictions.length) {
      const highlightedPrediction = this.predictions[this.highlightedIndex];
      this.selectPrediction(highlightedPrediction);
    }
  }

  selectPrediction(prediction: google.maps.places.AutocompletePrediction) {
    // Use places API to get details
    const placeId = prediction.place_id;

    // we just want to use PlacesService to programatically get a place, but for some reason the constructor requires an HTML element to be attached to, so we'll pass in a dummy
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    placesService.getDetails({ placeId: placeId }, (placeResult, status) => {

      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.emitPlace.next(placeResult);
        this.predictions = [];
      } else {
        console.error('Error fetching place details:', status);
      }

    });
  }

  public getFormControlParent(): FormGroup {
    if (this.inputtedFormControl) {
      return this.inputtedFormControl.parent as FormGroup;
    } else {
      return new FormGroup({});
    }
  }

  public getFormControlName(): string {
    let formGroup: { [key: string]: AbstractControl; } | AbstractControl[];
    if (this.inputtedFormControl) {
      formGroup = this.inputtedFormControl.parent.controls;
    } else {
      formGroup = [];
    }
    return Object.keys(formGroup).find(name => this.inputtedFormControl === formGroup[name]);
  }

}

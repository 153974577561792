import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {ModalService} from 'src/app/shared/service/modal.service';
import {environment} from 'src/environments/environment';
import {ModalComponent} from '../modal.component';
import {DomService} from '../../shared/service/dom.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RadioOpts} from '../../shared/model/radio-options.model';
import {OtpService} from '../../shared/service/otp.service';
import {FormControlCustom} from '../../shared/form-control-custom';
import {FormService} from '../../shared/service/form.service';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent extends ModalComponent implements OnInit {

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public domService: DomService,
    public otpService: OtpService,
    public formService: FormService
  ) {
    super(
      modalService,
      applicationService,
      elm,
      cdr,
      renderer,
      domService
    );
  }

  env = environment;
  stopListening = new Subject<void>();
  formGroup: FormGroup;
  radioOpts: Array<{ radioOpt: RadioOpts, customLabel: string }> = [];

  otpCall: RadioOpts = new RadioOpts(); // null
  otpText: RadioOpts = new RadioOpts(); // null
  otpEmail: RadioOpts = new RadioOpts(); // null

  phoneIsActive = false;
  emailIsActive = false;

  codeIsSent = false;
  codeIsVerified = false;

  promptText = '';

  isVerifyInErrorState = false;
  isSendInErrorState = false;
  isVerifyButtonEnabled = true;

  hasBeenSentMessage: string;

  protected readonly FormControl = FormControl;

  ngOnInit() {
    this.formGroup = new FormGroup({
      otp_options_radio: new FormControlCustom<string>({ required: 'Select where you want your OTP to be sent.' }, '', [Validators.required]),
      otp_verify: new FormControlCustom<string>({ required: 'One Time Passcode is invalid, please try again or send a new code' }, '', [Validators.required])
    });

    this.modalService.triggerOtpModal.pipe(takeUntil(this.endSubscriptions)).subscribe(async (otpOptions) => {

      this.radioOpts = [];
      this.codeIsSent = false;

      // Build Email Text
      if (otpOptions.email?.value !== '') {
        this.emailIsActive = true;
        this.otpEmail.label = otpOptions.email.label;
        this.otpEmail.value = 'email|\\' + otpOptions.email.value;

        this.radioOpts.push({ radioOpt: this.otpEmail, customLabel: 'Email' });
      }

      // build phone text if present
      if (otpOptions.phone?.value !== '') {
        this.phoneIsActive = true;

        // Build Phone Call
        this.otpCall.label = otpOptions.phone.label;
        this.otpCall.value = 'call|\\' + otpOptions.phone.value;

        // Build Phone Text
        this.otpText.label = otpOptions.phone.label;
        this.otpText.value = 'sms|\\' + otpOptions.phone.value;

        this.radioOpts.push({ radioOpt: this.otpCall, customLabel: 'Call' });
        this.radioOpts.push({ radioOpt: this.otpText, customLabel: 'Text' });
      }

      switch (true) {
        case (this.emailIsActive && this.phoneIsActive): {
          this.promptText = 'Get your verification code by either phone or email:';
          break;
        }
        case (!this.emailIsActive && this.phoneIsActive): {
          this.promptText = 'Get your verification code by phone:';
          break;
        }
        case (this.emailIsActive && !this.phoneIsActive): {
          this.promptText = 'Get your verification code by email:';
          break;
        }
        default: {
          this.promptText = 'Get your verification code:';
          break;
        }
      }
      this.showModal(); // need to show modal first in order to stand up viewchild components
    });

  } // end OnInit

  cancelOtpModal() {
    this.closeModal();
    this.triggerStopListening();
    this.otpService.validatedOTPToken.next('');
  }

  triggerStopListening() {
    this.stopListening.next();
    this.stopListening.complete();
  }

  SendOtpCode(event) {
    event.preventDefault();

    this.formGroup.get('otp_options_radio').markAllAsTouched();
    this.formGroup.get('otp_options_radio').updateValueAndValidity();

    if (this.formGroup.get('otp_options_radio').valid) {
      this.formGroup.get('otp_verify').setValue('');
      const selectedRadioValue = this.formGroup.get('otp_options_radio').value;
      const otpType: string = selectedRadioValue.split('|\\')[0]; // otpType will be SMS or call
      const phoneNumber: string = selectedRadioValue.split('|\\')[1]; // The phone number at the time the code is sent

      this.otpService.optedInDisclaimer.next({ type: otpType, number: phoneNumber}); // Pass the type AND number for each trigger of the subject

      const selectedRadio = this.radioOpts.find(opt => {
        return opt.radioOpt.value === selectedRadioValue;
      });

      switch (otpType) {
        case 'sms':
          this.hasBeenSentMessage = `A text message with your 6-digit verification code was just sent to the phone number ${selectedRadio.radioOpt.label}.`;
          break;
        case 'call':
          this.hasBeenSentMessage = `A call with your 6-digit verification code was just made to the phone number ${selectedRadio.radioOpt.label}.`;
          break;
        default:
          this.hasBeenSentMessage = `A text message with your 6-digit verification code was just sent to the phone number ${selectedRadio.radioOpt.label}.`;
      }

      this.otpService.sendOTP(phoneNumber, otpType).subscribe({
        next: result => {
          if (result) {
            this.codeIsSent = true;
          } else {
            if (!environment.production){
              this.codeIsSent = true;
            }
            else {
              this.codeIsSent = false;
              this.isSendInErrorState = true;
            }
          }
        },
        error: () => {
          if (!environment.production){
            this.codeIsSent = true;
          }
          else {
            this.codeIsSent = false;
            this.isSendInErrorState = true;
          }
        }
      });

    } // end if this.formGroup.get('otp_options_radio').valid
  } // end SendOtpCode()

  verifyOtpCode(event) {
    event.preventDefault();

    this.formGroup.get('otp_verify').markAllAsTouched();
    this.formGroup.get('otp_verify').updateValueAndValidity();

    if (this.formGroup.get('otp_verify').valid) {
      this.isVerifyButtonEnabled = false;

      const otpString: string = this.formGroup.get('otp_options_radio').value.split('|\\')[1];
      const otpInputCode: string = this.formGroup.get('otp_verify').value;
      const otpType = otpString.includes('@') ? 'email' : 'phone';

      if (otpInputCode.length > 5) {
        this.otpService.verifyOTP(otpInputCode, otpString).subscribe(result => {
          if (result) {
            this.validOTPCodeSubmitted(otpType);

          } else {
            if (!environment.production && otpInputCode === '4348675309') {
              this.validOTPCodeSubmitted(otpType);
            }
            else {
              this.isVerifyInErrorState = true;
              this.isVerifyButtonEnabled = true;
            }
          }
        }, () => {
          if (!environment.production && otpInputCode === '4348675309')
          {
            this.validOTPCodeSubmitted(otpType);
          }
          else {
            this.isVerifyInErrorState = true;
            this.isVerifyButtonEnabled = true;
          }
        });
      } else {
        this.isVerifyInErrorState = true;
        this.isVerifyButtonEnabled = true;
      }
    }
  }

  validOTPCodeSubmitted(otpType: string) {
    this.isVerifyInErrorState = false;
    this.isVerifyButtonEnabled = true;
    this.codeIsVerified = true;

    this.closeModal();
    this.triggerStopListening();
    this.otpService.validatedOTPToken.next(otpType);
  }

  changeVerficationMethod(event) {
    event.preventDefault();

    this.codeIsSent = false;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AppIdService} from '../provider/app-id.service';

@Injectable({
  providedIn: 'root'
})
export class OtpService {
  public validatedOTPToken = new Subject<string>();
  public optedInDisclaimer = new Subject<OTP>();
  private otpBaseUrl = '/rest/otp';

  constructor(
    private http: HttpClient,
    private appIDService: AppIdService
  ) {
  }

  public sendOTP(otpPhoneNumber: string, otpType: string) {
    return this.http.post<boolean>(`${this.otpBaseUrl}?type=${otpType}`, otpPhoneNumber).pipe(debounceTime(500));
  }

  public verifyOTP(code: string, input: string): Observable<boolean> {
    const appID = this.appIDService.getAppId();
    return this.http.put<boolean>(`${this.otpBaseUrl}/${appID}/${code}`, input);
  }
}

export interface OTP {
  type: string;
  number: string;
}

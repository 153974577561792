import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Application} from 'src/app/shared/model/application.model';
import {IncompleteApp} from 'src/app/shared/model/applyLUApiObjects/incomplete-app.model';
import {AppIdService} from 'src/app/shared/provider/app-id.service';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {CookieService} from 'src/app/shared/service/cookie.service';
import {DomService} from 'src/app/shared/service/dom.service';
import {FormService} from 'src/app/shared/service/form.service';
import {IncompleteAppService} from 'src/app/shared/service/incomplete-app.service';
import {ModalService} from 'src/app/shared/service/modal.service';
import {ModalComponent} from '../modal.component';
import {NavigationService, optionalSection} from '../../shared/service/navigation.service';
import {MenuItem} from '../../sidebar/sidebar-menu/menu-item.model';
import {SectionStatus} from '../../shared/model/app-form-builder.model';

@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styleUrls: ['./resume-modal.component.scss']
})
export class ResumeModalComponent extends ModalComponent implements OnInit {

  showResumePreviousApplication = false;
  applicantName = '';
  applicantStartDate = '';
  applicantPercentComplete = 0;
  previousApplication: Application = new Application();
  tempFormGroup: FormGroup = new FormGroup({});
  prefillFormGroup: FormGroup = new FormGroup({});
  parsedFormObject: {};

  possibleSections: MenuItem[] = this.navigationService.generateNavItems();

  theIncompleteApp: IncompleteApp;

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public domService: DomService,
    public appIdService: AppIdService,
    public cookieService: CookieService,
    private incompleteAppService: IncompleteAppService,
    private formService: FormService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    private navigationService: NavigationService
  ) {
    super(
      modalService,
      applicationService,
      elm,
      cdr,
      renderer,
      domService
    );
  }

  async ngOnInit(): Promise<void> {

    this.modalService.triggerResumeModal.pipe(takeUntil(this.endSubscriptions)).subscribe(async (formGroup: FormGroup) => {
        this.prefillFormGroup = formGroup;
        const appIdCookie = this.cookieService.getCookie(this.cookieService.cookieEnum.APP_ID);
        const resumeKeyCookie = this.cookieService.getCookie(this.cookieService.cookieEnum.RESUME_KEY);

        (this.incompleteAppService.getIncompleteAppByResumeKey(appIdCookie, resumeKeyCookie)).subscribe({

          next: (incompleteApp: IncompleteApp) => {

            this.theIncompleteApp = incompleteApp;

            // if the application has been previously submitted start a new app automatically and return
            if (incompleteApp.webId && incompleteApp.webId.length > 0) {
              this.startNewApp();
              return;
            }

            // create deep copy of FormGroup
            this.tempFormGroup = this.formService.cloneAbstractControl(formGroup);
            this.parsedFormObject = JSON.parse(incompleteApp.formObject);

            // even if an IncompleteApp is found & returned, sometimes the appJson is null
            // Parse our data from appliocation to use for modal text
            const parsedResponse = JSON.parse(incompleteApp.appJson);
            this.previousApplication = new Application(parsedResponse);
            if (this.previousApplication.firstName && this.previousApplication.firstName.length > 0) {
              this.applicantName = ', ' + this.previousApplication.firstName;
            } else {
              this.applicantName = this.previousApplication.firstName;
            }
            // format the date
            const prevDateArray: string[] = new Date(incompleteApp.createdTimestamp).toDateString().split(' ');
            if (Number(prevDateArray[2]) < 10) {
              prevDateArray[2] = prevDateArray[2].substring(1, 2) + ',';
            } else {
              prevDateArray[2] = prevDateArray[2] + ',';
            }
            prevDateArray[0] = '';
            this.applicantStartDate = prevDateArray.join(' ');

            if (this.parsedFormObject) {

              if ((this.parsedFormObject['optional']?.sectionStatus as SectionStatus)?.visible) {
                this.possibleSections.push(optionalSection);
              }
              this.possibleSections = this.navigationService.updateMenuItemsFromFormValues(this.parsedFormObject, this.possibleSections);

              // Before patching value we need to generate any selected schools because patchValue does not fill in items that do not yet exist in FormArrays
              const previousEdFormGroup = this.tempFormGroup.get('previousEducation') as unknown as FormGroup;
              this.formService.replaceSchoolsInCurrentForm(previousEdFormGroup, this.parsedFormObject);
              // calculate the progress with invisible formGroup
              this.tempFormGroup.patchValue(this.parsedFormObject);


              if (this.parsedFormObject['data_fields'].appProgress) {
                this.applicantPercentComplete = this.parsedFormObject['data_fields'].appProgress;
              }
              // if the application is empty start a new app
              if (this.applicantPercentComplete > 0) {
                this.showModal();
              } else {
                // formObject is not null, hasn't been touched, but we have a valid app with ID, can still continue using that app without creating a new one
                this.appIdService.setProperties(incompleteApp);
              }
            } else {
              this.startNewApp();
            }

          },

          error: (e) => {
            this.startNewApp();
          }


        });

      }
    );
  }

  startNewApp() {
    this.appIdService.load().subscribe();
    this.cookieService.startNewAppSubject.next(true);
    this.closeModal();
  }

  prefillApplication() {
    this.applicationService.resumeApp(this.theIncompleteApp);

    let activeSectionName = 'yourDegree';

    // get the current active section
    // active section is determined in the same way as the sidebar-menu determines the .in-progress div
    const activeSection = this.possibleSections.find(section => section.active);
    if (activeSection) {
      activeSectionName = activeSection.name;
    }

    this.formService.prefillForm.next({formData: this.parsedFormObject, activeSectionName});

    this.closeModal();

  }
}

<ng-container [formGroup]="formGroup">
  <div class="luid-search-container mb-2">
    <div class="row">
      <div class="col">
        <label for="luid_search_field">
          <span class="input-label-text">Liberty ID <app-tooltip>Include the 8 digit ID number leading with an uppercase L. Example: L00012345</app-tooltip></span>
        </label>
      </div>
    </div>
    <div class="luid_search_row row">
      <div class="col-sm-auto">
        <input type="text"
        name="luid_search_field"
        id="luid_search_field"
        [placeholder]="'Example: L00012345'"
        [pattern]="luidPattern"
        #luidSearchInput
        (keyup.enter)="getStudentByID($event)"
        >
      </div>
      <div class="col-sm-auto">
        <button tabindex="0" (click)="getStudentByID($event)" type="button" class="btn btn-secondary">Search by LUID</button>
      </div>
    </div>
  </div>

  <div class="luid-results mt-2" *ngIf="theStudent && !preloaded && !searching && searchSucceeded">
    <p class="mb-2">
      <b>{{theStudent.firstName}} {{theStudent.middleName}} {{theStudent.lastName}}</b><br>
      Email: <b>{{theStudent.email}}</b><br>
      Phone: <b>{{theStudent.phoneNumber}}</b><br>
      Birthdate: <b>{{theStudent.birthDate}}</b><br>
      Gender: <b>{{theStudent.gender}}</b><br>
      Address: <b *ngIf="theStudent.address || theStudent.zip">{{theStudent.address}}<br>
      {{theStudent.city}}, {{theStudent.state}} {{theStudent.zip}}</b>
    </p>
    <button class="btn btn-primary btn-small" id="preload-by-student" (click)="studentPrefillApp(theStudent)">Preload App With Student Info</button>
  </div>

  <app-alert type="primary" *ngIf="searching">Searching... <img src="assets/icons/loading-icon.png" class="rotation" style="width: 20px;" alt="Loading icon"></app-alert>
  <app-alert type="danger" *ngIf="!luidSearchInput.validity.patternMismatch && !searchSucceeded && !searching && searchFinished">{{ errorMessage }}</app-alert>
  <app-alert type="danger"
     *ngIf="luidSearchInput.value.length > 0 && luidSearchInput.validity.patternMismatch &&
     !searchSucceeded && !searching && searchFinished"
    >{{ errorMessage }}
  </app-alert>
  <app-alert type="success" *ngIf="preloaded" text="messageText">Information Preloaded Successfully</app-alert>

  <input type="hidden" formControlName="luid_prefill_happened">
</ng-container>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  months = [
    { name: 'January', code: '01' },
    { name: 'February', code: '02' },
    { name: 'March', code: '03' },
    { name: 'April', code: '04' },
    { name: 'May', code: '05' },
    { name: 'June', code: '06' },
    { name: 'July', code: '07' },
    { name: 'August', code: '08' },
    { name: 'September', code: '09' },
    { name: 'October', code: '10' },
    { name: 'November', code: '11' },
    { name: 'December', code: '12' },
  ];

  datePattern = /^(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.]([0-9]{4})$/;

  splitDate(dateString: string): { month: string, day: string, year: string } {
    const returnedDate = { month: '', day: '', year: '' };
    if (this.datePattern.test(dateString)) {
      let birthArray = dateString.split('-');
      if (birthArray.length === 1) {
        birthArray = dateString.split('/'); // try again wtih this character
      }
      returnedDate.month = birthArray[0];
      returnedDate.day = birthArray[1];
      returnedDate.year = birthArray[2];
    }
    return returnedDate;
  }

  public formatDate(date: Date): string {
    let formattedDate = [date.getMonth() + 1,
    date.getDate(),
    date.getFullYear()].join('/') + ' ';

    // determine wether preceding 0 and change to 12hr format
    if (date.getHours() > 12) {
      formattedDate = formattedDate +
        '0' + date.getHours() % 12 + ':';
    } else if (date.getHours() > 10) {
      formattedDate = formattedDate + date.getHours() + ':';
    } else {
      formattedDate = formattedDate + '0' + date.getHours() + ':';
    }

    // determine if preceding 0
    if (date.getMinutes() < 10) {
      formattedDate = formattedDate +
        '0' + date.getMinutes() + ':';
    } else {
      formattedDate = formattedDate + date.getMinutes() + ':';
    }

    // determine if preceding 0
    if (date.getSeconds() < 10) {
      formattedDate = formattedDate + '0' + date.getSeconds() + ' ';
    } else {
      formattedDate = formattedDate + date.getSeconds() + ' ';
    }

    // determine if AM or PM
    if (date.getHours() > 12) {
      formattedDate = formattedDate + 'PM';
    } else {
      formattedDate = formattedDate + 'AM';
    }

    return formattedDate;
  }
}

<ng-container [formGroup]="getFormControlParent()">
  <div class="form-control" (focusout)="resetItemSearch()">
    <label *ngIf="inputLabelText" for="{{getFormControlName()}}">
      <h4 *ngIf="!smallerLabel" class="input-label-text">{{inputLabelText}}</h4>
      <span *ngIf="smallerLabel" class="input-label-text">{{inputLabelText}}</span>
    </label>

    <div class="search-block-wrap" *ngIf="getSelectedOptionsControl().value.length > 0">
      <div class="search-block" *ngFor="let item of getSelectedOptionsControl().value">
        <div class="search-block-remove" [attr.aria-label]="'Remove selection for '+item[displayProperty]" tabindex="0" (keyup.enter)="removeItem(item)" (click)="removeItem(item)"></div>{{item[displayProperty]}}
      </div>
    </div>

    <div class="search-input-wrap input-wrap" (focusin)="showItems()">
      <div aria-live="polite" role="status" class="screen-reader-only">{{filteredObjects.length}} suggestion{{filteredObjects.length !== 1 ? 's' : ''}} found. Use up and down arrows to review</div>

      <div class="validation-error-bar" *ngIf="formService.hasErrors(itemFormControl) && getSelectedOptionsControl().value.length === 0"></div>
      <input
        (click)="showItems()"
        class="search-input"
        role="combobox"
        #searchInput
        type="text"
        name="{{getFormControlName()}}"
        id="{{getFormControlName()}}"
        [attr.readonly]="nonSearchableSelect && !allowCustom ? 'true' : null"
        formControlName="{{getFormControlName()}}"
        (keydown.arrowup)="showItems(); changeHighlightedItem('UP', $event)"
        (keydown.arrowdown)="showItems(); changeHighlightedItem('DOWN', $event)"
        (keyup.enter)="addItem(null, $event)"
        (keyup)="searchOptions($event.target.value, $event)"
        autocomplete="chrome-off"
        maxlength="100"
        [attr.aria-activedescendant]="highlightedItem ? getFormControlName()+'-'+highlightedItem[valueProperty] : ''"
        [attr.aria-owns]="getFormControlName()+'-results'"
        [attr.aria-invalid]="formService.hasErrors(itemFormControl) && getSelectedOptionsControl().value.length === 0 ? 'true': null"
        [attr.aria-describedby]="selectedOptionsControlName()+'-error'"
        [attr.aria-autocomplete]="'both'"
      >
        <div *ngIf="itemFormControl.value === '' || itemFormControl.value == null" class="placeholder">{{ inputtedObjectList.length === 0 ? 'Type' : placeholder }}</div>

        <app-icon class="dropdown-arrow-icon icon" aria-label="Dropdown arrow icon" [icon]="'dropdown-arrow'"></app-icon>
    </div>

    <div class="search-input-results" #searchResults *ngIf="displayItems">
      <ul role="listbox" *ngIf="!maxReached && !blocked" id="{{getFormControlName()}}-results">
        <li
          role="option"
          tabindex="-1"
          id="{{getFormControlName()+'-'+item[valueProperty]}}"
          [attr.aria-label]="item[displayProperty]"
          [attr.aria-selected]="highlightedItem && highlightedItem[displayProperty] === item[displayProperty] ? 'true' : 'false'"
          *ngFor="let item of getDifference(filteredObjects, getSelectedOptionsControl().value)"
          attr.data-code="{{item[valueProperty]}}"
          (mousedown)="addItem(item, $event)"
          [ngClass]="{'selected' : highlightedItem && highlightedItem[valueProperty] === item[valueProperty]}"
        >
          <span [innerHTML]="item[displayProperty] | highlight : searchString"></span>
        </li>

        <li
          role="option"
          tabindex="-1"
          class="not-listed-add"
          *ngIf="allowCustom && searchString.trim() !== ''"
          (mousedown)="addItem(false, $event)"
          (keyup.enter)="addItem(false, $event)"
          [ngClass]="{'selected' : highlightedItem === undefined}"
        >
          <b>{{ searchString }}</b>
          <span class="add-span">Add</span>
        </li>

        <li *ngIf="filteredObjects.length === 0 && !allowCustom && searchString !== ''">No results</li>

        <li *ngIf="notListedText && allowCustom && searchString === ''" class="not-listed-prompt">
          <b>{{notListedText}}</b><br>
          Type the full name and add
        </li>

      </ul>
      <ul *ngIf="maxReached && !blocked">
        <li style="pointer-events: none;">Max reached</li>
      </ul>
      <ul *ngIf="blocked">
        <li style="pointer-events: none;">Remove to see other options</li>
      </ul>
    </div>

    <span class="error" id="{{selectedOptionsControlName()}}-error" role="alert" *ngIf="formService.hasErrors(itemFormControl) && getSelectedOptionsControl().value.length === 0">{{ errorText || 'Choose at least one item' }}</span>

  </div>

  <input type="hidden" name="{{ selectedOptionsControlName() }}" formControlName="{{ selectedOptionsControlName() }}">
</ng-container>

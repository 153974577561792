import { Component, OnDestroy, OnInit } from '@angular/core';
import { Program } from 'src/app/shared/model/program.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { NationService } from 'src/app/shared/service/nation.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { TermService } from 'src/app/shared/service/term.service';
import { Subscription } from 'rxjs';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake.min';
import {AnalyticsService} from '../../shared/service/analytics.service';
import {AppIdService} from '../../shared/provider/app-id.service';

pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  }
};

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit, OnDestroy {

  html;
  pdf;
  name: string;
  selectedProgram = new Program();
  programCampus = '';
  gender: string;
  isCellphone: string;
  countryOfCitizenship: string;
  phoneNumber = '';

  programSub = new Subscription();

  constructor(
    public applicationService: ApplicationService,
    public programService: ProgramService,
    private termService: TermService,
    private nationService: NationService,
    private analyticsService: AnalyticsService,
    private appIDService: AppIdService
  ) {
    this.selectedProgram.degreeLevel = '';
    this.selectedProgram.displayDescriptionNoDegreeCode = '';
    this.selectedProgram.programCode = '';
  }

  ngOnInit(): void {

    this.selectedProgram = this.programService.getCurrentProgram();
    this.selectedProgram.campCode === 'D' ? this.programCampus = 'Online' : this.programCampus = 'Residential';

    this.programSub = this.programService.getProgramSub().subscribe(program => {
      this.selectedProgram = program;
      this.selectedProgram.campCode === 'D' ? this.programCampus = 'Online' : this.programCampus = 'Residential';
    });
  }

  ngOnDestroy() {
    this.programSub.unsubscribe();
  }

  generatePdf() {
    this.pushPdfDataLayer();
    dayjs.extend(localizedFormat);
    const today = dayjs().format('LLL');

    this.applicationService.application.gender === 'M' ? this.gender = 'Male' : this.gender = 'Female';
    this.applicationService.application.phones.forEach(phone => {
      phone.type === 'CL' ? this.isCellphone = 'Yes' : this.isCellphone = 'No';
    });
    this.applicationService.application.nation ? this.countryOfCitizenship = this.nationService.findByCode(this.applicationService.application.nation).name : this.countryOfCitizenship = 'United States';
    if (this.applicationService.application.phones[0].area.length === 0 && this.applicationService.application.phones[0].access.length > 0) {
      this.phoneNumber = this.applicationService.application.phones[0].access;
    } else {
      this.phoneNumber = `(${this.applicationService.application.phones[0].area}) ${this.applicationService.application.phones[0].phoneNumber}`;
    }

    // DON'T MESS WITH THIS FORMATTING, IT MUST BE DONE THIS WAY TO LOOK RIGHT
    let htmlString = `
    <div class="heading mb-5">Program Information:</div>
      <div class="ml-10 mb-5">Campus: <span class="underline">${this.programCampus}</span></div>
      <div class="ml-10 mb-5">Degree Level: <span class="underline">${this.selectedProgram.degreeLevel}</span></div>
      <div class="ml-10 mb-5">Program of Study: <span class="underline">${this.selectedProgram.displayDescriptionNoDegreeCode}   (${this.selectedProgram.programCode})</span>                <span>Starting Term: <span class="underline">${this.termService.getTermNameByCode(this.applicationService.application.term)}</span></span>
      </div>
    <div class="heading mb-5">Personal Information:</div>
      <div class="ml-10 mb-5">Name: <span class="underline">${this.applicationService.application.firstName + ' ' + this.applicationService.application.lastName}</span></div>
      <div class="ml-10">Address: <span class="underline">${this.applicationService.application.addresses[0].address1}</span></div>
      <div class="addresses"><span class="underline">${this.applicationService.application.addresses[0].city}   ${this.applicationService.application.addresses[0].state}   ${this.applicationService.application.addresses[0].zip}</span></div>
      <div class=" mb-5 addresses"><span class="underline">${this.nationService.findByCode(this.applicationService.application.addresses[0].nationCode).name}</span></div>
      <div class="ml-10 mb-5">Email: <span class="underline">${this.applicationService.application.emails[0].emailAddress}</span></div>
      <div class="ml-10 mb-5">Phone: <span class="underline">${this.phoneNumber}</span>      Cellphone: <span class="underline">${this.isCellphone}</span></div>
      <div class="ml-10 mb-5">Gender: <span class="underline">${this.gender}</span></div>
      <div class="ml-10 lastItem">Birthdate: <span class="underline">${this.applicationService.application.birthDate}</span></div>
    <div class="heading mb-5">Background Information:</div>
      <div class="ml-10 mb-5">Country of Citizenship: <span class="underline">${this.countryOfCitizenship}</span></div>
      <div class="ml-10 mb-5">Plan on using Federal Financial Aid: <span class="underline">${this.applicationService.getAnswer('305')}</span></div>
      <div class="ml-10 lastItem">Have you or your spouse served in the U.S. Armed Forces: <span class="underline">${this.applicationService.getAnswer('308')}</span></div>
    <div class="heading mb-5">Academic Information</div>
      <div class="ml-10 mb-5">Highschool Name: <span class="underline">${this.applicationService.application.highSchools[0].highSchoolName}</span>          State: <span class="underline">${this.applicationService.application.highSchools[0].state}</span></div>
    `;

    if (this.applicationService.application.highSchools.length > 0) {
      htmlString += `<div class="ml-10 lastItem">Name on Diploma: <span class="underline">${this.applicationService.getAnswer('242')} ${this.applicationService.getAnswer('243')}</span>      Graduation Year: <span class="underline">${this.applicationService.application.highSchools[0].exitDate.substr(6, 9)}</span></div>`;
    }

    htmlString += `
      <div class="heading mb-5">Additional Information:</div>
        <div class="ml-10 lastItem">I have read and agree to the Institutional Agreement and Terms <span class="underline">         </span></div>
        <div class="heading lastItem">Applicant Signature:<span class="underline">                                                       </span>        Date: <span class="underline">                         </span></div>
        <div class="bold lastItem">Print, Sign, and Mail to</div>
        <div class="ml-20">Liberty University</div>
        <div class="ml-20">Admissions Verification</div>
        <div class="ml-20">1971 University Blvd MSC Box 710686</div>
        <div class="ml-20 mb-30">Lynchburg, VA 24515</div>
      <hr>
       Source Code: D85626 <span class="alignRight alignToAboveText">${today}</span>
    `;


    this.html = htmlToPdfmake(htmlString);

    this.pdf = {
      content: this.html,
      defaultStyle: {
        font: 'Roboto'
      },
      styles: {
        'alignToAboveText': {
          marginTop: -14
        },
        'alignLeft': {
          alignment: 'left'
        },
        'alignRight': {
          alignment: 'right'
        },
        'heading': {
          fontSize: 16,
          bold: true,
        },
        'underline': {
          decoration: 'underline'
        },
        'addresses': {
          marginLeft: 60
        },
        'mb-5': {
          marginBottom: 10
        },
        'mb-30': {
          marginBottom: 30
        },
        'ml-10': {
          marginLeft: 10
        },
        'ml-20': {
          marginLeft: 20
        },
        'lastItem': {
          marginBottom: 15
        },
        'bold': {
          bold: true
        },
        'right': {
          right: 0
        }
      }
    };
    pdfMake.createPdf(this.pdf).open();
  }

  pushPdfDataLayer() {
    const dataLayerObj = {
      event: 'GTMevent',
      eventCategory: 'Download PDF of App',
      eventWebId: this.appIDService.getAppId(),
    };

    const campus = this.programService.getCampusByProgramCode(this.applicationService.application.majorFoses[0].programCode);
    if (campus === 'D') {
      dataLayerObj['campus'] = 'Online';
    } else if (campus === 'R') {
      dataLayerObj['campus'] = 'Residential';
    }
    this.analyticsService.dataLayerPush(dataLayerObj);
  }
}

import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Application} from 'src/app/shared/model/application.model';
import {IncompleteApp} from 'src/app/shared/model/applyLUApiObjects/incomplete-app.model';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {FormService} from 'src/app/shared/service/form.service';
import {IncompleteAppService} from 'src/app/shared/service/incomplete-app.service';
import {DomService} from '../../../shared/service/dom.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-app-id-search',
  templateUrl: './app-id-search.component.html',
  styles: [
    '.app_id_search { align-items: center; }',
    '.appid { padding: 9px 16px; }',
    '#app_id_search_field { width: 270px; }'
  ]
})
export class AppIdSearchComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() prefillEvent = new EventEmitter<boolean>();

  getAppSubscription: Subscription;
  endSubscriptions = new Subject<void>();
  isAppSubmitted = false;
  searchSuccess = false;
  searched = false;
  searching = false;
  preloaded = false;
  errorMessage = '';

  theApp = new Application();
  theForm = {};
  thePidm: string;

  constructor(
    private applicationService: ApplicationService,
    private incompleteAppService: IncompleteAppService,
    private formService: FormService,
    private domService: DomService
  ) {
  }

  ngOnInit(): void {
    this.formGroup.addControl('app_id_search', new FormControl<string>(''));
  }

  ngOnDestroy(): void {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }
  getAppById(event?) {
    if (event) {
      event.preventDefault();
    }
    this.domService.selectElementAsRoot('#app_id_search_field').blur();
    const id = this.formGroup.get('app_id_search').value;

    if (!id) {
      return false;
    }

    this.preloaded = false;
    this.theApp = null;
    this.searching = true;
    this.searched = false;

    // Unsubscribe to prevent memory leaks
    if (this.getAppSubscription) {
      this.getAppSubscription.unsubscribe();
    }

    this.getAppSubscription = this.incompleteAppService.getAppById(id).pipe(takeUntil(this.endSubscriptions),
      finalize(() => {
        this.searching = false;
        this.searched = true;
      })
    ).subscribe({
      next: (response: IncompleteApp) => {
        if (!response.id) {
          this.errorMessage = 'No application found with an ID of ' + id;
          this.searchSuccess = false;
          this.searched = true;
          return;
        }

        const parsedResponse = JSON.parse(response?.appJson || '{}');
        this.theApp = new Application(parsedResponse);
        this.theForm = JSON.parse(response?.formObject || '{}');

        this.thePidm = response?.pidm || null;
        this.searchSuccess = !!response.id;
        this.isAppSubmitted = !!response?.webId;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.errorMessage = 'No application found with an ID of ' + id;
        } else if (error.status === 401 || error.status === 403) {
          this.errorMessage = 'Unauthorized';
        } else if (error.status === 503) {
          this.errorMessage = 'Sorry, the Application Service is unavailable';
        } else {
          this.errorMessage = 'Sorry, there was an error with this request, please reach out to support';
        }
        this.searchSuccess = false;
        this.searched = true;
      },
    });
  }

  prefillForm(application: Application) {
    const prefillApp = this.formService.prefillFromApplication(application);

    this.applicationService.application = prefillApp;

    this.applicationService.prefillApplication.next(prefillApp);
    this.formService.prefillForm.next({formData: this.theForm, activeSectionName: 'yourDegree'});

    this.preloaded = true;
    if (this.thePidm) {
      this.applicationService.pidm = this.thePidm;
    }
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { Subject } from 'rxjs';
import { Term } from 'src/app/shared/model/term.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { takeUntil } from 'rxjs/operators';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';

@Component({
  selector: 'app-military-questions',
  templateUrl: './military-questions.component.html',
  styles: ['.military-input { max-width: 257px;}' ]
})
export class MilitaryQuestionsComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({});

  militaryStatuses: Term[] = [
    { name: 'Active Duty', value: 'MLAD' },
    { name: 'National Guard', value: 'MLNG' },
    { name: 'Reserves', value: 'MLRS' },
    { name: 'Discharged', value: 'MLDC' },
    { name: 'Retired', value: 'MLRT' },
    { name: 'Spouse', value: 'MLSP' },
    { name: 'Child', value: 'MLCD' },
  ];

  militaryBranches: Term[] = [
    { name: 'Air Force', value: 'MBAF' },
    { name: 'Army', value: 'MBAR' },
    { name: 'Coast Guard', value: 'MBCG' },
    { name: 'Marine Corps', value: 'MBMA' },
    { name: 'Navy', value: 'MBNV' },
    { name: 'Space Force', value: 'MBSF'}
  ];

  payGrades: Term[] = [
    { name: 'E1', value: 'MRE1' },
    { name: 'E2', value: 'MRE2' },
    { name: 'E3', value: 'MRE3' },
    { name: 'E4', value: 'MRE4' },
    { name: 'E5', value: 'MRE5' },
    { name: 'E6', value: 'MRE6' },
    { name: 'E7', value: 'MRE7' },
    { name: 'E8', value: 'MRE8' },
    { name: 'E9', value: 'MRE9' },
    { name: 'CW1', value: 'MRC1' },
    { name: 'CW2', value: 'MRC2' },
    { name: 'CW3', value: 'MRC3' },
    { name: 'CW4', value: 'MRC4' },
    { name: 'CW5', value: 'MRC5' },
    { name: 'O1', value: 'MRO1' },
    { name: 'O2', value: 'MRO2' },
    { name: 'O3', value: 'MRO3' },
    { name: 'O4', value: 'MRO4' },
    { name: 'O5', value: 'MRO5' },
    { name: 'O6', value: 'MRO6' },
    { name: 'O7', value: 'MRO7' },
    { name: 'O8', value: 'MRO8' },
    { name: 'O9', value: 'MRO9' },
    { name: 'O10', value: 'MROX' }
  ];

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  endSubscriptions = new Subject<void>();

  constructor(
    public formService: FormService,
    public appIdService: AppIdService,
    public programService: ProgramService
  ) { }

  ngOnInit(): void {

    this.formGroup.get('active_military').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (val === 'Yes') {
        this.formService.isActiveMilitary = true;
        this.formGroup.get('military_status').enable();
        this.formGroup.get('military_branch').enable();
        this.formGroup.get('military_tuition').enable();
        this.formGroup.get('military_tuition').setValue('No');
        this.formGroup.get('veterans_affairs_benefits').enable();
        this.formGroup.get('veterans_affairs_benefits').setValue('No');
      } else {
        this.disableMostMilitaryQuestions();
      }
    });

    this.formGroup.get('military_status').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (val !== '' && val !== 'MLSP' && val !== 'MLCD') {
        this.formGroup.get('military_paygrade').enable();
      } else {
        this.formGroup.get('military_paygrade').disable();
        this.formGroup.get('military_paygrade').setValue('');
      }
    });


    this.formService.getUpdatedCampusSub().pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

    this.formService.usCitizenSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

    this.formService.greenCardHolderSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

    this.maybeEnable();

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeEnable() {
    if (this.formService.currentCampus === 'R' && !this.formService.isUsCitizen && !this.formService.greenCardHolder ) {
      this.disableActiveMilitary();
      this.disableMostMilitaryQuestions();
    } else {
      this.enableActiveMilitary();
    }
  }

  disableMostMilitaryQuestions() {
    this.formGroup.get('military_status').setValue('');
    this.formGroup.get('military_branch').setValue('');
    this.formGroup.get('military_paygrade').setValue('');
    this.formGroup.get('military_tuition').setValue('');
    this.formGroup.get('veterans_affairs_benefits').setValue('');
    this.formGroup.get('military_status').disable();
    this.formGroup.get('military_branch').disable();
    this.formGroup.get('military_paygrade').disable();
    this.formGroup.get('military_tuition').disable();
    this.formGroup.get('veterans_affairs_benefits').disable();
    this.formService.isActiveMilitary = false;
  }

  enableActiveMilitary() {
    this.formGroup.get('active_military').enable();
  }

  disableActiveMilitary() {
    this.formGroup.get('active_military').setValue('No');
    this.formGroup.get('active_military').disable();
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileMenuComponent implements OnInit {

  progressNumber = this.formService.formProgress;
  hasScrolled = false;

  constructor(
    public applicationService: ApplicationService,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.formService.emitProgress.subscribe(progress => {
      this.progressNumber = progress;
    });
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    this.hasScrolled = window.scrollY > 0 && window.innerWidth <= 1160;
  }

}

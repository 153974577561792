<ng-container [formGroup]="formGroup">
  <div class="form-control use-has-errors-validation">
    <div class="relative">
      <div class="validation-error-bar"
        *ngIf="formService.hasErrors(formGroup.get('phoneNumberInput'))">
      </div>
      <label for="phoneNumber">
        <span class="input-label-text">Telephone</span>
        <input
          [ngClass]="{'has-errors': formService.hasErrors(formGroup.get('phoneNumberInput'))}"
          type="tel"
          (keydown.space)="$event.preventDefault()"
          name="phoneNumber"
          id="phoneNumber"
          formControlName="phoneNumberInput"
          (input)="phoneNumberUpdated($event.target.value, $event.inputType)"
          (blur)="checkForWhitespace()"
          aria-describedby="phone-error"
          [attr.aria-invalid]="formGroup.get('phoneNumberInput').touched && formGroup.get('phoneNumberInput').errors ? 'true': null"
        >
      </label>
      <span class="error" id="phone-error" *ngIf="formService.hasRequiredError(formGroup.get('phoneNumberInput'))">Enter a phone number</span>
      <ng-container *ngIf="formService.hasErrors(formGroup.get('phoneNumberInput')) && !formService.hasRequiredError(formGroup.get('phoneNumberInput'))">
        <span class="error" id="phone-error">{{getInternationalErrorMessage()}}</span>
      </ng-container>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchableSelectDropDownComponent } from './searchable-select-drop-down.component';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../../shared/shared.module';
import { IconModule } from 'src/app/shared/component/icon/icon.module';



@NgModule({
  declarations: [SearchableSelectDropDownComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SharedModule,
    IconModule
  ],
  exports: [SearchableSelectDropDownComponent]
})
export class SearchableSelectDropDownModule { }

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { Program } from '../../../shared/model/program.model';
import { Subscription } from 'rxjs';
import { ProgramService } from 'src/app/shared/service/program.service';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';

@Component({
  selector: 'app-college-credits-radio',
  templateUrl: './college-credits-radio.component.html',
  styles: [
  ]
})
export class CollegeCreditsRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

  selectedProgram: Program;

  programSub = new Subscription();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor(
    public formService: FormService,
    private programService: ProgramService
  ) { }

  ngOnInit() {

    this.programSub = this.programService.getProgramSub().subscribe(program => {
      this.selectedProgram = program;

      if (this.formGroup.get('college_credits_radio')) {
        if ((this.selectedProgram.degreeLevel === 'Bachelor' || this.selectedProgram.degreeLevel === 'Associate') && this.selectedProgram.campCode === 'D') {
          this.formGroup.get('college_credits_radio').enable();
        } else {
          this.formGroup.get('college_credits_radio').setValue('No');
          this.formGroup.get('college_credits_radio').disable();
        }
      }
    });

  }

  ngOnDestroy() {
    this.programSub.unsubscribe();

  }

}

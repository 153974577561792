import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { GenericInputModule } from '../generic/generic-input.module';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { CustomProgramQuestionsComponent } from './custom-program-questions.component';


@NgModule({
  declarations: [
    CustomProgramQuestionsComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    IconModule,
    GenericInputModule,
    SharedModule,
  ],
  exports: [
    CustomProgramQuestionsComponent
  ]
})
export class CustomProgramQuestionsModule { }

<ng-container [formGroup]="formGroup">
  <div
    *ngIf="this.sectionErrors.length > 0"
    class="error-container"
    tabindex="0"
    role="alert"
    aria-live="assertive"
  >
    <div class="problems"><b>Address the following to continue:</b></div>
    <ul>
      <li *ngFor="let error of sectionErrors" (click)="formService.navigateToControl.next(error)" tabindex="0" [attr.data-controlName]="error.controlName">{{ error.message }}</li>
    </ul>

  </div>
</ng-container>

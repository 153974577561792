import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HighlightSearch } from './pipe/highlight.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipComponent } from './component/tooltip/tooltip.component';
import { DecorativeCardComponent } from 'src/app/decorative-card/decorative-card.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { IconModule } from './component/icon/icon.module';
import { AlertModule } from '../alert/alert.module';
import { AlertComponent } from '../alert/alert.component';
import { DirectivesModule } from './directive/directives.module';

@NgModule({
  declarations: [
    HighlightSearch,
    SafeHtmlPipe,
    TooltipComponent,
    DecorativeCardComponent,
    SanitizeHtmlPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    AlertModule,
    DirectivesModule
  ],
  exports: [
    HighlightSearch,
    SafeHtmlPipe,
    ReactiveFormsModule,
    TooltipComponent,
    DecorativeCardComponent,
    SanitizeHtmlPipe,
    AlertComponent
  ]
})
export class SharedModule { }

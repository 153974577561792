import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';

@Component({
  selector: 'app-us-resident-radio',
  templateUrl: './us-resident-radio.component.html',
  styles: ['fieldset{ margin-bottom: 0 !important }']
})
export class UsResidentRadioComponent {

  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() usResidentSelection = new EventEmitter<string>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor() { }

  public emitAnswer(answer: 'Yes' | 'No') {
    this.usResidentSelection.emit(answer);
  }

}

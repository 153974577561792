import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { CookieService } from 'src/app/shared/service/cookie.service';
import { FormService } from 'src/app/shared/service/form.service';
import { NationService } from 'src/app/shared/service/nation.service';

@Component({
  selector: 'app-felon-radio',
  templateUrl: './felon-radio.component.html',
  styles: [
  ]
})
export class FelonRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

  textboxHeader = 'Please provide the following details: Was it a misdemeanor or a felony? What did you do to receive it and how long ago was it? Provide a statement that you will comply with the Liberty Way. (2,000 characters max)';

  endSubscriptions = new Subject<void>();

  radioOpts: RadioOpts[] = this.defaultOpts();

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService,
    public nationService: NationService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('felonRadio', new FormControlCustom<string>({ required: 'Indicate if you have ever been convicted of a felony or misdemeanor'}, { value: '', disabled: true }));

    this.formGroup.get('felonRadio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.formService.updatedCampus.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (val === 'R') {
        this.formGroup.get('felonRadio').enable();
        this.formGroup.get('felonRadio').setValidators([Validators.required]);
      } else {
      this.formGroup.get('felonRadio').setValue('');
      this.formGroup.get('felonRadio').disable();
      this.formGroup.get('felonRadio').clearValidators();
      }
    });

    this.formService.UpdatedAddress.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeShowDeclineOption();
    });

    this.cookieService.GDPRCookiesObtained.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeShowDeclineOption();
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeShowDeclineOption() {
    if (this.nationService.isEU() === false && this.formGroup.get('felonRadio').value === 'Decline') {
      this.formGroup.get('felonRadio').setValue('');
    }
    if (this.nationService.isEU() || this.cookieService.userIsEu) {
      this.radioOpts = this.optsWithDecline();
    } else {
      this.radioOpts = this.defaultOpts();
    }
  }

  defaultOpts(): RadioOpts[] {
    return [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ];
  }

  optsWithDecline(): RadioOpts[] {
    return [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
      { label: 'Decline to Answer', value: 'Decline' }
    ];
  }

}

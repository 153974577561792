<div>
  <fieldset>

    <legend>
      <h2 class="essayHeading">Admission Essay</h2>
      <h4 class="afterHeading">These questions are optional at this time; however, answering them now can speed up the
        admission process.</h4>
    </legend>

    <app-textbox
      [formGroup]="formGroup"
      [nameForControl]="'beliefsEssay'"
      [requiredErrorMessage]="'You have not met the word count criteria for Belief Essay'"
      [required]="false"
      [textboxHeader]="beliefHeader"
      [minWords]="150"
      [textboxExample]="beliefExample"
    >
    </app-textbox>

    <app-textbox
      [formGroup]="formGroup"
      [nameForControl]="'missionEssay'"
      [minWords]="150"
      [requiredErrorMessage]="'You have not met the word count criteria for Mission Essay'"
      [required]="false"
      [textboxHeader]="missionHeader"
      [textboxExample]="missionExample"
    >
    </app-textbox>
  </fieldset>
</div>


import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Student } from 'src/app/shared/model/student.model';
import { DateService } from 'src/app/shared/service/date.service';
import { FormService } from 'src/app/shared/service/form.service';
import { StudentService } from 'src/app/shared/service/student.service';

const date = new Date();

@Component({
  selector: 'app-birth-date',
  templateUrl: './birth-date.component.html',
  styleUrls: ['./birth-date.component.scss']
})
export class BirthDateComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});

  endSubscriptions = new Subject<void>();

  public daysInMonth: string[] = [];
  months = [
    { name: 'January', code: '01', days: 31 },
    { name: 'February', code: '02', days: 29 },
    { name: 'March', code: '03', days: 31 },
    { name: 'April', code: '04', days: 30 },
    { name: 'May', code: '05', days: 31 },
    { name: 'June', code: '06', days: 30 },
    { name: 'July', code: '07', days: 31 },
    { name: 'August', code: '08', days: 31 },
    { name: 'September', code: '09', days: 30 },
    { name: 'October', code: '10', days: 31 },
    { name: 'November', code: '11', days: 30 },
    { name: 'December', code: '12', days: 31 },
  ];

  birthDay = '';
  birthMonth = '';
  maxDays = 31;

  datePattern = /^(0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[-](19|20)\d\d$/;

  currentDay = date.getDate();
  currentMonth = date.getMonth();
  currentYear = date.getFullYear();
  maximumYear = this.currentYear;
  minimumYear = this.maximumYear - 100;

  leapYearPattern = /^(0?[1-9]|[1]\d|2[0-9])$/;
  nonLeapYearPattern = /^(0?[1-9]|[1]\d|2[0-8])$/;
  monthWith30 = /^(0?[1-9]|[12]\d|3[0])$/;
  monthWith31 = /^(0?[1-9]|[12]\d|3[01])$/;
  yearPattern = /^(?=\d{4}$)(1|2)\d+/;


  constructor(
    public formService: FormService,
    private dateService: DateService,
    private studentService: StudentService
  ) { }

  ngOnInit(): void {


    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {

      const dayVal = this.formGroup.get('birth_day').value.toString();

      if (dayVal.length === 1) {
        this.formGroup.get('birth_day').setValue('0' + dayVal);
      }
      if (dayVal.length > 0) {
        this.formGroup.get('birth_day').markAsTouched();
      }

      const monthVal = this.formGroup.get('birth_month').value.toString();
      if (monthVal.length === 1) {
        this.formGroup.get('birth_month').setValue('0' + monthVal);
      }
      if (monthVal.length > 0) {
        this.setMaxDays(this.formGroup.get('birth_year').value, parseInt(monthVal, 10));
      }

      if (this.formGroup.get('birth_year').value !== ''){
        this.formGroup.get('birth_year').markAsTouched();
      }

    });

    this.formGroup.get('birth_year').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(value => {
      this.setMaxDays(value, parseInt(this.formGroup.get('birth_month').value, 10));
    });

    merge( // using RXJS merge for all instead of just this.formGroup because it would otherwise also trigger on changes made to the hidden input and cause an infinite loop
      this.formGroup.get('birth_month').valueChanges,
      this.formGroup.get('birth_day').valueChanges,
      this.formGroup.get('birth_year').valueChanges
    ).pipe(
      takeUntil(this.endSubscriptions),
      debounceTime(80)
    )
    .subscribe(() => {
      this.checkIfBirthdateIsAfterToday();
    });

    this.studentService.emitStudent.pipe(takeUntil(this.endSubscriptions)).subscribe((student: Student) => {
      if (student.birthDate) {
        this.setBirthDate(student.birthDate);
      }
    });

  }

  ngOnDestroy() {

    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  getBirthMonth(value: string) {
    this.birthMonth = value;
    this.setMaxDays(this.formGroup.get('birth_year').value, parseInt(value, 10));
  }

  getBirthDay(value: string) {

    const parsedValue = parseInt(value, 10);
    const birthMonth = this.formGroup.get('birth_month').value;

    if (birthMonth.length > 0) {
      this.setMaxDays(this.formGroup.get('birth_year').value, parseInt(birthMonth, 10));
    } else {
      this.maxDays = 31;
    }

    if (parsedValue < 10 && value.length === 1) { // if less than 10 and only has 1 digit
      value = '0' + value;
      this.formGroup.get('birth_day').setValue(value, { onlySelf: false, emitEvent: false, emitViewToModelChange: false });
    }
    this.birthDay = value;
  }

  // not currently used by this or the form components, but a handy function for prefill, leave just in case
  setBirthDate(dateString: string) {
    const fullDate: { month: string; day: string; year: string; } = this.dateService.splitDate(dateString);

    if (Object.keys(fullDate).length === 3) {
      this.formGroup.get('birth_month').setValue(fullDate.month);
      this.getBirthMonth(fullDate.month);
      this.formGroup.get('birth_day').setValue(fullDate.day);
      this.getBirthDay(fullDate.day);
      this.formGroup.get('birth_year').setValue(fullDate.year);
    }
  }

  checkIfBirthdateIsAfterToday() {
    const isValidControl = this.formGroup.get('birth_date_is_valid');

    const inputMonth = parseInt(this.formGroup.get('birth_month').value);
    const inputDay = parseInt(this.formGroup.get('birth_day').value);
    const inputYear = parseInt(this.formGroup.get('birth_year').value);

    const inputDate = new Date(inputYear, inputMonth - 1, inputDay); // currentMonth is 0 indexed but the input val we get is 1 indexed

    // check to see if the fullDate provided takes place after todays date
    if (
      (inputMonth && inputDay && inputYear) // have all values
      && inputDate.getTime() > date.getTime()
    ) {
      isValidControl.setValue('No');
    } else {
      isValidControl.setValue('Yes');
    }

  }

  setMaxDays(year: number, month: number) {
    if (month === 2 && (!(year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)))) {
      this.formGroup.get('birth_day').setValidators([ Validators.required, Validators.pattern(this.nonLeapYearPattern) ]);
      this.formGroup.get('birth_day').updateValueAndValidity();
      this.maxDays = 28;
    } else if (this.months.length > 0 && month) {
      if (this.months[month - 1].days === 29) {
        this.maxDays = 29;
        this.formGroup.get('birth_day').setValidators([ Validators.required, Validators.pattern(this.leapYearPattern) ]);
        this.formGroup.get('birth_day').updateValueAndValidity();
      } else if (this.months[month - 1].days === 30) {
        this.maxDays = 30;
        this.formGroup.get('birth_day').setValidators([ Validators.required, Validators.pattern(this.monthWith30) ]);
        this.formGroup.get('birth_day').updateValueAndValidity();
      } else if (this.months[month - 1].days === 31) {
        this.maxDays = 31;
        this.formGroup.get('birth_day').setValidators([ Validators.required, Validators.pattern(this.monthWith31) ]);
        this.formGroup.get('birth_day').updateValueAndValidity();
      }
    }
  }
}

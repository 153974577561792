import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'ApplyLU';

  appInitializing = true;

  ngAfterViewInit() {

    // doesn't work without timeout, entire container will suddenly pop in
    setTimeout(() => {
      this.appInitializing = false;
    }, 1);
  }

}

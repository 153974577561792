import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {SearchService} from 'src/app/shared/service/search.service';
import {FormService} from '../../../../shared/service/form.service';
import {DomService} from '../../../../shared/service/dom.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-multi-searchable-select-dropdown',
  templateUrl: './multi-searchable-select-dropdown.component.html',
  styleUrls: ['./multi-searchable-select-dropdown.scss']
})
export class MultiSearchableSelectDropdownComponent<T> implements OnInit, OnDestroy {

  @Input() public itemFormControl: FormControl<any>;
  @Input() public inputtedObjectList = new Array<T>();
  @Input() public inputLabelText: string;
  @Input() public displayProperty = 'name';
  @Input() public valueProperty = 'value';
  @Input() public maxSelect: number;
  @Input() public blocker: string; // specify option value to serve as a blocker. If that option is selected, will remove other options & block further selections til removed
  @Input() public smallerLabel = false;
  @Input() public allowCustom = true;
  @Input() public errorText: string;
  @Input() public placeholder = 'Select';
  @Input() public nonSearchableSelect = false;
  @Input() public notListedText = 'Item not listed?';

  @Output() outputtedObjectList = new EventEmitter();

  formSub = new Subscription();

  searchString = '';
  filteredObjects = [];
  displayItems = false;
  maxReached = false;
  blocked = false;
  endSubscriptions = new Subject<void>();

  highlightedItem: T;

  @ViewChild('searchResults') searchResults;
  @ViewChild('searchInput') searchInput;

  constructor(
    public formService: FormService,
    private searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private domService: DomService
  ) {}

  ngOnInit() {
    this.filteredObjects = this.inputtedObjectList.slice();

    this.getFormControlParent().addControl(this.selectedOptionsControlName(), new FormControl<string[]>([]));

    this.getSelectedOptionsControl().valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe((selectedOpts: any[]) => {
      this.filteredObjects = this.inputtedObjectList.slice(); // makes a new copy of inputtedObjectList without overwriting the value
      // reset and re-remove options that are selected, we need to do this to assist with prefill behavior in case something has already been selected
      // iterate in reverse so we can splice out options if they are invalid
      if (selectedOpts.length > 0) {
        selectedOpts.reduceRight((acc, option, index, theseSelectedOpts) => {

          // filter through the values and remove any that aren't a valid option in the inputted list
          const itemIsValid = this.inputtedObjectList.findIndex(thisOption => thisOption[this.valueProperty] === option[this.valueProperty]);

          if (typeof option !== 'object' || itemIsValid === -1 && !this.allowCustom) {
            theseSelectedOpts.splice(index, 1);
            this.getSelectedOptionsControl().setValue(theseSelectedOpts);
          } else {

            if (!this.allowCustom) {
              // remove from filteredObjects
              const findIndex = this.filteredObjects.findIndex(thisOption => thisOption[this.valueProperty] === option[this.valueProperty]);
              if (findIndex) {
                this.filteredObjects.splice(findIndex, 1);
              }
            }

            // if blocker is provided
            if (this.blocker && option && option[this.valueProperty] === this.blocker) {
              this.filteredObjects = this.inputtedObjectList;
              this.blocked = true;
            }

          }

        }, []);
      }

    });


    this.formSub = this.formService.prefillForm.subscribe(() => {
      setTimeout(() => {
        this.evaluateMaxReached();
        this.cdr.detectChanges();

        const selectedOptions = this.getSelectedOptionsControl().value;

        if (selectedOptions.length > 0) {
          this.getSelectedOptionsControl().setValue(selectedOptions);
          this.highlightedItem = null;
          this.emitObject(selectedOptions);

          this.evaluateMaxReached();
        }
      }, 2);
    });

  }

  ngOnDestroy() {
    if (this.getFormControlParent() && this.getFormControlParent().get(this.selectedOptionsControlName())) {
      this.getFormControlParent().removeControl(this.selectedOptionsControlName());
    }
    this.endSubscriptions.next();
    this.endSubscriptions.complete();

    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  showItems() {
    this.displayItems = true;
  }

  searchOptions(s: string, event?) {

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      return false;
    }

    this.searchString = s.trim();

    if (s !== '') {
      this.filteredObjects = this.inputtedObjectList.filter((thisItem: any) => {
        if (thisItem[this.displayProperty].toLowerCase().includes(s.toLowerCase())) {
          return thisItem;
        }
      });
    } else {
      this.filteredObjects = this.inputtedObjectList.slice();
    }

    if (this.filteredObjects.length === 0) {
      this.highlightedItem = null; // initially unset highlightedItem to ensure that the custom add option isnt highlighted immediately
    }

  }

  resetItemSearch() {
    this.searchString = '';
    this.displayItems = false;
    if (this.getSelectedOptionsControl().value.length > 0) {
      this.resetSearchControl();
    }
    this.filteredObjects = this.inputtedObjectList.slice();

    // emit if empty and let the parent decide what to do with it
    if (this.getSelectedOptionsControl().value.length === 0) {
      this.emitObject(this.getSelectedOptionsControl().value);
    }
  }

  public addItem(addedOption?: any, event?) {
    if (event) {
      event.preventDefault(); // prevents form submission in the case of hitting enter on the search input
    }

    if (!addedOption && !this.highlightedItem && this.searchString.trim() === '') {
      return false;
    }

    let selectedOptions = this.getSelectedOptionsControl().value;

    if (addedOption || (!addedOption && this.highlightedItem)) {

      if (!addedOption && this.highlightedItem) {
        addedOption = this.highlightedItem;
      }

      // if blocker is selected
      if (this.blocker && addedOption && addedOption[this.valueProperty] === this.blocker) {
        // remove everything else from selectedObjects first
        selectedOptions = [];
        this.filteredObjects = this.inputtedObjectList;
        this.blocked = true;
      } else {
        this.blocked = false;
      }

      // add item to selected items list to be displayed
      selectedOptions.push(addedOption);

    } else if (!addedOption && this.allowCustom) { // add whatever the current searchString is as a custom item
      const customItem = {};
      const capSearchString = titleCase(this.searchString);
      customItem[this.displayProperty] = capSearchString;
      customItem[this.valueProperty] = capSearchString;
      // double check to ensure the option isn't already there, only allow 1
      const findOption = selectedOptions.findIndex(thisOpt => thisOpt[this.displayProperty] === capSearchString );
      if (findOption === -1) {
        selectedOptions.push(customItem);
      }
    }

    this.searchString = '';
    this.domService.selectElementAsRoot(`#${this.getFormControlName()}`).focus();       // hide the dropdown

    if (selectedOptions.length > 0) {
      this.resetSearchControl();
    }

    this.getSelectedOptionsControl().setValue(selectedOptions);
    this.highlightedItem = null;
    this.emitObject(selectedOptions);

    this.evaluateMaxReached();

    if (!event){
      this.searchInput.nativeElement.blur();
    }
    this.displayItems = false;

  }

  public removeItem(removedItem: any) {
    // removes the removedItem from the selected list
    const selectedOptions = this.getSelectedOptionsControl().value;

    const itemIndex = selectedOptions.findIndex(thisItem => thisItem[this.valueProperty] === removedItem[this.valueProperty]);

    if (this.blocker && selectedOptions[itemIndex][this.valueProperty] === this.blocker) { // if the item removed is the blocker
      this.blocked = false;
    }

    selectedOptions.splice(itemIndex, 1);

    this.getSelectedOptionsControl().setValue(selectedOptions);

    if (selectedOptions > 0) {
      this.resetSearchControl();
      this.emitObject(selectedOptions);
    } else {
      this.itemFormControl.markAsTouched();
    }
    this.highlightedItem = null;

    this.evaluateMaxReached();
    this.cdr.detectChanges();
  }

  public changeHighlightedItem(direction: string, event) {

    if (!this.displayItems) {
      this.showItems();
    }

    if (event) {
      event.preventDefault();
    }

    const availableOptions = this.getDifference(this.filteredObjects, this.getSelectedOptionsControl().value);

    const nextIndex = availableOptions.findIndex(thisOpt => thisOpt === this.highlightedItem) + (direction === 'UP' ? -1 : 1);

    // if we have not yet keyed up or down to make an initial selection, select either the very first or last items depending on direction
    if (!this.highlightedItem) {

      if (direction === 'UP') {
        this.highlightedItem = availableOptions[availableOptions.length - 1];
      } else if (direction === 'DOWN') {
        this.highlightedItem = availableOptions[0];
      }
    } else { // we already have an active selection, next to instead find the next/prev adjacent one
      if (direction === 'UP') {

        if (
          this.allowCustom
          && this.searchString !== ''
          && nextIndex < 0
        ) {
          // instead trigger custom add
          this.highlightedItem = undefined;
        } else {

          this.highlightedItem = this.searchService.findPrev(availableOptions, this.highlightedItem);
        }

      } else if (direction === 'DOWN') {

        if (
          this.allowCustom
          && this.searchString !== ''
          && nextIndex === availableOptions.length
        ) {
          // instead trigger custom add
          this.highlightedItem = undefined;
        } else {
          this.highlightedItem = this.searchService.findNext(availableOptions, this.highlightedItem);
        }

      }
    }

    if (this.searchResults && this.highlightedItem) {
      this.searchResults.nativeElement.querySelector('li[data-code= "' + this.highlightedItem[this.valueProperty] + '"]')
      .scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
    }

  }

  emitObject(passedObject) {
    this.outputtedObjectList.emit(passedObject);
  }

  getDifference(mainArray, filterOutThisArray) {
    return mainArray.filter((filterObject) => {
      let notSelected = true;
      for (const item of filterOutThisArray) {
        if (item[this.displayProperty] === filterObject[this.displayProperty]) {
          notSelected = false;
        }
      }
      return notSelected;
    });
  }

  evaluateMaxReached() {
    this.maxReached = this.maxSelect && this.getSelectedOptionsControl() && this.getSelectedOptionsControl().value.length >= this.maxSelect;
  }

  public getFormControlParent(): FormGroup {
    if (this.itemFormControl) {
      return this.itemFormControl.parent as FormGroup;
    } else {
      return new FormGroup({});
    }
  }

  public getFormControlName(): string {
    let formGroup: { [key: string]: AbstractControl; } | AbstractControl[];
    if (this.itemFormControl) {
      formGroup = this.itemFormControl.parent.controls;
    } else {
      formGroup = [];
    }
    return Object.keys(formGroup).find(name => this.itemFormControl === formGroup[name]);
  }

  public getSelectedOptionsControl(): AbstractControl {
    return this.getFormControlParent().get(this.selectedOptionsControlName());
  }
  public selectedOptionsControlName() {
    return this.getFormControlName() + '_selected_options';
  }

  public resetSearchControl() {
    this.itemFormControl.setValue('');
    this.itemFormControl.markAsUntouched();
    this.itemFormControl.markAsPristine();
  }
}

function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

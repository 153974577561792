<div class="mt-5">
  <fieldset>
    <legend>
      <h2 class="mb-2">Want to refer a friend?</h2>
      <p class="mb-4" *ngIf="!referralsSent">Invite a friend or family member to apply for FREE to Liberty today!</p>
    </legend>


    <form [formGroup]="referralForm" *ngIf="!referralsSent">
      <ng-container *ngFor="let thisGroup of referralForm['controls'] | keyvalue; let i = index">
        <div [formGroup]="referralForm['controls'][i]" [formGroupName]="i" class="row refer-{{i}}">
          <div class="col-sm-3 form-control">
            <span class="input-label-text">First Name</span>
            <input attr.aria-label="First Name of friend {{i + 1}}," formControlName="rf_fname" name="rf_fname" class="refer-{{i}}-rf_fname" type="text" maxlength="100" [required]="anyValuesInGroup(thisGroup)">
            <span class="error" *ngIf="referralForm['controls'][i].get('rf_fname').touched && referralForm['controls'][i].get('rf_fname').errors && referralForm['controls'][i].get('rf_fname').errors.required">
              Enter a first name
            </span>
          </div>
          <div class="col-sm-3 form-control">
            <span class="input-label-text">Last Name</span>
            <input attr.aria-label="Last Name of friend {{i + 1}}," formControlName="rf_lname" name="rf_lname" class="refer-{{i}}-rf_lname" type="text" maxlength="100" [required]="anyValuesInGroup(thisGroup)">
            <span class="error" *ngIf="referralForm['controls'][i].get('rf_lname').touched && referralForm['controls'][i].get('rf_lname').errors && referralForm['controls'][i].get('rf_lname').errors.required">
              Enter a last name
            </span>
          </div>
          <div class="col-sm-3 form-control">
            <span class="input-label-text">Email Address</span>
            <input attr.aria-label="Email Address of friend {{i + 1}}," formControlName="rf_email" name="rf_email" class="refer-{{i}}-rf_email" type="email" maxlength="128" [required]="anyValuesInGroup(thisGroup)">
            <span class="error" *ngIf="referralForm['controls'][i].get('rf_email').touched && referralForm['controls'][i].get('rf_email').errors && referralForm['controls'][i].get('rf_email').errors.required">
              Enter an email address
            </span>
            <span class="error" *ngIf="referralForm['controls'][i].get('rf_email').touched && referralForm['controls'][i].get('rf_email').errors && referralForm['controls'][i].get('rf_email').errors.pattern">
              Must provide a valid email
            </span>
          </div>
        </div>
      </ng-container>

      <div class="relative">
        <button type="button" class="button button-small relative" (click)="referralSubmit()"
          [attr.disabled]="disableSubmit || sending ? 'disabled' : null">
          Refer Friend(s)
          <div *ngIf="sending" class="spin-container absolute">
            <div class="spinner">
              <div></div>
            </div>
          </div>
        </button>

      </div>


    </form>

  </fieldset>


  <app-alert style="color: black" [type]="'success'" *ngIf="referralsSent">Thank you! Your information was successfully sent.</app-alert>
</div>

<div class="form-control">
  <div [formGroup]="formGroup" class="search-input-container agt_app_acode_container" (focusout)="resetAcodeSearch()">
    <label for="acode_search">
      <span class="input-label-text">APP ACODE</span>
    </label>

    <div class="search-block-wrap" *ngIf="formGroup.get('acodes').value.length > 0">
      <div class="search-block" *ngFor="let acode of formGroup.get('acodes').value">
        <a class="search-block-remove" (click)="removeAcode(acode)" (keyup.enter)="removeAcode(acode)" tabindex="0"></a>
        {{acode.code}} - {{acode.descript}}
      </div>
    </div>

    <div class="search-input-wrap">
      <input class="search-input" type="text" name="acode_search" id="acode_search" formControlName="acode_search"
        autocomplete="off" (keydown.arrowup)="changeHighlightedAcode('UP')" (keydown.arrowdown)="changeHighlightedAcode('DOWN')"
        (keydown.enter)="addAcode(null, $event)">

      <app-icon class="search-icon icon" aria-label="Search icon" [icon]="eIconTypes.Search"
        aria-label="Search icon"></app-icon>

    </div>

    <div class="search-input-results agt-acode-search-results" id="agt-acode-search-results"
      *ngIf="acodeSearch || (filteredAcodes.length > 0 && acodeSearch.length > 1 )">
      <span class="keep-typing" *ngIf="acodeSearch.length < 3" style="padding: 0 8px;">Keep typing...</span>
      <span class="keep-typing" *ngIf="acodeSearch.length > 2 && filteredAcodes.length === 0"
        style="padding: 0 8px;">Sorry, no results</span>
      <ul *ngIf="acodeSearch.length > 2">
        <li *ngFor="let acode of filteredAcodes;" attr.data-code="{{acode.code}}"
          [ngClass]="{'selected' : highlightedAcode.code === acode.code}" (mousedown)="addAcode(acode, $event)">
          <span [innerHTML]="acode.code + ' - ' + acode.descript | highlight : acodeSearch"></span>
        </li>
      </ul>
    </div>

    <input type="hidden" name="acodes" formControlName="acodes">

  </div>
</div>


import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { DomService } from 'src/app/shared/service/dom.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-search-modal',
  templateUrl: './app-search-modal.component.html',
  styleUrls: ['./app-search-modal.component.scss']
})
export class AppSearchModalComponent extends ModalComponent implements OnInit {

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public domService: DomService,
    public appIdService: AppIdService,
    public elm: ElementRef,
    private cdRef: ChangeDetectorRef,
    public renderer: Renderer2,
    public navigationService: NavigationService
  ) {
    super(modalService, applicationService, elm, cdRef, renderer, domService);
  }

  @ViewChild('tabContainer') tabContainer: ElementRef;
  @ViewChild('tabContentContainer') tabContentContainer: ElementRef;

  ngOnInit(): void {

    this.modalService.triggerAppSearchModal.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.showModal();
    });

  }


  activateTab(tabName: string) {
    const tabContainerEl = this.tabContainer.nativeElement;
    const tabContentEl = this.tabContentContainer.nativeElement;

    // first remove active class from all tabs and tab-content elements
    const allTabs = tabContainerEl.querySelectorAll('.tab') as HTMLElement[];
    allTabs.forEach(tabEl => {
      tabEl.classList.remove('active');
    });

    const allTabContent = tabContentEl.querySelectorAll('.tab-content') as HTMLElement[];
    allTabContent.forEach(tabEl => {
      tabEl.classList.remove('active');
    });

    // then add active class to desired tab and tab-content elements
    const activeTab = tabContainerEl.querySelector(`.tab[data-tab="${tabName}"]`) as HTMLElement;
    activeTab.classList.add('active');

    const activeContent = tabContentEl.querySelector(`.tab-content[data-tab="${tabName}"]`) as HTMLElement;
    activeContent.classList.add('active');

  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolSearchInputComponent } from './school-search-input.component';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    SchoolSearchInputComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    SchoolSearchInputComponent
  ]
})
export class SchoolSearchInputModule { }

export class Visa {
    public type: string; // (required; must be a value in saturn.stvvtyp.stvvtyp_code)
    public number: string; // (optional)
    public issueDate: string; // (optional) string formatted as MM/DD/YYYY
    public expireDate: string; // (optional) string formatted as MM/DD/YYYY

    constructor(type?: string) {
      this.type = type;
    }
  }

<ng-container [formGroup]="formGroup">
<div class="form-control">
  <div class="row">
    <div class="col">
      <label for="email_dup_checker">
        <span class="input-label-text">Email Dup Checker</span>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-auto">
      <div *ngIf="showLoadingAnimation" class="spinner-container">
        <div class="spinner">
          <div></div>
        </div>
      </div>
      <input
        (blur)="validateEmailBeforeEmit($event.target.value)"
        type="email_dup_checker" name="email_dup_checker"
        formControlName="email_dup_checker"
        [maxlength]="128"
      >
      <span class="error" *ngIf="formGroup.get('email_dup_checker').hasError('pattern')">Enter a valid email</span>
    </div>
    <div class="col-sm-auto">
      <button type="button" class="agt_dupChecker_btn" (click)="redirectToSSRS($event)">SSRS Email Report</button>
    </div>
  </div>
</div>
</ng-container>

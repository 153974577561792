import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {ADRoles, AuthenticationService} from '../shared/service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowedRoles = next.data['roles'] as ADRoles[];
    const authorized = this.authenticationService.isAuthorised(allowedRoles);
    if (!authorized) {
      this.router.navigateByUrl('/unauth');
    }
    return authorized;
  }
}

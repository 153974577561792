<ng-container [formGroup]="formGroup">
  <div class="form-control">

    <div class="application-origin-container">

      <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('application_origin'))"></div>

      <label for="application_origin">
        <span class="input-label-text">Application Origin</span>
      </label>

      <div class="select-input-wrap">
        <select name="application_origin" id="application_origin" formControlName="application_origin">
          <option value="" disabled="true" selected="true">Select</option>
          <option *ngFor="let origin of possibleOrigins" value="{{origin}}">{{origin}}</option>
        </select>

        <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
      </div>

      <span class="error" *ngIf="formService.hasErrors(formGroup.get('application_origin'))">Select an application origin</span>
    </div>

  </div>


</ng-container>

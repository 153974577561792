// tslint:disable: variable-name
export class Acode {

  public code: string;
  public descript: string;
  public deposit_discount_amt: string;

  constructor() {
  }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from 'src/app/shared/service/form.service';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {Phone} from 'src/app/shared/model/application/phone.model';
import {NationService} from 'src/app/shared/service/nation.service';
import {ProgramService} from 'src/app/shared/service/program.service';
import {Subject} from 'rxjs';
import {CookieService} from 'src/app/shared/service/cookie.service';
import {environment} from 'src/environments/environment';
import {takeUntil} from 'rxjs/operators';
import {FormControlCustom} from 'src/app/shared/form-control-custom';
import {RadioOpts} from 'src/app/shared/model/radio-options.model';

@Component({
  selector: 'app-tcparadio',
  templateUrl: './tcparadio.component.html',
  styles: ['.tcpa-radio {max-width: 100%;}']
})
export class TCPARadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});
  applicationPhone: Phone;

  questionText = 'Do you agree that Liberty and its affiliates may call and/or text you about its offerings and other business matters by any phone number you have provided and may provide in the future, including any wireless number, using automated technology?';
  showAlternateAlert: boolean;
  // tcpaPattern = /^(?!.*No).*$/;

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'}
  ];

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService,
    public nationService: NationService,
    private cookieService: CookieService,
    public programService: ProgramService
  ) {
  }

  ngOnInit(): void {
    this.formGroup.addControl('tcpa_radio', new FormControlCustom<string>({}, '', [Validators.required]));
    (this.formGroup.get('tcpa_radio') as FormControlCustom<any>).errorMessages = {
      required: 'Provide an answer regarding TCPA',
      pattern: 'You must agree to TCPA'
    };

    if (
      !environment.isAgent
      && this.programService.getCurrentProgram().campCode === 'D'
      && this.programService.getCurrentProgram().levelCode === 'UG'
    ) {
      this.formGroup.get('tcpa_radio').setValidators([Validators.required, Validators.pattern('^Yes$')]);
    }

    this.formGroup.addControl('tcpa_radio_isEU', new FormControl<string>(''));


    this.formService.UpdatedAddress.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.euGdprCompliant();
    });

    this.cookieService.GDPRCookiesObtained.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.euGdprCompliant();
    });

    this.applicationService.updatedPhone.pipe(takeUntil(this.endSubscriptions)).subscribe(phone => {
      this.applicationPhone = phone;
    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      if (program.campCode === 'D' && program.levelCode === 'UG' && !environment.isAgent) {
        this.showAlternateAlert = true;
        this.formGroup.get('tcpa_radio').setValidators([Validators.required, Validators.pattern('^Yes$')]);
      } else {
        this.formGroup.get('tcpa_radio').clearValidators();
        this.formGroup.get('tcpa_radio').setValidators([Validators.required]);
        this.showAlternateAlert = false;
      }
    });

    this.euGdprCompliant();

  }

  ngOnDestroy() {
    this.formGroup.removeControl('tcpa_radio');
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  euGdprCompliant() {
    if (this.nationService.isEU() || this.cookieService.userIsEu) {
      this.questionText = 'Do you agree that Liberty and its affiliates may call, text or email you about its offerings and other business matters by any phone number or email address you have provided and may provide in the future, including any wireless number, using automated technology?';
      this.formGroup.get('tcpa_radio_isEU').setValue(true);
    } else {
      this.questionText = 'Do you agree that Liberty and its affiliates may call and/or text you about its offerings and other business matters by any phone number you have provided and may provide in the future, including any wireless number, using automated technology?';
      this.formGroup.get('tcpa_radio_isEU').setValue(false);
    }
  }
}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-relative-info',
  templateUrl: './relative-info.component.html',
  styles: ['.large-question {font-size: 18px !important;}']
})
export class RelativeInfoComponent {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

  constructor(
  ) { }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DegreePickerModalComponent} from './degree-picker-modal/degree-picker-modal.component';
import {GetHelpModalComponent} from './get-help-modal/get-help-modal.component';
import {ModalComponent} from './modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from '../form/input/input.module';
import {GetHelpEmailFormComponent} from './get-help-modal/get-help-email-form/get-help-email-form.component';
import {ResumeModalComponent} from './resume-modal/resume-modal.component';
import {ProgressBarModule} from '../shared/component/progress-bar/progress-bar.module';
import {IconModule} from '../shared/component/icon/icon.module';
import {GenericInputModule} from '../form/input/generic/generic-input.module';
import {SharedModule} from '../shared/shared.module';
import {WelcomeBackModalComponent} from './welcome-back-modal/welcome-back-modal.component';
import {TextboxModule} from '../form/input/generic/textbox/textbox.module';
import {AppSearchModalComponent} from './app-search-modal/app-search-modal.component';
import {AppIdSearchComponent} from '../form/input/app-id-search/app-id-search.component';
import {AppEmailPhoneSearchComponent} from '../form/input/app-email-phone-search/app-email-phone-search.component';
import {SidebarModule} from '../sidebar/sidebar.module';
import { SubmissionModalComponent } from './submission-modal/submission-modal.component';
import { StudentResumeModalModule } from './student-resume-modal/student-resume-modal.module';
import { StudentResumeModalComponent } from './student-resume-modal/student-resume-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    GetHelpModalComponent,
    DegreePickerModalComponent,
    GetHelpEmailFormComponent,
    ResumeModalComponent,
    AppIdSearchComponent,
    AppEmailPhoneSearchComponent,
    WelcomeBackModalComponent,
    AppSearchModalComponent,
    SubmissionModalComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputModule,
        TextboxModule,
        GenericInputModule,
        ProgressBarModule,
        IconModule,
        SharedModule,
        SidebarModule,
        StudentResumeModalModule
    ],
  exports: [
    ModalComponent,
    GetHelpModalComponent,
    DegreePickerModalComponent,
    GetHelpEmailFormComponent,
    AppSearchModalComponent,
    AppIdSearchComponent,
    AppEmailPhoneSearchComponent,
    ResumeModalComponent,
    WelcomeBackModalComponent,
    SubmissionModalComponent,
    StudentResumeModalComponent
  ]
})
export class ModalModule {
}

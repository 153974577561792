import {ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {ModalService} from 'src/app/shared/service/modal.service';
import {ModalComponent} from '../modal.component';
import { environment } from 'src/environments/environment';
import {DomService} from '../../shared/service/dom.service';

@Component({
  selector: 'app-submission-modal',
  templateUrl: './submission-modal.component.html',
  styleUrls: ['./submission-modal.component.scss']
})
export class SubmissionModalComponent extends ModalComponent {

  environment = environment;

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public domService: DomService
  ) {
    super(
      modalService,
      applicationService,
      elm,
      cdr,
      renderer,
      domService
    );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { SsnFormatDirective } from './ssn.directive';
import { TrimDirective } from './trim.directive';

@NgModule({
  declarations: [
    NumbersOnlyDirective,
    SsnFormatDirective,
    TrimDirective
  ],
  imports: [
    CommonModule,

  ],
  exports: [
    NumbersOnlyDirective,
    SsnFormatDirective,
    TrimDirective
  ]
})

export class DirectivesModule { }

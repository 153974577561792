<div class="maint-wrapper">
<div id="maintenance-container">
  <div>
    <img id="liberty-logo" src="/assets/icons/liberty-logo.svg" alt="liberty-logo">
  </div>
  <div id="maintenance-text">
    <h2>This site is currently undergoing maintenance.</h2>
    <p>Try back a little later</p>
    <a
      href="https://www.liberty.edu"
      rel="noopener noreferrer nofollow"
      target="">
      Return to Liberty.edu <app-icon class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
    </a>
  </div>
</div>
</div>

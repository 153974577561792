import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousEducationComponent } from './previous-education.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { FormErrorModule } from '../../form-error/form-error.module';
import { ExpelledRadioModule } from '../../input/expelled-radio/expelled-radio.module';
import { GenericInputModule } from '../../input/generic/generic-input.module';
import { RadioModule } from '../../input/generic/radio/radio.module';
import { SchoolSearchModule } from '../../input/generic/school-search/school-search.module';
import { PreviousBalanceModule } from '../../input/previous-balance/previous-balance.module';
import {DirectivesModule} from '../../../shared/directive/directives.module';


@NgModule({
  declarations: [
    PreviousEducationComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PreviousBalanceModule,
        FormErrorModule,
        RadioModule,
        ExpelledRadioModule,
        SchoolSearchModule,
        GenericInputModule,
        IconModule,
        DirectivesModule
    ],
  exports: [
    PreviousEducationComponent,
  ]
})
export class PreviousEducationModule { }

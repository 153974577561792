export class GenerateLuidResponse {

  public outcome: string;
  public pidm: string;
  public luid: string;
  public username: string;

  constructor(luid?: string, username?: string, pidm?: string) {
    this.luid = luid;
    this.username = username;
    this.pidm = pidm;
  }

}

import { Component, Input } from '@angular/core';
import { EIconType } from 'src/app/shared/component/icon/icon.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {

  public eIconTypes: typeof EIconType = EIconType;

  @Input() type = 'generic';
  @Input() alertStyle = 'icon';
  @Input() goFullWidth = false;
  @Input() smaller = false;
  @Input() larger = false;
  @Input() hasCloseBtn = false;
  @Input() open = true;
  @Input() clean = false;

  constructor() { }

  closeAlert() {
    this.open = false;
  }

}

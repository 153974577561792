import { Component, Input, OnInit } from '@angular/core';
import { FormService } from 'src/app/shared/service/form.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() textPlacement = 'left';
  @Input() afterLoad = false;
  progressNumber = this.formService.formProgress;

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService
  ) { }
  ngOnInit(): void {

    if (this.afterLoad === true) {
      this.formService.emitProgress.subscribe(progress => {
        this.progressNumber = environment.isThankYouPage ? 100 : progress;
      });
    }
  }


}

<div [formGroup]="getFormControlParent()" (focusout)="resetSearch()">

  <div class="form-control">

    <div class="validation-error-bar" *ngIf="formService.hasErrors(itemFormControl)"></div>

    <label *ngIf="inputLabelText" for="{{getFormControlName()}}">
      <span class="input-label-text">{{inputLabelText}}</span>
    </label>

    <div class="search-input-wrap input-wrap" (focusin)="showAllItems()">

      <div aria-live="assertive" role="status" class="screen-reader-only">{{filteredItems.length}} suggestion{{filteredItems.length !== 1 ? 's' : ''}} found when searching {{itemSearch}}. Use up and down arrows to review</div>


      <input
        (click)="showAllItems()"
        #searchInput
        class="search-input"
        role="combobox"
        type="text"
        name="{{getFormControlName()}}"
        id="{{getFormControlName()}}"
        formControlName="{{getFormControlName()}}"
        autocomplete="{{autoComplete}}"
        (input)="searchItems($event.target.value)"
        (keydown.arrowup)="showSearchedItems(); changeHighlightedItem('UP')"
        (keydown.arrowdown)="showSearchedItems(); changeHighlightedItem('DOWN')"
        (keydown.enter)="selectItem(highlightedItem, $event)"
        [attr.aria-label]="(highlightedItem && highlightedItem[displayProperty]) ? highlightedItem[displayProperty] : getFormControlName()+' Search and select from a list of options'"
        [attr.aria-invalid]="formService.hasErrors(itemFormControl) ? 'true' : null"
        [attr.aria-describedby]="formService.hasErrors(itemFormControl) ? getFormControlName()+'-error' : null"
        [attr.aria-activedescendant]="highlightedItem ? getFormControlName()+'-'+highlightedItem[optionValueProperty] : ''"
        [attr.aria-owns]="getFormControlName()+'-results'"
        [attr.aria-autocomplete]="'both'"
      >
        <div *ngIf="itemFormControl.value === ''" class="placeholder">{{ placeholder }}</div>

        <app-icon class="dropdown-arrow-icon icon" aria-label="Dropdown arrow icon" [icon]="'dropdown-arrow'"></app-icon>

        <span class="error" id="{{getFormControlName()}}-error" role="dialog" aria-live="polite" *ngIf="formService.hasErrors(itemFormControl)">{{errorMessage}}</span>
      </div>

      <div class="search-input-results" id="search-input-results" *ngIf="displayItems" [ngStyle]="{'position': !floatingResults ? 'relative' : 'absolute'}">
        <ul role="listbox" id="{{getFormControlName()}}-results">

          <ng-container *ngIf="!tooManyResults">
            <li
              role="option"
              tabindex="-1"
              id="{{getFormControlName()+'-'+item[optionValueProperty]}}"
              [attr.aria-label]="item[displayProperty]"
              [attr.aria-selected]="highlightedItem && highlightedItem[displayProperty] === item[displayProperty] ? 'true' : 'false'"
              *ngFor="let item of filteredItems; trackBy: trackByFn"
              attr.data-code="{{item[optionValueProperty]}}"
              [ngClass]="{'selected' : highlightedItem ? highlightedItem[displayProperty] === item[displayProperty] : false}"
              (mousedown)="selectItem(item, $event)"
            >
              <span [innerHTML]="item[displayProperty] | highlight : itemSearch"></span>
            </li>
          </ng-container>

          <div class="no-matches text-center" *ngIf="tooManyResults">
            <small style="display: block;">Keep typing... too many results</small>
          </div>

          <div class="no-matches text-center" *ngIf="allowCustom && itemSearch.length > 3 && !tooManyResults">
            <small style="display: block;">No matches?</small>
            <small class="highlight">Type Full Name and Select below to add:</small>
          </div>

          <li *ngIf="allowCustom && itemSearch.length > 3 && !tooManyResults" class="school-add"
            (mousedown)="customAddItem(itemSearch)">
            <div class="circle"></div> <b class="school-name">{{ itemSearch }}</b> &larr; (Add Custom Selection)
          </li>

        </ul>
      </div>
  </div>
</div>

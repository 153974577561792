<nav class="sidebar sidebar--navComponent"
     [ngClass]="{'disabled': applicationService.submitSuccess}">
  <ul>
    <a *ngFor="let link of menuItems; let i = index">
      <li
        id="{{link.name}}"
        class="nav--item"
        tabindex="{{link.active && !applicationService.submitSuccess ? null : -1}}"
        (click)="activateSection(link)"
        (keyup.enter)="environment.isAgent || link.visited ? activateSection(link) : ''"
        [ngClass]="{
        'disabled': link.disabled,
        'visited': link.visited,
        'valid': (link.valid && !link.locked && environment.isAgent) || (link.valid && !link.locked && link.visited && !environment.isAgent),
        'inactive': !link.active,
        'active': link.active,
        'agent-link': environment.isAgent,
        'locked': link.locked
      }"
        attr.aria-current="{{link.active ? 'page' : ''}}"
        attr.name="{{link.nameAttribute}}"
        attr.valid="{{link.valid}}"
        role="tab"
        [attr.aria-selected]="link.active ? 'true' : 'false'"
        [attr.aria-controls]="link.name+'-tab'"
        (keydown.arrowup)="focusPrevNav($event, link)"
        (keydown.arrowleft)="focusPrevNav($event, link)"
        (keydown.arrowdown)="focusNextNav($event, link)"
        (keydown.arrowright)="focusNextNav($event, link)"
      >

        <div class="sidebar__navMenuIcon-div">


          <div class="sidebar--navMenuIcon" [attr.aria-label]="link.text"
               attr.alt="Step {{i+1}}{{!link.active ? ' step locked' : null}}{{link.valid ? ' complete' : null}}">

            <p
              aria-hidden="true"
              *ngIf="
              !link.valid
              || (!link.visited && link.valid && link.locked && !environment.isAgent )
              || (!link.visited && link.valid && !link.locked )
              || (link.name === 'optional' && !link.visited)
              "
            >{{ link.navNumber }}</p>

            <app-icon
              class="menu-padlock"
              *ngIf="
                link.locked
                || (!environment.isAgent && !link.visited && link.valid && !link.locked)
                || (link.name === 'optional' && !link.visited)
              "
              [icon]="eIconTypes.Padlock"
              aria-hidden="true"
            ></app-icon>

            <app-icon
              *ngIf="
              link.valid && !link.locked
              "
              class="menu-checkmark animated-checkmark"
              [icon]="eIconTypes.Check"
              aria-hidden="true"
            ></app-icon>
          </div>

        </div>
        <span class="mobile-hide">{{ link.text }}

        </span>
        <span class="mobile-show mobile-nav-text"
              [ngClass]="{'slideUp': mobileIconsHide}">{{ link.mobileText }}
        </span>
      </li>
    </a>
  </ul>
</nav>

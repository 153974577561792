import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormService } from 'src/app/shared/service/form.service';
import { ssnPattern } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-ssn',
  templateUrl: './ssn.component.html'
})
export class SsnComponent {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  @ViewChild('ssn') ssn: ElementRef;

  endSubscriptions = new Subject<void>();

  hiddenSsn = false;
  currentSsnValue = '';

  hasBeenFilled = false;

  constructor(
    private cdr: ChangeDetectorRef,
    public formService: FormService
  ) { }

  ngOnInit(): void {

    this.formService.usCitizenSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

    this.formService.greenCardHolderSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnable();
    });

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.onSsnInput(this.formGroup.get('ssn').value);
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeEnable() {
    if (this.formService.isUsCitizen || (!this.formService.isUsCitizen && this.formService.greenCardHolder)) {
      this.formGroup.get('ssn').enable();
    } else {
      this.hiddenSsn = false;
      this.formGroup.get('ssn').setValue('');
      this.formGroup.get('ssn').disable();
    }
  }

  onSsnInput(val: string, inputType = 'default') {

    // This value needs to be trimmed to catch paste events with bad values.
    val = this.trimAllCharExceptLetters(val);
    this.formatSsnField(val, inputType);

    if (val.length === 9) { // we have a completed ssn number now
      this.hasBeenFilled = true;
      this.formGroup.get('ssn').clearValidators();
      this.formGroup.get('ssn').updateValueAndValidity();
      this.cdr.detectChanges();
      this.hiddenSsn = true;
    } else if (val.length === 0) { // unset it if they delete the whole number
      this.hiddenSsn = false;
      this.formGroup.get('ssn').clearValidators();
    } else {
      this.hiddenSsn = false;

      if (this.hasBeenFilled) {
        this.formGroup.get('ssn').setValidators([Validators.minLength(11), Validators.pattern(ssnPattern)]);
      }

    }

    this.currentSsnValue = this.formGroup.get('ssn').value;

  }

  onSsnFocusout() {
    this.hasBeenFilled = true;

    if (this.currentSsnValue.length >= 11) {
      this.formGroup.get('ssn').clearValidators();
      this.hiddenSsn = true;
    } else {
      this.formGroup.get('ssn').setValidators([Validators.minLength(11), Validators.pattern(ssnPattern)]);
    }
    this.formGroup.get('ssn').updateValueAndValidity();

    this.cdr.detectChanges();
  }

  onHiddenSsnFocusin() {
    this.formGroup.get('ssn').setValue(this.currentSsnValue); // somehow able to pick up extra digits in the value when focused back on and inputted, this prevents that
    this.hiddenSsn = false;
    this.cdr.detectChanges();
    this.ssn.nativeElement.focus();
  }

  formatSsnField(currVal: string, inputType: string) {
    if (inputType !== 'deleteContentBackward') {

      // first strip all characters except numbers, we'll add dashes in
      currVal = this.trimAllCharExceptLetters(currVal);

      const first3 = currVal.substr(0, 3);
      const middle2 = currVal.substr(3, 2);
      const last4 = currVal.substr(5, 4);

      let newVal = '';

      if (first3) {
        newVal += first3;
      }
      if (middle2) {
        newVal += '-' + middle2;
      }
      if (last4) {
        newVal += '-' + last4;
      }

      this.formGroup.get('ssn').setValue(newVal, { emitEvent: false });
    }
  }

  trimAllCharExceptLetters(value: string) {
    return value.replace(/\D/g, '');
  }

}

import { ApplyLUSchool } from '../applyLUApiObjects/apply-lu-school.model';
import { School } from './school';

export class College extends School {
  public institutionName: string; // (optional; this field or ceebCode should have a value)
  public startDate: string; // (optional) must be formatted as MM/DD/YYYY
  // If present along with endDate, must be less than or equal to endDate.
  // If startDate is null but endDate is not, then startDate will be set to the value public passed through for endDate
  public endDate: string; // (optional) must be formatted as MM/DD/YYYY
  // If present along with startDate, must be greater than or equal to startDate
  // If endDate is null but startDate is not, then endDate will be set to the value public passed through for startDate
  public degreeDate: string; // (optional)
  declare public degree: string; // (optional) must be a value in saturn.stvdegc.stvdegc_code
  public degreeDescription: string; // (optional)
  public programCode: string; // (optional)

  constructor(applyLuSchool?: ApplyLUSchool) {
    super();
    if (applyLuSchool) {
      this.ceebCode = applyLuSchool.ceebCode;
      this.institutionName = applyLuSchool.description;
      this.city = applyLuSchool.city;
      this.state = applyLuSchool.state;
      this.nationCode = applyLuSchool.nationCode;
      this.degree = applyLuSchool.degree;
      this.description = applyLuSchool.description;
      this.schoolSearchString = applyLuSchool.schoolSearchString;
      this.setDate(applyLuSchool.lastAttended);
    }
  }

  public hasName(): boolean {
    return (this.institutionName !== undefined && this.institutionName !== '');
  }

  public setDate(date: string): void {
    this.endDate = date;
    this.startDate = date;
    this.degreeDate = date;
  }

  public getName(): string {
    return this.institutionName ? this.institutionName : '';
  }

  public setName(name: string): void {
    this.institutionName = name;
  }

}

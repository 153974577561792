<ng-container [formGroup]="formGroup">

  <app-school-search-input
    [formGroup]="formGroup"
    [inputFormControl]="getSchoolSearch()"
    (outputtedSchool)="emittedSchool($event)"
    [searchLabelText]="searchLabelText"
    [schoolType]="schoolType"
    [searchInputName]="searchInputName"
    [searchErrorText]="searchErrorText"
    class="form-control"
  ></app-school-search-input>

  <div class="row form-control">

    <fieldset class="col-lg-7 ">

      <div class="relative">

        <div class="validation-error-bar"
             *ngIf="formService.hasErrors(formGroup.get('last_month_attended')) || formService.hasErrors(formGroup.get('grad_year'))"></div>
        <fieldset class="row">
          <legend class="col-12 input-label-text">
            Last Date Attended
          </legend>
          <div class="col-7">
            <div class="select-input-wrap">

              <select
                formControlName="last_month_attended"
                aria-label="Graduation Month"
                [attr.aria-invalid]="formService.hasErrors(formGroup.get('last_month_attended')) ? 'true' : null"
                [attr.aria-describedby]="formService.hasErrors(formGroup.get('last_month_attended')) ? 'graduation-month-error' : null"
              >
                <option value selected disabled>Month</option>
                <option *ngFor="let month of dateService.months" value="{{ month.code }}">{{ month.code + ' - ' +
                month.name }}</option>
              </select>

              <app-icon class="icon" aria-label="Dropdown arrow icon" [icon]="'dropdown-arrow'"></app-icon>

            </div>
          </div>
          <app-year
            class="col-5"
            [itemFormControl]="formGroup.get('grad_year')"
            [minYear]="minimumYear"
            [maxYear]="maximumYear"
            [ariaLabel]="ariaLabelPrefix + ' ' + ariaLabelYear"
            (keydown.space)="$event.preventDefault()">

          </app-year>

        </fieldset>


        <div class="errors">
        <span class="error" id="graduation-month-error"
              *ngIf="formService.hasErrors(formGroup.get('last_month_attended'))">Select the last month attended</span>

          <span class="error" id="grad_year-error" role="dialog"
                *ngIf="formService.hasRequiredError(formGroup.get('grad_year'))">A year is required</span>

          <span class="error" id="grad_year-min-error" role="dialog"
                *ngIf="formService.hasErrors(formGroup.get('grad_year')) &&
                formGroup.get('grad_year').errors.min">Enter a valid graduation year after {{minimumYear - 1}}</span>

          <span class="error" id="grad_year-max-error" role="dialog"
                *ngIf="formService.hasErrors(formGroup.get('grad_year')) &&
                formGroup.get('grad_year').errors.max">Enter a valid graduation year before {{maximumYear + 1}}</span>
        </div>

      </div>

    </fieldset>
  </div>

  <div class="row mt-4" [hidden]="!enableSchoolStateNation">

    <app-searchable-select-drop-down
      class="col-sm-6 nation-select"
      [itemFormControl]="nationControl()"
      (emitedItem)="updateSchoolNation($event)"
      [itemList]="nationService.returnNations()"
      [displayProperty]="'name'"
      [optionValueProperty]="'code'"
      [inputLabelText]="'School\'s Country'"
      [placeholder]="'Select Country'"
      [initItem]="initNationCode"
    >
    </app-searchable-select-drop-down>

    <app-state-search
      class="col-sm-6"
      [itemFormControl]="formGroup.get('state_search_input')"
      [placeholder]="'School\'s State'"
      [hidden]="nationControl().value !== 'United States'"
      >
    </app-state-search>

  </div>

<ng-container *ngIf="schoolType === schoolTypes.Highschool && formGroup.get('city')">
  <div class="row mb-3" [ngClass]="{'hidden': formGroup.get('city').disabled }">
    <div class="col">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('city'))"></div>
        <label for="city">
          <span class="input-label-text">High School's City</span>
          <input maxlength="30" type="text" name="city" id="highschool-city" formControlName="city"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('city')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('city')) ? 'hs-city-error' : null">
        </label>
        <span class="error mt-3" id="hs-city-error" *ngIf="formService.hasErrors(formGroup.get('city'))">Enter a
          city</span>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-container>

    <div *ngIf="formGroup.get('did_graduate_radio') && isDidGraduateEnabled()" class="relative">
      <div class="row">
        <div class="col">
          <app-radio [label]="'Did you graduate with a degree from this institution?'" [radioOpts]="yesNoRadioOpts"
                     [radioFormControl]="formGroup.get('did_graduate_radio')"
                     [errorMessage]="'Indicate if you graduated from this institution'"
                     (radioChanged)="didGraduateChange($event)"></app-radio>
        </div>
      </div>
    </div>


    <div *ngIf="maybeEnableDegreeLevelReceived()" class="relative form-control">
      <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('degree_level_received'))"></div>

      <div class="row">
        <div class="col">
          <span class="input-label-text">Degree Level Received</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7">
          <div class="select-input-wrap">

            <select name="levelSelect" formControlName="degree_level_received"
                    [attr.aria-invalid]="formService.hasErrors(formGroup.get('degree_level_received')) ? 'true' : null"
                    [attr.aria-describedby]="formService.hasErrors(formGroup.get('degree_level_received')) ? 'degree-level-error' : null">
              <option value="" selected disabled>Select the degree level received</option>
              <option *ngFor="let degree of degreeTypes | keyvalue: keepOriginalOrder"
                      value="{{degree.key}}">{{degree.value.name}}</option>

            </select>
            <app-icon class="icon" aria-label="Dropdown icon" [icon]="'dropdown-arrow'"></app-icon>
            <br>
            <span class="error" id="degree-level-error"
                  *ngIf="formService.hasErrors(formGroup.get('degree_level_received'))">Select what degree level you
            received</span>
          </div>
        </div>
      </div>
    </div>

  </ng-container>


</ng-container>

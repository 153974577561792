import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleIXComponent } from './title-ix.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioModule } from '../generic/radio/radio.module';

@NgModule({
  declarations: [
    TitleIXComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RadioModule
  ],
  exports: [
    TitleIXComponent
  ]
})
export class TitleIXModule { }

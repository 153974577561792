import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-religion-select',
  templateUrl: './religion-select.component.html',
  styles: ['.religion_select_container{max-width: 340px} .religion_select_container .form-control {padding-left: 0; padding-right:0; max-width: 100%}'],
})
export class ReligionSelectComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  religions = [
    { name: 'African Methodist Episcopal', value: 'AM' },
    { name: 'Anglican', value: 'AN' },
    { name: 'Assembly of God', value: 'AG' },
    { name: 'Baptist', value: 'BP' },
    { name: 'Baptist: Southern Baptist', value: 'BT' },
    { name: 'Bible', value: 'BI' },
    { name: 'Buddhist', value: 'BU' },
    { name: 'Brethren', value: 'BR' },
    { name: 'Catholic', value: 'CA' },
    { name: 'Christian Missionary Alliance', value: 'CM' },
    { name: 'Church of Christ', value: 'CC' },
    { name: 'Church of God', value: 'CG' },
    { name: 'Christian Science', value: 'CS' },
    { name: 'Eastern Christian Orthodox', value: 'EC' },
    { name: 'Episcopal', value: 'EP' },
    { name: 'Evangelical', value: 'EV' },
    { name: 'Evangelical Free', value: 'EF' },
    { name: 'Free-Will Baptist', value: 'FB' },
    { name: 'Hindu', value: 'HI' },
    { name: 'Independent', value: 'IN' },
    { name: 'Independent Baptist', value: 'IB' },
    { name: 'Interdenominational/Bible', value: 'ID' },
    { name: 'Jewish', value: 'JE' },
    { name: 'Latter-Day Saints', value: 'LD' },
    { name: 'Lutheran', value: 'LU' },
    { name: 'Lutheran (Missouri Synod)', value: 'LM' },
    { name: 'Mennonite', value: 'MN' },
    { name: 'Methodist', value: 'ME' },
    { name: 'Mormon', value: 'MO' },
    { name: 'Muslim', value: 'MU' },
    { name: 'Nazarene', value: 'NZ' },
    { name: 'Non-Denominational', value: 'ND' },
    { name: 'Pentecostal/Charismatic', value: 'PE' },
    { name: 'Presbyterian', value: 'PR' },
    { name: 'Protestant', value: 'PO' },
    { name: 'Quaker / Society of Friends', value: 'QU' },
    { name: 'Reformed', value: 'RE' },
    { name: 'Roman Catholic', value: 'RC' },
    { name: 'Seventh-Day Adventist', value: 'SD' },
    { name: 'United Church of Christ', value: 'UC' },
    { name: 'Wesleyan', value: 'WE' },
    { name: 'No Religious Preference', value: 'NP' },
    { name: 'None', value: 'NO' }
  ];

  constructor(
    public formService: FormService,
  ) { }

  ngOnInit(): void {
    this.getReligionFormControl().enable();
  }

  ngOnDestroy() {
    this.getReligionFormControl().disable();
  }

  public getReligionFormControl(): FormControl {
    return this.formGroup?.get('religion_search')?.get('religion') as FormControl;
  }

  updateReligion(val) {
    this.formGroup.get('religion_search').get('religion_code').setValue(val.value);
  }
}

import { Component } from '@angular/core';
import { EIconType } from 'src/app/shared/component/icon/icon.component';

@Component({
  selector: 'app-decorative-card',
  templateUrl: './decorative-card.component.html',
  styleUrls: ['./decorative-card.component.scss']
})
export class DecorativeCardComponent {

  public eIconTypes: typeof EIconType = EIconType;

  constructor() { }

}

import { Injectable } from '@angular/core';
import { NationService } from './nation.service';
import {ProgramService} from './program.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(
    private nationService: NationService,
    private programService: ProgramService
  ) {}

  async init() {
    // we need all programs to init for when trying to get program by programCode/campus/level which a number of components rely on
    this.programService.initAllPrograms();
    await this.programService.initNestedPrograms();
    this.nationService.generateNations();
  }
}

<div class="enrollment-deposit-radio form-control" [formGroup]="formGroup">
  <h2 class="mt-5">Enrollment Deposit</h2>
  <span class="bold-label mb-2">Your ${{ edAmount }} Enrollment Deposit <app-tooltip>{{ depositTooltipText }}
    </app-tooltip> gives you access to our most generous scholarship packages (up to $10,000) and a free pass to one of our upcoming College For a Weekend <app-tooltip>{{ cfawTooltipText }}</app-tooltip> events (a $50 value), and a free application ($50 value).</span>

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('enrollment_deposit_radio')"
    [label]="questionText"
  >
  </app-radio>

</div>

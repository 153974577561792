<div class="mb-2" style="max-width: 217px" [formGroup]="formGroup" [ngClass]="{'hidden': formGroup.get('ssn').disabled}">
    <div class="form-control use-has-errors-validation">
        <div class="validation-error-bar" *ngIf="formGroup.get('ssn').errors && formGroup.get('ssn').errors.pattern"></div>
        <label for="ssn">
            <span class="input-label-text">Social Security Number</span>
        </label>
        <input
          *ngIf="!hiddenSsn"
          [ngClass]="{'has-errors': formGroup.get('ssn').errors && formGroup.get('ssn').errors.pattern}"
          formControlName="ssn"
          type="text"
          inputmode="numeric"
          name="ssn"
          id="ssn"
          #ssn
          appSsnFormat
          (keydown.space)="$event.preventDefault()"
          (blur)="onSsnInput($event.target.value, $event.inputType); onSsnFocusout()"
          (input)="onSsnInput($event.target.value, $event.inputType)"
          [attr.aria-invalid]="formGroup.get('ssn').errors && formGroup.get('ssn').errors.pattern ? 'true': false"
          [attr.aria-describedby]="'ssn-error'"
        >
        <input *ngIf="hiddenSsn" value="•••-••-••••" type="text" name="hidden_ssn" id="hidden_ssn" (focusin)="onHiddenSsnFocusin()">
        <span class="error mt-3" role="alert" id="ssn-error" *ngIf="formGroup.get('ssn').errors && formGroup.get('ssn').errors.pattern">Enter a valid SSN</span>
    </div>
</div>

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit, QueryList,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subject, empty } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DegreePickerSelectComponent } from 'src/app/form/input/degree-picker-select/degree-picker-select.component';
import { Program } from 'src/app/shared/model/program.model';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { DomService } from 'src/app/shared/service/dom.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { ModalComponent } from '../modal.component';
import { EIconType } from 'src/app/shared/component/icon/icon.component';
import { CookieService } from 'src/app/shared/service/cookie.service';
import { PickerNode } from '../../shared/model/picker-node.model';
import { TreeDataService } from '../../shared/service/tree-data-service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-degree-picker-modal',
  templateUrl: './degree-picker-modal.component.html',
  styleUrls: [
    '../modal.component.scss',
    '../../form/input/degree-picker/degree-picker.component.scss',
    '../../form/input/degree-picker-select/degree-picker-select.component.scss',
    './degree-picker-modal.component.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class DegreePickerModalComponent extends ModalComponent implements OnInit {

  @ViewChild(DegreePickerSelectComponent) degreePickerSelect: DegreePickerSelectComponent;
  @ViewChild('degreeSearch') degreeSearch: ElementRef;
  @Input() formGroup: FormGroup = new FormGroup({});

  public eIconTypes: typeof EIconType = EIconType;

  displayedPrograms: Array<{ area: string, programs: any[] }>; // the final structured array of programs displayed in the picker

  pickerTree: Array<PickerNode>;

  areaOfFocus = '';
  infoBoxHeader = '';
  showInfoBox = false;

  endSubscriptions = new Subject<void>();
  programsLoaded = false;

  selectedProgram = new Program();

  emptyProgram = new Program();

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public domService: DomService,
    public appIdService: AppIdService,
    private programService: ProgramService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public cookieService: CookieService,
    private treeDataService: TreeDataService
  ) {
    super(modalService, applicationService, elm, cdr, renderer, domService);
    this.emptyProgram.programCode = '';
  }

  ngOnInit(): void {

    this.modalService.triggerDegreePickerModal.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.resetModal();
      this.showModal();
    });


    this.treeDataService.getCurrentPickerTreeSub().pipe(takeUntil(this.endSubscriptions)).subscribe(newTree => {
      this.resetModal();
      this.reGeneratePrograms(newTree);
    });

    this.formGroup.addControl('program', new FormControl<string>(''));
    this.formGroup.addControl('programSearchString', new FormControl<string>('')) ;
  }

  ngOnDestroy() {
    this.formGroup.removeControl('program');
    this.formGroup.removeControl('programSearchString');
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  onProgramSelected(program: Program) {

    this.selectedProgram = this.programService.getProgramByProgramCode(program.programCode);

    if (this.selectedProgram.programCode) {
      const degreeCode = this.selectedProgram.displayDescription.split(':')[0];

      if (degreeCode.includes('CRT')) {
        this.infoBoxHeader = 'Undergrad Certificate';
      } else {
        this.infoBoxHeader = this.selectedProgram.degree;
      }
    } else {
      this.infoBoxHeader = '';
    }

    this.showInfoBox = this.selectedProgram.programCode !== '';
    setTimeout(() => {
      this.modalContainer.nativeElement.querySelector('.button').focus();
    }, 10);
  }

  shutDownThisModal() {
    this.resetSearch();
    this.closeModal();
    this.areaOfFocus = '';
  }

  onProgramChosen() {
    this.closeModal();
    this.showInfoBox = false;

    this.programService.pickerSelectedSubNext(this.selectedProgram);

    setTimeout(() => {
      this.programService.programSubNext(this.selectedProgram);
      this.selectedProgram = new Program();
      this.resetSearch();
    }, 300);
  }

  resetModal() {
    this.resetSearch();
    this.degreePickerSelect?.updateSelectedProgramState(true);
    this.showInfoBox = false;
    this.areaOfFocus = '';
    this.selectedProgram = this.emptyProgram;
  }

  resetSearch() {
    if (this.degreeSearch) {
      this.degreeSearch.nativeElement.value = '';
    }
    this.onSearchInput('');
  }

  backButtonClick() {
    this.resetSearch();
    this.areaOfFocus = '';
    this.showInfoBox = false;
  }

  onSearchInput(input: string) {
    this.formGroup?.controls['programSearchString']?.setValue(input);
  }

  private reGeneratePrograms(newTree: PickerNode[]) {
    this.pickerTree = newTree;

    // also reset degree search
    this.resetSearch();
  }
}

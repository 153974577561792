import {PhoneNumber} from 'libphonenumber-js';

export type PhoneType = 'CL' | 'AP' | 'TX';

export class Phone {
  sequenceNumber: string; // (optional, must not be 3) integer, not sure what this is for
  type: PhoneType; // (optional) type of phone number
  // default type is AP
  // cell phone type is CL
  // if they agree to tcpa consent also add it to type TX
  country: string; // (optional) country of phone number, most likely country code
  area: string; // (optional) 3 digit area code
  phoneNumber: string; // 7 digit phone number
  extension: string;
  access: string; // (optional)

  constructor(phoneNumber?: PhoneNumber) {
    this.area = '';
    this.phoneNumber = '';
    if (phoneNumber) {
      let nationalNumber = phoneNumber.nationalNumber.toString();
      this.phoneNumber = nationalNumber.substr(-(nationalNumber.length - 3));
      nationalNumber = nationalNumber.substring(0, nationalNumber.length - 7); // remove last 7 numbers to get the area
      this.area = nationalNumber.substr(-3);
    }
  }

  public isValid(): boolean {
    let checkedNumber = '';
    if (this.area) {
      checkedNumber += this.area;
    }
    if (this.phoneNumber) {
      checkedNumber += this.phoneNumber;
    }
    return checkedNumber.length >= 10;
  }
}

<form #upayPaymentForm ngNoForm method="post">
  <input #upaySiteId type="hidden" name="UPAY_SITE_ID" value="" id="upay-site-id"/>
  <input #paymentAmount type="hidden" name="AMT" value="" id="payment-amount" />
  <input #extTransId type="hidden" name="EXT_TRANS_ID" value="" id="ext-trans-id" />
  <input #cancelLink type="hidden" name="CANCEL_LINK" value="" id="cancel-link"/>
  <input #billEmailAddress type="hidden" name="BILL_EMAIL_ADDRESS" value="" id="bill-email-address"/>
  <input #billStreet1 type="hidden" name="BILL_STREET1" value="" id="bill-street-1"/>
  <input #billStreet2 type="hidden" name="BILL_STREET2" value="" id="bill-street-2"/>
  <input #billCity type="hidden" name="BILL_CITY" value="" id="bill-city"/>
  <input #billState type="hidden" name="BILL_STATE" value="" id="bill-state"/>
  <input #billZip type="hidden" name="BILL_POSTAL_CODE" value="" id="bill-zip"/>
  <input #billCountry type="hidden" name="BILL_COUNTRY" value="" id="bill-country"/>
  <input #validationKey type="hidden" name="VALIDATION_KEY" value="" id="validation-key"/>
</form>

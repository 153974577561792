import { Component } from '@angular/core';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-get-help',
  templateUrl: './get-help.component.html',
})
export class GetHelpComponent {

  env = environment;

  constructor(
    private modalService: ModalService,
    public applicationService: ApplicationService,
    public authenticationService: AuthenticationService
  ) {}

  onTriggerGetHelpModal() {
    this.modalService.triggerGetHelpModal.next(true);
  }

  onTriggerAppSearchModal() {
    this.modalService.triggerAppSearchModal.next(true);
  }

}

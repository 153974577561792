import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-year',
  templateUrl: './year.component.html',
  styles: [
    '.year { max-width: 70px; }',
  ]
})
export class YearComponent {
  @Input() public itemFormControl: FormControl;
  @Input() public minYear: number;
  @Input() public maxYear: number;
  @Input() public ariaLabel = 'Year Input';

  @Output() emitYear = new EventEmitter<string>();

  constructor(
    public formService: FormService
  ) { }

  onYearInput(year: number) {
    this.emitYear.emit(year.toString());
  }

  public getFormControlName(): string {
    const formGroup = this.itemFormControl.parent.controls;
    return Object.keys(formGroup).find(name => this.itemFormControl === formGroup[name]);
  }

  public getFormControlParent(): FormGroup {
    return this.itemFormControl.parent as FormGroup;
  }

}

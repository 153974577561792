export class Address {
  public sequenceNumber: string; // (required for each record in this array). maybe a 1, 2, 3, etc index?
  public type: string; // (optional) not sure what possible values are
  public houseNumber: string; // (optional)
  public address1: string; // (optional)
  public address2: string; // (optional)
  public address3: string; // (optional)
  public address4: string; // (optional)
  public city: string; // (optional)
  public state: string;
  public zip: string;
  public county: string; // (optional)
  public nationCode: string; // (optional) must be a value in saturn.stvnatn.stvnatn_code

  constructor(address?: Address) {
    if (address) {
      this.sequenceNumber = address.sequenceNumber;
      this.type = address.type;
      this.houseNumber = address.houseNumber;
      this.address1 = address.address1;
      this.address2 = address.address2;
      this.address3 = address.address3;
      this.address4 = address.address4;
      this.city = address.city;
      this.state = address.state;
      this.zip = address.zip;
      this.county = address.county;
      this.nationCode = address.nationCode;
    }
  }
}

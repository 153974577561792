import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalInformationComponent } from './personal-information.component';
import { InputModule } from '../../input/input.module';
import { SsnModule } from '../../input/citizenship/ssn/ssn.module';
import { CitizenshipModule } from '../../input/citizenship/citizenship.module';
import { FullAddressModule } from '../../input/personal-info/full-address/full-address.module';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { MiniAlertModule } from 'src/app/alert/mini-alert/mini-alert.module';

@NgModule({
  declarations: [
    PersonalInformationComponent,
  ],
  imports: [
    CommonModule,
    InputModule,
    CitizenshipModule,
    MiniAlertModule,
    FullAddressModule,
    SsnModule,
    IconModule
  ],
  exports: [
    PersonalInformationComponent,
  ]
})
export class PersonalInformationModule { }

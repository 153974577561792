<div [ngClass]="{'hidden': getReligionFormControl().disabled }" class="religion_select_container">
  <app-searchable-select-drop-down
  [itemList]="religions"
  [itemFormControl]="getReligionFormControl()"
  [inputLabelText]="'Religion'"
  [displayProperty]="'name'"
  [optionValueProperty]="'value'"
  [placeholder]="'Select'"
  class="form-control col-sm-3"
  (emitedItem)="updateReligion($event)"
  ></app-searchable-select-drop-down>
</div>

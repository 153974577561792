<button type="button" tabindex="0" class="continueButton">
  <span class="d-flex justify-content-center align-items-center">

    <div #ref>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!ref.hasChildNodes()">Save &amp; Continue</ng-container>

    <app-icon class="continueArrow" aria-hidden="true" [icon]="eIconTypes.SideWhiteArrow"></app-icon>
  </span>
</button>

import { Component, OnInit } from '@angular/core';
import {DomService} from '../shared/service/dom.service';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html'
})
export class UnauthComponent implements OnInit {

  constructor(private domService: DomService) { }

  ngOnInit(): void {
      // fade out loadingWrapper manually just in case the app has trouble loading
    const loadingWrapper = this.domService.selectElementAsRoot('#loading-wrapper');
    if (loadingWrapper) {
      loadingWrapper.style.opacity = '0';
      // delay removing element so that fade transition has time to happen
      setTimeout(() => {
        if (loadingWrapper) {
          loadingWrapper.remove();
        }
      }, 300);
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompletedBannerApp } from 'src/app/shared/model/applyLUApiObjects/completed-banner-app.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { CompletedApplicationsService } from 'src/app/shared/service/completed-applications.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import moment from 'moment';
import { emailPattern } from 'src/app/shared/service/form.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-dup-checker',
  templateUrl: './email-dup-checker.component.html',
  styleUrls: ['./email-dup-checker.component.scss']
})
export class EmailDupCheckerComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() emailAddress: string;
  @Output() emailAddressNuVal = new EventEmitter<string>();

  showLoadingAnimation = false;

  constructor(
    public applicationService: ApplicationService,
    private completedAppService: CompletedApplicationsService,
    private modalService: ModalService,
    private programService: ProgramService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('email_dup_checker', new FormControl<string>(this.emailAddress, { validators: [Validators.pattern(emailPattern)], updateOn: 'blur' }));
  }

  redirectToSSRS(event) {
    event.preventDefault();
    if (!this.formGroup.get('email_dup_checker').hasError('pattern') && this.formGroup.get('email_dup_checker').value != null && this.formGroup.get('email_dup_checker').value !== '') {
      window.open('https://ssrsprod.liberty.edu/ReportServer/Pages/ReportViewer.aspx?%20%2fEnrollment+Management%2fLUO%2fAdmissions%2fEmailAppDup%20&rs:Command=Render&email=' + this.formGroup.get('email_dup_checker').value, '_blank');
    } else {
      window.open('https://ssrsprod.liberty.edu/ReportServer/Pages/ReportViewer.aspx?%20%2fEnrollment+Management%2fLUO%2fAdmissions%2fEmailAppDup%20&rs:Command=Render', '_blank');
    }
  }

  validateEmailBeforeEmit(email: string) {
    // length must be greater than one to prevent empty API requests
    if (this.formGroup.get('email_dup_checker').valid && email.length > 1) {
      this.emailAddressNuVal.emit(email);

      const regressionParam = this.route.snapshot.queryParams.regression;
      if (!regressionParam || environment.envType === 'prod') {
        this.showLoadingAnimation = true;

        // reach out to API to see if we have any duplicated emails
        this.completedAppService.getCompletedBannerAppByEmail(email).subscribe((returnedApps: CompletedBannerApp[]) => {
          returnedApps = returnedApps.filter((app) => app.applyluId.startsWith('ALU'));
          let appCount = returnedApps.length;
          if (appCount > 0) {
            if (appCount > 20) {
              appCount = 20;
            }
            const tableHeaderText = appCount < 20 ? appCount.toString() : appCount.toString() + ' or more';

            let preparedContent = `<p><b>${tableHeaderText}</b> duplicate application(s) found for: ${email}</p>`;
            preparedContent += `<table>
              <tr>
              <th>WEB_ID</th>
              <th>OPUS/ApplyLU ID</th>
              <th>Term</th>
              <th>Program</th>
              <th>Name</th>
              <th>Posted</th>
              </tr>`;
            let appIndex = 1;

            // sort them by their dates
            returnedApps = returnedApps.sort((a, b) => new Date(b.completeDate).getTime() - new Date(a.completeDate).getTime());

            // loop over all apps
            returnedApps.forEach(app => {
              if (appIndex > 20) { // stop when you hit 20
                return;
              }

              const completeDate = moment(app.completeDate).format('MMMM Do YYYY [at] h:mm A');
              const atString = moment(app.completeDate).startOf('minute').fromNow();

              const programName = this.programService.getProgramByProgramCode(app.program).degreePicker;
              preparedContent += `<tr>
                  <td>${app.webId || 'n/a'}</td>
                  <td>${app.applyluId || app.opusId || 'n/a'}</td>
                  <td>${app.termCode || 'n/a'}</td>
                  <td>${programName || 'Unknown/Undecided'}</td>
                  <td>${app.firstName} ${app.lastName}</td>
                  <td>${completeDate} (${atString})</td>
                </tr>`;
              appIndex++;
            });

            preparedContent += '</table>';
            // trigger modal
            this.modalService.displayModal(preparedContent, 'Close & Continue');
          }
          //  The .add here provides a default tearDown logic when the subscription completes and calls "unsubscribe" (automatically handled by rxjs).
        }).add(() => {
          this.showLoadingAnimation = false;
        });
      }
    }
  }
}

import {Component, OnInit} from '@angular/core';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { FormService } from 'src/app/shared/service/form.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss']
})
export class SidebarFooterComponent implements OnInit {

  progressNumber = environment.isThankYouPage ? 100 : this.formService.formProgress;

  activeSectionName = this.navigationService.activeSection.name;

  appId: string;
  environment = environment;

  constructor(
    public navigationService: NavigationService,
    public appIdService: AppIdService,
    public formService: FormService,
    public applicationService: ApplicationService
    ) {
  }
  ngOnInit(): void {

    this.formService.emitProgress.subscribe(progress => {
      if (this.applicationService.submitSuccess || environment.isThankYouPage) {
        this.progressNumber = 100;
      } else {
        this.progressNumber = progress;
      }
    });

  }

}

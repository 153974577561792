<div class="file-upload-wrapper">

  <h3 *ngIf="sentToAPI" class="sent" role="alert" tabindex="0">{{fileListLength}} File{{fileListLength === 1 ? ' has' :
    's have'}} been sent</h3>

  <div class="file-upload">
    <h3 *ngIf="failedSendToAPI" role="alert" class="failed-send">{{fileListLength}} File{{fileListLength === 1 ? '' :
      's'}} unable to send.
      Email your File to {{sendToAddress}}.</h3>

    <div>
      <app-file-upload [uploadType]="fileName" [approvedFileTypes]="approvedFileTypes"
        [approvedFileTypesName]="approvedFileTypesNames" (filesEmitted)="sendAdditionalDocuments($event)"></app-file-upload>
    </div>
  </div>
</div>

<div class="row mb-3" [formGroup]="formGroup">
  <div class="col-md-8" *ngIf="inputFormControl">
    <div class="relative school-search-container" (focusin)="showItems()" (focusout)="resetSchoolSearch()">
      <div class="validation-error-bar" *ngIf="formService.hasErrors(inputFormControl)"></div>
      <label class="input-label-text" for="{{searchInputName}}">{{ searchLabelText }}</label>

      <div
        class="school-search-status screen-reader-only"
        aria-live="assertive"
        role="status"
      >
        {{filteredSchools.length}} suggestions found use the up and down arrow keys to navigate
      </div>

      <div class="search-input-wrap" >

        <input
          id="{{searchInputName}}"
          #searchInput
          formControlName="{{searchInputName}}"
          type="search"
          placeholder="Search schools"
          autocomplete="off" role="combobox"
          (input)="searchSchools($event.target.value)"
          (keydown.enter)="addSchool(highlightedSchool)" (keydown.arrowup)="changeHighlightedSchool('UP')"
          (keydown.arrowdown)="changeHighlightedSchool('DOWN')"
          role="combobox"
          aria-owns="school-input-results"
          aria-autocomplete="both"
          [attr.aria-invalid]="formService.hasErrors(inputFormControl) ? 'true' : null"
          [attr.aria-describedby]="formService.hasErrors(inputFormControl) ? searchInputName+'-error' : 'school-search-desc'"
          [attr.aria-activedescendant]="highlightedSchool ? highlightedSchool.ceebCode : searchInputName+'_add_custom'"
          [attr.aria-owns]="searchInputName+'-results'" [attr.aria-autocomplete]="'both'"
        >

        <app-icon *ngIf="!currentSchool || !currentSchool.hasName()" class="search-icon icon" aria-label="Search icon"
                  [icon]="'search'"></app-icon>

        <app-icon *ngIf="currentSchool && currentSchool.hasName()" (click)="clearCurrentSchool()" class="x-icon icon"
                  aria-label="Clear school icon" [icon]="'x'"></app-icon>

      </div>
      <span class="error" id="{{searchInputName}}-error" role="alert"
            *ngIf="formService.hasErrors(inputFormControl)">{{searchErrorText}}</span>

      <div class="search-input-results" #searchResults id="school-search-results"
           *ngIf="displayItems && schoolSearchString.length > 0">

        <ul class="school-search-results" id="school-search-listbox" tabindex="0" role="listbox">

          <ng-container *ngIf="!tooManyResults">

            <li
              *ngFor="let school of filteredSchools;"
              role="option"
              id="{{school.ceebCode}}"
              [attr.aria-label]="school.getName() + ' ' + school.city +', '+ school.state"
              [attr.aria-selected]="highlightedSchool && highlightedSchool.getName() === school.getName() ? 'true' : 'false'"
              tabindex="-1"
              (keydown.enter)="addSchool(highlightedSchool)"
              (keydown.tab)="changeHighlightedSchool('TAB')"
              [ngClass]="{'selected' : highlightedSchool === school}"
              (mousedown)="addSchool(school)"
              attr.data-ceebCode="{{school.ceebCode}}" class="school-search-result"
            >

              <span class="school-name" [innerHTML]="school.description | highlight : schoolSearchString"></span>
              <br>
              <span class="school-location"
                    [innerHTML]="school.city +', '+ school.state | highlight : schoolSearchString"></span>
            </li>

          </ng-container>

          <div class="no-matches text-center" *ngIf="!tooManyResults">
            <small style="display: block;">No matches?</small>
            <small class="highlight">Type Full School Name and Select below to add:</small>
          </div>

          <li
            *ngIf="!tooManyResults"
            role="option"
            id="add_custom"
            class="school-add"
            (mousedown)="customAddSchool(schoolSearchString)"
            (keydown.enter)="customAddSchool(schoolSearchString)"
            [attr.aria-label]="'Select to add a custom school entry for '+schoolSearchString"
            [attr.aria-selected]="highlightCustom ? 'true' : 'false'"
            [ngClass]="{'selected' : highlightCustom}"
          >
            <div class="circle"></div>
            <b class="school-name">{{ schoolSearchString }}</b>
            <button type="button" class="school-button btn btn-secondary">Add School</button>
          </li>

          <div class="no-matches text-center" *ngIf="tooManyResults">
            <small style="display: block;"><strong>Too many results found.</strong><br>
              Try adding your city and state to narrow down your search results<br>
              Example: Liberty University Lynchburg, VA</small>
          </div>

        </ul>
      </div>
    </div>

  </div>
</div>

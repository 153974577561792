import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Program } from 'src/app/shared/model/program.model';
import { FormService } from 'src/app/shared/service/form.service';
import { Subject } from 'rxjs';
import { TermService } from 'src/app/shared/service/term.service';
import { Term } from 'src/app/shared/model/applyLUApiObjects/term.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';

@Component({
  selector: 'app-start-term-select',
  templateUrl: './start-term-select.component.html',
  styleUrls: ['./start-term-select.component.scss'],
})
export class StartTermSelectComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  selectedProgram = new Program();

  environment = environment;

  endSubscriptions = new Subject<void>();

  displayInput = false;

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService,
    public termService: TermService,
    private programService: ProgramService,
    private cdr: ChangeDetectorRef
  ) { }

  displayedTerms: Array<Term> = [];

  ngOnInit(): void {

    if (this.programService.getCurrentProgram().programCode) {
      this.selectedProgram = this.programService.getCurrentProgram();
    }

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(formObj => {
      const startTerm = formObj.formData['yourDegree']?.['start_term'];
      // need to check for and fill in select dropdown because during prefill the programSub is triggered and the dropdown gets cleared out
      // tested to ensure this consistently occurs after programSub
      if (startTerm) {
        this.updateTermSelect(startTerm);
      }
    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.selectedProgram = program;
      this.startTermInput().setValue('');

      if (program.terms) {

        this.displayedTerms = program.terms.map((termCode: string) => {
          const convertToTerm = new Term();
          convertToTerm.termCode = termCode;
          convertToTerm.termName = this.termService.getTermNameByCode(termCode);
          return convertToTerm;
        }).sort((a, b) => a.termCode.localeCompare(b.termCode));

      } else {
        this.displayedTerms = [];
      }

      this.maybeDisplayInput();

    });



  }

  ngOnDestroy() {

    this.endSubscriptions.next();
    this.endSubscriptions.complete();

    if (this.startTermInput()) {
      this.startTermInput().setValue('');
      this.formGroup.removeControl('start_term');
    }
  }

  private maybeDisplayInput() {
    if (this.applicationService.spcApp) {
      this.displayInput = true;
      this.startTermInput().enable();
    }
    if (
      this.displayedTerms.length > 0
      && this.selectedProgram.campCode === 'D'
      && !environment.isAgent
    ) {
      this.updateTermSelect(this.displayedTerms[0].termCode);
      this.startTermInput().disable();
      this.displayInput = false;
    } else if (
      // should always display and never be disabled for spc/festival apps
      (this.applicationService.spcApp || this.applicationService.festivalsApp) ||
      (this.selectedProgram.programCode && this.selectedProgram.programCode !== '')
    ) {
      this.startTermInput().enable();
      this.displayInput = true;
    } else {
      this.startTermInput().setValue('');
      this.startTermInput().disable();
      this.displayInput = false;
    }
  }

  public updateTermSelect(value: string) {
    this.startTermInput().setValue(value);
  }

  startTermInput(): FormControlCustom<any> {
    return this.formGroup.get('start_term') as FormControlCustom<any>;
  }

}

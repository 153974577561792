<ng-container [formGroup]="formGroup">

  <app-radio
    [ngClass]="{'hidden' : !hideCellPhone}"
    [label]="'Is '+formattedPhone+' a cellphone number?'"
    [radioFormControl]="formGroup.get('cellPhoneRadio')"
    [radioOpts]="yesNoRadioOpts"
    [errorMessage]="'Indicate if your number is a cell phone'"
  >
  </app-radio>

</ng-container>


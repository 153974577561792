import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-title-ix',
  templateUrl: './title-ix.component.html'
})
export class TitleIXComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor(
    public formService: FormService
  ) { }

  ngOnInit(): void {

    this.formGroup.addControl('titleIXRadio', new FormControlCustom<string>({ required: 'Indicate if you have ever violated any Title IX-related policies'}, { value: '', disabled: true }));

    this.formService.updatedCampus.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (val === 'R') {
        this.formGroup.get('titleIXRadio').enable();
        this.formGroup.get('titleIXRadio').setValidators([Validators.required]);
      } else {
        this.formGroup.get('titleIXRadio').setValue('');
        this.formGroup.get('titleIXRadio').disable();
        this.formGroup.get('titleIXRadio').clearValidators();
      }
    });


  }

  ngOnDestroy(): void {
      this.endSubscriptions.next();
      this.endSubscriptions.complete();
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-mini-alert',
  templateUrl: './mini-alert.component.html',
  styles: [`.mini-alert {
    border: 1px solid #D1E5FD;
    background: #F2F8FF;
    padding: 16px;
    font-size: 14px;
    color: #0A254E;
    margin-top: -14px;
    margin-bottom: 15px;
  }`
  ]
})
export class MiniAlertComponent {

}

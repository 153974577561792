import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalInformationModule } from './personal-information/personal-information.module';
import { PreviousEducationModule } from './previous-education/previous-education.module';
import { PreviousEducationComponent } from './previous-education/previous-education.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    PersonalInformationModule,
    PreviousEducationModule
  ],
  exports: [
    PreviousEducationComponent,
    PersonalInformationComponent
  ]
})
export class SectionsModule { }

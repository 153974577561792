<ng-container [formGroup]="formGroup">
  <div class="form-control">
    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('agent_notepad'))"></div>
    <div class="agent-textarea-container">
      <label for="agent_notepad">
        <span class="input-label-text">Agent Notepad Comments</span>
      </label>

      <textarea name="agent_notepad" rows="6" formControlName="agent_notepad" (keyup)="validateNotepad($event.target.value)" (blur)="validateNotepad($event.target.value)" maxlength="2000" tabindex="0"></textarea>


      <small class="notepad-length">{{ notepadLength }} characters used. (Max 2,000) </small>

      <app-alert type="generic" *ngIf="notepadLength >= 2000">You have reached the maximum character count, submit a supplemental notepad to capture the additional information.</app-alert>
      <span class="error" *ngIf="formGroup.get('agent_notepad').invalid && formGroup.get('agent_notepad').touched && this.notepadLength <= 0">Enter comments</span>

    </div>
  </div>

</ng-container>

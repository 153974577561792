import { ApplyLUSchool } from '../applyLUApiObjects/apply-lu-school.model';
import { School } from './school';

export class HighSchool extends School {
  public graduationDate: string; // (optional) string, docs dont specify formatting so lets assume MM/DD/YYYY
  public entryDate: string; // (optional) a string formatted as MM/DD/YYYY or MMYYYY
  public exitDate: string; // (optional) a string formatted as MM/DD/YYYY or MMYYYY
  public highSchoolName: string;

  constructor(applyLuSchool?: ApplyLUSchool) {
    super();
    if (applyLuSchool) {
      this.ceebCode = applyLuSchool.ceebCode;
      this.highSchoolName = applyLuSchool.description;
      this.city = applyLuSchool.city;
      this.state = applyLuSchool.state;
      this.nationCode = applyLuSchool.nation;
      this.description = applyLuSchool.description;
      this.schoolSearchString = applyLuSchool.schoolSearchString;
      this.setDate(applyLuSchool.lastAttended);
    }
  }

  setDate(date: string): void {
    this.exitDate = date;
    this.graduationDate = date;
    this.entryDate = date;
  }

  hasName() {
    return (this.highSchoolName !== undefined && this.highSchoolName !== '');
  }

  public getName(): string {
    return this.highSchoolName ? this.highSchoolName : '';
  }

  public setName(name: string): void {
    this.highSchoolName = name;
  }
}

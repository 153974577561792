import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EmailMessage, EmailType} from '../model/email-message.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  public successfulUpload = true;

  constructor(
    private http: HttpClient
  ) { }


  sendEmail(emailMessage: EmailMessage) {
    return this.http.post('/rest/smtp', emailMessage, {responseType: 'text'});
  }

  sendErrorEmail(subject: string, body: string) {
    const emailMessage = new EmailMessage(EmailMessage.NO_REPLY, EmailMessage.SAT, '', subject, body, EmailType.API_ERROR);

    this.sendEmail(emailMessage).subscribe();
  }

  sendEmailWithAttachment(fileList: File[], emailMessage: EmailMessage) {
    let totalFileSizeForThisEmail = 0;
    let formData = new FormData();
    // fileList.forEach((file: File) => {
    //   formData.append('files', file, file.name);
    // })

    fileList.forEach((file: File) => {
      if (file.size + totalFileSizeForThisEmail < 25000000){
        formData.append('files', file, file.name);
        totalFileSizeForThisEmail += file.size;

      } else {
        // attach and send current file list and contiue looping through
        formData.append('emailMessage', new Blob([JSON.stringify(emailMessage)], { type: 'application/json'}));
        this.http.post('/rest/smtp/attachment' , formData).subscribe(() => {}, () => {
          this.successfulUpload = false;
        });
        formData = new FormData();
        totalFileSizeForThisEmail = 0;
        formData.append('files', file, file.name);
        totalFileSizeForThisEmail += file.size;
      }
    });

    formData.append('emailMessage', new Blob([JSON.stringify(emailMessage)], { type: 'application/json'}));
    return this.http.post('/rest/smtp/attachment' , formData);
  }
}

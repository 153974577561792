<ng-container [formGroup]="formGroup">

  <app-radio
    *ngIf="isEnabled(formGroup.get('faa_powerplant_certificate'))"
    [label]="'I understand that in order to graduate from the Bachelor of Science in Aviation Maintenance Management, I must hold a FAA Airframe and Powerplant certificate (training for this certificate cannot be completed online) or FAA Foreign Equivalent (Airframe and Powerplant Certificates cannot be obtained online and this program does not qualify students for F1 visa status).'"
    [radioFormControl]="formGroup.get('faa_powerplant_certificate')"
    [radioOpts]="yesNoRadioOpts"
    [errorMessage]="'Select a response regarding FAA Airframe, Powerplant or FAA Foreign Equivalent certificates'"
  ></app-radio>

  <div class="form-control">
    <app-radio
      *ngIf="isEnabled(formGroup.get('professional_pilot_program'))"
      [label]="'Do you currently hold an FAA Commercial Pilot Certificate (or Equivalent)?'"
      [radioFormControl]="formGroup.get('professional_pilot_program')"
      [radioOpts]="yesNoRadioOpts"
      [errorMessage]="'Indicate if you hold an FAA Commercial Pilot Certificate (or Equivalent)'"
    >
    </app-radio>

    <p class="disclaimer mb-4" *ngIf="formGroup.get('professional_pilot_program').value !== ''">Email a copy of your FAA or Equivalent Commercial Pilot Certificate to <a href="mailto: luoverify@liberty.edu">luoverify&#64;liberty.edu</a> for Experience Plus credit</p>
  </div>

  <app-radio
    *ngIf="isEnabled(formGroup.get('additional_flight_training'))"
    [label]="'Do you wish to complete any additional flight training through Liberty?'"
    [radioFormControl]="formGroup.get('additional_flight_training')"
    [radioOpts]="yesNoRadioOpts"
    [errorMessage]="'Select a response regarding additional flight training'"
  >
  </app-radio>


  <div class="form-control" *ngIf="isEnabled(formGroup.get('faa_location'))" (focusout)="resetFlightSchoolSearch()">
    <div class="search-input-container faa_location_container" (focusin)="showFlightSchools()">
      <label for="faa_location">
        <span class="input-label-text">Location where you will complete flight training</span>
      </label>
      <div class="search-input-wrap input-wrap">
        <input class="search-input"
        #searchInput
        type="text"
        name="faa_location"
        id="faa_location"
        formControlName="faa_location"
        autocomplete="off"
        (keydown.arrowup)="changeSelectFlightSchool('UP')"
        (keydown.arrowdown)="changeSelectFlightSchool('DOWN')"
        (keydown.enter)="addFlightSchool('', $event)"
        >
        <div *ngIf="formGroup.get('faa_location').value === '' || formGroup.get('faa_location').value == null" class="placeholder">Select</div>
        <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
      </div>

      <div class="search-input-results" id="flight-schools-search-results" *ngIf="displayFlightSchools">
        <ul>
          <li *ngFor="let school of filteredFlightSchools" attr.data-code="{{ school.attributeCode }}" [ngClass]="{'selected' : highlightedFlightSchool.attributeCode === school.attributeCode}" (mousedown)="addFlightSchool(school.attributeCode, $event)">
            <span [innerHTML]="school.combinedDescription | highlight : flightSchoolSearch"></span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <fieldset class="form-control" [ngClass]="{'hidden': isEducationLeadership()}" role="radiogroup">
    <div [ngClass]="'alert alert-generic alert-no-icon no-bullets'">
      <app-checkbox [itemFormGroup]="getEducationLeadershipGroup()" [options]="educationalLeadershipOpts" [style]="'none'"></app-checkbox>
    </div>
  </fieldset>

  <fieldset  class="form-control" [ngClass]="{'hidden': !isEnabled(getRnCheckGroup())}" role="radiogroup">
    <div [ngClass]="'alert alert-generic alert-no-icon no-bullets'">

        <app-checkbox [itemFormGroup]="getRnCheckGroup()" [options]="rnLicenseCheckOpts" [style]="'none'"></app-checkbox>

      <div id="nursing_number_container">
        <label for="nursingLicenseNumber">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('nursing_license_number'))"></div>
          <span class="input-label-text">What is your Nursing License Number?</span>
          <input class="col-md-7" formControlName='nursing_license_number' id="nursingLicenseNumber" name="nursingLicenseNumber" type="text">
          <span *ngIf="formService.hasErrors(formGroup.get('nursing_license_number'))" class="error">Enter your nursing license number</span>
        </label>
      </div>

      <div class="row mt-4">
        <app-state-search
          [currentCountry]="'US-only'"
          [inputLabelText]="'State where License was Issued'"
          [itemFormControl]="formGroup.get('nursing_license_state')"
          [placeholder]="'Select'"
          stateError="Select your nursing license state"

          class="col-md-7"
        ></app-state-search>
      </div>
    </div>
  </fieldset>

  <fieldset class="form-control" [ngClass]="{'hidden': !isEnabled(getAprnCheckGroup())}" role="radiogroup">
    <app-checkbox [itemFormGroup]="getAprnCheckGroup()" [options]="aprnLicenseCheckOpts"></app-checkbox>
  </fieldset>

  <div class="row mais-hour-select-wrap" *ngIf="isEnabled(formGroup.get('mais_12_hour_select')) && isEnabled(formGroup.get('mais_9_hour_select'))">

    <label class="bold-label some-padding">Choose your primary and secondary areas of study</label>
    <div class="col-sm-12 col-md-6 form-control">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('mais_12_hour_select'))"></div>
        <label class="input-label-text">Primary (12-Hours)</label>
        <div class="select-input-wrap">
          <select
            name="mais_12_hour_select"
            id="mais_12_hour_select"
            formControlName="mais_12_hour_select"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('mais_12_hour_select')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('mais_12_hour_select')) ? 'mais-12-hour-error' : null"
          >
            <option value="" selected>Select Area of Study</option>

            <ng-container *ngFor="let disciplineGroup of maisAllOptionsArray">
              <ng-container *ngIf="disciplineGroup.programs.length === 1 && !disciplineGroup.disabledFor12">
                <ng-container *ngFor="let programs of disciplineGroup.programs">
                  <option *ngIf="programs.twelveCode" value="{{ programs.twelveCode }}">{{programs.name}}</option>
                </ng-container>
              </ng-container>

              <optgroup *ngIf="!disciplineGroup.disabledFor12 && disciplineGroup.programs.length > 1" class="greyText"  label="{{disciplineGroup.discipline}}">
                <ng-container *ngFor="let subOption of disciplineGroup.programs">
                  <option *ngIf="!subOption.disabledFor12 && subOption.twelveCode" value="{{ subOption.twelveCode }}">{{ subOption.name }}</option>
                </ng-container>
              </optgroup>
            </ng-container>

          </select>
          <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
        </div>
        <span class="error" id="mais-12-hour-error" *ngIf="formService.hasErrors(formGroup.get('mais_12_hour_select'))">Select an area of study (12h)</span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 form-control">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('mais_9_hour_select'))"></div>
        <label class="input-label-text">Secondary (9-Hours)</label>
        <div class="select-input-wrap">
          <select
            name="mais_9_hour_select"
            id="mais_9_hour_select"
            formControlName="mais_9_hour_select"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('mais_9_hour_select')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('mais_9_hour_select')) ? 'mais-9-hour-error' : null"
          >
            <option value="" selected>Select Area of Study</option>

            <ng-container *ngFor="let disciplineGroup of maisAllOptionsArray">
              <ng-container *ngIf="disciplineGroup.programs.length === 1 && !disciplineGroup.disabledFor9">
                <ng-container *ngFor="let programs of disciplineGroup.programs">
                  <option *ngIf="programs.nineCode" value="{{ programs.nineCode }}">{{programs.name}}</option>
                </ng-container>
              </ng-container>

              <optgroup *ngIf="!disciplineGroup.disabledFor9 && disciplineGroup.programs.length > 1" class="greyText" label="{{disciplineGroup.discipline}}">
                <ng-container *ngFor="let subOption of disciplineGroup.programs">
                  <option *ngIf="!subOption.disabledFor9 && subOption.nineCode" value="{{ subOption.nineCode }}">{{ subOption.name }}</option>
                </ng-container>
              </optgroup>
            </ng-container>

          </select>
          <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
        </div>
        <span class="error" id="mais-9-hour-error" *ngIf="formService.hasErrors(formGroup.get('mais_9_hour_select'))">Select an area of study (9h)</span>
      </div>
    </div>

  </div>

  <div class="row indr-hour-select-wrap" *ngIf="isEnabled(formGroup.get('indr_18_hour_select')) && isEnabled(formGroup.get('indr_12_hour_select'))">

    <label class="bold-label some-padding">Choose your primary and secondary areas of study</label>
    <div class="col-sm-12 col-md-6 form-control">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('indr_18_hour_select'))"></div>
        <label class="input-label-text">Primary (18-Hours)</label>
        <div class="select-input-wrap">
          <select
            name="mais_12_hour_select"
            id="indr_18_hour_select"
            formControlName="indr_18_hour_select"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('indr_18_hour_select')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('indr_18_hour_select')) ? 'indr-18-hour-error' : null"
          >
            <option value="" selected>Select Area of Study</option>

            <ng-container *ngFor="let disciplineGroup of indrAllOptionsArray">
              <ng-container *ngIf="disciplineGroup.programs.length === 1 && !disciplineGroup.disabledFor18">
                <ng-container *ngFor="let programs of disciplineGroup.programs">
                  <option *ngIf="programs.eighteenCode" value="{{ programs.eighteenCode }}">{{programs.name}}</option>
                </ng-container>
              </ng-container>

              <optgroup *ngIf="!disciplineGroup.disabledFor18 && disciplineGroup.programs.length > 1" class="greyText"  label="{{disciplineGroup.discipline}}">
                <ng-container *ngFor="let subOption of disciplineGroup.programs">
                  <option *ngIf="!subOption.disabledFor18 && subOption.eighteenCode" value="{{ subOption.eighteenCode }}">{{ subOption.name }}</option>
                </ng-container>
              </optgroup>
            </ng-container>

          </select>
          <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
        </div>
        <span class="error" id="mais-18-hour-error" *ngIf="formService.hasErrors(formGroup.get('indr_18_hour_select'))">Select an area of study (18h)</span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 form-control">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('indr_12_hour_select'))"></div>
        <label class="input-label-text">Secondary (12-Hours)</label>
        <div class="select-input-wrap">
          <select
            name="indr_12_hour_select"
            id="indr_12_hour_select"
            formControlName="indr_12_hour_select"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('indr_12_hour_select')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('indr_12_hour_select')) ? 'indr_12_hour_error' : null"
          >
            <option value="" selected>Select Area of Study</option>

            <ng-container *ngFor="let disciplineGroup of indrAllOptionsArray">
              <ng-container *ngIf="disciplineGroup.programs.length === 1 && !disciplineGroup.disabledFor12">
                <ng-container *ngFor="let programs of disciplineGroup.programs">
                  <option *ngIf="programs.twelveCode" value="{{ programs.twelveCode }}">{{programs.name}}</option>
                </ng-container>
              </ng-container>

              <optgroup *ngIf="!disciplineGroup.disabledFor12 && disciplineGroup.programs.length > 1" class="greyText" label="{{disciplineGroup.discipline}}">
                <ng-container *ngFor="let subOption of disciplineGroup.programs">
                  <option *ngIf="!subOption.disabledFor12 && subOption.twelveCode" value="{{ subOption.twelveCode }}">{{ subOption.name }}</option>
                </ng-container>
              </optgroup>
            </ng-container>

          </select>
          <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>
        </div>
        <span class="error" id="indr_12_hour_error" *ngIf="formService.hasErrors(formGroup.get('indr_12_hour_select'))">Select an area of study (12h)</span>
      </div>
    </div>

  </div>

</ng-container>

// tslint:disable: variable-name
export class Student {
  public address = '';
  public addressName = '';
  public addressType = '';
  public birthDate = '';
  public city = '';
  public country = '';
  public email = '';
  public firstName = '';
  public gender = '';
  public lastName = '';
  public middleName = '';
  public phoneNumber = '';
  public state = '';
  public zip = '';
  public pidm = '';

  constructor(
  ) {
  }
}

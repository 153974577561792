import {Email} from './application/email.model';
import {Code} from './application/code.model';
import {Phone} from './application/phone.model';
import {Address} from './application/address.model';
import {MajorFoses} from './application/major-foses.model';
import {HighSchool} from './application/highschool.model';
import {College} from './application/college.model';
import {CustomAnswer} from './application/custom-answer.model';
import {Proxy} from './application/proxy.model';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {Visa} from './visa.model';

// couple notes:

// everything can be null because we'd like to generate an Application object right away when the app loads,
// because most of the possible values are optional for Banner, everything is initially null

// a bunch specify a saturn database table, looking something like 'saturn.stvnatn.....'
// have to check the entries on those tables to see what the possible options are

// tslint:disable: variable-name
export class Application {

  public wapp: string; // (required if op is null, must be a valid value from saturn.stvwapp)
  // wapp code for the type of student applying (type depends on the student’s previous academic history and their intended studies)
  public term: string;
  public prev_applied_resp: string; // (optional; must be Y or N) whether the applicant has previously applied
  public prev_attend_resp: string; // (optional; must be Y or N) whether the applicant has previously applied
  public rtlCode: string; // (optional) individual relationship code
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public gender: string; // must be M or F
  public citizenship = ''; // (optional; must be a value in saturn.stvcitv.stvcitz_code)
  public nation: string; // (optional; must be a value in saturn.stvnatn.stvnatn_code)
  public race: string[]; // array of Strings. Strings should be a value in general.gorrace.gorrace_race_cde and not null.
  public religion: string;
  public ipAddress: string; // (required) IP of the user submitting the app
  public ssn: string; // (optional)
  public birthDate: string; // (optional) must be formatted as MM/DD/YYYY exactly, 10 characters
  public maritalStatus: string; // (optional) must be a value in saturn.stvmrtl.stvmrtl_code)
  public formerName: string; // (optional) is this last name only?
  public ethnicityCategory: string; // (optional; value must be a string of 1 or 2)
  public birthCountry: string; // (optional; must be a value in saturn.stvnatn.stvnatn_code
  public nativeLanguage: string; // (optional; must be a value in saturn.stvlang.stvlang_code)
  public homeLanguage: string; // (optional; must be a value in saturn.stvlang.stvlang_code)
  public prefix: string; // (optional) name prefix of the applicant (such as Mr. or Mrs.)
  public suffix: string; // (optional) name suffix of the applicant (such as Jr. or Sr.)
  public virginiaResident: string; // (optional) Y or N
  public sources = new Array<Code>(); // (required) array of . If no sources, then pass an empty array
  public submitStatus: string; // (optional) Y or N. Whether or not to submit the application (or just save all this data).
  // when would it be N?
  public emails: Email[] = new Array<Email>(); // array of the following, for our app validation we require at least one
  public phones = new Array<Phone>(); // (optional) array of the following. If no phones then provide empty array
  public addresses: Address[] = new Array<Address>(); // (optional) array of the following
  public attributes: Code[] = new Array<Code>(); // array of the following
  // attribute codes (saturn.stvatts.stvatts_code)
  public majorFoses: MajorFoses[] = new Array<MajorFoses>(); // array of the following
  public visa: Visa;
  public highSchools = new Array<HighSchool>(); // (optional) array of the following
  public priorColleges: College[] = []; // (optional) array of the following
  public customAnswers = new Array<CustomAnswer>(); // (optional) array of the following
  // some notes
  // question 61 is basically required
  // pass ApplyLU unique app ID in question 300

  public contactPreferences: { // object of the following
    method: string, // (phone, email, etc.)
    timeframe: string, // hours during the day (8am-12pm, 12pm-5pm, etc.)
    timezone: string // 3 character timezone (UTC, etc.)
  };
  public proxy: Proxy[] = new Array<Proxy>(); // array of the following

  constructor(appJson?: {}) {
    if (appJson) {
      Object.assign(this, appJson);
    }
  }

  public convertSchools() {
    const nuColArray: College[] = [];
    this.priorColleges.forEach(thisCollege => {
      const convertedCollege = new College();
      Object.assign(convertedCollege, thisCollege);
      if (convertedCollege.degree === '000000') {
        convertedCollege.degreeDate = '';
      }
      nuColArray.push(convertedCollege);
    });
    this.priorColleges = nuColArray;
    const nuHSArray: HighSchool[] = [];
    this.highSchools.forEach(thisHighschool => {
      const convertedHighschool = new HighSchool();
      Object.assign(convertedHighschool, thisHighschool);
      nuHSArray.push(convertedHighschool);
    });
    this.highSchools = nuHSArray;
  }

  public addCollege(college: College) {
    if (!college.sequenceNumber) {
      college.sequenceNumber = (this.priorColleges.length + 1).toString();
    }
    this.priorColleges.push(college);
  }

  public addHighSchool(highSchool: HighSchool) {
    if (!highSchool.sequenceNumber) {
      highSchool.sequenceNumber = (this.highSchools.length + 1).toString();
    }
    this.highSchools.push(highSchool);
  }

  public removeCollege(college: College) {
    this.priorColleges.splice(this.priorColleges.indexOf(college), 1);
  }

  public removeCollegeBySequenceNum(sequenceNum: string) {
    const removedCollegeIndex = this.priorColleges.findIndex((college: College) => college.sequenceNumber === sequenceNum);
    this.priorColleges.splice(removedCollegeIndex, 1);
  }

  public removeHighSchool(highSchool: HighSchool) {
    this.highSchools.splice(this.highSchools.indexOf(highSchool), 1);
  }

  setApplicationEmail(email: string) {
    let alreadyHasEmail = false;

    for (const theEmail of this.emails) {
      if (theEmail.emailAddress === email) {
        alreadyHasEmail = true;
      }
    }

    if (!alreadyHasEmail && this.emails.length === 0) {
      const pushThatEmail = new Email();
      pushThatEmail.emailAddress = email;
      this.emails.push(pushThatEmail);
      pushThatEmail.sequenceNumber = this.emails.length.toString();
    } else {
      this.emails[0].emailAddress = email;
    }

    return true;
  }

  public setApplicationPhone(phone: Phone): void {
    let alreadyHasType = false;
    // default type is AP
    // cell phone type is CL
    // if they agree to tcpa consent also add it to type TX
    if (!phone.type) {
      phone.type = 'AP';
    }

    phone.phoneNumber = phone.phoneNumber.replace(/\s/g, "");

    switch (phone.type) {
      case 'AP':
        phone.sequenceNumber = '1';
        break;

      case 'CL':
        phone.sequenceNumber = '2';
        break;

      case 'TX':
        phone.sequenceNumber = '4';
        break;

      default:
        phone.sequenceNumber = '1';
        break;
    }
    for (const thisPhone of this.phones) {
      if (thisPhone.type === phone.type) {
        alreadyHasType = true;
      }
    }
    const phoneWithoutCountryCode = new Phone();
    Object.assign(phoneWithoutCountryCode, phone);

    if (phone.country !== 'US') {
      phoneWithoutCountryCode.access = phone.phoneNumber;
      phoneWithoutCountryCode.area = '';
      phoneWithoutCountryCode.phoneNumber = '';
    }

    phoneWithoutCountryCode.country = '';

    if (!alreadyHasType) {
      this.phones.push(phoneWithoutCountryCode);
    } else {
      const phoneIndex = this.phones.findIndex((thisPhone: Phone) => {
        return thisPhone.type === phoneWithoutCountryCode.type;
      });
      this.phones[phoneIndex] = phoneWithoutCountryCode;
    }

  }

  removeApplicationPhone(phone: Phone) {
    let phoneIndex = -1;
    if (!phone) {
      return;
    }
    if (phone.type) {
      phoneIndex = this.phones.findIndex((thisPhone: Phone) => {
        return thisPhone.type === phone.type;
      });
    } else {
      phoneIndex = this.phones.findIndex((thisPhone: Phone) => {
        return thisPhone.phoneNumber === phone.phoneNumber;
      });
    }
    if (phoneIndex >= 0) {
      this.phones.splice(phoneIndex, 1);
    }
  }

  public parsePhoneNumber(number: string): { fullPhoneNumber: string, areaCode: string, localPhoneNumber: string, country: string } {
    const parsedPhone = parsePhoneNumberFromString(number, 'US');

    const fullPhoneNumber = parsedPhone.nationalNumber.length === 11 ? parsedPhone.nationalNumber.substring(1) : parsedPhone.nationalNumber.toString();
    const areaCode = fullPhoneNumber.length === 10 ? fullPhoneNumber.substring(0, 3) : '';
    const localPhoneNumber = fullPhoneNumber.length === 10 ? fullPhoneNumber.slice(-7) : fullPhoneNumber;

    return {fullPhoneNumber, areaCode, localPhoneNumber, country: null};
  }

  public getCleanFullAppPhone(): string {
    let result = '';
    let phone: Phone;

    if (this.phones.length > 0) {
      phone = this.phones[0];
      result = phone.phoneNumber ? (phone.area + phone.phoneNumber) : phone.access;
      result = result.replace(/[^0-9]/g, '');
      result = result.replace(/\s/g, "");
    }
    return result;
  }

  addAcode(acode: string) {
    const alreadyHasCode: boolean = this.sources.some(thisAcode => thisAcode.code === acode.replace(/\s/g, ""));
    if (!alreadyHasCode) {
      this.sources.push({code: acode});
    }
  }

  getAllAcodes() {
    return this.sources;
  }

  removeAcode(acode: string) {
    const acodeIndex: number = this.sources.findIndex(item => {
      return item.code === acode;
    });
    if (acodeIndex >= 0) { // acodeIndex will be -1 if no match is found
      this.sources.splice(acodeIndex, 1);
    }
  }

  addAttribute(attributeCode: string) {
    const alreadyHasAttribute: boolean = this.attributes.some(thisAttribute => {
      return thisAttribute.code === attributeCode;
    });
    if (!alreadyHasAttribute && attributeCode && attributeCode !== '') {
      this.attributes.push({code: attributeCode});
    }
  }

  removeAttribute(attributeCode: string) {
    const attributeIndex: number = this.attributes.findIndex(item => {
      return item.code === attributeCode;
    });
    if (attributeIndex >= 0) { // attributeIndex will be -1 if no match is found
      this.attributes.splice(attributeIndex, 1);
    }
  }

  setCustomAnswer(answer: string, questionNumber: string) {
    const findAnswer = this.customAnswers.find((thisAnswer: CustomAnswer) => thisAnswer.questionNumber === questionNumber);

    if (findAnswer) {

      if (answer === '') { // remove it, no need to save an empty value

        const answerIndex = this.customAnswers.findIndex((thisAnswer: CustomAnswer) => thisAnswer.questionNumber === questionNumber);
        if (answerIndex) {
          this.customAnswers.splice(answerIndex, 1);
        }

      } else {
        findAnswer.answer = answer;
      }

    } else if (answer && answer !== '') {
      this.customAnswers.push({answer, questionNumber});
    }
  }

  getCustomAnswer(questionNumber: string) {
    const findAnswer = this.customAnswers.find((thisAnswer: CustomAnswer) => thisAnswer.questionNumber === questionNumber);
    return findAnswer ? findAnswer.answer : '';
  }

  setMajorFoses(programCode: string) {
    if (this.majorFoses.length === 0) {
      this.majorFoses.push({etrySequenceNumber: '1', sareFosSequenceNumber: '', programCode});
    } else {
      this.majorFoses[0].programCode = programCode;
    }
  }

  updateSchool(school: HighSchool | College): void {
    if (school instanceof HighSchool) {
      this.updateHighSchool(school);
    } else if (school instanceof College) {
      this.updateCollege(school);
    }
  }

  private updateHighSchool(highSchool: HighSchool): HighSchool {
    this.highSchools[0] = highSchool;
    return highSchool;
  }

  private updateCollege(college: College): College {
    let updatedCollege: College;

    updatedCollege = this.priorColleges.find(thisSchool => thisSchool.ceebCode === college.ceebCode);
    if (!updatedCollege) {
      updatedCollege = new College();
      updatedCollege.ceebCode = college.ceebCode;
      this.addCollege(updatedCollege);
    }

    return updatedCollege;
  }

}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild, OnChanges, SimpleChanges
} from '@angular/core';
import {AustraliaStates, CanadaStates, States} from 'src/app/shared/model/states.model';
import { FormControl, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { State } from 'src/app/shared/model/state.model';
import {SearchableSelectDropDownComponent} from '../searchable-select-drop-down/searchable-select-drop-down.component';

enum ECountry {
  US = 'US',
  USOnly = 'US-only',
  CA = 'CA',
  AU = 'AU'
}

@Component({
  selector: 'app-state-search',
  templateUrl: './state-search.component.html',
  styles: ['.search-input-wrap .search-input {font-weight: normal;}']
})
export class StateSearchComponent implements OnChanges {

  @Input() itemFormControl: FormControl<string> = new FormControl<string>('');
  @Input() placeholder = 'Select';
  @Input() inputLabelText: string;
  @Input() currentCountry: ECountry = ECountry.US;
  @Input() stateError: string;
  @Output() emitedState = new EventEmitter<State>();
  @ViewChild(SearchableSelectDropDownComponent) searchableSelectDropDownComponent: SearchableSelectDropDownComponent<State>;

  displayStates = false;
  highlightedState = new State();
  stateSearch: string;
  chosenState: State = new State();

  usStates = States.sort();
  usOnlyStates = this.usStates;
  canadaStates = CanadaStates.sort();
  australiaStates = AustraliaStates.sort();

  allStates: State[] = this.usStates;
  filteredStates = this.allStates;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentCountry) {
      const currentValue = changes.currentCountry.currentValue;
      if (this.searchableSelectDropDownComponent) {
        if (currentValue === 'US-only' || currentValue === 'US') {
          this.searchableSelectDropDownComponent.clearSelection();
          this.allStates = this.usOnlyStates;
        } else if (currentValue === 'CA') {
          this.searchableSelectDropDownComponent.clearSelection();
          this.allStates = this.canadaStates;
        } else if (currentValue === 'AU') {
          this.searchableSelectDropDownComponent.clearSelection();
          this.allStates = this.australiaStates;
        }
      }
    }
    }

  constructor(
    public formService: FormService,
  ) { }

  public addState(specifiedState: State) {

    if (!specifiedState) {
        specifiedState = new State();
      }

    if (specifiedState.code) {
        // Leave this for full-address component; passes up to
        this.chosenState = specifiedState;
        // emit state to parent
        this.emitedState.emit(specifiedState);
      //  Fire off subject to update disclosure
        this.formService.updatedState.next(specifiedState);
      }

    this.itemFormControl.setValue(specifiedState.name);
    this.itemFormControl.markAsPristine();
  }

  public getFormControlParent(): FormGroup {
    return this.itemFormControl.parent as FormGroup;
  }

  public getFormControlName(): string {
    const formGroup = this.itemFormControl.parent.controls;
    return Object.keys(formGroup).find(name => this.itemFormControl === formGroup[name]);
  }

  trackByFn(index, item) {
    return item.name; // or item.id
  }

}

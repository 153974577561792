<ng-container [formGroup]="formGroup">

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('tcpa_radio')"
    [label]="questionText"
  >
  </app-radio>

  <app-alert class="mb-3" role="alert" aria-live="alert" [goFullWidth]="true" [smaller]="true" *ngIf="formGroup.get('tcpa_radio').value === 'No' && !showAlternateAlert">By selecting "No", you are opting out of receiving such calls and/or texts from Liberty and its affiliates using automated technology. You may still receive manually-dialed calls and/or texts from Liberty University. University communications will also be sent to your Liberty University email address. You will be responsible for all information, including academic and administrative information, provided to you in this format. Liberty University does believe opting in to automated communication is the most effective way to help you stay up to date on important and time-sensitive information. Given these reasons, we encourage you to reconsider by selecting "Yes".</app-alert>

  <app-alert class="mb-3" role="alert" aria-live="alert" [goFullWidth]="true" [smaller]="true" *ngIf="formGroup.get('tcpa_radio').value === 'No' && showAlternateAlert">By selecting "No", you are opting out of receiving such calls and/or texts from Liberty and its affiliates using automated technology. You may still receive manually-dialed calls and/or texts from Liberty University. University communications will also be sent to your Liberty University email address. You will be responsible for all information, including academic and administrative information, provided to you in this format. Liberty University does believe opting in to automated communication is the most effective way to help you stay up to date on important and time-sensitive information. Given these reasons, we encourage you to reconsider by selecting "Yes".

  <p>If you would like to continue with your current preference and not receive automated phone communication, click the button below to print, sign and mail your application to:</p>

  <p>Admissions Verification
  <br>
  1971 University Blvd
  <br>
  Lynchburg, VA 24515</p></app-alert>

  <app-pdf *ngIf="this.formGroup.get('tcpa_radio').value === 'No' && showAlternateAlert"></app-pdf>

</ng-container>

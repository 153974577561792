import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {FormService} from 'src/app/shared/service/form.service';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {AsYouType} from 'libphonenumber-js';
import {Phone} from '../../../shared/model/application/phone.model';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BlockedApplicationService} from '../../../shared/service/blocked-application.service';
import { FormControlCustom } from 'src/app/shared/form-control-custom';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Output() emitPhone = new EventEmitter<Phone>();

  americanPhonePattern = /([1]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})/;
  internationalPhonePattern = /(\+[\d]{1,16})/m;

  updatedAddressSubscription = new Subscription();
  phoneSub = new Subscription();
  prefillFormSub = new Subscription();

  constructor(
    public formService: FormService,
    public cdr: ChangeDetectorRef,
    private applicationService: ApplicationService,
    private blockedAppService: BlockedApplicationService
  ) { }

  ngOnInit(): void {

    // need to transform a phone into an instance of a phone in case we have one
    this.prefillFormSub = this.formService.prefillForm.subscribe(formObject => {
      const theForm = formObject.formData;
      if (
        theForm['personalInformation']
        && theForm['personalInformation']['phone_fields']
        && theForm['personalInformation']['phone_fields']['phoneNumberInput']
      ) {
        this.phoneNumberUpdated(theForm['personalInformation']['phone_fields']['phoneNumberInput']);
      }
    });

    this.formGroup.get('phoneNumberInput').valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000))
      .subscribe(phoneNumber => {
      if ((this.formGroup.get('isPossible').value as boolean) || this.isInternationalAndValid()){

        const trimmedPhone = this.trimPhone(phoneNumber);

        this.blockedAppService.checkBlockedPhone(trimmedPhone).then(result => {
          result.subscribe(blockedResource => {
            this.applicationService.isBlockedResPhone = blockedResource.isResBlocked === 'Y';
            this.applicationService.isBlockedOnlinePhone = blockedResource.isLuBlocked === 'Y';
          });
        });
      }
    });
  }

  ngOnDestroy() {
    this.updatedAddressSubscription.unsubscribe();
    this.prefillFormSub.unsubscribe();
  }

  phoneNumberUpdated(phoneNumber: string, inputType = 'default') {
    const formatter = new AsYouType('US');
    const formattedPhoneNumber = formatter.input(phoneNumber);

    if (formatter.getCountry() === 'US' || phoneNumber.length === 0) {
      this.formGroup.get('phoneNumberInput').setValidators([FormControlCustom.customValidatorByBoolean(formatter.isPossible()), Validators.required, Validators.pattern(this.americanPhonePattern)]);
    } else {
      this.formGroup.get('phoneNumberInput').setValidators([Validators.required, Validators.min(16), Validators.pattern(this.internationalPhonePattern)]);
    }

    this.formGroup.get('isPossible').setValue(formatter.isPossible());
    this.formGroup.get('isInternational').setValue(formatter.isInternational());
    this.formGroup.get('completePhone').get('country').setValue(formatter.getCountry());

    if (formatter.isPossible() || (this.isInternationalAndValid())) {
      this.formGroup.get('phoneNumberInput').clearValidators();

      if (formatter.isInternational()) {
        this.formGroup.get('completePhone').get('area').setValue('');
        this.formGroup.get('completePhone').get('phoneNumber').setValue(phoneNumber);
      } else {
        this.formGroup.get('completePhone').get('area').setValue(formatter.getNationalNumber().substr(0, 3));
        this.formGroup.get('completePhone').get('phoneNumber').setValue(formatter.getNationalNumber().substr(3, 10));
      }
    } else {
      this.formGroup.get('completePhone').get('area').setValue('');
      this.formGroup.get('completePhone').get('phoneNumber').setValue('');
    }

    this.formGroup.get('phoneNumberInput').updateValueAndValidity();

    if (inputType !== 'deleteContentBackward'
      || (inputType === 'deleteContentBackward'
        && this.trimPhone(phoneNumber).length !== 3)) {
      this.getPhoneFormControl().setValue(formattedPhoneNumber, {emitEvent: false});
      this.getPhoneFormControl().updateValueAndValidity();
    }
  }

  public getPhoneFormControl(): FormControlCustom<string> {
    return this.formGroup.get('phoneNumberInput') as FormControlCustom<string>;
  }

  checkForWhitespace() {
    let phoneNumber = this.getPhoneFormControl().value;
    if (/^\s/.test(phoneNumber)) { // starts with an empty string
      phoneNumber = phoneNumber.trim();
      this.getPhoneFormControl().setValue(phoneNumber);
    }
  }

  isInternationalAndValid() {
    const input = this.formGroup.get('phoneNumberInput').value as string;
    return this.formGroup.get('isInternational').value as boolean && (input !== '+' && input.length <= 16);
  }

  trimPhone(rawPhone): string {
    return rawPhone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');
  }

  getInternationalErrorMessage(): string {
    const phone = this.trimPhone(this.formGroup.get('phoneNumberInput').value);
    if (phone.length > 10) {
      if (phone.substring(0, 1) === '+' || phone.substring(0, 3) === '011'){
        return 'Enter a valid international phone number';
      } else {
        return 'If this is an international phone number, please enter "+" followed by your country code';
      }
    } else {
      return 'Enter a valid phone number';
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
/*
 * Highlight text within a string
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/

@Pipe({
  name: 'highlight'
})

export class HighlightSearch implements PipeTransform {

  transform(value: any, args: any): any {
    if (!args) { return value; }
    const re = new RegExp(args, 'gi'); // 'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    return value.replace(re, `<span class="highlight">$&</span>`);
  }
}

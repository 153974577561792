<ng-container [formGroup]="formGroup">

  <app-checkbox
  [itemFormGroup]="formGroup.get('refund_terms_radio')"
  [options]="refundCheckboxOpts"
  ></app-checkbox>

  <div class="mb-4">
    <small>
      The Enrollment Deposit is fully refundable toward your Liberty University account and can be paid at any time.
    </small>
  </div>
</ng-container>

<div *ngIf="!isProd">
  <hr>
  <input (click)="isActive = !isActive" type="checkbox" name="appToggle" id="appToggle" class="mr-1 mt-3">
  <label for="appToggle">Display Form Object</label>
  <div [ngClass]="{'active': isActive}" class="app_toggle_content mt-3" [innerHTML]="parsedForm | safeHtml"></div>

  <hr>

  <div [ngClass]="{'active': isActive}" class="app_toggle_content mt-3"><!-- parse the app object as well -->
    <h3 class="mb-2">Application Object</h3>
    <div [ngClass]="{'active': isActive}" class="app_toggle_content mt-3">
      <ng-container *ngFor="let property of application | keyvalue;">

        <div class="mb-2" [attr.data-property]="property.key">
          {{ property.key }}: <span *ngIf="isArray(property.value)">[</span>

          <ng-container *ngIf="isObject(property.value)">{{ '{' }}

            <div class="pl-4 items" *ngFor="let subItem of property.value | keyvalue" [attr.data-value]="subItem.value">
              <span [attr.data-key]="subItem.key">{{subItem.key }}</span>: "<span
                [attr.data-value]="subItem.value">{{
                subItem.value }}</span>"
            </div>

            {{ '}' }}
          </ng-container>

          <ng-container *ngIf="!isObject(property.value) && !isArray(property.value)">
            "<span [attr.data-value]="property.value ">{{ property.value }}</span>"
          </ng-container>

          <div class="pl-4 items" *ngIf="isArray(property.value) && property.value.length > 0">

            <ng-container *ngFor="let item of property.value; let index = index; let propLength = count">

              <ng-container *ngIf="!isObject(item)">"<span [attr.data-value]="item">{{ item }}</span>"<ng-container
                  *ngIf="propLength > index + 1">, </ng-container>
              </ng-container>

              <div *ngIf="isObject(item)" class="subitem my-1">

                <span class="key-value-pair"
                  *ngFor="let subItem of item | keyvalue; let index = index; let objLength = count">
                  <ng-container *ngIf="index === 0">{{ '{ ' }}</ng-container> <span
                    [attr.data-key]="subItem.key">{{subItem.key }}</span>: "<span
                    [attr.data-value]="subItem.value">{{ subItem.value }}</span>"<ng-container
                    *ngIf="objLength > index + 1">, </ng-container>
                  <ng-container *ngIf="objLength === index + 1"> {{ '},' }}<br></ng-container>
                </span>

              </div>

            </ng-container>

          </div>

          <ng-container *ngIf="isArray(property.value)">]</ng-container>,

        </div>


      </ng-container>
    </div>
  </div>
</div>

<button
  type="button"
  class="button button-small"
  tabindex="1"
  (click)="onTriggerGetHelpModal()"
  (keyup.enter)="onTriggerGetHelpModal()"
  aria-haspopup="true"
>Get Help</button>

<br>

<button
  *ngIf="env.isAgent && !applicationService.submitSuccess"
  type="button"
  class="button button-small button-less-padding mt-3"
  tabindex="1"
  (click)="onTriggerAppSearchModal()"
  (keyup.enter)="onTriggerAppSearchModal()"
  aria-haspopup="true"
  style="width: 125px"
>Search Apps</button>

<button
  *ngIf="!env.isAgent && authenticationService.isLoggedIn() && !env.production"
  type="button"
  class="button button-small button-less-padding mt-3"
  tabindex="1"
  (click)="authenticationService.logout()"
  (keyup.enter)="authenticationService.logout()"
  aria-haspopup="true"
>Logout (Appears on test only)</button>

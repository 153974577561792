import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Popup } from '../model/popup.model';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  public emitPopupContent: Subject<Popup>;

  constructor() {
    this.emitPopupContent = new Subject();
  }

  public displayPopup(popupContent: Popup) {
    this.emitPopupContent.next(popupContent);
  }
}

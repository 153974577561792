import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // directive name style guide https://angular.io/guide/styleguide#style-02-08
  selector: '[appSsnFormat][formControlName]',
})
export class SsnFormatDirective {

  constructor(
    public ngControl: NgControl
    ) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  onInputChange(value: string) {

    let newVal = value;

    // http://rion.io/2013/09/10/validating-social-security-numbers-through-regular-expressions-2/#validatingthenumber
    // each group cannot contain all zeros
    if (newVal === null) {
      return '';
    }
    value.replace(' ', '');

    // if new input is a triggered zero at the end, middle, or beginning sections
    if (newVal.match(/^\d{3}-\d{2}-0{4}|\d{3}-0{2}|(?:0{4})|^0{3}$/g)) {
      newVal = newVal.substring(0, newVal.length - 1); // remove last character entered
      this.ngControl.valueAccessor.writeValue(newVal);
      return;
    }

    // check again for zeros in each section, can be snuck in by clicking on input field and entering 0 again
    if (newVal.match(/^(\d{3}-\d{2}-0{4})|(\d{3}-0{2})|(0{3}-)$/g)) {
      // they shouldn't be doing this, empty out value
      newVal = '';
      this.ngControl.valueAccessor.writeValue(newVal);
      return;
    }

  }
}

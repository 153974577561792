<div [ngClass]="{'hidden': !isModalDisplayed}">
  <div class="modal-container modal-with-tabs" #modalContainer tabindex="0">

    <div class="tabs" #tabContainer>
      <div class="tab active" data-tab="appId" (click)="activateTab('appId')">By App ID</div>
      <div class="tab" data-tab="emailPhone" (click)="activateTab('emailPhone')">By Email/Phone</div>
    </div>

    <div class="modalContent relative">
      <a class="modalClose" (click)="closeModal()" (keyup.enter)="closeModal()" tabindex="0"></a>


      <div class="tab-content-container" #tabContentContainer>

        <div class="tab-content active" data-tab="appId">
          <h3 class="mb-2">Search incomplete apps by ID</h3>
          <app-app-id-search class="form-control" (prefillEvent)="closeModal()"></app-app-id-search>
        </div>


        <div class="tab-content" data-tab="emailPhone">
          <h3 class="mb-2">Search incomplete apps by phone and/or email</h3>
          <app-email-phone-search></app-email-phone-search>
        </div>

      </div>


    </div>
  </div>
  <div class="modalOverlay" (click)="closeModal()" (keyup.enter)="closeModal()" tabindex="-1"></div>
</div>

<div class="form-control" #checkElement [formGroup]="itemFormGroup">

  <div [ngClass]="{'alert alert-generic alert-no-icon': style === 'alert'}">

    <div class="mb-0" *ngFor="let control of itemFormGroup.controls | keyvalue; let i = index;">

      <div class="checkbox-wrap form-control mb-0">

        <div class="validation-error-bar" *ngIf="itemFormGroup.get(control.key).touched && itemFormGroup.get(control.key).errors"></div>

        <span id="{{control.key}}"></span>
        <!-- this allows us to find and 'scroll' to the formControl when clicking on an error message -->

          <div class="d-flex">
            <input
              class="col-auto"
              type="checkbox"
              formControlName="{{control.key}}"
              (change)="onCheckboxChange(control)"
              id="checkbox_val_{{control.key}}"
              [attr.required]="control.required ? 'required' : null"
              [attr.aria-invalid]="itemFormGroup.get(control.key).touched && itemFormGroup.get(control.key).errors ? 'true' : null"
              [attr.aria-describedby]="itemFormGroup.get(control.key).touched && itemFormGroup.get(control.key).errors ? control.key+'-error' : null"
            >
            <label for="checkbox_val_{{control.key}}" class="col" [innerHTML]="control.value.label || control.key"></label>
          </div>

          <span class="error font-weight-normal mt-3" id="{{control.key}}-error"
            *ngIf="itemFormGroup.get(control.key).touched && itemFormGroup.get(control.key).errors">{{getRequiredErrorMessage(itemFormGroup.get(control.key)).required}}</span>
        </div>

    </div>

  </div>

</div>

    <app-searchable-select-drop-down
      [inputLabelText]="'State'"
      [placeholder]="'Select'"
      [itemFormControl]="itemFormControl"
      [formGroup]="getFormControlParent()"
      [displayProperty]="'name'"
      [optionValueProperty]="'code'"
      [itemList]="allStates"
      (emitedItem)="(addState($event))"
      [canSearchByValues]="true"
      [errorMessage]="stateError"
    ></app-searchable-select-drop-down>

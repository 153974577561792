<div [formGroup]="formGroup" [ngClass]="{'hidden': formGroup.disabled }" class="relativeInfo">
  <fieldset>

    <legend>
      <span class="large-question mb-3">Tell us about your parent/guardian.</span>
    </legend>

    <app-guardian-relationship [formGroup]="formGroup"></app-guardian-relationship>

    <app-guardian-name-fields [formGroup]="formGroup"></app-guardian-name-fields>

    <div class="row">
      <div class="col-sm-8">

        <app-guardian-email [formGroup]="formGroup"></app-guardian-email>
      </div>
    </div>

  </fieldset>
</div>

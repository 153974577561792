import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { VerticalRadioComponent } from './vertical-radio.component';

@NgModule({
  declarations: [VerticalRadioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [
    VerticalRadioComponent
  ]
})
export class VerticalRadioModule { }

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {FormService, intlDisclaimerProgramCodes} from 'src/app/shared/service/form.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { NationService } from 'src/app/shared/service/nation.service';
import { Nation } from 'src/app/shared/model/nation.model';
import { Program } from 'src/app/shared/model/program.model';
import { Subject } from 'rxjs';
import { Address } from 'src/app/shared/model/application/address.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { CookieService } from 'src/app/shared/service/cookie.service';
import { SearchableSelectDropDownComponent } from '../../generic/searchable-select-drop-down/searchable-select-drop-down.component';
import { takeUntil } from 'rxjs/operators';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { CheckboxOpts } from 'src/app/shared/model/checkbox-opts.model';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import {ApplicationTransformerService} from '../../../../shared/service/application-transformer.service';

@Component({
  selector: 'app-us-citizen-questions',
  templateUrl: './us-citizen-questions.component.html',
  styles: ['.nation-search {max-width: 257px; display: block;}'
  ]
})
export class UsCitizenQuestionsComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @ViewChild('countryOfBirthDropdown') countryOfBirthDropdown: SearchableSelectDropDownComponent<Nation>;

  selectedProgram: Program = new Program();
  allNations = new Array<Nation>();
  allNationsForEu = [];

  applicationAddress: Address;
  citizenshipCodeUs = false;
  citizenshipCodeNe = false;
  showFaaDisclaimer = false;

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  showIntMatdDisclosure = false;
  intMatdDisclosureOpts: CheckboxOpts[] = [
    {
      name: 'international_matd_disclosure',
      required: false,
      disabled: true,
      defaultVal: false,
      label: '<b>The application for a state teaching license from the Virginia Department of Education requires that the applicant have a valid Social Security Number (SSN). International students will not be eligible to apply for a Virginia state teaching license if they do not have a valid SSN. I understand that I will need to obtain a Social Security Number prior to applying for state licensure.</b>',
      errorMessage: ''
    }
  ];

  public isCitizenOrGreenCardHolder = new Subject<boolean>();

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService,
    public nationService: NationService,
    private programService: ProgramService,
    private cookieService: CookieService,
    private transformerService: ApplicationTransformerService
  ) {
    this.selectedProgram.campCode = '';
    this.selectedProgram.programCode = '';
    this.applicationAddress = this.formService.UpdatedAddress.value;
  }

  ngOnInit(): void {

    this.cookieService.GDPRCookiesObtained.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.generateCorrectNations();
    });

    this.formService.UpdatedAddress.pipe(takeUntil(this.endSubscriptions)).subscribe(address => {
      this.applicationAddress = new Address(address);
      this.updateCitizenship();
      this.generateCorrectNations();
      this.maybeShowCountryOfBirth();
    });

    this.allNations = this.nationService.returnNations();

    this.nationService.nationsSubject.pipe(takeUntil(this.endSubscriptions)).subscribe(data => {
      this.allNations = data;
      Object.assign(this.allNationsForEu, this.allNations);
    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.selectedProgram = program;
      this.maybeShowCountryOfBirth();
      this.maybeShowCityOfBirth();
      this.checkForFaaDisclaimer();
      this.maybeEnableIntMatdDisclosure();
    });

    this.getUsCitizenRadio().valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (val === 'Yes') {
        this.formService.usCitizenSubject.next(true);
        this.formService.currentNationCode = 'US';
        this.formGroup.get('nation_search').disable();
        this.formGroup.get('green_card_radio').disable();
        this.formGroup.get('green_card_radio').setValue('');
        this.formService.greenCardHolderSubject.next(true);

        this.maybeShowCountryOfBirth();

      } else if (val === 'No') {
        this.formService.usCitizenSubject.next(false);
        if (this.formService.currentNationCode === 'US') {
          this.formService.currentNationCode = '';
        }

        this.formService.greenCardHolderSubject.next(false);
        if (val !== '') {
          this.formGroup.get('nation_search').enable();
          this.formGroup.get('green_card_radio').enable();
          this.formGroup.get('green_card_radio').markAsPristine();
          this.formGroup.get('green_card_radio').markAsUntouched();
        }


        this.maybeShowCountryOfBirth();
        this.generateCorrectNations();
        this.maybeShowCityOfBirth();
      }

      this.updateCitizenship();
      if (this.formGroup.get('green_card_radio').enabled && this.formGroup.get('green_card_radio').value !== '') {
        if (this.formGroup.get('green_card_radio').value === 'Yes') {
          this.formService.usCitizenSubject.next(true);
        } else {
          this.formService.usCitizenSubject.next(false);
        }
      }
      this.checkForFaaDisclaimer();
    });

    this.formGroup.get('green_card_radio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (val === 'Yes') {
        this.formService.greenCardHolderSubject.next(true);
        this.formGroup.get('city_of_birth').setValue('');
        this.formGroup.get('city_of_birth').disable();
        this.formGroup.get('city_of_birth').clearValidators();
      } else if (val === 'No' && this.selectedProgram && this.selectedProgram.campCode === 'R') {
        this.formService.greenCardHolderSubject.next(false);
        this.formGroup.get('city_of_birth').enable();
        this.formGroup.get('city_of_birth').markAsPristine();
        this.formGroup.get('city_of_birth').markAsUntouched();
        this.formGroup.get('city_of_birth').setValidators([Validators.required]);
      } else if (val === 'No') {
        this.formService.greenCardHolderSubject.next(false);
      } else {
        this.formService.greenCardHolderSubject.next(false);
        this.formGroup.get('city_of_birth').setValue('');
        this.formGroup.get('city_of_birth').disable();
        this.formGroup.get('city_of_birth').clearValidators();
      }
      this.updateCitizenship();
      this.checkForFaaDisclaimer();

      // add another check for international MAT-D program disclaimer
      this.maybeEnableIntMatdDisclosure();
    });

    this.updateCitizenship();

    this.formService.updatedCampus.subscribe((campCode: string) => {
      if (campCode === 'D' && this.getUsCitizenRadio().value === '') {
        this.getUsCitizenRadio().setValue('Yes');
      }
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  updateCountryOfBirth(country: Nation) {
    if (!country) {
      country = new Nation();
      country.code = '';
      country.name = '';
    }
    this.applicationService.application.birthCountry = country.code;
    this.formGroup.get('country_of_birth_search_code').setValue(country.code);
    this.formService.birthCountryUpdated.next(country);

  }

  updateNation(nation: Nation) {
    this.formService.currentNationCode = nation.code;
    this.formGroup.get('nation_search_code').setValue(nation.code);

  }

  updateCitizenship() {
    const getCitizenRadio = this.getUsCitizenRadio().value;
    const getGreenCardRadio = this.formGroup.get('green_card_radio').value;
    let addressNationCode: string;
    let appCitizenship: string;
    if (this.applicationAddress.nationCode && this.applicationAddress.nationCode !== '') {
      addressNationCode = this.applicationAddress.nationCode;
    } else {
      addressNationCode = '';
    }
    if (getCitizenRadio === 'Yes') {
      appCitizenship = 'US';
    } else if (
      addressNationCode !== 'US'
      && getCitizenRadio === 'No'
      && getGreenCardRadio === 'No'
      && this.selectedProgram.campCode === 'D'
    ) {
      appCitizenship = 'IL';
    } else if (
      (getCitizenRadio === 'No' && addressNationCode === 'US' && this.selectedProgram.campCode === 'D')
      || (getCitizenRadio === 'No' && addressNationCode !== 'US' && getGreenCardRadio === 'Yes' && this.selectedProgram.campCode === 'D')
      || (getCitizenRadio === 'No' && getGreenCardRadio === 'Yes' && this.selectedProgram.campCode === 'R')
    ) {
      appCitizenship = 'NE';
    } else if (getCitizenRadio === 'No' && getGreenCardRadio === 'No' && this.selectedProgram.campCode === 'R') {
      appCitizenship = 'NI';
    } else {
      appCitizenship = 'NE';
    }
    this.formGroup.get('citizenship').setValue(appCitizenship);
    this.applicationService.application.citizenship = appCitizenship;
  }

  checkForFaaDisclaimer() {
    this.showFaaDisclaimer = this.getUsCitizenRadio()
      && this.formGroup.get('green_card_radio')
      && this.getUsCitizenRadio().value === 'No'
      && this.formGroup.get('green_card_radio').value === 'No'
      && this.selectedProgram.programCode
      && this.selectedProgram.programCode === 'AVAT-BS-D';
  }

  maybeShowCountryOfBirth() {
    if (
      this.selectedProgram
      && this.selectedProgram.campCode === 'R'
      && this.getUsCitizenRadio().value === 'No'
    ) {
      this.formGroup.get('country_of_birth_search').enable();

      if (this.formGroup.get('country_of_birth_search').value) {
        const nationCondeSearched = this.allNations.find(
          (nation) => {
            return nation.name === this.formGroup.get('country_of_birth_search').value;
          });
        this.updateCountryOfBirth(nationCondeSearched);
      }
    } else {
      if (this.formGroup.get('country_of_birth_search')) {
        this.formGroup.get('country_of_birth_search').disable();
        this.applicationService.application.birthCountry = '';
      }
    }
  }

  maybeShowCityOfBirth() {
    if (this.formGroup.get('green_card_radio')) {
      const val = this.formGroup.get('green_card_radio').value;
      if (val === 'Yes') {
        this.formGroup.get('city_of_birth').setValue('');
        this.formGroup.get('city_of_birth').disable();
        this.formGroup.get('city_of_birth').clearValidators();
      } else if (val === 'No' && this.selectedProgram.campCode === 'R') {
        this.formGroup.get('city_of_birth').enable();
        this.formGroup.get('city_of_birth').markAsPristine();
        this.formGroup.get('city_of_birth').markAsUntouched();
        this.formGroup.get('city_of_birth').setValidators([Validators.required]);
      } else {
        this.formGroup.get('city_of_birth').setValue('');
        this.formGroup.get('city_of_birth').disable();
        this.formGroup.get('city_of_birth').clearValidators();
      }
    }
    this.formGroup.get('city_of_birth').updateValueAndValidity();
  }

  maybeEnableIntMatdDisclosure() {
    const checkboxControl = this.formGroup.get('international_matd_disclosure_checkbox').get('international_matd_disclosure');
    const programCode = this.selectedProgram.programCode;

    if (checkboxControl && programCode !== null && programCode) {
      if (
        this.formGroup.get('green_card_radio').value === 'No' && this.selectedProgram &&
        (intlDisclaimerProgramCodes.some(programCodeEnding => programCode.includes(programCodeEnding)))
      )
      {
        this.showIntMatdDisclosure = true;
        checkboxControl.enable();
      } else {
        this.showIntMatdDisclosure = false;
        checkboxControl.disable();
      }
    }
  }

  generateCorrectNations() {
    Object.assign(this.allNationsForEu, this.allNations);
    const declineToAnswerOption = { code: 'decline', name: 'Decline to Answer' };
    if (
      this.getUsCitizenRadio().value === 'No'
      && (this.nationService.euNations.includes(this.applicationAddress.nationCode)
      || this.cookieService.userIsEu)
      && this.allNations[0] && this.allNations[0].name !== 'Decline to Answer'
    ) {
      this.allNationsForEu.unshift(declineToAnswerOption);
    } else if (
      (this.getUsCitizenRadio().value === 'Yes' || !this.nationService.euNations.includes(this.applicationAddress.nationCode))
      && this.allNations[0] && this.allNations[0].name === 'Decline to Answer'
    ) {
      this.allNationsForEu.shift();
    }
    if (this.formGroup.get('country_of_birth_search').value === 'Decline to Answer' && !this.nationService.euNations.includes(this.applicationAddress.nationCode)) {
      this.formGroup.get('country_of_birth_search').setValue('');
      this.countryOfBirthDropdown.selectItem(new Nation());
    }
  }

  getUsCitizenRadio(): FormControlCustom<string> {
    return this.formGroup.get('us_citizen_radio') as FormControlCustom<string>;
  }
}

<app-us-resident-radio *ngIf="showResideRadio" [formGroup]="formGroup" (usResidentSelection)="updateUsResidencySelection($event)">
</app-us-resident-radio>

<div [formGroup]="formGroup">
  <fieldset>
    <div style="max-width: 565px;" class="form-control street-address-wrapper">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('street_address'))"></div>
        <label for="full_address_street_address">
          <span class="input-label-text">Street Address</span>

          <app-places-autocomplete
            *ngIf="activateAutoComplete"
            [ngClass]="{ 'input-has-errors': formService.hasErrors(formGroup.get('street_address')) }"
            (emitPlace)="onGooglePlacesAutocomplete($event)" (focusout)="onAutoCompleteBlur($event)"
            [inputtedFormControl]="formGroup.get('street_address')"
            [activateAutocomplete]="formGroup.get('personal_info_country_code').value === 'US'"
          ></app-places-autocomplete>

          <input
            *ngIf="!activateAutoComplete"
            maxlength="50"
            type="text"
            name="street_address"
            formControlName="street_address"
            autocomplete="address-line1"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('street_address')) ? 'street-address-error' : null"
          />

        </label>
        <span class="error" id="street-address-error" role="dialog" aria-live="polite"
          *ngIf="formService.hasErrors(formGroup.get('street_address'))">Provide a street address</span>
      </div>
    </div>



    <div class="row">

      <div style="max-width: 215px;" class="form-control col city-wrapper">
        <div style="position: relative">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('city'))"></div>
          <label for="city">
            <span class="input-label-text">City</span>
            <input
              maxlength="30"
              type="text"
              name="city"
              id="city"
              appTrim
              formControlName="city"
              aria-label="City of address"
              aria-describedby="city-error"
              [attr.aria-invalid]="formService.hasErrors(formGroup.get('city')) ? 'true' : false"
            >
          </label>
          <span class="error" id="city-error" role="dialog" aria-live="polite" *ngIf="formService.hasErrors(formGroup.get('city'))">Provide a city</span>
        </div>
      </div>

      <input class="screen-reader-only" tabindex="-1" type="text" autocomplete="on" placeholder="State" formControlName="shipping-state" name="shipping-state">

      <app-state-search class="col" style="max-width: 275px;" [itemFormControl]="formGroup.get('personal_info_state')"
        [placeholder]="'Select a state or province'" [inputLabelText]="'State / Province'" [stateError]="'Select a state or province'"
        [ngClass]="{'hidden' : formGroup.get('personal_info_state').disabled}" [currentCountry]="stateSearchCountry">
      </app-state-search>

      <div [ngClass]="{'hidden' : formGroup.get('international_state').disabled}" style="max-width: 215px;"
        class="col form-control international-state-wrapper">
        <div style="position: relative">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('international_state'))"></div>
          <label for="international_state">
            <span class="input-label-text">State / Province</span>
            <input class="" type="text" name="international_state" id="international_state"
              formControlName="international_state">
            <span class="error" id="international_state-error" role="dialog" aria-live="polite" *ngIf="formService.hasErrors(formGroup.get('international_state'))">Provide a state or province</span>
          </label>
        </div>
      </div>
    </div>

    <input type="hidden" formControlName="international_disclosure" tabindex="-1">

    <div class="row form-control" *ngIf="showInternationalDisclosure">
      <div class="col">
        <app-alert type="danger">
          Based on the state and program you have selected, you will not be able to gain admission due to the state's unique board requirements needed for licensure. For more information, please call us at (800) 424-9596 or visit <a href="https://www.liberty.edu/statelicensure/" target="_blank" rel="noopener noreferrer nofollow">States with Unique Board Requirements.</a>
        </app-alert>
      </div>
    </div>

    <div class="row">
      <div class="col-auto form-control zip-code-wrapper" style="max-width: 130px;">
        <div style="position: relative">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('zip_code'))"></div>
          <label for="zip_code">
            <span class="input-label-text">Zip/Postal Code</span>
            <input [ngClass]="{'hidden' : formGroup.get('personal_info_country_code').value !== 'US'}" appNumbersOnly maxlength="5" type="text"
              name="zip_code" id="us_zip_code" formControlName="zip_code" aria-label="Zip Code"
              aria-describedby="zip-error"
              [attr.aria-invalid]="formService.hasErrors(formGroup.get('zip_code')) ? 'true' : false">
            <input [ngClass]="{'hidden' : formGroup.get('personal_info_country_code').value === 'US'}" type="text" maxlength="5" name="zip_code"
              id="international_zip_code" formControlName="zip_code" aria-label="Zip Code" aria-describedby="zip-error"
              [attr.aria-invalid]="formService.hasErrors(formGroup.get('zip_code')) ? 'true' : false">
          </label>
          <span class="error" id="zip-error" role="dialog" aria-live="polite"
            *ngIf="formService.hasErrors(formGroup.get('zip_code'))">Enter a valid zip/postal code</span>

        </div>
      </div>

      <app-searchable-select-drop-down
        (emitedItem)="updateCurrentPersonalInfoCountry($event)"
        #nationSelect
        style="max-width: 300px;"
        class="personal-info-country col form-control"
        [placeholder]="'Country'"
        [itemFormControl]="formGroup.get('personal_info_country')"
        [initItem]="'US'"
        [inputLabelText]="'Country'"
        [displayProperty]="'name'"
        [optionValueProperty]="'code'"
        [itemList]="allCountries"
      >
      </app-searchable-select-drop-down>

      <input type="hidden" tabindex="-1" name="personal_info_country_code" formControlName="personal_info_country_code">

    </div>

  </fieldset>
</div>

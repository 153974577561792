import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Popup } from 'src/app/shared/model/popup.model';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { PopupService } from 'src/app/shared/service/popup.service';

@Component({
  selector: 'app-little-popup',
  templateUrl: './little-popup.component.html',
  styleUrls: ['./little-popup.component.scss']
})
export class LittlePopupComponent implements OnInit, OnDestroy {
  message: string;
  showPopup = false;
  timeBeforeClose: any;
  showButton = false;

  popupSub = new Subscription();
  sectionSub = new Subscription();

  constructor(
    public popupService: PopupService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    this.popupSub = this.popupService.emitPopupContent.subscribe((popupContent: Popup) => {
      this.showButton = popupContent.button;
      if (popupContent.message !== '') {
        this.message = popupContent.message;
        this.timeBeforeClose = setTimeout(() => {this.showPopup = false; }, popupContent.timeActive);
        this.showPopup = true;
      } else {
        this.showPopup = false;
        clearTimeout(this.timeBeforeClose);
      }
    });

    this.sectionSub = this.navigationService.getEmitActiveSectionSub().subscribe(section => {
      if (section.name !== 'yourDegree') {
        this.showPopup = false;
      }
    });
  }

  closePopup() {
    this.showPopup = false;
  }

  ngOnDestroy() {
    this.popupSub.unsubscribe();
    this.sectionSub.unsubscribe();
  }

}

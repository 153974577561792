import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-hear-about-us-dropdown',
  templateUrl: './hear-about-us-dropdown.component.html',
  styles: ['.hear_about_us_dropdown {max-width: 257px;}']
})
export class HearAboutUsDropdownComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({});

  choices = ['Alumni', 'An Online Ad', 'An Online Student', 'A Lynchburg, VA Student', 'Facebook', 'Hulu', 'Instagram', 'LinkedIn', 'Military', 'Online Search', 'Other websites', 'Pandora', 'Recruiter', 'Referral', 'Spotify', 'TV', 'Twitter', ];
  showHearAboutUs = false;
  campusSub = new Subscription();

  constructor(
    public formService: FormService,
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('hear_about_us_dropdown', new FormControl<string>(''));
    this.campusSub = this.formService.updatedCampus.subscribe(() => {
      this.maybeShow();
    });
  }

  ngOnDestroy() {
    this.formGroup.removeControl('hear_about_us_dropdown');
  }

  maybeShow() {
    if (this.formService.getCurrentCampus() === 'D') {
      this.showHearAboutUs = true;
    } else {
      this.formGroup.get('hear_about_us_dropdown').setValue('');
      this.showHearAboutUs = false;
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BlockedAppResource} from '../model/blocked-app-resource';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root'
})
export class BlockedApplicationService {
  private static readonly BLOCKED_PHONE_BASE_URL = '/rest/sfs-hold/phone';
  private static readonly BLOCKED_EMAIL_BASE_URL = '/rest/sfs-hold/email';

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService
  ) { }

  checkBlockedEmail(email: string) {
    this.http.get<BlockedAppResource>(`${BlockedApplicationService.BLOCKED_EMAIL_BASE_URL}/${email}`).subscribe(blockedResource => {
      this.applicationService.isBlockedResEmail = blockedResource.isResBlocked === 'Y';
      this.applicationService.isBlockedOnlineEmail = blockedResource.isLuBlocked === 'Y';
    });
  }

  async checkBlockedPhone(phone: string) {
     return this.http.get<BlockedAppResource>(`${BlockedApplicationService.BLOCKED_PHONE_BASE_URL}/${phone}`);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Acode } from 'src/app/shared/model/applyLUApiObjects/acode.model';
import { SearchService } from 'src/app/shared/service/search.service';
import { AcodeService } from 'src/app/shared/service/acode.service';
import { RouterService } from 'src/app/shared/service/router.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { Subscription } from 'rxjs';
import { EIconType } from 'src/app/shared/component/icon/icon.component';
import {DomService} from '../../../shared/service/dom.service';

@Component({
  selector: 'app-acode-search',
  templateUrl: './acode-search.component.html',
  styleUrls: ['./acode-search.component.scss']
})
export class AcodeSearchComponent implements OnInit {

  @Input() formGroup: FormGroup<any> = new FormGroup({});
  public eIconTypes: typeof EIconType = EIconType;

  appPrefillSub = new Subscription();

  acodeSearch = '';
  filteredAcodes: Acode[] = [];
  allAcodes: Acode[] = [];
  highlightedAcode = new Acode();

  constructor(
    private searchService: SearchService,
    public acodeService: AcodeService,
    public routerService: RouterService,
    private applicationService: ApplicationService,
    private domService: DomService
  ) { }

  ngOnInit(): void {

    this.formGroup.addControl('acode_search', new FormControl<string>(''));
    this.formGroup.addControl('acodes', new FormControl<string[]>([])); // saves the value as an array

    this.refreshAllAcodes();

    this.formGroup.get('acode_search').valueChanges.subscribe((x: string) => {
      if (this.allAcodes.length === 0) {
        this.refreshAllAcodes();
      }
      this.acodeSearch = x;

      this.filteredAcodes = this.allAcodes.filter((code: Acode) => {
        const searchString = `${code.code} - ${code.descript}`;
        const lowerSearch = searchString.toLowerCase();
        if (lowerSearch.includes(x.toLowerCase())) {
          return code;
        }
      });

    });

    this.formGroup.get('acodes').valueChanges.subscribe((acodes: Acode[]) => {
      // reset and re-remove acodes, we need to do this to assist with prefill behavior in case something has already been selected
      this.refreshAllAcodes();
      for (const acode of acodes) {
        const findIndex = this.allAcodes.findIndex(thisAcode => thisAcode.code === acode.code);
        if (findIndex) {
          this.allAcodes.splice(findIndex, 1);
          this.filteredAcodes = this.allAcodes;
        }
      }
    });

    this.appPrefillSub = this.applicationService.prefillApplication.subscribe(() => {
      this.refreshAllAcodes();
    });

  } // end ngOnInit

  ngOnDestroy() {
    this.appPrefillSub.unsubscribe();
  }

  private refreshAllAcodes() {
    this.allAcodes = [...this.acodeService.returnAcodes()];
    this.checkForAndRemoveUrlParamAcodes();
  }

  public changeHighlightedAcode(direction: string) {
    // no need to do anything if the search input is empty
    if (this.acodeSearch === '' || this.acodeSearch.length === 1) {
      return false;
    }

    // if we have not yet keyed up or down to make an initial selection, select either the very first or last items depending on direction
    if (!this.highlightedAcode.code) {
      if (direction === 'UP') {
        this.highlightedAcode = this.filteredAcodes[this.filteredAcodes.length - 1];
      } else if (direction === 'DOWN') {
        this.highlightedAcode = this.filteredAcodes[0];
      }
    } else { // we already have an active selection, next to instead find the next/prev adjacent one
      if (direction === 'UP') {
        this.highlightedAcode = this.searchService.findPrev(this.filteredAcodes, this.highlightedAcode);
      } else if (direction === 'DOWN') {
        this.highlightedAcode = this.searchService.findNext(this.filteredAcodes, this.highlightedAcode);
      }
    }

    // scroll to the acode in the container if it's outtasight, "DY-NO-MIIITE"
    this.domService.selectElementAsRoot('#agt-acode-search-results li[data-code="' + this.highlightedAcode.code + '"]')
      .scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
  }

  public resetAcodeSearch() {
    this.acodeSearch = '';
    this.highlightedAcode = new Acode();
    this.formGroup.controls['acode_search'].setValue('');
  }

  public addAcode(specifiedAcode: Acode | null, event?: KeyboardEvent | MouseEvent) {
    if (event) {
      event.preventDefault(); // prevents form submission in the case of hitting enter on the search input
    }

    if (!specifiedAcode) { // then get currently selected acode
      specifiedAcode = this.highlightedAcode;
    }

    if (!specifiedAcode) { // if for some reason we still dont have a value, dont do anything
      return false;
    }

    if (this.filteredAcodes.length === 0) {
      this.filteredAcodes = this.allAcodes;
    }

    const currentCodes: Acode[] = this.formGroup.get('acodes').value;

    currentCodes.push(specifiedAcode);

    // need to remove this from all possible codes to display
    this.allAcodes = this.allAcodes.filter((filteredAcode) => {
      if (filteredAcode.code !== specifiedAcode.code) {
        return specifiedAcode;
      }
    });
    this.filteredAcodes = this.filteredAcodes.filter((filteredAcode) => {
      if (filteredAcode.code !== specifiedAcode.code) {
        return specifiedAcode;
      }
    });

    this.resetAcodeSearch();
    this.domService.selectElementAsRoot('#acode_search').blur();

    this.formGroup.controls['acodes'].setValue(currentCodes);

  }


  public removeAcode(removedAcode: Acode) {
    // put the code back into allAcodes
    this.allAcodes.unshift(removedAcode);
    this.filteredAcodes.unshift(removedAcode);

    const currentCodes: Acode[] = this.formGroup.get('acodes').value;

    const findCodeIndex = currentCodes.indexOf(removedAcode);

    if (findCodeIndex > -1) {
      currentCodes.splice(findCodeIndex, 1);
    }

    this.formGroup.controls['acodes'].setValue(currentCodes);
  }

  checkForAndRemoveUrlParamAcodes() {
    // if any Acodes are provided in URL parameters, remove it from the possible list of acodes
    // gatherUrlParams also provides any saved in custom question 244
    const allTheParams = this.gatherUrlParams();

    if (allTheParams.acode && allTheParams.acode !== '') {
      const acodeIndex = this.allAcodes.findIndex(thisAcode => thisAcode.code === allTheParams.acode);
      if (acodeIndex !== -1) {
        this.allAcodes.splice(acodeIndex, 1);
      }
    }
  }


  gatherUrlParams() {

    // gather any URL params from the current URL
    const returnedParams = this.routerService.gatherUrlParams();

    // also gather any URL params from custom question 244
    // this will overwrite any currently provided by the route.snapshot if they use the same name
    if (this.applicationService.application) {
      const answer244 = this.applicationService.getAnswer('244');
      if (answer244) {
        let answer244Params = answer244.split('?')[1];
        if (answer244Params) {
          answer244Params = answer244Params.split('#')[0]; // in case there's a hash after the params in the URL
          const splitAnswer244Params = answer244Params.split('&');
          for (const param of splitAnswer244Params) {
            const splitParam = param.split('=');
            const key = splitParam[0];
            const val = splitParam[1] ? splitParam[1] : '';
            returnedParams[key] = val.replace(' ', ''); // we save an empty space in the value to prevent weird things happening in the API
          }
        }
      }
    }
    return returnedParams;
  }

}

import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {FormControlCustom} from 'src/app/shared/form-control-custom';
import {RadioOpts} from 'src/app/shared/model/radio-options.model';
import {CookieService} from 'src/app/shared/service/cookie.service';
import {DomService} from 'src/app/shared/service/dom.service';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styles: ['fieldset {margin-bottom: 20px;}']
})
export class RadioComponent implements OnChanges {

  @Input() radioOpts: RadioOpts[];
  @Input() radioFormControl: FormControl<any> | FormControlCustom<any>;
  @Input() label: string;
  @Input() errorMessage = 'This field is required';

  @Output() radioChanged = new EventEmitter<string>();

  @ViewChild('radioContainer') radioContainer: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.radioOpts && !changes.radioOpts.firstChange) {
      this.radioOpts = changes.radioOpts.currentValue;
    }
  }

  constructor(
    public domService: DomService,
    public cookieService: CookieService
  ) {
  }

  getRadioControl(): FormControl<any> | FormControlCustom<any> {
    return this.radioFormControl;
  }

  public getFormControlParent(): FormGroup {
    if (this.radioFormControl) {
      return this.radioFormControl.parent as FormGroup;
    } else {
      return new FormGroup({});
    }
  }

  public getFormControlName(): string {
    let formGroup: { [key: string]: AbstractControl; } | AbstractControl[];
    if (this.radioFormControl) {
      formGroup = this.radioFormControl.parent.controls;
    } else {
      formGroup = [];
    }
    return Object.keys(formGroup).find(name => this.radioFormControl === formGroup[name]);
  }

  radioControlIsCustom(formControl: AbstractControl): boolean {
    return formControl instanceof FormControlCustom;
  }

  getCustomErrorMessages(): any {
    if (this.getRadioControl() instanceof FormControlCustom) {
      if (!(this.getRadioControl() as FormControlCustom<any>).errorMessages) {
        return {};
      } else {
        return (this.getRadioControl() as FormControlCustom<any>).errorMessages;
      }
    } else {
      return false;
    }
  }

  onClick(event: PointerEvent) {
    event.preventDefault();
    const labelEl = event.target as HTMLElement;
    const radioEl = labelEl.querySelector('input');
    this.setRadioVal(radioEl);
  }

  focusRadio(event: KeyboardEvent, currentIndex: number, direction: 'prev' | 'next') {
    event.preventDefault(); // prevents pressing up down and making the page scroll
    let desiredLabel: HTMLElement;
    if (direction === 'prev') {
      desiredLabel = currentIndex === 0 ? this.getRadioLabels()[this.getRadioLabels().length - 1] : this.getRadioLabels()[currentIndex - 1];
    } else if (direction === 'next') {
      desiredLabel = currentIndex === this.getRadioLabels().length - 1 ? this.getRadioLabels()[0] : this.getRadioLabels()[currentIndex + 1];
    }

    const radioEl = desiredLabel.querySelector('input');
    this.setRadioVal(radioEl);
    desiredLabel.focus();
  }

  setRadioVal(radioEl: HTMLElement) {
    const val = radioEl.getAttribute('data-val');
    this.getRadioControl().setValue(val);
    this.radioChanged.next(val);
  }

  getRadioLabels(): HTMLElement[] {
    return this.radioContainer.nativeElement.querySelectorAll('label');
  }

}

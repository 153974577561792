<ng-container [formGroup]="formGroup">

  <app-radio [ngClass]="{'hidden': formGroup.get('titleIXRadio').disabled}" [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('titleIXRadio')"
    [label]="'Have you been under investigation, left/withdrawn while under investigation, or been found responsible for violating any institution\'s Title IX-related policy (e.g., sexual harassment, sexual assault, sexual misconduct, etc.)?'"
    [errorMessage]="'Indicate if you have ever violated any Title IX-related policies'"
  >
  </app-radio>

</ng-container>

/// <reference types="@types/google.maps" />

import { Directive, ElementRef, Output, EventEmitter, NgZone, Injectable, Input } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Directive({
  selector: '[google-places]',
  exportAs: 'ngx-google-places'
})
export class NgxGooglePlacesDirective {
  @Output() onGetResults: EventEmitter<any> = new EventEmitter();

  autoCompleteOptions = {
    componentRestrictions: {
      country: ['US']
    },
    types: ['address'],
    fields: ['address_components']
  };

  private debounceTimeout: any;

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.initializeAutocompleteWithDebounce();
  }

  initializeAutocompleteWithDebounce() {
    this.ngZone.runOutsideAngular(() => {
      this.el.nativeElement.addEventListener('input', () => {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.handleAutocompleteSearch();
        }, 500); // Adjust the debounce time here in milliseconds
      });
    });
  }

  handleAutocompleteSearch() {
    const inputText = this.el.nativeElement.value;
    if (inputText.trim() !== '') {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: inputText,
          ...this.autoCompleteOptions
        },
        (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {

            const predictionResults = predictions.map(prediction => ({
              place_id: prediction.place_id,
              formatted_address: prediction.structured_formatting.main_text + ' <small>' + prediction.structured_formatting.secondary_text + '</small>',
            }));

            this.onGetResults.emit(predictionResults);

          }
        }
      );
    }
  }



}

import { Injectable } from '@angular/core';
import { UpayForm } from '../model/upay-form.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationService } from './application.service';
import { Acode } from '../model/applyLUApiObjects/acode.model';
import { Code } from '../model/application/code.model';
import { AcodeService } from './acode.service';

const DEFAULT_PAYMENT_AMOUNT = 250;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private upayFormsUrl = '/rest/upay-forms';

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
    private acodeService: AcodeService
  ) {}

  public async buildUpayForm(upayForm: UpayForm): Promise<Observable<UpayForm>> {
    let headers: HttpHeaders;

    upayForm.amount = this.getPaymentAmount();

    return this.http.post<UpayForm>(this.upayFormsUrl, upayForm, { headers });
  }

  public postToUpay(upayForm: UpayForm): void {
    this.http.post<UpayForm>(upayForm.uPayUrl, upayForm);
  }

  public getPaymentAmount(): number {
    const allAcodes = this.acodeService.returnAcodes();
    const allSelectedAcodesOnApplication: Code[] = this.applicationService.getAllAcodes();

    const result: Acode[] = [];

    allSelectedAcodesOnApplication.forEach(acodeOnApp => {

      const fullAcode = allAcodes.find((thisAcode: Acode) => thisAcode.code === acodeOnApp.code);

      if (fullAcode) {
        result.push(fullAcode);
      }

    });

    if (result.length === 0) {
      return DEFAULT_PAYMENT_AMOUNT;
    } else if (result.length === 1) {
      return parseInt(result[0].deposit_discount_amt);
    } else {
      let smallestEdAmount = parseInt(result[0].deposit_discount_amt);
      result.forEach(acode => {
        if (parseInt(acode.deposit_discount_amt) < smallestEdAmount) {
          smallestEdAmount = parseInt(acode.deposit_discount_amt);
        }
      });
      return smallestEdAmount;
    }
  }
}

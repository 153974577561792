import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../../shared/service/form.service';
import { Phone } from '../../../shared/model/application/phone.model';
import { AsYouType } from 'libphonenumber-js';
import { Subscription } from 'rxjs';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';

@Component({
  selector: 'app-cell-phone-radio',
  templateUrl: './cell-phone-radio.component.html'
})
export class CellPhoneRadioComponent implements OnInit, OnDestroy {

  constructor(
    public formService: FormService,
  ) { }

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  applicationPhone = new Phone();
  formattedPhone: string;
  addressSub = new Subscription();
  phoneSub = new Subscription();
  currentNationCode: string;

  hideCellPhone = false;

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  ngOnInit(): void {


    this.formGroup.get('phoneNumberInput').valueChanges.subscribe(phoneNumber => {

      this.formattedPhone = phoneNumber;
      this.hideCellPhone = (this.formGroup.get('isPossible').value as boolean || (this.isInternationalAndValid()) as boolean);
    });

  }

  ngOnDestroy() {
    this.addressSub.unsubscribe();
    this.phoneSub.unsubscribe();
  }

   isInternationalAndValid() {
    const input = this.formGroup.get('phoneNumberInput').value as string;
    return this.formGroup.get('isInternational').value && (input !== '+' && input.length <= 25);
  }

  setCellPhone(providedPhone: Phone) {
    const appPhone = new Phone(); // creating a new one here because a 'Phone' provided via prefill isnt always an instance of Phone
    Object.assign(appPhone, providedPhone);

    if (appPhone.isValid()) {
      if (this.currentNationCode === 'US') {
        const formatter = new AsYouType('US');
        this.formattedPhone = formatter.input(appPhone.area + appPhone.phoneNumber);
      } else {
        const formatter = new AsYouType();
        this.formattedPhone = formatter.input(appPhone.area + appPhone.phoneNumber);

      }
    }
  }

}

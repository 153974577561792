<ng-container [formGroup]="formGroup">
<div class="form-control" [ngClass]="showMobileVersionOnDesktop ? 'show-mobile-version' : ''" #levelRadioContainer>
  <fieldset class="styled-radio-container level-radio form-control" role="radiogroup">
    <legend class="mb-3">
      <span [ngClass]="'sub-header'" id="level-header">What degree level are you wanting to pursue?</span>
    </legend>

    <div class="row styled-radio-row">

      <div class="degree-level"
      [ngClass]="{'mobile-hide': (formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Bachelor'),
      'degree-level-selected':  (formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value === 'Bachelor')}">
        <label
          class="styled-radio-label styled-radio-stacked text-center has-icon"
          for="level_radio_bachelor"
          tabindex="0"
          role="radio"
          (keydown.arrowdown)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowright)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          [attr.aria-checked]="formGroup.get('degree_level').value === 'Bachelor' ? 'true' : 'false'"
          (keyup.enter)="formGroup.get('degree_level').setValue('Bachelor'); emitNewLevel('Bachelor')"
          (keyup.space)="formGroup.get('degree_level').setValue('Bachelor'); emitNewLevel('Bachelor')"
          [ngClass]="{'active': formGroup.get('degree_level').value === 'Bachelor',
          'inactive': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Bachelor'}"
        >
          <input type="radio"
          tabindex="-1"
          id="level_radio_bachelor"
          name="degree_level"
          formControlName="degree_level"
          value="Bachelor"
          (change)="emitNewLevel($event.target.value)">

          <div class="radio-icon-container" aria-hidden="true">
            <app-icon class="radio-icon" [icon]="'bachelor'"></app-icon>
          </div>

          <app-icon *ngIf="formGroup.get('degree_level').value === 'Bachelor'" tabindex="-1" class="animated-checkmark checkmark-icon mobile-hide" [icon]="'check'" aria-label="Checkmark icon" aria-hidden="true"></app-icon>

          <div *ngIf="currentLevel === 'Bachelor' || this.formGroup.get('degree_level').value === 'Bachelor'" (click)="emitNewLevel('')" class="edit mobile-show" tabindex="0">
            <app-icon class="icon" [icon]="'edit'" aria-label="Edit icon"></app-icon>
            <span>Edit</span>
          </div>

          <div>
            <h3>Bachelor</h3>
            <h4>Undergraduate</h4>
          </div>

        </label>
      </div>
      <div class="degree-level"
      [ngClass]="{'mobile-hide': (formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Master'),
      'degree-level-selected':  (formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value === 'Master')}">
        <label
          class="styled-radio-label styled-radio-stacked text-center has-icon"
          for="level_radio_master"
          tabindex="-1"
          role="radio"
          (keydown.arrowup)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowleft)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowdown)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowright)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          [attr.aria-checked]="formGroup.get('degree_level').value === 'Master' ? 'true' : 'false'"
          (keyup.enter)="formGroup.get('degree_level').setValue('Master'); emitNewLevel('Master')"
          (keyup.space)="formGroup.get('degree_level').setValue('Master'); emitNewLevel('Master')"
          [ngClass]="{'active': formGroup.get('degree_level').value === 'Master',
          'inactive': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Master'}"
        >
          <input type="radio"
          tabindex="-1"
          id="level_radio_master"
          name="degree_level"
          formControlName="degree_level"
          value="Master"
          (change)="emitNewLevel($event.target.value)">

          <div class="radio-icon-container" aria-hidden="true">

            <app-icon class="radio-icon" [icon]="'master'"></app-icon>

          </div>

          <app-icon *ngIf="formGroup.get('degree_level').value === 'Master'" aria-hidden="true" tabindex="-1" class="animated-checkmark checkmark-icon mobile-hide" [icon]="'check'" aria-label="Checkmark icon"></app-icon>

          <div *ngIf="currentLevel === 'Master' || this.formGroup.get('degree_level').value === 'Master'"  (click)="emitNewLevel('')" class="edit mobile-show" tabindex="0">
            <app-icon class="icon" [icon]="'edit'" aria-label="Edit icon"></app-icon>
            <span>Edit</span>
          </div>

          <div>
            <h3>Master</h3>
            <h4>Graduate</h4>
          </div>

        </label>
      </div>

      <div class="degree-level"
      [ngClass]="{'mobile-hide': (formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Doctorate'),
      'degree-level-selected':  (formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value === 'Doctorate')}">
        <label
          class="styled-radio-label styled-radio-stacked text-center has-icon"
          for="level_radio_doctorate"
          tabindex="-1"
          role="radio"
          (keydown.arrowup)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowleft)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowdown)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowright)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          [attr.aria-checked]="formGroup.get('degree_level').value === 'Doctorate' ? 'true' : 'false'"
          (keyup.enter)="formGroup.get('degree_level').setValue('Doctorate'); emitNewLevel('Doctorate')"
          (keyup.space)="formGroup.get('degree_level').setValue('Doctorate'); emitNewLevel('Doctorate')"
          [ngClass]="{'active': formGroup.get('degree_level').value === 'Doctorate',
          'inactive': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Doctorate'}"
        >
          <input type="radio"
          tabindex="-1"
          id="level_radio_doctorate"
          name="degree_level"
          formControlName="degree_level"
          value="Doctorate"
          (change)="emitNewLevel($event.target.value)">

          <div class="radio-icon-container" aria-hidden="true">
            <app-icon class="radio-icon" [icon]="'doctorate'"></app-icon>
          </div>

          <app-icon *ngIf="formGroup.get('degree_level').value === 'Doctorate'" aria-hidden="true" tabindex="-1" class="animated-checkmark checkmark-icon mobile-hide" [icon]="'check'" aria-label="Checkmark icon"></app-icon>

          <div *ngIf="currentLevel === 'Doctorate' || this.formGroup.get('degree_level').value === 'Doctorate'"  (click)="emitNewLevel('')" class="edit mobile-show" tabindex="0">
            <app-icon class="icon" [icon]="'edit'" aria-label="Edit icon"></app-icon>
            <span>Edit</span>
          </div>

          <div>
            <h3>Doctorate</h3>
            <h4>Postgraduate</h4>
          </div>

        </label>
      </div>

    </div>

    <div class="row styled-radio-row">

      <div class="col-sm-6" [ngClass]="{'mobile-hide': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Associate'}">
        <label
          class="styled-radio-label"
          for="level_radio_associate"
          tabindex="-1"
          role="radio"
          (keydown.arrowup)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowleft)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowdown)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowright)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
          [attr.aria-checked]="formGroup.get('degree_level').value === 'Associate' ? 'true' : 'false'"
          (keyup.enter)="formGroup.get('degree_level').setValue('Associate'); emitNewLevel('Associate')"
          (keyup.space)="formGroup.get('degree_level').setValue('Associate'); emitNewLevel('Associate')"
          [ngClass]="{'active': formGroup.get('degree_level').value === 'Associate',
          'inactive': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Associate'}"
        >
          <input type="radio"
          tabindex="-1"
          id="level_radio_associate"
          name="degree_level"
          formControlName="degree_level"
          value="Associate"
          (change)="emitNewLevel($event.target.value)">

          <app-icon *ngIf="formGroup.get('degree_level').value === 'Associate'" tabindex="-1" aria-hidden="true" class="animated-checkmark checkmark-icon mobile-hide" [icon]="'check'" aria-label="Checkmark icon"></app-icon>

          <div *ngIf="currentLevel === 'Associate' || this.formGroup.get('degree_level').value === 'Associate'"  (click)="emitNewLevel('')" class="edit mobile-show" tabindex="0">
            <app-icon class="icon" [icon]="'edit'" aria-label="Edit icon"></app-icon>
            <span>Edit</span>
          </div>

          <div>
            <h3>Associate</h3>
            <h4>Undergraduate</h4>
          </div>

        </label>
      </div>

      <div class="col-sm-6" [ngClass]="{'mobile-hide': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Certificate'}">
        <label
          class="styled-radio-label"
          for="level_radio_certificate"
          tabindex="-1"
          role="radio"
          (keydown.arrowup)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          (keydown.arrowleft)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
          [attr.aria-checked]="formGroup.get('degree_level').value === 'Certificate' ? 'true' : 'false'"
          (keyup.enter)="formGroup.get('degree_level').setValue('Certificate'); emitNewLevel('Certificate')"
          (keyup.space)="formGroup.get('degree_level').setValue('Certificate'); emitNewLevel('Certificate')"
          [ngClass]="{'active': formGroup.get('degree_level').value === 'Certificate',
          'inactive': formGroup.get('degree_level').value !== '' && formGroup.get('degree_level').value !== 'Certificate'}"
        >
          <input type="radio"
          tabindex="-1"
          id="level_radio_certificate"
          name="degree_level"
          formControlName="degree_level"
          value="Certificate"
          (change)="emitNewLevel($event.target.value)">

          <app-icon *ngIf="formGroup.get('degree_level').value === 'Certificate'" tabindex="-1" aria-hidden="true" class="animated-checkmark checkmark-icon mobile-hide" [icon]="'check'" aria-label="Checkmark icon"></app-icon>

          <div *ngIf="currentLevel === 'Certificate' || this.formGroup.get('degree_level').value === 'Certificate'"  (click)="emitNewLevel('')" class="edit mobile-show" tabindex="0">
            <app-icon class="icon" [icon]="'edit'" aria-label="Edit icon"></app-icon>
            <span>Edit</span>
          </div>

          <div>
            <h3>Certificate</h3>
            <h4>Undergraduate & Graduate</h4>
          </div>

        </label>
      </div>

    </div>
  </fieldset>
</div>
</ng-container>

<ng-container *ngIf="formGroup.get('maritalStatus').enabled" [formGroup]="formGroup">
  <div id="maritalStatus-container" class="col-sm-3 form-control">
    <div class="validation-error-bar"
        *ngIf="formService.hasErrors(formGroup.get('maritalStatus'))">
      </div>
    <label for="maritalStatus">
      <span class="input-label-text">Marital Status</span>
    </label>
    <div class="select-input-wrap">
      <select
        name="maritalStatus"
        id="maritalStatus"
        formControlName="maritalStatus"
        [attr.aria-invalid]="formService.hasErrors(formGroup.get('maritalStatus')) ? 'true' : null"
        [attr.aria-describedby]="formService.hasErrors(formGroup.get('maritalStatus')) ? 'marital-status-error' : null"
      >
        <option value="" disabled="true" selected="true">Select</option>
        <option *ngFor="let status of maritalStatuses" value="{{status.value}}">{{status.name}}</option>
      </select>

      <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>

    </div>
    <span class="error" id="marital-status-error" role="dialog" aria-live="polite" *ngIf="formService.hasErrors(formGroup.get('maritalStatus'))">Enter your marital status</span>
  </div>
</ng-container>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomAnswer } from 'src/app/shared/model/application/custom-answer.model';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { emailPattern } from 'src/app/shared/service/form.service';
import { HttpService } from 'src/app/shared/service/http.service';

@Component({
  selector: 'app-referral-form',
  templateUrl: './referral-form.component.html',
  styles: ['.spin-container {right: -5px; top: 0;}']
})
export class ReferralFormComponent implements OnInit {

  referralForm: FormGroup;

  referralsSent = false;
  sending = false;
  disableSubmit = true;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private appIdService: AppIdService,
    private applicationService: ApplicationService,
    private httpService: HttpService
  ) {
    this.createReferralForm();
  }

  ngOnInit() {

    this.referralForm.valueChanges.subscribe(theForm => {
      this.disableSubmit = this.referralForm.invalid;

      let totalVal = ''; // is there an easier way to determine if theForm is totally empty? not sure yet

      Object.values(theForm).forEach(thisGroup => {
        Object.values(thisGroup).forEach(val => {
          totalVal += val;
        });
      });

      if (totalVal.length === 0) { // also disable if totally empty
        this.disableSubmit = true;
      }

      this.cdr.detectChanges();

    });

  }

  anyValuesInGroup(formGroup: FormGroup) {
    return Object.values(formGroup.value.value).join('').trim().length > 0;
  }

  createReferralForm() {
    this.referralForm = this.formBuilder.group([
      this.referralFieldsGroup(), this.referralFieldsGroup(), this.referralFieldsGroup()
    ]);
  }

  referralFieldsGroup(): FormGroup {
    return this.formBuilder.group({
      rf_fname: '',
      rf_lname: '',
      rf_email: ['', [Validators.pattern(emailPattern)]]
    });
  }

  referralSubmit() {

    this.sending = true;

    const aidm = this.applicationService.webId.substring(1);

    const referArray = [];

    Object.values(this.referralForm.value).forEach((thisGroup) => {
      if (Object.values(thisGroup).join('').trim().length > 0) {

        const appMatchObj = new AppMatchObject();
        appMatchObj.email = this.applicationService.application.emails[0].emailAddress;
        appMatchObj.phone = this.applicationService.getAppPhone().area + this.applicationService.getAppPhone().phoneNumber;
        appMatchObj.birthdate = this.applicationService.application.birthDate;

        const referralObj = new ReferralObject();
        referralObj.app_id = this.appIdService.getAppId();
        referralObj.vi_id = '';
        referralObj.lu_aidm = aidm;
        referralObj.app_match = appMatchObj;
        referralObj.app_posted = 1;
        referralObj.rf_fname = thisGroup['rf_fname'];
        referralObj.rf_lname = thisGroup['rf_lname'];
        referralObj.rf_email = thisGroup['rf_email'];

        referArray.push(referralObj);
      }
    });

    const theAnswer = new CustomAnswer();
    theAnswer.questionNumber = '301';
    theAnswer.answer = JSON.stringify(referArray);

    this.httpService.putRequest(`/rest/applications/${this.appIdService.getAppId()}/answer`, theAnswer).subscribe(() => {
      this.referralsSent = true;
      this.sending = false;
    });

  }

}

class ReferralObject {
  // tslint:disable:variable-name
  app_id: string;
  vi_id: string;
  lu_aidm: string;
  app_match: AppMatchObject;
  app_posted: number;
  rf_fname: string;
  rf_lname: string;
  rf_email: string;
  // tslint:enable:variable-name
}

class AppMatchObject {
  email: string;
  phone: string;
  birthdate: string;
}

<div class="sidebar--footerContainer">

  <p *ngIf="environment.isAgent" class="app-id text-white">App ID - {{ appIdService.getAppId() || 'Loading'}}</p>

  <span class="sidebar--progress-completed">{{progressNumber}}% of application completed</span>
  <div class="sidebar--progress">
    <div class="progress--bar" [style.width]="progressNumber+'%'"></div>
  </div>

  <div class="sidebar--footer">
    <p>
      Copyright &copy; Liberty University · <a tabindex="1" href="https://www.liberty.edu/offices/privacy-policy/" target="_blank">Privacy Policy</a>
    </p>

  </div>
</div>

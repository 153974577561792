<ng-container [formGroup]="formGroup">
  <div class="form-control textarea-container">

    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get(nameForControl))">
    </div>
    <label for="{{nameForControl}}" *ngIf="textboxHeader">
      <span class="input-label-text">{{textboxHeader}}</span>
    </label>
    <span class="input-example-text smGrayItalic">{{textboxExample}}</span>

    <textarea
      placeholder="{{prompt}}"
      name="{{nameForControl}}"
      id="{{nameForControl}}"
      rows="6"
      formControlName="{{nameForControl}}"
      (input)="updateLength($event.target.value)"
      (blur)="updateTextbox($event.target.value)"
      maxlength="{{answerlength}}"
      tabindex="0"
      [attr.aria-invalid]="formService.hasErrors(formGroup.get(nameForControl)) ? 'true' : null"
      [attr.aria-describedby]="nameForControl+'-error'"
    ></textarea>

    <small *ngIf="!minWords" aria-live="polite" class="textbox-length">{{ textboxLength }} characters used. (Max {{answerlength}}) </small>
    <small *ngIf="minWords" aria-live="polite" class="textbox-length">{{ wordLength }} words used. (Min {{minWords}}) </small>
    <small class="greenText" aria-live="polite" *ngIf="textboxLength > answerlength - 100 && textboxLength < answerlength - 20">({{ textboxLength }} / {{answerlength}})</small>
    <small class="orangeText" aria-live="polite" *ngIf="textboxLength >= answerlength-20">({{ textboxLength }} / {{answerlength}})</small>

    <br>
    <span class="error" id="{{nameForControl}}-error" role="alert" *ngIf="formService.hasRequiredError(formGroup.get(nameForControl)) && !minWords">{{textboxError}}</span>
  </div>
</ng-container>

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { FormService } from 'src/app/shared/service/form.service';
import { ProgramService } from 'src/app/shared/service/program.service';

@Component({
  selector: 'app-alcohol-radio',
  templateUrl: './alcohol-radio.component.html'
})
export class AlcoholRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

  textboxHeader = 'Please provide the following details: Which specific substance(s) (alcohol, tobacco, or name of the specific drug)? How often is it used and in what context (i.e. I have a drink with my dad when I go home every few months or I smoke marijuana with my friends every other weekend)? Provide a statement that you will comply with the Liberty Way. (2,000 characters max)';

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor(
    public formService: FormService,
    public programService: ProgramService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('alcoholRadio', new FormControlCustom<string>({}, {value: '', disabled: true} ) );
    (this.formGroup.get('alcoholRadio') as FormControlCustom<any>).errorMessages.required = 'Indicate if you have used alcohol, tobacco, or non-medical drugs';

    this.formGroup.get('alcoholRadio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      if (
        program.level === 'Undergraduate' && program.campCode === 'R'
      ) {
        this.formGroup.get('alcoholRadio').enable();
        this.formGroup.get('alcoholRadio').setValidators([Validators.required]);
      } else {
        this.formGroup.get('alcoholRadio').setValue('');
        this.formGroup.get('alcoholRadio').clearValidators();
        this.formGroup.get('alcoholRadio').disable();
      }
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

}

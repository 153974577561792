import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'src/app/shared/service/cookie.service';
import { DomService } from 'src/app/shared/service/dom.service';
import { ECampusCode, FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-location-radio',
  templateUrl: './location-radio.component.html',
  styleUrls: ['./location-radio.component.scss']
})
export class LocationRadioComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  constructor(
    public domService: DomService,
    private formService: FormService,
    public route: ActivatedRoute,
    public cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('campus', new FormControl<string>(''));

    this.route.queryParams.subscribe(params => {
      if (params.c) {
        if (params.c === '1') {
          this.formGroup.get('campus').setValue(ECampusCode.D);
          this.formService.updatedCampus.next(ECampusCode.D);
        } else if (params.c === '2') {
          this.formGroup.get('campus').setValue(ECampusCode.R);
          this.formService.updatedCampus.next(ECampusCode.R);
        }
      }
    });
  }

  ngOnDestroy() {
    this.formGroup.removeControl('campus');
  }

  emitNewCampus(campus: string) {

      const scrollAnchor = this.domService.selectElementAsRoot('#degree-picker-anchor');
      if (scrollAnchor) {
        this.domService.scrollIntoView(scrollAnchor);
      }

      setTimeout(() => {
      const newCampus = ECampusCode[campus];
      this.formService.updatedCampus.next(newCampus);
    }, 500);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DismissalRadioComponent } from './dismissal-radio.component';
import { ExpelledRadioModule } from '../expelled-radio/expelled-radio.module';
import { TextboxModule } from '../generic/textbox/textbox.module';
import { RadioModule } from '../generic/radio/radio.module';
import { ReactiveFormsModule } from '@angular/forms';
import {SchoolSearchInputModule} from "../generic/school-search-input/school-search-input.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  declarations: [
    DismissalRadioComponent
  ],
  imports: [
    CommonModule,
    ExpelledRadioModule,
    TextboxModule,
    ReactiveFormsModule,
    RadioModule,
    SchoolSearchInputModule,
    SharedModule,
  ],
  exports: [
    DismissalRadioComponent
  ]
})
export class DismissalRadioModule { }

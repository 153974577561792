import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Term } from 'src/app/shared/model/term.model';

@Component({
  selector: 'app-guardian-relationship',
  templateUrl: './guardian-relationship.component.html',
  styles: [
    '.relationship-wrapper { max-width: 320px; }',
  ]
})
export class GuardianRelationshipComponent {

  @Input() formGroup: FormGroup = new FormGroup({});
  relationships: Array<Term> = [
    {name: 'Father', value: 'F'},
    {name: 'Friend', value: 'R'},
    {name: 'Grandparent', value: 'G'},
    {name: 'Guardian', value: 'U'},
    {name: 'Mother', value: 'M'},
    {name: 'Relative', value: 'O'}
  ];

  constructor(
  ) { }

}

import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {FormControlCustom} from '../form-control-custom';
import {FormError} from '../model/form-error.model';
import {Nation} from '../model/nation.model';
import {EProgLevel} from '../model/prog-level.model';
import {Application} from '../model/application.model';
import {AppFormBuilder} from '../model/app-form-builder.model';
import {College} from '../model/application/college.model';
import {HighSchool} from '../model/application/highschool.model';
import {Address} from '../model/application/address.model';
import {
  SchoolType
} from '../../form/input/generic/school-search/school-search-fieldset/school-search-fieldset.component';
import {State} from '../model/state.model';
import {environment} from '../../../environments/environment';

export const maxiumumBirthYear = (new Date()).getFullYear();
export const minimumBirthYear = maxiumumBirthYear - 100;

export enum ECampusCode {
  D = 'D',
  R = 'R'
}

export enum ECampus {
  Online = 'Online',
  Residential = 'Residential'
}

export const emailPattern = `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$`;
export const ssnPattern = '^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$';
export const intlDisclaimerProgramCodes = ['MAT-D', 'BED-D', 'BED-R'];

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public formGroup: FormGroup = new FormGroup({});
  public formProgress = 0;
  public emitProgress = new BehaviorSubject(this.formProgress);
  public submitted = false; // this will be changed when submit function runs

  public usCitizenSubject = new BehaviorSubject(true);
  public greenCardHolderSubject = new BehaviorSubject(true);
  public birthCountryUpdated = new Subject<Nation>();
  public UpdatedAddress = new BehaviorSubject<Address>(new Address());
  public currentNationCode = '';
  public updatedState = new Subject<State>();

  public currentCampus = '';
  public currentLevel = '';
  public isUsCitizen = true;
  public greenCardHolder = true;
  public updatedCampus = new BehaviorSubject<string>('');
  public updatedLevel = new BehaviorSubject<string>('');
  public prefillForm = new Subject<{ formData: {}, activeSectionName: string }>();
  public residesInUs = new BehaviorSubject<boolean>(true);
  public navigateToControl = new Subject<{ message: string, controlName: string, sectionName: string }>();
  public isActiveMilitary = false;

  // for student login functionality
  public mainFormData = {};
  private shouldDisplayIdUpload = new Subject<boolean>();
  private studentDataImported = new Subject<boolean>();

  constructor() {

    this.updatedCampus.subscribe((campusCode: string) => {
      this.currentCampus = ECampusCode[campusCode];
    });

    this.updatedLevel.subscribe((newLevel: string) => {
      this.currentLevel = EProgLevel[newLevel];
    });

    this.usCitizenSubject.subscribe((status: boolean) => {
      this.isUsCitizen = status;
    });

    this.greenCardHolderSubject.subscribe((status: boolean) => {
      this.greenCardHolder = status;
    });

  }

  getShouldDisplayIdUploadSub(): Observable<boolean> {
    return this.shouldDisplayIdUpload.asObservable();
  }
  setShouldDisplayIdUploadSub(bool: boolean) {
    this.shouldDisplayIdUpload.next(bool);
  }
  getStudentDataImportedSub(): Observable<boolean> {
    return this.studentDataImported.asObservable();
  }
  setStudentDataImportedSub(bool: boolean) {
    this.studentDataImported.next(bool);
  }

  getUpdatedCampusSub(): Observable<string> {
    return this.updatedCampus.asObservable();
  }

  getUpdatedLevelSub(): Observable<string> {
    return this.updatedLevel.asObservable();
  }

  getCurrentLevel(): string {
    return this.currentLevel;
  }

  getCurrentCampus(): string {
    return this.currentCampus;
  }

  hasErrors(formcontrol: AbstractControl) {
    formcontrol.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
    return !!(formcontrol.touched && formcontrol.errors);
  }

  hasRequiredError(formcontrol: AbstractControl) {
    formcontrol.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
    return !!(formcontrol.touched && formcontrol.errors && formcontrol.errors.required);
  }

  hasPatternError(formcontrol: AbstractControl) {
    formcontrol.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
    return !!(formcontrol.touched && formcontrol.errors && formcontrol.errors.pattern);
  }

  isRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator && abstractControl.enabled) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl.get('controls')) {
      for (const controlName in abstractControl.get('controls')) {
        if (abstractControl.get('controls')[controlName]) {
          if (this.isRequiredField(abstractControl.get('controls')[controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public setApplicationAddress(address: Address) {
    this.currentNationCode = address.nationCode;
    this.UpdatedAddress.next(address);
  }

  calculateProgress(allControls: {}, makeOneHundred = false): number {
    let totalRequired = 0;
    let requiredIsValid = 0;

    for (const controlName in allControls) {

      if (Object.prototype.hasOwnProperty.call(allControls, controlName)) {

        const thisControl: AbstractControl = allControls[controlName];
        if (this.isRequiredField(thisControl) && thisControl.enabled) {
          totalRequired++;
          if (thisControl.valid) {
            requiredIsValid++;
          }
        }
      }

    }

    this.formProgress = Math.ceil(requiredIsValid / totalRequired * 100);

    /*Commented Code will wait to make form 100% until after submit*/
    // if (this.formProgress === 100 && !makeOneHundred) {
    //   this.formProgress = 99;
    // }

    if (makeOneHundred || environment.isThankYouPage) {
      this.formProgress = 100;
    }
    this.emitProgress.next(this.formProgress);
    return this.formProgress;

  }

  cloneAbstractControl<T extends AbstractControl>(control: T): T {
    let newControl: T;
    if (control instanceof FormGroup) {
      const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;
      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
      });
      newControl = formGroup as any;
    } else if (control instanceof FormArray) {
      const formArray = new FormArray([], control.validator, control.asyncValidator);
      control.controls.forEach(formControl => formArray.push(this.cloneAbstractControl(formControl)));
      newControl = formArray as any;
    } else if (control instanceof FormControl) {
      newControl = new FormControl<string>(control.value, control.validator, control.asyncValidator) as any;
    } else {
      throw new Error('Error: unexpected control value');
    }
    if (control.disabled) {
      newControl.disable({emitEvent: false});
    }
    return newControl;
  }


  generateGradYears() {
    const currentYear = (new Date()).getFullYear();
    const range = (start: number, stop: number, step: number) => Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step));
    return range(currentYear + 5, currentYear - 80, -1);
  }

  gatherFormErrors(passedControls: FormGroup): FormError[] {
    const gatheredErrors: FormError[] = [];

    const sectionNames = ['agentQuestions', 'yourDegree', 'previousEducation', 'personalInformation', 'agreeSubmit', 'optional', 'enrollmentDeposit'];
    let sectionName = '';

    const recursiveLoopOverControls = (group: FormGroup) => {
      Object.keys(group.controls).forEach((controlName: string) => {
        const abstractControl = group.get(controlName);


        const onSection = sectionNames.find(thisSection => thisSection === controlName);
        if (onSection) { // we're on a new section
          sectionName = onSection;
        }
        if (abstractControl instanceof FormArray) {

          for (const thisControl of abstractControl.controls) {

            // as of now, the only time ApplyLU uses formArrays are for the school search and they only contain formGroups, no direct formControls. If we ever implement a different formArray elsewhere that directly contains regular formControls we'll want to refactor the code below for formControls into a function that can also be used here
            if (thisControl instanceof FormGroup) {
              recursiveLoopOverControls(thisControl);
            }

          }

        } else if (abstractControl instanceof FormGroup) {
          // recursively calls itself to get to the bottom level FormControl nested inside as many FormGroup as needed
          recursiveLoopOverControls(abstractControl);
        } else {
          const thisControl = abstractControl as FormControlCustom<any>;

          if (thisControl.errors) {
            // loop over any errors on the formControl to provide appropriate messages
            Object.keys(thisControl.errors).forEach((errorName: string) => {

              const thisError = new FormError();
              thisError.controlName = controlName;
              thisError.sectionName = sectionName;
              if (errorName === 'custom') {
                if (thisControl.errorMessages && thisControl.errorMessages.custom) {
                  thisError.message = thisControl.errorMessages.custom;
                }
              }

              if (errorName === 'required') {

                if (thisControl.errorMessages && thisControl.errorMessages.required) {
                  thisError.message = thisControl.errorMessages.required;
                } else if (controlName === 'school_search') { // hard code a fix because I cant figure out where its coming from
                  thisError.message = 'A school selection is required';
                } else if (controlName !== 'fields_active') {
                  thisError.message = thisError.controlName + ' is required';
                  console.warn('Required form control ' + thisError.controlName + ' has no error message set');
                }

              }

              if (errorName === 'pattern') {

                if (thisControl.errorMessages && thisControl.errorMessages.pattern) {
                  thisError.message = thisControl.errorMessages.pattern;
                } else {
                  thisError.message = 'Enter a valid value for ' + thisError.controlName;
                  console.warn('Pattern validation for form control ' + thisError.controlName + ' has no error message set');
                }

              }

              if (errorName === 'min') {

                if (thisControl.errorMessages && thisControl.errorMessages.min) {
                  thisError.message = thisControl.errorMessages.min;
                } else {
                  thisError.message = 'Enter a valid value for ' + thisError.controlName;
                  console.warn('Min validation for form control ' + thisError.controlName + ' has no error message set');
                }

              }

              if (errorName === 'max') {

                if (thisControl.errorMessages && thisControl.errorMessages.max) {
                  thisError.message = thisControl.errorMessages.max;
                } else {
                  thisError.message = 'Enter a valid value for ' + thisError.controlName;
                  console.warn('Max validation for form control ' + thisError.controlName + ' has no error message set');
                }

              }
              gatheredErrors.push(thisError);
            });

          }
        }
      });
    };

    recursiveLoopOverControls(passedControls);

    return gatheredErrors;
  }

  gatherAllControls(group: FormGroup, returnedControls: {}) {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        // recursively calls itself to get to the bottom level FormControl nested inside as many FormGroup as needed
        this.gatherAllControls(abstractControl, returnedControls);
      } else {
        returnedControls[key] = abstractControl;
      }
    });
    return returnedControls;
  }

  prefillFromApplication(application: Application) {
    const prefillApp = new Application();
    prefillApp.highSchools = [];
    prefillApp.priorColleges = [];
    prefillApp.addresses = [];
    prefillApp.phones = [];
    prefillApp.citizenship = '';
    Object.assign(prefillApp, application);
    return prefillApp;
  }

  replaceSchoolsInCurrentForm(prevEduSection: FormGroup, prefillFormData: {}) {
    const highschoolArray = prevEduSection.get('highschoolSearch').get('selected_schools') as FormArray;
    highschoolArray.clear();

    const prefillHsData = prefillFormData['previousEducation']['highschoolSearch'] ? prefillFormData['previousEducation']['highschoolSearch']['selected_schools'] : [];

    prefillHsData.forEach((highschoolData) => {
      const newHsFields = AppFormBuilder.generateSchoolFields(undefined, SchoolType.Highschool);

      newHsFields.removeControl('degree_level_received');

      // also transform data of selected_school to a HighSchool
      highschoolData.selected_school = new HighSchool(highschoolData.selected_school);

      newHsFields.patchValue(highschoolData);

      highschoolArray.push(newHsFields);
    });

    const collegeGroups = ['bachelorsSearch', 'mastersSearch', 'doctoralSearch', 'multiCollegeSearch'];

    collegeGroups.forEach(collegeGroupName => {
      const collegeArray = prevEduSection.get(collegeGroupName).get('selected_schools') as FormArray;
      collegeArray.clear();

      const collegePrefillData = prefillFormData['previousEducation'][collegeGroupName] ? prefillFormData['previousEducation'][collegeGroupName]['selected_schools'] : [];

      collegePrefillData.forEach((collegeData) => {
        const defaultLevel = collegeData['degree_level_received'] ? collegeData['degree_level_received'] : '';
        const generateDidGraduate = collegeData['did_graduate_radio'];
        const newCollegeFields = AppFormBuilder.generateSchoolFields(defaultLevel, generateDidGraduate);

        // also transform data of selected_school to a College
        collegeData.selected_school = new College(collegeData.selected_school);

        newCollegeFields.patchValue(collegeData);
        collegeArray.push(newCollegeFields);
      });
    });


  }
}

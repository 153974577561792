<ng-container [formGroup]="formGroup">
<div class="form-control mt-5">
  <fieldset class="styled-radio-container" id="campus-anchor" role="radiogroup" tabindex="-1">

    <ng-container >
      <legend class="mb-4">
        <span class="large-header" id="location-header">Your Degree</span>
      </legend>
    </ng-container>
      <legend class="mb-3">
        <span [ngClass]="'sub-header'" id="location-question">Where would you like to study?</span>
      </legend>

    <div class="row styled-radio-row" >

      <div class="col-sm-6">

        <label class="styled-radio-label has-icon"
        for="location_radio_online"
        tabindex="0"
        role="radio"
        [attr.aria-checked]="formGroup.get('campus').value === 'D' ? 'true' : 'false'"
        (keyup.enter)="formGroup.get('campus').setValue('D'); emitNewCampus('D')"
        (keyup.space)="formGroup.get('campus').setValue('D'); emitNewCampus('D')"
        (keydown.arrowdown)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement)"
        (keydown.arrowright)="domService.arrowIndex($event, 'next', $event.target.parentElement.parentElement)"
        [ngClass]="{'active': formGroup.get('campus').value === 'D',
        'inactive': formGroup.get('campus').value !== '' && formGroup.get('campus').value !== 'D'}">

          <input type="radio"
          tabindex="0"
          id="location_radio_online"
          name="campus"
          formControlName="campus"
          value="D"
          (change)="emitNewCampus($event.target.value)">

          <div class="radio-icon-container" aria-hidden="true">
            <app-icon class="radio-icon" [icon]="'desk'"></app-icon>
          </div>

          <app-icon *ngIf="formGroup.get('campus').value === 'D'" class="animated-checkmark checkmark-icon v-center" aria-hidden="true" [icon]="'check'"></app-icon>

          <h3>Online</h3>
        </label>
      </div>

      <div class="col-sm-6">

        <label class="styled-radio-label has-icon"
        id="location_radio_residential_label"
        for="location_radio_residential"
        tabindex="-1"
        role="radio"
        [attr.aria-checked]="formGroup.get('campus').value === 'R' ? 'true' : 'false'"
        (keyup.enter)="formGroup.get('campus').setValue('R'); emitNewCampus('R')"
        (keyup.space)="formGroup.get('campus').setValue('R'); emitNewCampus('R')"
        (keydown.arrowup)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement)"
        (keydown.arrowleft)="domService.arrowIndex($event, 'previous', $event.target.parentElement.parentElement)"
        [ngClass]="{'active': formGroup.get('campus').value === 'R',
        'inactive': formGroup.get('campus').value !== '' && formGroup.get('campus').value !== 'R' }">

          <input type="radio"
          tabindex="-1"
          id="location_radio_residential"
          name="campus"
          formControlName="campus"
          value="R"
          (change)="emitNewCampus($event.target.value)">

          <div class="radio-icon-container" aria-hidden="true">
            <app-icon class="radio-icon" [icon]="'on-campus'"></app-icon>
          </div>

          <app-icon *ngIf="formGroup.get('campus').value === 'R'" class="animated-checkmark checkmark-icon v-center" aria-hidden="true" [icon]="'check'"></app-icon>
          <h3>On Campus</h3>
        </label>
      </div>

    </div>
  </fieldset>
</div>
</ng-container>

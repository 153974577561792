import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-guardian-name-fields',
  templateUrl: './guardian-name-fields.component.html',
})
export class GuardianNameFieldsComponent {
  @Input() formGroup: FormGroup<any> = new FormGroup({});


}

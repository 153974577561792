import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsCitizenQuestionsComponent } from './us-citizen-questions/us-citizen-questions.component';
import { FinancialAidComponent } from './financial-aid/financial-aid.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchableSelectDropDownModule } from '../generic/searchable-select-drop-down/searchable-select-drop-down.module';
import { VisaStatusSelectComponent } from './visa-status-select/visa-status-select.component';
import { SsnModule } from './ssn/ssn.module';
import { GenericInputModule } from '../generic/generic-input.module';

@NgModule({
  declarations: [
    FinancialAidComponent,
    UsCitizenQuestionsComponent,
    VisaStatusSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SearchableSelectDropDownModule,
    GenericInputModule
  ],
  exports: [
    FinancialAidComponent,
    UsCitizenQuestionsComponent,
    VisaStatusSelectComponent,
    SsnModule
  ]
})
export class CitizenshipModule { }

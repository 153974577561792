<div class="row " [formGroup]="formGroup">
  <div class="form-control col-sm-4">
    <label for="guardian_first_name" class="input-label-text">Parent/Guardian First Name</label>
    <div style="position: relative">
      <input
        type="text"
        name="first_name"
        id="guardian_first_name"
        formControlName="first_name"
        appTrim
      >
    </div>
  </div>

  <div class="form-control col-sm-4">
    <label for="guardian_last_name" class="input-label-text">Parent/Guardian Last Name</label>
    <div style="position: relative">
      <input
        type="text"
        name="last_name"
        id="guardian_last_name"
        formControlName="last_name"
        appTrim
      >
    </div>
  </div>
</div>

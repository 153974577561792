import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormControlCustom} from 'src/app/shared/form-control-custom';
import {College} from 'src/app/shared/model/application/college.model';
import {HighSchool} from 'src/app/shared/model/application/highschool.model';
import {School} from 'src/app/shared/model/application/school';
import {FormService} from 'src/app/shared/service/form.service';
import {SchoolService} from 'src/app/shared/service/school.service';
import {SearchService} from 'src/app/shared/service/search.service';
import fuzzysort from 'fuzzysort';
import {SchoolType} from '../school-search/school-search-fieldset/school-search-fieldset.component';
import {DomService} from '../../../../shared/service/dom.service';

@Component({
  selector: 'app-school-search-input',
  templateUrl: './school-search-input.component.html',
  styles: ['.x-icon {cursor: pointer; pointer-events: initial;}', '.school-button {padding: 5px 5px !important; margin-left: 5px}']
})
export class SchoolSearchInputComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() type: string;
  @Input() schoolType: SchoolType;
  @Input() formGroup: FormGroup;
  @Input() searchLabelText = '';
  @Input() searchErrorText = '';
  @Input() searchInputName = 'school_search';
  @Input() inputFormControl: FormControlCustom<string> = new FormControlCustom<string>();
  @Output() outputtedSchool = new EventEmitter<(College | HighSchool)>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchResults') searchResults: ElementRef;

  allSchools: School[] = [];
  filteredSchools: School[] = [];
  highlightedSchool: School;
  highlightedSchoolName: string;
  currentSchool: School;
  emittedSchool: College | HighSchool;
  schoolSearchString = '';
  displayItems = false;
  endSubscriptions = new Subject<void>();
  errorMessage: string;
  tooManyResults = false;
  highlightCustom = false;

  constructor(
    private schoolService: SchoolService,
    private searchService: SearchService,
    public formService: FormService,
    private cdr: ChangeDetectorRef,
    private domService: DomService
  ) {
  }

  private static toTitleCase(phrase: string): string {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  @HostListener('click', ['$event']) onClick(event) {
    const element = (event.target as HTMLElement);
    if (this.domService.selectAllElementsAsRoot('div.search-input-wrap > input#' + this.searchInputName + ', div#school-search-results').includes(element)) {
      event.preventDefault();
    } else {
      this.resetSchoolSearch();
    }
  }

  ngOnInit(): void {
    // TODO: evaluate if querying for a very unrelated element needs to be here, maybe a focusout event handler in the template would be better for resetting search
    const sidebarEl = this.domService.selectElementAsRoot('#right-sidebar');
    if (sidebarEl) {
      sidebarEl.onclick = () => {
        if (this.displayItems) {
          this.resetSchoolSearch();
        }
      };
    }

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.prefillIfSchools();
    });

    this.cdr.detectChanges();

  }

  ngAfterViewInit() {

    let errorMessage: string;
    switch (this.schoolType) {
      case SchoolType.College:
        if (this.searchLabelText === '') {
          this.searchLabelText = 'Name of College or University';
        }
        errorMessage = 'A college selection is required';
        break;

      case SchoolType.Highschool:
        if (this.searchLabelText === '') {
          this.searchLabelText = 'Name of High School';
        }
        errorMessage = 'A school selection is required';
        break;

      case SchoolType.All:
        if (this.searchLabelText === '') {
          this.searchLabelText = 'Name of College or High School';
        }
        errorMessage = 'A College/High School selection is required';
        break;

      default:
        if (this.searchLabelText === '') {
          this.searchLabelText = 'Name of College or University';
        }
        errorMessage = 'A College/High School selection is required';
        break;
    }

    if (this.searchErrorText) {
      errorMessage = this.searchErrorText;
    }

    if (this.inputFormControl) {
      this.inputFormControl.errorMessages.required = errorMessage;
    }

    this.prefillIfSchools();

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  prefillIfSchools() {
    this.cdr.detectChanges();

    if (this.inputFormControl && !this.currentSchool && this.inputFormControl.value !== '') {
      if (this.schoolType === SchoolType.Highschool) {
        this.currentSchool = new HighSchool();
      } else {
        this.currentSchool = new College();
      }
      this.currentSchool.setName(this.inputFormControl.value);
    }

    if (this.currentSchool?.getName()) {
      this.inputFormControl.setValue(this.currentSchool.getName());
      this.inputFormControl.updateValueAndValidity();
    }
  }

  showItems() {
    this.displayItems = true;
  }

  searchSchools(search: string) {
    if (this.allSchools.length === 0) {
      this.regenerateSchools();
    }

    this.schoolSearchString = search;
    this.filteredSchools = fuzzysort.go(search, this.allSchools, {
      keys: ['schoolSearchString'],
      threshold: -999,
      scoreFn: this.searchSort
    }).map((result) => result.obj);

    this.tooManyResults = this.filteredSchools.length > 100;


    // if filteredSchools contains Liberty, put it at the top
    const libertaaay = this.filteredSchools.find(thisSchool => thisSchool.ceebCode === '005385');
    if (libertaaay) {
      this.filteredSchools = this.filteredSchools.filter(thisSchool => thisSchool !== libertaaay);
      this.filteredSchools.unshift(libertaaay);
    }
  }

  searchSort = (curResult: Fuzzysort.KeyResult<School>[]) => Math.max(
    curResult[0] ? curResult[0].score + 100 : -1000,
    curResult['obj']?.searchString?.toLowerCase().split(' ').some(descWord => descWord.includes(this.schoolSearchString.toLowerCase()))
      ? curResult[0].score + 500
      : -1000)

  regenerateSchools() {
    switch (this.schoolType) {
      case SchoolType.Highschool:
        this.allSchools = this.schoolService.returnHighSchools();
        break;

      case SchoolType.College:
        this.allSchools = this.schoolService.returnColleges();
        break;

      default:
        this.allSchools = this.schoolService.returnColleges();
        break;
    }
    this.filteredSchools = this.allSchools;
  }

  public changeHighlightedSchool(direction: string, scroll = true) {

    // no need to do anything if the search input is empty
    if (this.schoolSearchString === '' || this.schoolSearchString.length === 1) {
      return false;
    }
    // if we have not yet keyed up or down to make an initial selection, select either the very first or last items depending on direction
    if (!this.highlightedSchool) {
      if (direction === 'TAB') {
        this.resetSchoolSearch();
        return true;
      }
      if (direction === 'UP') {

        if (!this.highlightCustom) {
          this.highlightAddCustom();
          return false;
        } else {

          if (this.filteredSchools.length > 0) {
            this.highlightedSchool = this.filteredSchools[this.filteredSchools.length - 1];
            this.highlightedSchoolName = this.highlightedSchool.getName();
          } else {
            this.highlightAddCustom();
            return false;
          }

        }

      } else if (direction === 'DOWN') {
        if (this.filteredSchools.length > 0) {
          this.highlightedSchool = this.filteredSchools[0];
          this.highlightedSchoolName = this.highlightedSchool.getName();
        } else {
          this.highlightAddCustom();
          return false;
        }

      }
    } else { // we currently have a highlighted school, decide which direction to move
      if (direction === 'UP') {

        if (this.highlightedSchool.ceebCode === this.filteredSchools[0].ceebCode) { // if highlighted school is currently the first available, then highlight custom
          this.highlightAddCustom();
          return false;
        } else {

          if (this.filteredSchools.length > 0) {
            this.highlightedSchool = this.searchService.findPrev(this.filteredSchools, this.highlightedSchool);
            this.highlightedSchoolName = this.highlightedSchool.getName();
          } else {
            this.highlightAddCustom();
            return false;
          }

        }

      } else if (direction === 'DOWN') {

        if (this.filteredSchools.length === 0 || this.highlightedSchool.ceebCode === this.filteredSchools[this.filteredSchools.length - 1].ceebCode) { // if highlighted school is currently the last available in filtered schools, then highlight custom
          this.highlightAddCustom();
          return false;
        } else {
          this.highlightedSchool = this.searchService.findNext(this.filteredSchools, this.highlightedSchool);
          this.highlightedSchoolName = this.highlightedSchool.getName();
        }

      }
    }
    this.highlightCustom = false;

    if (scroll && this.highlightedSchool) {
      // this is a safe way of using document as body is read-only HTMLElement being used only to scroll into view
      document.body.querySelector('.school-search-results li[data-ceebCode="' + this.highlightedSchool.ceebCode + '"]').scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }

  highlightAddCustom() {
    this.highlightedSchool = null;
    this.highlightCustom = true;
    this.searchResults.nativeElement.querySelector('#add_custom').scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'nearest'
    });
  }

  resetSchoolSearch() {
    this.displayItems = false;
    if (this.currentSchool?.getName()) {
      this.inputFormControl.setValue(this.currentSchool.getName());
    } else {
      (this.searchInput.nativeElement as HTMLInputElement).blur();
      this.inputFormControl.setValue('');
      this.inputFormControl.updateValueAndValidity();
    }
    this.schoolSearchString = '';
  }

  customAddSchool(schoolName: string) {
    const formattedSchoolName = SchoolSearchInputComponent.toTitleCase(schoolName);
    this.resetCurrentSchool();
    this.currentSchool.description = formattedSchoolName;
    this.highlightCustom = false;
    this.addSchool(this.currentSchool);
  }

  addSchool(addedSchool: School) {

    if (this.highlightCustom) { // if custom add is currently highlighted, dont do regular add
      this.customAddSchool(this.schoolSearchString);
      return true;
    }

    addedSchool.setName(addedSchool.description);
    this.currentSchool = addedSchool;

    let newSchool: HighSchool | College;
    switch (this.schoolType) {
      case SchoolType.College:
        newSchool = addedSchool as College;
        break;

      case SchoolType.Highschool:
        newSchool = addedSchool as HighSchool;
        break;

      case SchoolType.All:
        newSchool = addedSchool as HighSchool | College;
        break;

      default:
        break;
    }

    this.emitSchool(newSchool);

    this.searchInput.nativeElement.focus();

    this.inputFormControl.setValue(this.currentSchool.getName());
    this.schoolSearchString = '';
  }

  resetCurrentSchool() {
    switch (this.schoolType) {
      case SchoolType.College:
        this.currentSchool = new College();
        break;

      case SchoolType.Highschool:
        this.currentSchool = new HighSchool();
        break;

      case SchoolType.All:
        this.currentSchool = new College();
        break;

      default:
        break;
    }
  }

  clearCurrentSchool() {
    this.resetCurrentSchool();
    this.resetSchoolSearch();
    const currentSchool = this.schoolType === SchoolType.Highschool ? this.currentSchool as HighSchool : this.currentSchool as College;
    this.emitSchool(currentSchool);
  }

  emitSchool(passedSchool: College | HighSchool) {
    this.outputtedSchool.emit(passedSchool);
  }

}

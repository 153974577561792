import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Phone } from '../shared/model/application/phone.model';
import { Student } from '../shared/model/student.model';
import { AppIdService } from '../shared/provider/app-id.service';
import { ApplicationService } from '../shared/service/application.service';
import { CookieService } from '../shared/service/cookie.service';
import { ECampusCode, FormService } from '../shared/service/form.service';
import { NavigationService } from '../shared/service/navigation.service';
import { PopupService } from '../shared/service/popup.service';
import { ProgramService } from '../shared/service/program.service';
import { RouterService } from '../shared/service/router.service';
import { SchoolService } from '../shared/service/school.service';
import { StudentService } from '../shared/service/student.service';
import { AsYouType } from 'libphonenumber-js';
import { States } from 'src/app/shared/model/states.model';
import { State } from '../shared/model/state.model';
import { Lead } from '../shared/model/lead';
import { DomService } from '../shared/service/dom.service';
import { DegreePickerComponent } from './input/degree-picker/degree-picker.component';
import { DegreeLevelRadioComponent } from './input/degree-level-radio/degree-level-radio.component';
import { EProgLevel } from '../shared/model/prog-level.model';
import { FormError } from '../shared/model/form-error.model';
import { Address } from '../shared/model/application/address.model';
import { InitService } from '../shared/service/init.service';
import { AppFormBuilder } from '../shared/model/app-form-builder.model';
import { HighSchool } from '../shared/model/application/highschool.model';
import { College } from '../shared/model/application/college.model';
import { Nation } from '../shared/model/nation.model';
import { NationService } from '../shared/service/nation.service';
import { StateService } from '../shared/service/state.service';
import { PersonalInformationComponent } from './sections/personal-information/personal-information.component';
import mockFormData from 'src/assets/cache/mockFormData.json';
import { SchoolType } from './input/generic/school-search/school-search-fieldset/school-search-fieldset.component';
import { ModalService } from '../shared/service/modal.service';

@Component({
  selector: 'app-form',
  template: 'build ur own dang form'
})

export class FormComponent implements OnInit, AfterViewInit {

  @ViewChild(PersonalInformationComponent) personalInfoComponent: PersonalInformationComponent;
  @ViewChild(DegreeLevelRadioComponent) levelRadioComponent: DegreeLevelRadioComponent;
  @ViewChild(DegreePickerComponent) degreePickerComponent: DegreePickerComponent;

  appFormBuilder = new AppFormBuilder();

  activeSectionName = this.navigationService.activeSection.name;

  applicationForm: FormGroup<any> = new FormGroup({});

  successData: string;
  formErrors = [];

  endSubscriptions = new Subject<void>();
  showResumePreviousApplication = false;

  allStates: State[] = States;

  prefilling = false;

  constructor(
    public cdr: ChangeDetectorRef,
    public navigationService: NavigationService,
    public programService: ProgramService,
    public applicationService: ApplicationService,
    public route: ActivatedRoute,
    public formService: FormService,
    public schoolService: SchoolService,
    public appIdService: AppIdService,
    public popupService: PopupService,
    public routerService: RouterService,
    public cookieService: CookieService,
    public nationService: NationService,
    public stateService: StateService,
    public studentService: StudentService,
    public el: ElementRef,
    public domService: DomService,
    public initService: InitService,
    public modalService: ModalService
  ) {
  }

  ngOnInit(): void {

    const initSection = this.cookieService.getCookie('applylu_init_section');
    if (initSection && initSection != '') {
      this.navigationService.emitActiveSectionNext(initSection);
      this.activeSectionName = initSection;
      this.cookieService.deleteCookie('applylu_init_section');
    }

    // enabling prefilling of some required fields if the URL param 'prefill' equals 'true'
    if (this.route.snapshot.queryParams.prefill === 'true' && environment.envType !== 'prod') {
      const prefillForm = mockFormData;

      // emit some values needed for other components to respond to
      if (prefillForm['yourDegree']) {
        this.formService.updatedCampus.next(ECampusCode[prefillForm['yourDegree']['campus']]);
        this.formService.updatedLevel.next(EProgLevel[prefillForm['yourDegree']['degree_level']]);
        const prefillProgram = this.programService.getProgramByProgramCode(prefillForm['yourDegree']['program']);
        this.programService.programSubNext(prefillProgram);
      }

      this.applicationForm.patchValue(prefillForm);
      this.cdr.detectChanges();

    }


    // pass empty form to navigationService, the navService will then have this objects reference, and will be updated concurrently because of that shared reference.
    this.navigationService.updateFormForNavService(this.applicationForm);

    // whenever our applicationForm updates
    this.applicationForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500), // this was firing hundreds of times without a debounce
        switchMap((appForm) => {

          this.formService.calculateProgress(this.formService.gatherAllControls(this.applicationForm, {}));

          return of(appForm);
        })
      )
      .pipe(
        takeUntil(this.endSubscriptions),
        distinctUntilChanged(), // this will prevent updates being sent to the API if the form value isn't changed
        debounceTime(1000), // also give a pause between valueChanges before we send the update to prevent requests from being spammed
        switchMap((appForm) => {
          return of(appForm);
        })
      )
      .subscribe(() => {

        // so that we capture certain cookies that are being added after app initialization
        // set emitEvent to false so we don't end up triggering applicationForm.valuechanges again
        this.applicationForm.get('data_fields').get('allCookies').setValue(this.cookieService.getAllCookies().toString(), { emitEvent: false });

        const formData = this.applicationForm.getRawValue();
        this.formService.mainFormData = formData;
        if (environment.isAgent) {
          this.applicationService.updateAgentApplication(formData);
        } else {
          this.applicationService.updateStudentApplication(formData);
        }
      });

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(async prefillObj => {
      const prefillForm = prefillObj.formData;
      this.prefilling = true;

      const preparedForm = new FormGroup({});
      Object.assign(preparedForm, prefillForm);

      if (preparedForm['yourDegree']) {
        if (preparedForm['yourDegree']['campus']) {
          this.formService.updatedCampus.next(preparedForm['yourDegree']['campus']);
        }
        if (preparedForm['yourDegree']['degree_level']) {
          this.formService.updatedLevel.next(preparedForm['yourDegree']['degree_level']);
        }
        this.maybeTriggerPrefillProgram(preparedForm['yourDegree']['program']);
      }

      // needs to happen after program prefill and before patch value
      // formArrays can't prefill without a little work before patchValue takes place. we don't know how many items there are in a given formArray (schools in this app), need to first count how many and generate them ahead of time before patchValue is used
      this.replaceSchoolsInCurrentForm(preparedForm);

      this.applicationForm.patchValue(preparedForm);

      this.navigationService.validateAllSections();
      this.cdr.detectChanges();

      this.prefilling = false;

      this.navigationService.updateFormForNavService(this.applicationForm);

      this.formService.calculateProgress(this.formService.gatherAllControls(this.applicationForm, {}));
    });

    this.formService.navigateToControl.pipe(takeUntil(this.endSubscriptions)).subscribe((formError: FormError) => {
      if (formError.sectionName && formError.sectionName !== '' && formError.sectionName !== this.activeSectionName) {
        this.navigationService.emitActiveSectionNext(formError.sectionName);
      }
      setTimeout(() => { // wait for the section fade-in animation to finish so that the top offset of the control is correct
        this.scrollToControl(formError.controlName);
      }, 420);
    });

    // subscribe to Lead Prefill
    this.applicationService.prefillLead.pipe(takeUntil(this.endSubscriptions)).subscribe((lead: Lead) => {

      if (lead.firstName) {
        this.updateFirstName(lead.firstName);
      }
      if (lead.lastName) {
        this.updateLastName(lead.lastName);
      }
      if (lead.email != null && lead.email.length > 0) {
        this.updateEmailAddress(lead.email);
      }
      if (lead.address) {
        this.updateAddressLead(lead);
      }
      if (lead.phones) {
        const formatter = new AsYouType('US');
        formatter.input(lead.phones[0].phoneNumber);
        const getFormatPhone = formatter.getNumber();
        const newPhone = new Phone(formatter.getNumber());

        if (newPhone?.isValid()) {
          newPhone.type = 'AP';
          newPhone.country = getFormatPhone.countryCallingCode.toString();
          this.updatePhone(newPhone);
        }
      }
      if (lead.gender) {
        if (lead.gender.includes('Male') || lead.gender.toUpperCase() === 'M') {
          lead.gender = 'M';
        } else if (lead.gender.includes('Female') || lead.gender.toUpperCase() === 'F') {
          lead.gender = 'F';
        } else if (lead.gender.includes('U') || lead.gender.includes('Unknown')) {
          lead.gender = '';
        }
        this.applicationForm.get('personalInformation').get('genderRadio').setValue(lead.gender);
      }
    });

    // subscribe to LUID prefill
    this.studentService.emitStudent.pipe(takeUntil(this.endSubscriptions)).subscribe((student: Student) => {
      if (student.firstName) {
        this.updateFirstName(student.firstName);
      }
      if (student.lastName) {
        this.updateLastName(student.lastName);
      }
      if (student.middleName) {
        this.applicationForm.get('personalInformation').get('name_fields').get('middleName').setValue(student.middleName);
      }
      if (student.email) {
        this.updateEmailAddress(student.email);
      }
      if (student.address) {
        this.updateAddressStudent(student);
      }

      if (student.gender) {
        if (student.gender.includes('Male')) {
          student.gender = 'M';
        } else if (student.gender.includes('Female')) {
          student.gender = 'F';
        } else if (student.gender.includes('U') || student.gender.includes('Unknown')) {
          student.gender = '';
        }
        this.applicationForm.get('personalInformation').get('genderRadio').setValue(student.gender);
      }
      if (student.phoneNumber) {
        const formatter = new AsYouType('US');
        formatter.input(student.phoneNumber);
        const getFormatPhone = formatter.getNumber();
        const newPhone = new Phone(formatter.getNumber());

        if (newPhone?.isValid()) {
          newPhone.type = 'AP';
          newPhone.country = getFormatPhone.countryCallingCode.toString();

          this.updatePhone(newPhone);
        }
      }


    });

  } // end ngOnInit

  ngAfterViewInit() {

    const queryParams = this.route.snapshot.queryParams;

    let pParam: string = queryParams.p;
    if (Array.isArray(pParam)) {// can return as an array if there is more than 1 p param in URL
      pParam = pParam[0];
    }
    if (pParam && pParam !== '' && !queryParams.prefill) {
      const pParamCode = pParam.toUpperCase();
      if (this.programService.allPrograms.length === 0) {

        this.programService.getAllProgramsLoadedSub().pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
          this.maybeTriggerPrefillProgram(pParamCode);
        });
      } else {
        this.maybeTriggerPrefillProgram(pParamCode);
      }

    }

    // enabling setting default section on page load if a valid URL param 'section' is specified
    if (queryParams.section && environment.envType !== 'prod') {
      const sectionUrlParam = queryParams.section;

      const findSection = this.navigationService.possibleSections.find(thisSection => thisSection.name === sectionUrlParam);
      if (findSection) {
        this.navigationService.activeSection = findSection;
        this.navigationService.emitActiveSectionNext(findSection.name);
      }
    }
    // after app is fully initialized, recalculate the progress so we get the exact right progress at the start.
    this.formService.calculateProgress(this.formService.gatherAllControls(this.applicationForm, {}));

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();

  }

  clearProgramDependentFields() {

    const previousEd = this.applicationForm.get('previousEducation');
    const schoolSearchGroups = [previousEd.get('highschoolSearch'), previousEd.get('multiCollegeSearch'), previousEd.get('bachelorsSearch'), previousEd.get('mastersSearch'), previousEd.get('doctoralSearch')];

    schoolSearchGroups.forEach(thisGroup => {
      (thisGroup.get('selected_schools') as FormArray).clear();
    });

  }

  public updatePhone(phone: Phone) {
    let updatedNumber = '';
    if (phone.area) {
      updatedNumber += phone.area;
    }
    updatedNumber += phone.phoneNumber;
    this.applicationForm.get('personalInformation').get('phone_fields').get('phoneNumberInput').setValue(updatedNumber);
    this.personalInfoComponent.phoneNumberComponent.phoneNumberUpdated(updatedNumber);
  }

  public gatherAllControls(group: FormGroup, returnedControls: {}) {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        // recursively calls itself to get to the bottom level FormControl nested inside as many FormGroup as needed
        this.gatherAllControls(abstractControl, returnedControls);
      } else {
        returnedControls[key] = abstractControl;
      }
    });
    return returnedControls;
  }

  public hsDiplomaFNameUpdated(fName: string) {
    const firstNameField = this.applicationForm.get('personalInformation').get('name_fields').get('firstName');
    if (firstNameField.value === '') {
      firstNameField.setValue(fName);
    }

  }

  public hsDiplomaLNameUpdated(lName: string) {
    const lastNameField = this.applicationForm.get('personalInformation').get('name_fields').get('lastName');
    if (lastNameField.value === '') {
      lastNameField.setValue(lName);
    }
  }

  public updateFirstName(firstName: string) {
    this.applicationForm.get('personalInformation').get('name_fields').get('firstName').markAsTouched();
    this.applicationForm.get('personalInformation').get('name_fields').get('firstName').setValue(firstName);
    this.applicationForm.get('previousEducation').get('hs_diploma_firstName').setValue(firstName);
    this.applicationForm.get('previousEducation').get('hs_diploma_firstName').markAsTouched();
    this.cdr.detectChanges();
  }

  public updateLastName(lastName: string) {
    if (this.applicationForm.get('personalInformation').get('name_fields').get('lastNameChange').value === 'Yes') {
      this.applicationForm.get('personalInformation').get('name_fields').get('lastName').markAsTouched();
      this.applicationForm.get('personalInformation').get('name_fields').get('lastName').setValue(lastName);
      this.applicationForm.get('previousEducation').get('hs_diploma_lastName').markAsTouched();
    } else {
      this.applicationForm.get('personalInformation').get('name_fields').get('lastName').markAsTouched();
      this.applicationForm.get('personalInformation').get('name_fields').get('lastName').setValue(lastName);
      this.applicationForm.get('previousEducation').get('hs_diploma_lastName').setValue(lastName);
      this.applicationForm.get('previousEducation').get('hs_diploma_lastName').markAsTouched();
    }
  }

  public updateEmailAddress(email: string) {
    if (this.applicationForm.get('personalInformation') &&
      this.applicationForm.get('personalInformation') &&
      this.applicationForm.get('personalInformation').get('email')
    ) {
      this.applicationForm.get('personalInformation').get('email').setValue(email);
    }
    this.applicationService.applicationEmail = email;
    this.cdr.detectChanges();
  }

  public updateAddressStudent(student: Student) {
    const prefillAddress = new Address();
    prefillAddress.address1 = student.address;
    prefillAddress.city = student.city;
    prefillAddress.zip = student.zip;
    prefillAddress.state = student.state;
    prefillAddress.nationCode = student.country ? student.country : 'US';

    this.prefillByAddress(prefillAddress);
  }

  public updateAddressLead(lead: Lead) {
    const prefillAddress = new Address();
    prefillAddress.address1 = lead.address.line1;
    prefillAddress.city = lead.address.city;
    prefillAddress.zip = lead.address.zip;
    prefillAddress.state = lead.address.state;
    prefillAddress.nationCode = 'US';

    this.prefillByAddress(prefillAddress);

  }

  prefillByAddress(address: Address) {
    let findCountry: Nation;

    const addressFields = this.applicationForm.get('personalInformation').get('address_fields');

    if (address.nationCode) {
      findCountry = this.nationService.findByCode(address.nationCode);
      if (!findCountry) {
        findCountry = this.nationService.findByName(address.nationCode);
      }
    }

    addressFields.get('street_address').setValue(address.address1);

    addressFields.get('city').setValue(address.city);

    if (address.nationCode === 'US') {
      addressFields.get('us_resident_radio').setValue('Yes');
    } else {
      addressFields.get('us_resident_radio').setValue('No');
    }

    // this wasn't being updated if there was already a zip. Moving this line down here as the last thing helped, not sure why
    addressFields.get('zip_code').setValue(address.zip);

    if (findCountry) {
      addressFields.get('personal_info_country').setValue(findCountry.name);
      addressFields.get('personal_info_country_code').setValue(findCountry.code);
    }

    const findState = this.stateService.getStateByCode(address.state);
    if (findState) {

      if (addressFields.get('personal_info_country_code').value === 'US') {
        addressFields.get('personal_info_state').setValue(findState.name ? findState.name : findState);
      } else {
        addressFields.get('international_state').setValue(findState.name ? findState.name : findState);
      }

    }


  }

  public async onSubmit(token?: string) {

    this.validateForm();

    if (this.formErrors.length > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    } else {
      // success
      const theProgram = this.programService.getProgramByProgramCode(this.applicationService?.application?.majorFoses[0]?.programCode);
      await this.applicationService.submitApplication(this.applicationForm.getRawValue(), token);
      this.routerService.navigate('/thank-you/.', theProgram, true);
    }
  } // end onSubmit()

  public validateForm() {
    this.formService.submitted = true;
    this.applicationForm.markAllAsTouched();
    this.applicationForm.updateValueAndValidity();

    this.navigationService.validateAllSections();

    this.formErrors = this.formService.gatherFormErrors(this.applicationForm);
  }

  public scrollToControl(controlName: string) {
    let queryControl: HTMLElement;


    // I (The Expert Known As Chris) personally prefer not to use IDs, but try it first because there should ideally only be 1 on the page
    queryControl = this.el.nativeElement.querySelector(
      `*:not(.hidden) [id=${controlName}]:not(.hidden):not([disabled])`
    );

    if (!queryControl) {
      queryControl = this.el.nativeElement.querySelector(
        `*:not(.hidden) [formcontrolname=${controlName}]:not(.hidden):not([disabled])`
      );
    }

    // sometimes that selector returns null because unavailable or the attribute isnt totally there, try one more time with name attribute
    if (!queryControl) {
      queryControl = this.el.nativeElement.querySelector(
        `*:not(.hidden) [name=${controlName}]:not(.hidden):not([disabled])`
      );
    }

    if (queryControl) {
      this.domService.scrollIntoView(queryControl, 40);
      queryControl.focus();
    }

  }

  replaceSchoolsInCurrentForm(prefillForm: FormGroup) {
    const prevEduSection = this.applicationForm.get('previousEducation');
    const highschoolArray = prevEduSection.get('highschoolSearch').get('selected_schools') as FormArray;
    highschoolArray.clear();

    const prefillHsData = prefillForm['previousEducation']['highschoolSearch'] ? prefillForm['previousEducation']['highschoolSearch']['selected_schools'] : [];

    prefillHsData.forEach((highschoolData) => {
      const newHsFields = AppFormBuilder.generateSchoolFields('', SchoolType.Highschool);

      // also transform data of selected_school to a HighSchool
      highschoolData.selected_school = new HighSchool(highschoolData.selected_school);

      newHsFields.patchValue(highschoolData);

      highschoolArray.push(newHsFields);
    });

    const collegeGroups = this.schoolService.collegeGroups;

    collegeGroups.forEach(collegeGroupName => {
      const collegeArray = prevEduSection.get(collegeGroupName).get('selected_schools') as FormArray;
      collegeArray.clear();

      const collegePrefillData = prefillForm['previousEducation'][collegeGroupName] ? prefillForm['previousEducation'][collegeGroupName]['selected_schools'] : [];

      collegePrefillData.forEach((collegeData) => {
        const defaultLevel = collegeData['degree_level_received'] ? collegeData['degree_level_received'] : '';
        const generateDidGraduate = collegeData['did_graduate_radio'];
        const newCollegeFields = AppFormBuilder.generateSchoolFields(defaultLevel, SchoolType.College, generateDidGraduate);

        // also transform data of selected_school to a College
        collegeData.selected_school = new College(collegeData.selected_school);

        newCollegeFields.patchValue(collegeData);

        collegeArray.push(newCollegeFields);
      });
    });


  }

  private async maybeTriggerPrefillProgram(programCode: string) {
    let prefillProgram = this.programService.getProgramByProgramCode(programCode);

    // not initially found, check to see if crosswalk
    if (prefillProgram.programCode === '' && programCode !== '') {
      prefillProgram = await this.programService.getCrosswalkProgram(programCode);
    }

    if (prefillProgram && prefillProgram.programCode !== null && prefillProgram.programCode !== '') {

      const campCode = this.programService.getCampusByProgramCode(prefillProgram.programCode);
      let levelCode: string;
      if (prefillProgram.degreeLevel === 'Certificate' && prefillProgram.level === 'Graduate' || prefillProgram.degreeLevel === 'Certificate' && prefillProgram.level === 'Doctorate') {
        levelCode = 'Certificate';
      } else {
        levelCode = this.programService.getLevelByProgramCode(prefillProgram.programCode);
      }

      // Set hidden fields
      if (prefillProgram.displayDescription.includes('CRT:')) {
        this.applicationForm.get('yourDegree')?.get('program_type')?.setValue('CRT');
      } else if (prefillProgram.displayDescription.includes('CTG:')) {
        this.applicationForm.get('yourDegree')?.get('program_type')?.setValue('CTG');
      } else if (prefillProgram.displayDescription.includes('THM:')) {
        this.applicationForm.get('yourDegree')?.get('program_type')?.setValue('THM');
      }
      this.applicationForm.get('yourDegree')?.get('degree_level')?.setValue(prefillProgram.degreeLevel);
      this.applicationForm.get('yourDegree')?.get('level')?.setValue(prefillProgram.level);
      this.applicationForm.get('yourDegree')?.get('level_code')?.setValue(prefillProgram.levelCode);

      this.formService.updatedCampus.next(campCode);
      this.formService.updatedLevel.next(levelCode);

      this.applicationForm.get('yourDegree')?.get('campus')?.setValue(campCode);
      this.applicationForm.get('yourDegree')?.get('degree_level')?.setValue(levelCode);
      this.applicationForm.get('yourDegree')?.get('program')?.setValue(prefillProgram.programCode);

      setTimeout(() => {
        // small timeout to let other parts of the app catch up to the campus/level changes before emitting program
        // emit some values needed for other components to respond to
        this.programService.pickerSelectedSubNext(prefillProgram);
        this.programService.programSubNext(prefillProgram);
        this.levelRadioComponent.showMobileVersionOnDesktop = true;
        this.degreePickerComponent.showMobileVersionOnDesktop = true;
      }, 300);

    }
  }


}

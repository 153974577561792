import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-warning',
  templateUrl: './environment-warning.component.html',
  styleUrls: ['./environment-warning.component.scss']
})
export class EnvironmentWarningComponent implements OnInit {
  environment: string;

  constructor() { }

  ngOnInit(): void {
    this.environment = (this.getEnvironment() === 'pprd') ? 'NOTICE! This is a training instance. All submissions will be sent to the test database.'

    : `NOTICE! This is a ${this.getEnvironment()} instance. All submissions will be sent to the ${this.getEnvironment()} database.` ;
  }

  public getEnvironment() {
    return environment.envType;
  }

  public getIsAgent() {
    return environment.isAgent;
  }

}

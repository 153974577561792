import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { FormService } from 'src/app/shared/service/form.service';
import { SchoolService } from '../../../shared/service/school.service';
import { College } from 'src/app/shared/model/application/college.model';
import { HighSchool } from 'src/app/shared/model/application/highschool.model';
import { Subject } from 'rxjs';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { takeUntil } from 'rxjs/operators';
import { SchoolSearchInputComponent } from '../generic/school-search-input/school-search-input.component';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import {SchoolType} from '../generic/school-search/school-search-fieldset/school-search-fieldset.component';

@Component({
  selector: 'app-expelled-radio',
  templateUrl: './expelled-radio.component.html',
})
export class ExpelledRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Input() justSearch: boolean;

  @ViewChild(SchoolSearchInputComponent) schoolSearchInputComponent: SchoolSearchInputComponent;

  schoolType = SchoolType;

  currentSchool: HighSchool | College;

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor(
    public formService: FormService,
    public schoolService: SchoolService,
    public applicationService: ApplicationService
  ) { }

  ngOnInit(): void {

    if (!this.justSearch) {
      this.getExpelledRadio().enable();
    }

    this.formGroup.get('expelled_school').enable();
    this.formGroup.get('school_search').enable();

    this.formGroup.updateValueAndValidity();
    this.getExpelledRadio().updateValueAndValidity();

    if (this.applicationService.festivalsApp) {
      this.getExpelledRadio().clearValidators();
    }

    this.maybeEnableInputs();

    this.getExpelledRadio().valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      this.maybeEnableInputs();
    });

    this.applicationService.prefillApplication.subscribe(application => {
      this.prefillFromAppObject(application);
    });

  }

  ngOnDestroy() {

    this.getExpelledRadio().disable();
    this.getExpelledRadio().setValue('');
    this.formGroup.get('expelled_school').disable();
    this.formGroup.get('expelled_school').setValue('');
    this.formGroup.get('school_search').disable();
    this.formGroup.get('school_search').setValue('');

    this.endSubscriptions.next();
    this.endSubscriptions.complete();

  }

  prefillFromAppObject(application) {
    const answer49 = application.getCustomAnswer('49');
    if (answer49) {
      this.getExpelledRadio().enable();

      if (answer49 !== 'No') {
        this.formGroup.get('expelled_school').enable();

        this.getExpelledRadio().setValue('Yes', {onlySelf: false, emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false});
        if (answer49 !== 'Yes' && this.schoolSearchInputComponent) {
          // we have a ceebCode, get school and update property
          // returns something like 'Yes_SchoolName: 007124', so cut out first 16 characters
          const theCeebCode = answer49.substring(16);
          const findSchool: College | HighSchool = this.schoolService.getSchoolByCeebCode(theCeebCode);
          if (findSchool) {
            this.schoolSearchInputComponent.currentSchool = findSchool;
            this.schoolSearchInputComponent.prefillIfSchools();
            this.currentSchool = findSchool;
          } else {
            // no school found, let's make a custom one (will assume its a college)
            const makeASchool = new College();
            makeASchool.setName(theCeebCode);

            this.schoolSearchInputComponent.currentSchool = makeASchool;
            this.schoolSearchInputComponent.prefillIfSchools();
            this.currentSchool = makeASchool;
          }
        }

      } else {
        this.getExpelledRadio().setValue('No', {onlySelf: false, emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false});
      }
    }
  }

  maybeEnableInputs() {
    const radioVal = this.getExpelledRadio().value;
    if ( (radioVal === 'Yes' || this.justSearch)) {
      this.formGroup.get('expelled_school').enable();
      this.formGroup.get('school_search').enable();
      this.formGroup.get('school_search').setValidators([Validators.required]);

    } else {
      this.formGroup.get('expelled_school').disable();
      this.formGroup.get('expelled_school').setValue('');
      this.formGroup.get('school_search').disable();
      this.formGroup.get('school_search').setValue('');
      this.formGroup.get('school_search').clearValidators();

    }

    this.formGroup.get('expelled_school').updateValueAndValidity();
    this.formGroup.get('school_search').updateValueAndValidity();
  }

  emittedSchool(emittedSchool: (College|HighSchool)) {
    if (emittedSchool.ceebCode) {
      this.formGroup.get('expelled_school').setValue(emittedSchool.ceebCode);
    } else if (emittedSchool.description) {
      this.formGroup.get('expelled_school').setValue(emittedSchool.description);
    }
    this.formGroup.get('school_search').setValue(emittedSchool.description);
    this.currentSchool = emittedSchool;
  }

  getExpelledRadio(): FormControlCustom<string> {
    return this.formGroup.get('expelled_radio_question') as FormControlCustom<string>;
  }

}

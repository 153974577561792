<div class="ra-select-container" [formGroup]="formGroup">
    <app-multi-searchable-select-dropdown
      [itemFormControl]="formGroup.get('race_select')"
      [inputLabelText]="'Race and Ethnicities'"
      [nonSearchableSelect]="true"
      [maxSelect]="5"
      [smallerLabel]="true"
      [blocker]="'RF'"
      [inputtedObjectList]="raceOptions"
      [allowCustom]="false"
      [placeholder]="'Select all applicable. (Up to 5)'"
    ></app-multi-searchable-select-dropdown>
</div>

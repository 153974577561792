import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniAlertComponent } from './mini-alert.component';

@NgModule({
  declarations: [
    MiniAlertComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MiniAlertComponent
  ]
})
export class MiniAlertModule { }

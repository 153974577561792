<ng-container [formGroup]="formGroup">
  <div class="form-control">
    <div style="position: relative">
      <div class="validation-error-bar"
        *ngIf="formGroup.get('email').touched && formGroup.get('email').errors">
      </div>
      <label for="{{replaceSlashAndSpace(labelText)}}">
        <span class="input-label-text">{{ labelText }}</span>
        <div *ngIf="showLoadingAnimation" class="spinner-container">
          <div class="spinner">
            <div></div>
          </div>
        </div>
        <input
          id="{{replaceSlashAndSpace(labelText)}}"
          type="text"
          name="email"
          appTrim
          aria-describedby="email-error"
          [maxlength]="128"
          formControlName="email" (blur)="validateEmailBeforeEmit($event.target.value)"
          [attr.aria-invalid]="formGroup.get('email').touched && formGroup.get('email').errors ? 'true': null"
        >
      </label>
      <span class="error" role="dialog" id="email-error" aria-live="polite"
        *ngIf="formGroup.get('email').touched && formGroup.get('email').errors && formGroup.get('email').errors.required">Enter an email</span>
        <span class="error" role="dialog" id="email-error" aria-live="polite"
        *ngIf="formGroup.get('email').touched && formGroup.get('email').errors && formGroup.get('email').errors.pattern">Provide a valid email</span>
    </div>
  </div>
</ng-container>

import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';

class ErrorMessages {
  public pattern?: string;
  public required?: string;
  public min?: string;
  public max?: string;
  public custom?: string;
}

export class FormControlCustom<T> extends FormControl {
  public label = '';

  constructor(
    public errorMessages?: ErrorMessages,
    formState: any = null,
    validatorOrOpts?: ValidatorFn | AbstractControlOptions | ValidatorFn[],
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  static customValidatorByBoolean(bool: boolean): ValidatorFn {
    return (): { [key: string]: any } | null =>
      bool ? null : { custom: true };
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { FormControlCustom } from 'src/app/shared/form-control-custom';

@Component({
  selector: 'app-application-origin',
  templateUrl: './application-origin.component.html',
  styleUrls: ['./application-origin.component.scss']
})
export class ApplicationOriginComponent implements OnInit, OnDestroy {

  @Input() formGroup = new FormGroup({});

  possibleOrigins = ['Outbound', 'Inbound', 'Chat', 'Visitor'];

  constructor(
    public formService: FormService,
  ) { }

  ngOnInit(): void {

    this.formGroup.addControl('application_origin', new FormControlCustom<string>({ required: 'Select an application origin' }, '', { validators: [Validators.required] }));

  }

  ngOnDestroy() {
    this.formGroup.removeControl('application_origin');
  }

}

import { trigger, state, style, transition, animate, group } from '@angular/animations';

export const NgIfSlideInOutAnimation = [
  trigger('ngIfSlideInOutAnimation', [
    state('in', style({ height: '*', opacity: 0 })),
    transition(':leave', [
      style({ height: '*', opacity: 1, 'margin-bottom': '*', 'margin-top': '*' }),

      group([
        animate(300, style({ height: 0, 'margin-bottom': 0, 'margin-top': 0 })),
        animate('200ms ease-in-out', style({ 'opacity': '0' }))
      ])

    ]),
    transition(':enter', [
      style({ height: '0', opacity: 0, 'margin-bottom': 0, 'margin-top': 0 }),

      group([
        animate(300, style({ height: '*', 'margin-bottom': '*', 'margin-top': '*' })),
        animate('400ms ease-in-out', style({ 'opacity': '1' }))
      ])

    ])
  ])
];

export const NgClassHiddenSlideInOutAnimation = [
  trigger(
    'ngClassHiddenSlideInOutAnimation',
    [
      state('inactive', style({ 'height': '0px', 'opacity': '0', 'display': 'none' })),
      state('active', style({ 'height': '30px', 'opacity': '1' })),

      transition('inactive => active', [
        style({ 'display': 'block' }),
        animate('5000ms ease-in')
      ]),

      transition('active => inactive', [
        animate('5000ms ease-in')
      ])
    ]
  )
];

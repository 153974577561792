import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AppIdService } from 'src/app/shared/provider/app-id.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { DomService } from 'src/app/shared/service/dom.service';
import { ModalService } from 'src/app/shared/service/modal.service';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-welcome-back-modal',
  templateUrl: './welcome-back-modal.component.html',
  styleUrls: ['./welcome-back-modal.component.scss']
})
export class WelcomeBackModalComponent extends ModalComponent implements OnInit {

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public domService: DomService,
    public appIdService: AppIdService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2
  ) {
    super(
      modalService,
      applicationService,
      elm,
      cdr,
      renderer,
      domService
    );
  }

  ngOnInit(): void {

    this.modalService.triggerWelcomeBackModal.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.showModal();
    });

  }

}

<div class="noResults" *ngIf="!loading && noMatch && programSearchString.length > 0">No results found for <br>
  "{{ programSearchString }}"
</div>

<app-loading-ring class="mt-5" [circleColor]="'#0059C0'" *ngIf="loading"></app-loading-ring>
<div [class.loading]="loading">
  <tree-root #treeRoot aria-label="List of Degrees" (initialized)="this.treeComponent.treeModel.setExpandedNode(0, true);"
             (toggleExpanded)="$event.treeModel.expandedNodeIds[0] = true" [focused]="false"
             [nodes]="displayedNodes" [options]="options">
    <ng-template #treeNodeWrapperTemplate let-node let-index="index">
      <div class="tree-node-wrapper no-pointer"
           [attr.aria-label]="node.data.name"
           [attr.aria-expanded]="calculateAriaExpanded(node)"
           [tabindex]="node.data.isRoot ? -1 : 0"
           (keydown)="onKeyDown(node, $event)">

        <tree-node-expander [node]="node"></tree-node-expander>

        <div class="node-content-wrapper pointer"
             [attr.data-program]="node.data.programCode"
             (click)="node.mouseAction('click', $event)"
             (mousedown)="onMouseDown($event)"
             (mouseup)="onMouseUp($event)">
          <div class="tree-column-wrapper">

            <span *ngIf="node.data.isLeaf" class="screen-reader-only" role="alert"
                  [innerHTML]="node.data.programCode === selectedProgram.programCode ? (selectedProgram.cognate || selectedProgram.majorGroup)+' is currently selected below' : '' "></span>

            <app-icon
              *ngIf="node.data.programCode === selectedProgram.programCode && node.data.name === (selectedProgram.cognate || selectedProgram.majorGroup)"
              class="check-icon v-center animated-checkmark"
              aria-hidden="true"
              [icon]="'check'"
            ></app-icon>

            <div class="tree-column column-name"
                 [title]="node.data.name"
                 [innerHTML]="highlightSearchString(node.data.name, programSearchString)">
            </div>

            <div
              class="tree-column column-degreecode"
              [title]="node.data.shortCode">
              {{ node.data.shortCode }}
            </div>

          </div>
        </div>
      </div>
    </ng-template>
  </tree-root>
</div>

  <div class="sticky-spacer" style="padding-top: 147px"></div>
  <div class="mobile-sticky" [ngClass]="{'scrolled': hasScrolled}">
    <div class="d-flex">
      <div class="col-8"><app-sidebar-logo></app-sidebar-logo></div>
      <div class="col-4 text-right pt-3"><app-get-help></app-get-help></div>
    </div>

    <app-sidebar-menu class="row mobile-version"></app-sidebar-menu>
  </div>

  <hr class="menu-line" [ngClass]="{'scrolled': hasScrolled}">

  <div class="d-flex mt-30 mb-30 progress-holder">
      <div class="sidebar--progress">
        <div class="progress--bar" [style.width]="progressNumber + '%'"></div>
      </div>
      <span class="sidebar--progress-completed ml-30 flex-no-shrink">{{progressNumber}}% complete</span>
  </div>


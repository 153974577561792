<div class="file-upload">

    <h3 *ngIf="sentToAPI" class="sent" role="alert" tabindex="0">{{fileListLength}} Transcript{{fileListLength === 1 ? ' has' : 's have'}} been sent to admissions</h3>

    <h3 *ngIf="failedSendToAPI" role="alert" class="failed-send">{{fileListLength}} Transcript{{fileListLength === 1 ? '' : 's'}} unable to send to admissions.
        Email your Transcript to {{toAddress}}.</h3>

    <div *ngIf="!sentToAPI && !failedSendToAPI">
        <app-file-upload [uploadType]="'Transcript(s)'" [approvedFileTypes]="approvedFileTypes" [approvedFileTypesName]="approvedFileTypesNames" (filesEmitted)="sendTranscripts($event)" ></app-file-upload>
    </div>
</div>

import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from 'src/app/shared/service/form.service';
import {ApplicationService} from 'src/app/shared/service/application.service';
import {FlightSchool} from 'src/app/shared/model/flight-school.model';
import {SearchService} from 'src/app/shared/service/search.service';
import {FlightschoolService} from 'src/app/shared/service/flightschool.service';
import {ProgramService} from 'src/app/shared/service/program.service';
import {Subject, Subscription} from 'rxjs';
import {FormControlCustom} from 'src/app/shared/form-control-custom';
import {CheckboxOpts} from 'src/app/shared/model/checkbox-opts.model';
import {RadioOpts} from 'src/app/shared/model/radio-options.model';
import {DisciplineGroup, getIndrAllDisciplines, getMaisAllDisciplineOptions} from './mais-options-residential';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-custom-program-questions',
  templateUrl: './custom-program-questions.component.html',
  styles: ['.mais-hour-select-wrap { width: 713px; max-width: 100%; } .faa_location_container { max-width: 400px; } option{color: initial}']
})

export class CustomProgramQuestionsComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @ViewChild('searchInput') searchInput: ElementRef;

  programSub = new Subscription();

  selectedProgram = this.programService.getCurrentProgram();
  selectedProgramCode: string;

  flightSchoolsLoaded = false;
  highlightedFlightSchool: FlightSchool = new FlightSchool();
  flightSchoolSearch = '';
  displayFlightSchools = false;
  allFlightSchools: FlightSchool[] = [];
  displayedFlightSchools: FlightSchool[] = [];
  filteredFlightSchools: FlightSchool[] = [];
  selectedFlightSchool: FlightSchool;
  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  maisAllOptionsArray: DisciplineGroup[] = [];
  indrAllOptionsArray: DisciplineGroup[] = [];
  mais12HrOptions: { discipline: string, programs: [{ name: string, code: string }] }[];
  mais9HrOptions: { discipline: string, programs: [{ name: string, code: string }] }[];
  indr12HrOptions: { discipline: string, programs: [{ name: string, code: string }] }[];
  indr18HrOptions: { discipline: string, programs: [{ name: string, code: string }] }[];

  rnLicenseCheckOpts: CheckboxOpts[] = [
    {
      name: 'rn_license_check',
      disabled: true,
      required: true,
      defaultVal: false,
      label: 'I understand that an RN license is a requirement prior to acceptance into this program.',
      errorMessage: 'Indicate that you understand that an RN license is a requirement prior to acceptance into this program'
    }
  ];

  educationalLeadershipOpts: CheckboxOpts[] = [
    {
      name: 'asel_check',
      disabled: true,
      required: true,
      defaultVal: false,
      label: 'I understand that at least 1 year of experience as a full-time, contractual teacher, with an initial teaching license, must be completed prior to beginning this program\'s internship.',
      errorMessage: 'Indicate that you understand that at least 1 year of experience as a full-time, contractual teacher, with an initial teaching license, must be completed prior to beginning this program\'s internship.'
    }
  ];

  aprnLicenseCheckOpts: CheckboxOpts[] = [
    {
      name: 'aprn_license_check',
      disabled: true,
      required: true,
      defaultVal: false,
      label: 'I understand that an APRN license and associated professional certification is a requirement prior to acceptance into this program.',
      errorMessage: 'Indicate that you understand that an APRN license is a requirement prior to acceptance into this program'
    }
  ];

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService,
    private searchService: SearchService,
    private flightschoolService: FlightschoolService,
    private cdr: ChangeDetectorRef,
    private programService: ProgramService
  ) {
  }

  ngOnInit(): void {


    this.formGroup.addControl('faa_powerplant_certificate', new FormControlCustom<string>({}, {
      value: '',
      disabled: true
    }, [Validators.required]));

    this.formGroup.addControl('professional_pilot_program', new FormControlCustom<string>({required: 'Indicate if you hold an FAA Commercial Pilot Certificate (or Equivalent)'}, {
      value: '',
      disabled: true
    }, [Validators.required]));

    this.formGroup.addControl('additional_flight_training', new FormControlCustom<string>({required: 'Select a response regarding additional flight training'}, {
      value: '',
      disabled: true
    }, [Validators.required]));


    this.formGroup.addControl('faa_location', new FormControlCustom<string>({required: 'Select a Flight school location'}, {
      value: '',
      disabled: true
    }, [Validators.required]));


    this.formGroup.addControl('mais_12_hour_select', new FormControlCustom<string>({required: 'Select an area of study (12h)'}, {
      value: '',
      disabled: true
    }, [Validators.required]));

    this.formGroup.addControl('mais_9_hour_select', new FormControlCustom<string>({required: 'Select an area of study (9h)'}, {
      value: '',
      disabled: true
    }, [Validators.required]));

    this.formGroup.addControl('indr_12_hour_select', new FormControlCustom<string>({required: 'Select an area of study (12h)'}, {
      value: '',
      disabled: true
    }, [Validators.required]));

    this.formGroup.addControl('indr_18_hour_select', new FormControlCustom<string>({required: 'Select an area of study (18h)'}, {
      value: '',
      disabled: true
    }, [Validators.required]));


    this.formService.prefillForm.subscribe(() => {
      this.maybeEnableControls(this.selectedProgramCode);
      this.isEducationLeadership();
    });

    this.formGroup.get('professional_pilot_program').valueChanges
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe(val => {
      if (val === 'No') {
        this.formGroup.get('additional_flight_training').setValue('');
      }
    });

    this.formGroup.addControl('flight_school_selection', new FormControl({ value: '', disabled: true }));

    this.formGroup.addControl('rn_license', new FormGroup({}));
    this.formGroup.get('rn_license').disable();

    this.formGroup.addControl('nursing_license_number', new FormControlCustom({ required: 'Enter your nursing license number' }, {
      value: '',
      disabled: true
    }, [Validators.required]));
    this.formGroup.get('nursing_license_number').enable();

    this.formGroup.addControl('nursing_license_state', new FormControlCustom({ required: 'Enter your nursing license state' }, {
      value: '',
      disabled: true
    }, [Validators.required]));
    this.formGroup.get('nursing_license_state').enable();

    this.formGroup.addControl('aprn_license', new FormGroup({}));
    this.formGroup.get('aprn_license').disable();

    this.formGroup.addControl('education_leadership_license', new FormGroup({}));

    if (this.selectedProgram && this.selectedProgram.programCode) {
      this.selectedProgramCode = this.selectedProgram.programCode;
      this.maybeEnableControls(this.selectedProgramCode);
    }

    this.selectedFlightSchool = new FlightSchool();
    this.selectedFlightSchool.attributeCode = '';

    this.selectedProgramCode = this.selectedProgram ? this.selectedProgram.programCode : '';

    this.highlightedFlightSchool.attributeCode = ''; // need to add a blank one on init

    const formControls = this.formGroup.controls;
    Object.keys(formControls).forEach((key: string) => {
      if (key !== 'faa_location' && key !== 'nursing_license_number' && key !== 'nursing_license_state' && key !== 'education_leadership_license') {
        formControls[key].valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
          this.maybeEnableControls(this.selectedProgramCode);
        });
      }
    });

    this.generateMaisOptions();
    this.generateIndrOptions();

    this.formGroup.get('faa_location').valueChanges.subscribe(search => {
      this.flightSchoolSearch = search;
      this.filteredFlightSchools = this.displayedFlightSchools.filter(flightSchool => {
        const lowerSearch = flightSchool.combinedDescription.toLowerCase();
        if (lowerSearch.includes(search.toLowerCase())) {
          return flightSchool;
        }
      });
    });

    this.formGroup.get('mais_12_hour_select').valueChanges
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe(nuVal => {
      this.aosFieldUpdated(nuVal, 'mais12');
    });

    this.formGroup.get('mais_9_hour_select').valueChanges
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe(nuVal => {
      this.aosFieldUpdated(nuVal, 'mais9');
    });

    this.formGroup.get('indr_12_hour_select').valueChanges
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe(nuVal => {
      this.aosFieldUpdated(nuVal, 'indr12');
    });

    this.formGroup.get('indr_18_hour_select').valueChanges
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe(nuVal => {
      this.aosFieldUpdated(nuVal, 'indr18');
    });

    this.programSub = this.programService.getProgramSub().subscribe(program => {
      this.selectedProgram = program;
      // only overwrite the programCode if it has been changed
      this.selectedProgramCode = this.selectedProgram.programCode;
      this.removeCustomQuestions();
      // then re-enable any formControls as needed based on the new program
      this.maybeEnableControls(this.selectedProgramCode);
    });

    this.getAprnCheckGroup().valueChanges
      .pipe(takeUntil(this.endSubscriptions))
      .subscribe(() => {
      if (this.selectedProgramCode === 'CTMH-CTG-D' || this.selectedProgramCode === 'DPMH-DNP-D') {
        this.enableCtrlNoEvent(this.formGroup.get('nursing_license_number'));
        this.enableCtrlNoEvent(this.formGroup.get('nursing_license_state'));
      }
    });

  }

  ngOnDestroy() {
    this.removeCustomQuestions();

    this.formGroup.removeControl('faa_powerplant_certificate');
    this.formGroup.removeControl('professional_pilot_program');
    this.formGroup.removeControl('additional_flight_training');
    this.formGroup.removeControl('faa_location');
    this.formGroup.removeControl('flight_school_selection');
    this.formGroup.removeControl('rn_license_check');
    this.formGroup.removeControl('mais_12_hour_select');
    this.formGroup.removeControl('mais_9_hour_select');

    this.endSubscriptions.next();
    this.endSubscriptions.complete();

    this.programSub.unsubscribe();

  }

  maybeEnableControls(programCode: string) {
    this.disableAllCtrls();

    if (programCode !== '') {
      switch (programCode) {
        case 'AVME-BS-D': // Aviation Maintenance Management
          this.enableCtrlNoEvent(this.formGroup.get('faa_powerplant_certificate'));
          break;

        case 'AVAT-BS-D': // Aviation (Professional Pilot)

          this.generateDisplayedFlightSchools();

          this.enableCtrlNoEvent(this.formGroup.get('professional_pilot_program'));

          if (this.formGroup.get('professional_pilot_program').value === 'Yes') {
            this.enableCtrlNoEvent(this.formGroup.get('additional_flight_training'));
          }

          if ((this.formGroup.get('additional_flight_training').value === 'Yes' &&
            this.formGroup.get('additional_flight_training').value === 'Yes') ||
            (this.formGroup.get('professional_pilot_program').value === 'No' &&
              this.formGroup.get('additional_flight_training').disabled)
          ) {
            this.enableCtrlNoEvent(this.formGroup.get('faa_location'));
          }

          break;

        case 'CTMH-CTG-D':

          this.enableCtrlNoEvent(this.getAprnCheckGroup());

          break;

        case 'DPMH-DNP-D':

          this.enableCtrlNoEvent(this.getAprnCheckGroup());

          break;
        // MAIS 9 and 12 hour AoS options
        case 'INDS-MA-D':
        case 'INDS-MA-R':
          this.enableCtrlNoEvent(this.formGroup.get('mais_12_hour_select'));
          this.enableCtrlNoEvent(this.formGroup.get('mais_9_hour_select'));
          this.maisAllOptionsArray = getMaisAllDisciplineOptions();
          break;

        //  INDR 12 and 18 hour AoS options
        case 'INDR-MA-D':
          this.enableCtrlNoEvent(this.formGroup.get('indr_12_hour_select'));
          this.enableCtrlNoEvent(this.formGroup.get('indr_18_hour_select'));
          this.indrAllOptionsArray = getIndrAllDisciplines();
          break;
        default:
          break;
      }

      // also check for nursing programs by degreePicker property
      let pickerVal = '';
      if (this.selectedProgram.degreePicker) {
        pickerVal = this.selectedProgram.degreePicker;
      } else if (this.selectedProgram.degreePicker) {
        pickerVal = this.selectedProgram.degreePicker;
      }

      if (
        this.selectedProgram && this.selectedProgram.programCode
        && (
          (pickerVal.includes('Nursing') && this.programService.getCampusByProgramCode(programCode) === 'D')
          || (this.selectedProgram.programCode.includes('-DNP-') && this.programService.getCampusByProgramCode(programCode) === 'R')
        )
      ) {
        this.enableCtrlNoEvent(this.getRnCheckGroup());
        // Adding these explicit formcontrols to be enabled to fix the disabling issue on prefill
        this.enableCtrlNoEvent(this.formGroup.get('nursing_license_number'));
        this.enableCtrlNoEvent(this.formGroup.get('nursing_license_state'));
      }

    }
  }

  disableAllCtrls() {
    const formControls = this.formGroup.controls;
    Object.keys(formControls).forEach((key: string) => {
      this.disableCtrlNoEvent(formControls[key]);
    });
  }

  disableCtrlNoEvent(formControl: AbstractControl) {
    formControl.disable({
      onlySelf: true,
      emitEvent: false
    });
    this.cdr.detectChanges();
  }

  enableCtrlNoEvent(formControl: AbstractControl) {
    formControl.enable({
      onlySelf: true,
      emitEvent: false
    });
    this.cdr.detectChanges();
  }

  public isEnabled(formControl: AbstractControl) {
    return formControl.enabled;
  }

  public generateMaisOptions() {
    const mais9HrVal = this.formGroup.get('mais_9_hour_select').value;
    const mais12HrVal = this.formGroup.get('mais_12_hour_select').value;
    let alreadyIn9Hour = false;
    let alreadyIn12Hour = false;

    this.maisAllOptionsArray.forEach(disciplineGroup => {
      disciplineGroup.disabledFor9 = false;
      disciplineGroup.disabledFor12 = false;
      const programs = disciplineGroup.programs;

      // first check to see if the values are selected by on of the dropdowns, and those codes to the already in 12/9 props
      alreadyIn9Hour = programs.some(item => mais9HrVal === item.nineCode);
      alreadyIn12Hour = programs.some(item => mais12HrVal === item.twelveCode);

      programs.forEach(program => {
        let specialSkip9 = false;
        let specialSkip12 = false;
        const { nineCode, twelveCode } = program;

        // These are the special cases outlined
        if (nineCode === 'G09C' && mais12HrVal === 'G12B' || nineCode === 'G09B' && mais12HrVal === 'G12C') { specialSkip9 = true; }
        if (twelveCode === 'G12C' && mais9HrVal === 'G09B' || twelveCode === 'G12B' && mais9HrVal === 'G09C') { specialSkip12 = true; }

        // Check for special skip cases and disable accordingly
        (specialSkip9) ? program.disabledFor9 = true : program.disabledFor9 = false;
        (specialSkip12) ? program.disabledFor12 = true : program.disabledFor12 = false;

        // Now use the properties defined above to disable the appropriate fields
        if (alreadyIn12Hour) { disciplineGroup.disabledFor9 = true; }
        if (alreadyIn9Hour) { disciplineGroup.disabledFor12 = true; }

        // Disable the program if it doesn't contain a 12 or 9 code for that side
        if (!program.nineCode) { disciplineGroup.disabledFor9 = true; }
        if (!program.twelveCode) { disciplineGroup.disabledFor12 = true; }

      });
    });
  }

  public generateIndrOptions() {
    const indr12HrVal = this.formGroup.get('indr_12_hour_select').value;
    const indr18HrVal = this.formGroup.get('indr_18_hour_select').value;
    let alreadyIndr12Hour = false;
    let alreadyIndr18Hour = false;

    this.indrAllOptionsArray.forEach(disciplineGroup => {
      disciplineGroup.disabledFor12 = false;
      disciplineGroup.disabledFor18 = false;
      const programs = disciplineGroup.programs;

      // first check to see if the values are selected by one of the dropdowns, and those codes to the already in 12/18 props
      alreadyIndr12Hour = programs.some(item => indr12HrVal === item.twelveCode);
      alreadyIndr18Hour = programs.some(item => indr18HrVal === item.eighteenCode);

      programs.forEach(program => {
        // Now use the properties defined above to disable the appropriate fields
        if (alreadyIndr12Hour) { disciplineGroup.disabledFor18 = true; }
        if (alreadyIndr18Hour) { disciplineGroup.disabledFor12 = true; }

        // Disable the program if it doesn't contain a 12 or 18 code for that side
        if (!program.twelveCode) { disciplineGroup.disabledFor12 = true; }
        if (!program.eighteenCode) { disciplineGroup.disabledFor18 = true; }

      });
    });
  }

  aosFieldUpdated(nuVal: string, type: string) {
    // push the attribute
    this.applicationService.addAttribute(nuVal);

    // refresh the correct list, which will not include the one selected
    if (type === 'mais9' || type === 'mais12') {
      this.generateMaisOptions();
    } else {
      this.generateIndrOptions();
    }
  }

  trackByFn(index, item) {
    if (item.value.constructor.name === 'Array') {
      return item.value;
    } else {
      return item.value.name;
    }
  }

  showFlightSchools() {
    if (this.selectedFlightSchool.attributeCode !== '') {
      this.formGroup.get('faa_location').setValue('');
    }
    this.displayFlightSchools = true;
  }

  public resetFlightSchoolSearch() {
    if (this.selectedFlightSchool.attributeCode !== '') {
      this.formGroup.get('faa_location').setValue(this.selectedFlightSchool.combinedDescription);
    }
    this.displayFlightSchools = false;
    this.flightSchoolSearch = '';
  }

  generateDisplayedFlightSchools() {

    if (!this.flightSchoolsLoaded && this.allFlightSchools.length === 0) {
      this.flightschoolService.generateFlightschools(() => {
        this.flightSchoolsLoaded = true;
        this.allFlightSchools = this.flightschoolService.returnFlightschools();

        if (this.selectedFlightSchool.attributeCode !== '') { // remove the previous one first if it exists
          this.removeFlightSchool();
          this.formGroup.get('flight_school_selection').setValue('');
        }

        this.displayedFlightSchools = [];
        for (const school in this.allFlightSchools) {
          if (Object.prototype.hasOwnProperty.call(this.allFlightSchools, school)) {
            const thisSchool = this.allFlightSchools[school];
            this.displayedFlightSchools.push(thisSchool);
          }
        }
        this.displayedFlightSchools.sort((a, b) => (a.combinedDescription > b.combinedDescription) ? 1 : -1);
        this.filteredFlightSchools = this.displayedFlightSchools;
      });

    }


  }

  public changeSelectFlightSchool(direction: string) {
    // if we have not yet keyed up or down to make an initial selection, select either the very first or last items depending on direction
    if (!this.highlightedFlightSchool) {
      if (direction === 'UP') {
        this.highlightedFlightSchool = this.filteredFlightSchools[this.filteredFlightSchools.length - 1];
      } else if (direction === 'DOWN') {
        this.highlightedFlightSchool = this.filteredFlightSchools[0];
      }
    } else { // we already have an active selection, next to instead find the next/prev adjacent one
      if (direction === 'UP') {
        this.highlightedFlightSchool = this.searchService.findPrev(this.filteredFlightSchools, this.highlightedFlightSchool);
      } else if (direction === 'DOWN') {
        this.highlightedFlightSchool = this.searchService.findNext(this.filteredFlightSchools, this.highlightedFlightSchool);
      }
    }

    // this is a safe way of using document as body is read-only HTMLElement being used only to scroll into view
    document.body.querySelector('#flight-schools-search-results li[data-code="' + this.highlightedFlightSchool.attributeCode + '"]')
      .scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
  }

  public removeFlightSchool() {
    this.formGroup.get('faa_location').setValue('');
    this.formGroup.get('faa_location').markAsPristine();
    this.formGroup.get('faa_location').markAsUntouched();
  }

  public addFlightSchool(specifiedFlightSchoolCode: string, event?: KeyboardEvent | MouseEvent) {
    if (event) {
      event.preventDefault(); // prevents form submission in the case of hitting enter on the search input
    }
    if (this.selectedFlightSchool.attributeCode !== '') { // remove the previous one first
      this.removeFlightSchool();
    }

    if (specifiedFlightSchoolCode === '' && this.filteredFlightSchools.length === 1) {
      specifiedFlightSchoolCode = this.filteredFlightSchools[0].attributeCode;
      this.highlightedFlightSchool = this.filteredFlightSchools[0];
    } else if (specifiedFlightSchoolCode === '') { // then get currently selected school
      specifiedFlightSchoolCode = this.highlightedFlightSchool.attributeCode;
    }
    if (!specifiedFlightSchoolCode) { // if for some reason we still dont have a value, dont do anything
      return false;
    }

    // update the selected flightSchool by code
    this.selectedFlightSchool = this.allFlightSchools.find(thisSchool => thisSchool.attributeCode === specifiedFlightSchoolCode);

    this.formGroup.get('faa_location').setValue(this.selectedFlightSchool.combinedDescription);
    this.formGroup.get('flight_school_selection').setValue(this.selectedFlightSchool);
    this.formGroup.get('faa_location').markAsPristine();

    this.displayFlightSchools = false;
    if (this.searchInput) { // Had to add this if statement otherwise the test would throw an error
      this.searchInput.nativeElement.blur();
    }
  }

  public removeCustomQuestions() {
    if (this.formGroup.get('additional_flight_training')) {
      this.formGroup.get('additional_flight_training').setValue('');
      this.formGroup.get('additional_flight_training').markAsUntouched();
    }

    if (this.getRnCheckbox()) {
      this.getRnCheckbox().setValue(false);
      this.getRnCheckbox().markAsUntouched();
    }

    if (this.formGroup.get('faa_powerplant_certificate')) {
      this.formGroup.get('faa_powerplant_certificate').setValue('');
      this.formGroup.get('faa_powerplant_certificate').markAsUntouched();
    }

    if (this.formGroup.get('professional_pilot_program')) {
      this.formGroup.get('professional_pilot_program').setValue('');
      this.formGroup.get('professional_pilot_program').markAsUntouched();
    }

    if (this.formGroup.get('mais_12_hour_select') && this.formGroup.get('mais_9_hour_select')) {
      if (this.formGroup.get('mais_12_hour_select').value || this.formGroup.get('mais_9_hour_select').value) {
        this.applicationService.removeAttribute(this.formGroup.get('mais_12_hour_select').value);
        this.applicationService.removeAttribute(this.formGroup.get('mais_9_hour_select').value);
        this.formGroup.get('mais_12_hour_select').setValue('');
        this.formGroup.get('mais_9_hour_select').setValue('');
        this.formGroup.get('mais_12_hour_select').markAsUntouched();
        this.formGroup.get('mais_9_hour_select').markAsUntouched();
        this.formGroup.get('mais_12_hour_select').markAsPristine();
        this.formGroup.get('mais_9_hour_select').markAsPristine();
      }
    }

    if (this.formGroup.get('indr_12_hour_select') && this.formGroup.get('indr_18_hour_select')) {
      if (this.formGroup.get('indr_12_hour_select').value || this.formGroup.get('indr_18_hour_select').value) {
        this.applicationService.removeAttribute(this.formGroup.get('indr_12_hour_select').value);
        this.applicationService.removeAttribute(this.formGroup.get('indr_18_hour_select').value);
        this.formGroup.get('indr_12_hour_select').setValue('');
        this.formGroup.get('indr_18_hour_select').setValue('');
        this.formGroup.get('indr_12_hour_select').markAsUntouched();
        this.formGroup.get('indr_18_hour_select').markAsUntouched();
        this.formGroup.get('indr_12_hour_select').markAsPristine();
        this.formGroup.get('indr_18_hour_select').markAsPristine();
      }
    }

  }

  getRnCheckbox(): FormControl {
    // if statement is here because component deconstruction console errors are thrown when other components (like forms) call this in unit tests
    // the error states that the formGroup is undefined, this is a workaround, not sure exactly what the solution is as of now
    if (this.formGroup && this.formGroup.get('rn_license') && this.formGroup.get('rn_license').get('rn_license_check')) {
      return this.formGroup.get('rn_license').get('rn_license_check') as FormControl;
    }
  }

  getRnCheckGroup(): FormGroup {
    return this.formGroup.get('rn_license') as FormGroup;
  }

  getEducationLeadershipGroup(): FormGroup {
    return this.formGroup.get('education_leadership_license') as FormGroup;
  }

  isEducationLeadership(): boolean {
    if (this.formGroup.get('education_leadership_license') != null) {
      if (this.selectedProgram && this.selectedProgram.programCode === 'ASEL-EDD-D') {
        if (this.getEducationLeadershipGroup().disabled) {
          this.getEducationLeadershipGroup().enable({ emitEvent: false });
        }
        return false;
      } else {
        if (this.getEducationLeadershipGroup().enabled) {
          this.getEducationLeadershipGroup().disable({ emitEvent: false });
        }
        return true;
      }
    }
  }

  getAprnCheckbox(): FormControl {
    // if statement is here because component deconstruction console errors are thrown when other components (like forms) call this in unit tests
    // the error states that the formGroup is undefined, this is a workaround, not sure exactly what the solution is as of now
    if (this.formGroup && this.formGroup.get('aprn_license') && this.formGroup.get('aprn_license').get('aprn_license_check')) {
      return this.formGroup.get('aprn_license').get('aprn_license_check') as FormControl;
    }
  }

  getAprnCheckGroup(): FormGroup {
    return this.formGroup.get('aprn_license') as FormGroup;
  }

}

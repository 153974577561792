<ng-container *ngIf="formGroup.get('alcoholRadio').enabled" [formGroup]="formGroup">

  <app-radio
    [label]="'Have you used alcohol, tobacco, or non-medical drugs during the past 12 months?'"
    [radioFormControl]="formGroup.get('alcoholRadio')"
    [radioOpts]="yesNoRadioOpts"
    [errorMessage]="'Select if you have used alcohol, tobacco, or non-medical drugs'">
  </app-radio>

  <app-textbox *ngIf="formGroup.get('alcoholRadio').value === 'Yes'" [formGroup]="formGroup"
    [nameForControl]="'textbox_alcohol'" [requiredErrorMessage]="'Provide information on substances used'"
    [required]="true" [textboxHeader]="textboxHeader"
    [textboxError]="'Provide the details asked for above regarding alcohol, tobacco, or non-medical drugs'">
  </app-textbox>

</ng-container>

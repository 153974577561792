import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullAddressComponent } from './full-address.component';
import { UsResidentRadioComponent } from '../../us-resident-radio/us-resident-radio.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchableSelectDropDownModule } from '../../generic/searchable-select-drop-down/searchable-select-drop-down.module';
import { PlacesAutocompleteModule } from './places-autocomplete/places-autocomplete.module';
import { GenericInputModule } from '../../generic/generic-input.module';
import { DirectivesModule } from 'src/app/shared/directive/directives.module';


@NgModule({
  declarations: [
    FullAddressComponent,
    UsResidentRadioComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DirectivesModule,
    SharedModule,
    GenericInputModule,
    SearchableSelectDropDownModule,
    PlacesAutocompleteModule,
  ],
  exports: [
    FullAddressComponent,
    UsResidentRadioComponent,
  ]
})
export class FullAddressModule { }

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Term } from 'src/app/shared/model/term.model';

@Component({
  selector: 'app-race-select',
  templateUrl: './race-select.component.html',
  styles: ['.ra-select-container {max-width: 340px;}']
})
export class RaceSelectComponent {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  raceOptions: Term[] = [
    { value: 'AI', name: 'American Indian or Alaska Native' },
    { value: 'AS', name: 'Asian' },
    { value: 'AF', name: 'Black or African American' },
    { value: 'HO', name: 'Hispanic or Latino' },
    { value: 'PI', name: 'Native Hawaiian or Pacific Islander' },
    { value: 'WT', name: 'White' },
    { value: 'RF', name: 'Prefer not to say' }
  ];

  constructor(
  ) { }

}

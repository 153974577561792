<div class="summary-wrapper mb-4">
  <div class="degree summary-container">
    <div><b>{{programLocation}} &mdash; {{selectedProgram.level}}</b></div>
    <div>{{selectedProgram.degree}}</div>
    <div>{{selectedProgram.displayDescription}}</div>
    <div
      (click)="navigationService.emitActiveSectionNext('yourDegree')"
      (keydown.enter)="navigationService.emitActiveSectionNext('yourDegree')"
      class="edit"
      role="link"
      tabindex="0"
      aria-label="Click to edit your degree selection on step 1"
    >
      <app-icon class="icon" aria-hidden="true" [icon]="'edit'"></app-icon>
      <span aria-hidden="true">Edit</span>
    </div>
  </div>
  <div *ngIf="application" class="personal-info summary-container">
    <div><b>{{application.firstName}} {{application.lastName}}</b></div>
    <div id="summary_email" *ngIf="application.emails.length > 0">{{application.emails[0].emailAddress}}</div>
    <div *ngIf="application.phones.length > 0 && !application.phones[0].access">{{ '(' + application.phones[0].area + ') ' + application.phones[0].phoneNumber.slice(0, 3) + '-' + application.phones[0].phoneNumber.slice(-4) }}</div>
    <div *ngIf="application.phones.length > 0 && application.phones[0].access">{{ application.phones[0].access }}</div>
    <div
      (click)="navigationService.emitActiveSectionNext('personalInformation')"
      (keydown.enter)="navigationService.emitActiveSectionNext('personalInformation')"
      class="edit"
      role="link"
      tabindex="0"
      aria-label="Click to edit your personal information on step 3"
    >
      <app-icon class="icon" aria-hidden="true" [icon]="'edit'"></app-icon>
      <span aria-hidden="true">Edit</span>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-agent-user',
  templateUrl: './agent-user.component.html',
  styleUrls: ['./agent-user.component.scss']
})
export class AgentUserComponent {

  constructor(
    public authService: AuthenticationService
  ) { }

}

<ng-container [formGroup]="formGroup">

  <legend *ngIf="fieldsetLegend" class="mb-3"><b>{{fieldsetLegend}}</b></legend>

  <div class="row">
    <div class="col-sm-3 col-md-4 form-control">
      <div class="relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('firstName'))">
        </div>
        <label>
          <span class="input-label-text">First Name</span>
          <input type="text" name="firstName" appTrim formControlName="firstName"
            (blur)="firstNameNuVal.emit($event.target.value)"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('firstName')) ? 'true' : null"
            aria-describedby="fname-error">
        </label>

        <span class="error" id="fname-error" *ngIf="formService.hasErrors(formGroup.get('firstName'))">Enter your first
          name</span>
      </div>
    </div>

    <div class="col-sm-3 col-md-4 form-control">
      <div class="relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('middleName'))">
        </div>
        <label for="middleName">
          <span class="input-label-text">Middle Name</span>
          <input type="text" appTrim name="middleName" id="middleName" formControlName="middleName">
        </label>

        <span class="error" *ngIf="formService.hasErrors(formGroup.get('middleName'))">Enter your middle name</span>
      </div>
    </div>

    <div class="col-sm-3 col-md-4 form-control">
      <div style="position: relative">
        <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('lastName'))">
        </div>
        <label>
          <span class="input-label-text">Last Name</span>
          <input type="text" name="lastName" appTrim formControlName="lastName"
            (blur)="lastNameNuVal.emit($event.target.value)"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('lastName')) ? 'true' : null"
            aria-describedby="lname-error">
        </label>

        <span class="error" id="lname-error" *ngIf="formService.hasErrors(formGroup.get('lastName'))">Enter your last
          name</span>
      </div>

    </div>

  </div>

</ng-container>

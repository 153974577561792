import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import init from '@elastic/apm-rum';

@Directive({
  selector: '[appNumbersOnly][formControlName]'
})
export class NumbersOnlyDirective {

  constructor(
    private _el: ElementRef,
    @Optional() private ngControl: NgControl
  ) {
  }

  // Prevent improper characters from hitting the form control
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home'];
    const isControlKey = (event.ctrlKey || event.metaKey);
    const isNumericKey = !isNaN(Number(event.key));

    // Allow shift keyboard operations and Selection/CopyPaste Commands
    if (allowedKeys.includes(event.key) || (isControlKey && ['a', 'c', 'x', 'v'].includes(event.key))) {
      return;
    }

    // Otherwise, block non-numeric characters from being keyed
    if (!isNumericKey || event.shiftKey) {
      event.preventDefault();
    }
  }

  // If somehow an invalid character hits the form control, sanitize it of any non-numerical values
  @HostListener('input', ['$event']) onInputChange(event) {
    const value = this._el.nativeElement.value;
    if (/[^0-9]*/g.test(value.trim())) {
      const newValue = value.replace(/[^0-9]*/g, '').trim();
      this.ngControl.control.setValue(newValue);
    }
    }
}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-optional-essay-questions',
  templateUrl: './optional-essay-questions.component.html',
  styles: ['.essayHeading {margin-bottom: 0}']
})

export class OptionalEssayQuestionsComponent {

  @Input() formGroup = new FormGroup({});

  beliefHeader = 'In 100-150 words, describe how your personal faith and beliefs contribute to your college search process.';
  beliefExample = 'Examples include reasons for choosing Liberty, background information about your personal faith and beliefs, or other information you deem relevant.';

  missionHeader = 'Liberty’s mission is to develop Christ-centered leaders. In 100-150 words, describe how you will contribute to this mission as a Liberty student.';
  missionExample = 'Examples include interests in church or community service, personal achievements or goals, or other examples of what you hope to achieve as a student.';

  constructor(
  ) { }



}

import { Injectable } from '@angular/core';
import { PickerNode } from '../model/picker-node.model';
import { FormService } from './form.service';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { first, map, retry, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


/**
 * Service for handling tree data operations, including retrieval and caching.
 *
 * (Root) = Area of Study
 * (Branch) = Major Group
 * (Leaf) = Program
 * Please note that a Program is always a leaf because even if it has a cognate or doesnt, its still the endpoint of a Tree.
 *
 * Properties of Roots        Properties of Branches          Properties of Leafs
 * - has Children             - has Children                  - has no Children
 * - is never a child         - is a child of a Root          - is a Child of a Root or a Branch
 * - is always expanded       - is expandable                 - is not expandable
 *                            - always has a short code       - sometimes has a shortCode
 *
 * ++++:Example:++++
 * --+ (Area) Arts and Humanities (root)
 *   |---+ (Group) Literature and Languages (branch)
 *   |   |- (Program w/ cognate) English (leaf)
 *   |   |- (Program w/ cognate) French (leaf)
 *   |--- (Program w/o cognate) Philosophy (leaf)
 *   |---+ (Group) History (branch)
 *       |- (Program w/ cognate) American History (leaf)
 */
@Injectable({
  providedIn: 'root',
})
export class TreeDataService {

  public campusLevelTreePickerNodes: Array<PickerNode> = Array<PickerNode>();
  private cache: { [key: string]: PickerNode[] } = {};
  private CASAS = 'College of Applied Studies and Academic Success';

  private currentPickerTreeSub = new BehaviorSubject<PickerNode[]>([]);

  public currentCampus: string = this.formService.getCurrentCampus();
  public currentLevel: string = this.formService.getCurrentLevel();

  constructor(private formService: FormService, private http: HttpClient) {

    this.formService.updatedCampus.subscribe(newCampus => {
      if (newCampus === this.currentCampus) { // dont do anything if value is the same
        return;
      }

      this.currentCampus = newCampus;
      this.maybeRequestNewTree();
    });

    this.formService.updatedLevel.subscribe(newLevel => {
      if (newLevel === this.currentLevel) { // dont do anything if value is the same
        return;
      }

      this.currentLevel = newLevel;
      this.maybeRequestNewTree();
    });

  }

  getCurrentPickerTreeSub(): Observable<PickerNode[]> {
    return this.currentPickerTreeSub.asObservable();
  }

  currentPickerTreeSubNext(newTree: PickerNode[]): void {
    this.currentPickerTreeSub.next(newTree);
  }

  maybeRequestNewTree() {
    if (this.currentCampus?.length > 0 && this.currentLevel?.length > 0) {
      this.requestCampusLevelTree(this.currentCampus, this.currentLevel);
    }
  }

  requestCampusLevelTree(currentCampus: string, currentLevel: string) {

    const cacheKey = `${currentCampus}-${currentLevel}`;
    const cache = this.retrieveFromCache(cacheKey);
    if (cache) {
      // If data is already in local memory, return it
      this.currentPickerTreeSubNext(cache);

    } else {

      this.http.get<PickerNode[]>(`/rest/programs/tree/${currentCampus}/${currentLevel}`)
        .pipe(
          switchMap(tree => of(tree)),
          first(),
          retry({ delay: 2000 }),
          map(responseTree => {
            if (!environment.isAgent) {
              const indexOfCASAS = responseTree.findIndex(node => node.name === this.CASAS);
              if (indexOfCASAS !== -1 && currentLevel === 'Bachelor') {
                const CasasArea = responseTree[indexOfCASAS];
                CasasArea.children = CasasArea.children.filter(node => node.programCode !== 'SPCU-BS-R');
              } else if (indexOfCASAS !== -1 && currentLevel === 'Master') {
                responseTree.splice(indexOfCASAS, 1);
              }
            }

            // this is critical here, adds it to local memory for future use
            this.addToCache(cacheKey, responseTree);
            return responseTree;
          })
        )
        .subscribe({
          next: (res) => {
            this.currentPickerTreeSubNext(this.retrieveFromCache(cacheKey));
          }
        });

    }


  }


  /**
   * Retrieves a tree from the cache based on the provided key.
   * @param key - The cache key.
   * @returns The cached tree of PickerNodes, or undefined if not found in the cache.
   */
  public retrieveFromCache(key: string): PickerNode[] | undefined {
    return this.cache[key];
  }

  /**
   * Adds a tree to the cache based on the provided key.
   * @param key - The cache key.
   * @param value - The tree of PickerNodes to be cached.
   */
  public addToCache(key: string, value: PickerNode[]): void {
    this.cache[key] = value;
  }
}

import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ModalService} from '../shared/service/modal.service';
import {ApplicationService} from 'src/app/shared/service/application.service';
import { Subject } from 'rxjs';
import {DomService} from '../shared/service/dom.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  content: string;
  isModalDisplayed = false;
  focusableEls: Array<HTMLElement> = [];
  focusableSelector = 'a[href], area[href], input:not([tabindex="-1"]) input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

  @ViewChild('modalContainer') modalContainer: ElementRef;
  endSubscriptions = new Subject<void>();

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (this.modalContainer) {
      this.focusableEls = this.modalContainer.nativeElement.querySelectorAll('button:not([disabled])');
      const overlay = this.domService.selectAllElementsAsRoot('.modalOverlay');

      if (overlay.length > 0 && overlay[0] === btn) {
        this.focusableEls[0].focus();
      }
    }

  }

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    if (this.modalContainer.nativeElement) {
      this.focusableEls = this.modalContainer.nativeElement.querySelectorAll(this.focusableSelector);
      if (e.shiftKey && e.keyCode === 9) {
        if (e.target === this.focusableEls[0]) {
          this.focusableEls[this.focusableEls.length - 1].focus();
          e.preventDefault();
        }
      } else if (e.keyCode === 9) {
        if (e.target === this.focusableEls[this.focusableEls.length - 1]) {
          this.focusableEls[0].focus();
          e.preventDefault();
        }
      }
    }
  }

  constructor(
    public modalService: ModalService,
    public applicationService: ApplicationService,
    public elm: ElementRef,
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public domService: DomService
  ) {
  }

  ngOnInit(): void {
    this.modalService.emitModalContent.subscribe((modalContent: string) => {
      this.content = modalContent;
      this.showModal();
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  async showModal() {
    this.isModalDisplayed = true;
    this.cdr.detectChanges();
    const root = document.documentElement;
    this.renderer.addClass(root, 'modal-open');

    if (this.modalContainer) {

      this.focusableEls = this.modalContainer.nativeElement.querySelectorAll(this.focusableSelector);
      if (this.focusableEls.length > 0) {
        this.focusableEls[0].focus();
      } else {
        this.modalContainer.nativeElement.focus();
      }
    }

  }

  async closeModal() {
    this.isModalDisplayed = false;
    const root = document.documentElement;
    this.renderer.removeClass(root, 'modal-open');
    if (this.elm.nativeElement.querySelector('.institutional_agreement')) { // at this point the IA radio should interact with its own modal component rather than the generic modal
      await this.elm.nativeElement.querySelector('.institutional_agreement').focus();
    }
  }

  async acceptAndClose() {
    this.closeModal();
    if (this.modalService.closeButtonCallback) {
      this.modalService.closeButtonCallback();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (this.isModalDisplayed) {
      this.closeModal();
    }
  }

}

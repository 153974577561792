<app-modal></app-modal>
<app-get-help-modal></app-get-help-modal>
<app-resume-modal></app-resume-modal>
<app-degree-picker-modal></app-degree-picker-modal>
<app-welcome-back-modal></app-welcome-back-modal>
<app-search-modal></app-search-modal>
<app-submission-modal></app-submission-modal>
<app-student-resume-modal></app-student-resume-modal>
<app-otp-modal></app-otp-modal>

<div id="main-content">
  <div class="lg-flex">
    <app-sidebar-component></app-sidebar-component>

    <div style="width: 100%">

      <app-login-banner></app-login-banner>

      <div class="d-flex dt-flex--ReverseRow" style="width: 100%">
        <app-right-sidebar id="right-sidebar" class="col mobile-hide"></app-right-sidebar>
        <div class="main-content-wrapper">
          <router-outlet name="form"></router-outlet>
        </div>
      </div>
    </div>

  </div>

  <div class="mobile-show">
    <div class="col text-center my-3">
      <small>
        Copyright &copy; Liberty University · <a href="https://www.liberty.edu/offices/privacy-policy/"
                                                 target="_blank">Privacy
        Policy</a>
      </small>
    </div>
  </div>

</div>

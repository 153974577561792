export class Program {
  public campCode?: string;
  public cognate?: string;
  public college?: string;
  public crosswalkProgramCode?: string;
  public degree?: string;
  public degreeCode?: string;
  public degreeLevel?: string;
  public degreePicker?: string;
  // tslint:disable-next-line:variable-name
  public degree_picker?: string;
  public displayDescription?: string;
  public displayDescriptionNoDegreeCode?: string;
  public endTerm?: string;
  public exclusionTerms?: string;
  public internationalBanned?: string;
  public level?: string;
  public levelCode?: string;
  public majorGroup?: string;
  public programAttr?: string;
  public programCode?: string;
  public startTerm?: string;
  public terms?: string[];
  public wappCode?: string;

  constructor(
    programCode?: string
  ) {
    this.programCode = programCode;
  }
}

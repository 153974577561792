export class Term {
  public campus: string;
  public termLevel: string;
  public termCode: string;
  public termName: string;
  public termStart: string;
  public termEnd: string;

  constructor(termJson?: {}) {
    if (termJson) {
      Object.assign(this, termJson);
    }
  }

}

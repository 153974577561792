<div tabindex="0"
     (mouseover)="active = true"
     (mouseleave)="active = false"
     (focusin)="active = true; "
     (focusout)="active = false"
     (click)="$event.preventDefault()"
     class="tooltip-component">
  <span class="decorated-text" *ngIf="decoratedText">{{ decoratedText }}</span>
  <div class="tooltip-container">
    <app-icon class="tooltip-icon" aria-label="Tooltip icon" [icon]="'tooltip'"></app-icon>

    <div [ngClass]="active ? 'active' : ''" class="tooltip-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>



import { Component, OnInit, Input } from '@angular/core';

import { FormGroup, Validators } from '@angular/forms';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-agent-notepad',
  templateUrl: './agent-notepad.component.html',
  styleUrls: []
})
export class AgentNotepadComponent implements OnInit {

  @Input() formGroup: FormGroup<any> = new FormGroup({});

  constructor(
    public formService: FormService
  ) { }

  notepadLength = 0;

  ngOnInit(): void {

    this.formGroup.addControl('agent_notepad', new FormControlCustom<string>({}, '', { validators: Validators.required, updateOn: 'blur' }));

    (this.formGroup.controls['agent_notepad'] as FormControlCustom<any>).errorMessages.required = 'Enter comments';

    this.notepadLength = this.formGroup.controls['agent_notepad'].value.length;

    this.formGroup.get('agent_notepad').valueChanges.subscribe(val => {
      this.validateNotepad(val);
    });

  }

  validateNotepad(val: string) {
    this.notepadLength = val.length;
    if (this.formGroup.status === 'VALID' || this.notepadLength > 0) {
      this.formGroup.markAsPristine();
      this.formGroup.get('agent_notepad').markAsPristine();
    } else {
      this.formGroup.markAsDirty();
      this.formGroup.get('agent_notepad').markAsDirty();
    }
  }


}

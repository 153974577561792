<div [formGroup]="formGroup">
  <app-radio
    [hidden]="formGroup.get('financial_aid').disabled"
    [label]="'Do you plan on using Federal Financial Aid (FAFSA)?'"
    [radioFormControl]="formGroup.get('financial_aid')"
    [radioOpts]="yesNoRadioOpts"
  >
  </app-radio>

</div>

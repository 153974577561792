import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ProgramStateDisclosure} from 'src/app/shared/model/applyLUApiObjects/program-state-disclosure.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramStateDisclosureService {
  private static readonly API_BASE_URL = '/rest/programstatedisclosure';

  constructor(
    private http: HttpClient,
  ) { }

  requestMade = new Subject();

  public getProgramStateDisclosure = (programCode: string, stateCode: string) => new Promise<ProgramStateDisclosure>(resolve => {
    this.requestMade.next(undefined);

    this.http.get<ProgramStateDisclosure>(`${ProgramStateDisclosureService.API_BASE_URL}/${stateCode}/${programCode}`)
    .pipe(
      switchMap((disclosure: ProgramStateDisclosure) => {
        return of(disclosure).toPromise();
      }),
      debounceTime(1000),
      distinctUntilChanged(), // this will prevent updates being sent to the API if the form value isn't changed
      takeUntil(this.requestMade), // this will cancel the request if it's made again, preventing duplicates and also only returning the most recent one

    ).toPromise().then(res => {
      resolve(res);
    });


  })

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UpayForm } from 'src/app/shared/model/upay-form.model';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent implements OnInit {
  @ViewChild('upayPaymentForm') upayPaymentForm: ElementRef;
  @ViewChild('upaySiteId') upaySiteId: ElementRef;
  @ViewChild('paymentAmount') paymentAmount: ElementRef;
  @ViewChild('extTransId') extTransId: ElementRef;
  @ViewChild('cancelLink') cancelLink: ElementRef;
  @ViewChild('billEmailAddress') billEmailAddress: ElementRef;
  @ViewChild('billStreet1') billStreet1: ElementRef;
  @ViewChild('billStreet2') billStreet2: ElementRef;
  @ViewChild('billCity') billCity: ElementRef;
  @ViewChild('billState') billState: ElementRef;
  @ViewChild('billZip') billZip: ElementRef;
  @ViewChild('billCountry') billCountry: ElementRef;
  @ViewChild('validationKey') validationKey: ElementRef;

  constructor() { }

  ngOnInit(): void {

  }

  public submitForm(upayForm: UpayForm) {
    // Set form input values
    this.upaySiteId.nativeElement.value = upayForm.uPaySiteId;
    this.paymentAmount.nativeElement.value = upayForm.amount.toString();
    this.extTransId.nativeElement.value = upayForm.extTransId;
    this.cancelLink.nativeElement.value = upayForm.cancelLink;
    this.billEmailAddress.nativeElement.value = upayForm.billEmailAddress;
    this.billStreet1.nativeElement.value = upayForm.billStreet1;
    this.billStreet2.nativeElement.value = upayForm.billStreet2;
    this.billCity.nativeElement.value = upayForm.billCity;
    this.billState.nativeElement.value = upayForm.billState;
    this.billZip.nativeElement.value = upayForm.billPostalCode;
    this.billCountry.nativeElement.value = upayForm.billCountry;
    this.validationKey.nativeElement.value = upayForm.validationKey;

    // Set form action to the uPay URL
    this.upayPaymentForm.nativeElement.action = upayForm.uPayUrl;

    // Append form to the document body to avoid "Form submission canceled because the form is not connected".
    // See https://stackoverflow.com/questions/42053775/getting-error-form-submission-canceled-because-the-form-is-not-connected.
    // this is a safe way of using document as body is read-only HTMLElement
    document.body.appendChild(this.upayPaymentForm.nativeElement);

    // Submit the form to navigate to uPay
    this.upayPaymentForm.nativeElement.submit();
  }

}

<ng-container [formGroup]="getFormControlParent()">
  <div class="year">
    <div class="form-control use-has-errors-validation">
      <div class="validation-error-bar" *ngIf="formService.hasErrors(itemFormControl)"></div>
      <div class="select-input-wrap">
        <input
          (input)="onYearInput($event.target.value)"
          [ngClass]="{'has-errors': formService.hasErrors(itemFormControl)}"
          appNumbersOnly
          type="text"
          inputmode="numeric"
          placeholder="YYYY"
          name="{{getFormControlName()}}"
          formControlName="{{getFormControlName()}}"
          maxlength="4"
          minlength="4"
          [attr.aria-label]="ariaLabel"
          [attr.aria-invalid]="formService.hasErrors(itemFormControl) ? 'true' : null"
          [attr.aria-describedby]="formService.hasErrors(itemFormControl) ? getFormControlName()+'-error' : null"
        >
      </div>
      <span class="error" id="{{getFormControlName()}}-error" role="dialog" *ngIf="formService.hasRequiredError(itemFormControl)">A year is required</span>
      <span class="error" id="{{getFormControlName()}}-error" role="dialog" *ngIf="formService.hasPatternError(itemFormControl) || (itemFormControl.errors && (itemFormControl.errors.min || itemFormControl.errors.max))">A valid year is required</span>
    </div>
  </div>
</ng-container>

<div class="sidebarComponent mobile-hide">
  <div>
    <app-sidebar-logo></app-sidebar-logo>
    <app-sidebar-menu></app-sidebar-menu>
  </div>
  <div>
    <app-sidebar-footer></app-sidebar-footer>
  </div>
</div>

<app-mobile-menu class="mobile-show"></app-mobile-menu>


<ng-container *ngIf="isModalDisplayed">
  <dialog class="resume modal-container" aria-modal="true" role='alertdialog' #modalContainer>
    <div class="modalContent">
      <h2>Welcome back{{applicantName}}!</h2>
      <p>You started your application on {{applicantStartDate}}</p>
      <div class="completion-bar">
        <p>{{applicantPercentComplete}}% of application completed</p>
        <div class="sidebar--progress">
          <div class="progress--bar" [style.width]="applicantPercentComplete + '%'"></div>
        </div>
      </div>
      <div class="btn-container">
        <button class="start-button btn-link" tabindex="0" (click)="startNewApp()" (keyup.enter)="startNewApp()"> Start
          New App
        </button>
        <button class="btn" (click)="prefillApplication()"> Continue Previous App
          <app-icon class="continueArrow" aria-hidden="true" [icon]="'side-white-arrow'"></app-icon>
        </button>
      </div>
    </div>
  </dialog>
  <div class="modalOverlay" tabindex="-1"></div>
</ng-container>

import { Component } from '@angular/core';
import { EIconType } from 'src/app/shared/component/icon/icon.component';

@Component({
  selector: 'app-continue-button',
  templateUrl: './continue-button.component.html'
})
export class ContinueButtonComponent {

  public eIconTypes: typeof EIconType = EIconType;

  constructor() { }

}

export enum EmailType {
  TRANSCRIPT = 'TRANSCRIPT', ESSAY = 'ESSAY' , GET_HELP = 'GET_HELP', API_ERROR = 'API_ERROR', ADDITIONAL_DOCS = 'ADDITIONAL_DOCS', TEST = 'TEST', UNKNOWN = 'UNKNOWN'
}

export enum Origin {
  LU = 'LU', API = 'API', TEST = 'TEST'
}

export class EmailMessage {
    public static readonly ADMISSION = 'admissions@liberty.edu';
    public static readonly ADMISSION_LUO = 'luoadmissions@liberty.edu';
    public static readonly ADMISSION_DOCS_RES = 'admissionsdocs@liberty.edu';
    public static readonly SAT = 'appdevsat@liberty.edu';
    public static readonly NO_REPLY = 'noreply@liberty.edu';
    public static readonly ADMISSION_DOCS_ONLINE = 'luoverify@liberty.edu';

    fromAddress: string;
    toAddress: string;
    replyToAddress: string;
    subject: string;
    messageBody: string;
    origin: Origin;
    emailType: EmailType;

    constructor(fromAddress?: string, toAddress?: string,  replyToAddress?: string, subject?: string, body?: string, emailType?: EmailType) {
        this.fromAddress = fromAddress;
        this.toAddress = toAddress;
        this.subject = subject;
        this.replyToAddress = replyToAddress;
        this.messageBody = body;
        this.emailType = emailType;
        this.origin = Origin.LU;
    }

}

<form class="email" [formGroup]="getHelpEmailForm">
  <div class="submitted-message" *ngIf="submittedHelp">
      <h3>Thank You, {{getHelpEmailForm.get('fullName').value}}! We will reach out to you soon with assistance. Please monitor your email.</h3>
  </div>
  <div class="email-form-body" *ngIf="!submittedHelp">

    <app-radio
      [radioOpts]="campusRadioOpts"
      [radioFormControl]="getHelpEmailForm.get('get_help_campus')"
      [label]="'Which campus are you applying for?'"
      [errorMessage]="'Select a campus'"
    >
    </app-radio>

    <hr>
    <div class="row">
      <div class="form-control mb-1 col-md-6">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(getHelpEmailForm.get('fullName'))"></div>
          <label for="fullName">
              <span class="input-label-text">Full Name</span>
              <input class="" type="text" name="fullName" id="fullName" formControlName="fullName">
          </label>
          <span class="error" *ngIf="formService.hasRequiredError(getHelpEmailForm.get('fullName'))">Enter a first name</span>
      </div>
      <div class="form-control col-md-6">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(getHelpEmailForm.get('emailAddress'))">
          </div>
          <label for="emailAddress">
              <span class="input-label-text">Email Address</span>
              <input class="" type="text" [maxlength]="128" name="emailAddress" id="emailAddress" formControlName="emailAddress">
          </label>
          <span class="error" *ngIf="formService.hasRequiredError(getHelpEmailForm.get('emailAddress'))">Enter an email</span>
          <span class="error" *ngIf="formService.hasPatternError(getHelpEmailForm.get('emailAddress'))">Please enter a valid email</span>
      </div>
    </div>
    <hr>

    <app-radio
      [radioOpts]="helpReasonOpts"
      [radioFormControl]="getHelpEmailForm.get('get_help_reason')"
      [label]="'What is your question related to?'"
      [errorMessage]="'Select what type of question you have'"
    >
    </app-radio>

    <app-radio
      *ngIf="isAdmissionQ"
      [radioOpts]="levelRadioOpts"
      [radioFormControl]="getHelpEmailForm.get('degree_level_radio')"
      [errorMessage]="'Select a level'"
      (click)="clearProgramField()"
    >
    </app-radio>

    <div *ngIf="isAdmissionQ && getHelpEmailForm.get('get_help_campus').value !== '' && getHelpEmailForm.get('degree_level_radio').value !== ''">

      <app-searchable-select-drop-down
        [itemFormControl]="getHelpEmailForm.get('degree_search')"
        [itemList]="programsByCampusLevel[getHelpEmailForm.get('get_help_campus').value][getHelpEmailForm.get('degree_level_radio').value]"
        [displayProperty]="'displayDescription'"
        [optionValueProperty]="'displayDescription'"
        [allowCustom]="true"
        [placeholder]="'Search Programs'"
        [floatingResults]="false"
        [initItem]="initProgram"
        [autoComplete]="'off'"
        (emitedItem)="selectProgram($event)"
      ></app-searchable-select-drop-down>

      <app-alert style="display: block;" class="mb-3" [goFullWidth]="true">Undecided? That's okay too. Just let us know so we can best assist you.</app-alert>
    </div>


    <div class="form-control">
      <div class="validation-error-bar" *ngIf="messageLengthValidation"></div>
      <p class="textbox-p">Enter message here:</p>
      <app-textbox class="textbox" [formGroup]="getHelpEmailForm" [nameForControl]="'get_help_message'" [required]="true" (textboxEmmitter)="checkLength($event)">
      </app-textbox>
      <span class="error" *ngIf="messageLengthValidation">This field has a minimum length of 5 characters</span>
  </div>
    <div class="error mb-3" *ngIf="this.sendingError">
      Something went wrong when sending your request.
      <br> Please email your request to {{toAddress}}
    </div>
    <div class="get-help-submit">
        <button type="button" [attr.disabled]="disableHelpSubmit ? 'disabled' : null" (click)="submit()">Send</button>

      <div *ngIf="showLoadingAnimation" class="spinner-container">
            <div class="spinner">
                <div></div>
            </div>
        </div>

    </div>
  </div>
</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AcodeSearchComponent } from './acode-search/acode-search.component';
import { AgentNotepadComponent } from './agent-notepad/agent-notepad.component';
import { AgentUserComponent } from './agent-user/agent-user.component';
import { AlcoholRadioComponent } from './alcohol-radio/alcohol-radio.component';
import { ApplicantGoalsComponent } from './applicant-goals/applicant-goals.component';
import { ApplicationOriginComponent } from './application-origin/application-origin.component';
import { BirthDateComponent } from './birth-date/birth-date.component';
import { CellPhoneRadioComponent } from './cell-phone-radio/cell-phone-radio.component';
import { CollegeCertsRadioComponent } from './college-certs-radio/college-certs-radio.component';
import { CollegeCreditsRadioComponent } from './college-credits-radio/college-credits-radio.component';
import { DegreeLevelRadioComponent } from './degree-level-radio/degree-level-radio.component';
import { EmailAddressComponent } from './email-address/email-address.component';
import { EmailDupCheckerComponent } from './email-dup-checker/email-dup-checker.component';
import { FelonRadioComponent } from './felon-radio/felon-radio.component';
import { GenderSelectComponent } from './gender-select/gender-select.component';
import { HearAboutUsDropdownComponent } from './hear-about-us-dropdown/hear-about-us-dropdown.component';
import { InstitutionalAgreementComponent } from './institutional-agreement/institutional-agreement.component';
import { LocationRadioComponent } from './location-radio/location-radio.component';
import { LuidSearchComponent } from './luid-search/luid-search.component';
import { MaritalStatusComponent } from './marital-status/marital-status.component';
import { MilitaryQuestionsComponent } from './military-questions/military-questions.component';
import { NameFieldsComponent } from './name-fields/name-fields.component';
import { ProgramStateDisclosureComponent } from './personal-info/program-state-disclosure/program-state-disclosure.component';
import { ProbationRadioComponent } from './probation-radio/probation-radio.component';
import { RaceSelectComponent } from './race-select/race-select.component';
import { GuardianEmailComponent } from './relative-info/inputs/guardian-email/guardian-email.component';
import { GuardianNameFieldsComponent } from './relative-info/inputs/guardian-name-fields/guardian-name-fields.component';
import { GuardianRelationshipComponent } from './relative-info/inputs/guardian-relationship/guardian-relationship.component';
import { RelativeInfoComponent } from './relative-info/relative-info.component';
import { ReligionSelectComponent } from './religion-select/religion-select.component';
import { StartTermSelectComponent } from './start-term-select/start-term-select.component';
import { TCPARadioComponent } from './tcparadio/tcparadio.component';
import { TshirtComponent } from './tshirt/tshirt.component';
import { EnrollmentDepositRadioComponent } from './enrollment-deposit-radio/enrollment-deposit-radio.component';
import { RefundTermsRadioComponent } from './refund-terms-radio/refund-terms-radio.component';
import { DegreePickerComponent } from './degree-picker/degree-picker.component';
import { PdfComponent } from '../pdf/pdf.component';
import { DegreePickerSelectComponent } from './degree-picker-select/degree-picker-select.component';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { SeekingPlaComponent } from './seeking-pla/seeking-pla.component';
import { PhoneNumberModule } from './phone-number/phone-number.module';
import { GenericInputModule } from './generic/generic-input.module';
import { LoadingRingModule } from 'src/app/shared/component/loading-ring/loading-ring.module';
import { DirectivesModule } from 'src/app/shared/directive/directives.module';
import { TitleIXModule } from './title-ix/title-ix.module';
import { TitleIXComponent } from './title-ix/title-ix.component';
import { DismissalRadioComponent } from './dismissal-radio/dismissal-radio.component';
import { CustomProgramQuestionsModule } from './custom-program-questions/custom-program-questions.module';
import { DismissalRadioModule } from './dismissal-radio/dismissal-radio.module';
import { SchoolSearchModule } from './generic/school-search/school-search.module';
import { CustomProgramQuestionsComponent } from './custom-program-questions/custom-program-questions.component';
import { NameFieldsFirstMidLastModule } from './name-fields/name-fields-first-mid-last/name-fields-first-mid-last.module';
import { NameFieldsFirstMidLastComponent } from './name-fields/name-fields-first-mid-last/name-fields-first-mid-last.component';
import { MiniAlertModule } from 'src/app/alert/mini-alert/mini-alert.module';
import {TreeModule} from '@ali-hm/angular-tree-component';

@NgModule({
  declarations: [
    AcodeSearchComponent,
    AgentUserComponent,
    ApplicationOriginComponent,
    ApplicantGoalsComponent,
    EmailDupCheckerComponent,
    LocationRadioComponent,
    AgentNotepadComponent,
    DegreeLevelRadioComponent,
    StartTermSelectComponent,
    NameFieldsComponent,
    EmailAddressComponent,
    LuidSearchComponent,
    CellPhoneRadioComponent,
    GenderSelectComponent,
    BirthDateComponent,
    MilitaryQuestionsComponent,
    InstitutionalAgreementComponent,
    CollegeCertsRadioComponent,
    TCPARadioComponent,
    HearAboutUsDropdownComponent,
    CollegeCreditsRadioComponent,
    ProgramStateDisclosureComponent,
    RaceSelectComponent,
    TshirtComponent,
    ReligionSelectComponent,
    MaritalStatusComponent,
    AlcoholRadioComponent,
    FelonRadioComponent,
    ProbationRadioComponent,
    RelativeInfoComponent,
    GuardianRelationshipComponent,
    GuardianNameFieldsComponent,
    GuardianEmailComponent,
    EnrollmentDepositRadioComponent,
    RefundTermsRadioComponent,
    DegreePickerComponent,
    DegreePickerSelectComponent,
    PdfComponent,
    SeekingPlaComponent,
  ],
    imports: [
        CommonModule,
        HttpClientModule,
        MiniAlertModule,
        GenericInputModule,
        SchoolSearchModule,
        NameFieldsFirstMidLastModule,
        SharedModule,
        DismissalRadioModule,
        DirectivesModule,
        TitleIXModule,
        IconModule,
        CustomProgramQuestionsModule,
        LoadingRingModule,
        TreeModule
    ],
  exports: [
    AcodeSearchComponent,
    AgentUserComponent,
    ApplicationOriginComponent,
    ApplicantGoalsComponent,
    EmailDupCheckerComponent,
    LocationRadioComponent,
    DegreePickerComponent,
    DegreePickerSelectComponent,
    CustomProgramQuestionsComponent,
    AgentNotepadComponent,
    DegreeLevelRadioComponent,
    TitleIXComponent,
    StartTermSelectComponent,
    NameFieldsComponent,
    NameFieldsFirstMidLastComponent,
    EmailAddressComponent,
    LuidSearchComponent,
    CellPhoneRadioComponent,
    GenderSelectComponent,
    BirthDateComponent,
    MilitaryQuestionsComponent,
    InstitutionalAgreementComponent,
    CollegeCertsRadioComponent,
    TCPARadioComponent,
    HearAboutUsDropdownComponent,
    DismissalRadioComponent,
    CollegeCreditsRadioComponent,
    ProgramStateDisclosureComponent,
    RaceSelectComponent,
    TshirtComponent,
    ReligionSelectComponent,
    MaritalStatusComponent,
    AlcoholRadioComponent,
    FelonRadioComponent,
    ProbationRadioComponent,
    RelativeInfoComponent,
    GuardianEmailComponent,
    GuardianNameFieldsComponent,
    GuardianRelationshipComponent,
    EnrollmentDepositRadioComponent,
    RefundTermsRadioComponent,
    PdfComponent,
    SeekingPlaComponent,
    PhoneNumberModule,
  ]
})
export class InputModule { }

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Program} from 'src/app/shared/model/program.model';
import {DomService} from 'src/app/shared/service/dom.service';
import {ITreeOptions, TreeComponent, TreeNode} from '@ali-hm/angular-tree-component';
import {PickerNode} from 'src/app/shared/model/picker-node.model';
import {ProgramService} from '../../../shared/service/program.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-degree-picker-select',
  templateUrl: './degree-picker-select.component.html',
  styleUrls: ['./degree-picker-select.component.scss'],
})
export class DegreePickerSelectComponent implements OnChanges, OnInit, OnDestroy {
  @Input() tree: Array<PickerNode> = [];
  @Input() formGroup: FormGroup;
  @Input() selectedProgram = new Program();
  @Output() emitSelectedProgram = new EventEmitter<Program>();
  @Output() hasSearched = new EventEmitter<string>();
  @ViewChild('treeRoot') treeComponent: TreeComponent;
  private worker: Worker;
  protected programSearchString = '';
  loading = false;

  options: ITreeOptions = {
    isExpandedField: 'isInitiallyExpanded',
    nodeClass: (node => node.data.className),
    actionMapping: {
      mouse: {
        expanderClick: (model, node, event) => this.onClick(node, event),
        click: (model, node, event) => this.onClick(node, event)
      }
    },
    levelPadding: 10,
  };
  displayedNodes: Array<PickerNode>;
  noMatch: boolean;

  constructor(
    public domService: DomService,
    public cdr: ChangeDetectorRef,
    private programService: ProgramService,
  ) {
    this.selectedProgram.programCode = '';
  }

  ngOnInit() {
    this.displayedNodes = this.tree ? this.tree : null;
    // Create a worker for tree-data. This will help sort without holding up the thread for the DOM
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../../../shared/worker/tree-data.worker.ts', import.meta.url), {type: 'module'});
      this.worker.onmessage = ({data}) => {
        this.displayedNodes = data;
        this.noMatch = data.length === 0;
        this.collapseOrExpandGroups();
        this.loading = false;
        this.cdr.detectChanges();
        this.hasSearched.emit(this.programSearchString);
      };

      this.formGroup.controls['programSearchString'].valueChanges.pipe(debounceTime(300)).subscribe(value => {
        this.onSearchInput(value);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tree?.currentValue) {
      this.displayedNodes = changes.tree?.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  onSearchInput(value: string) {
    this.loading = true;
    this.cdr.detectChanges();
    this.programSearchString = value || '';
    this.worker.postMessage({tree: this.tree, searchString: value});
  }

  /**
   * Handles keydown events for tree nodes.
   *
   * @param node - The tree node associated with the event.
   * @param $event - The keyboard event.
   */
  onKeyDown(node: TreeNode, $event: KeyboardEvent) {
    switch ($event.key) {
      case 'ArrowDown':
        document.activeElement.classList.remove('lastSelectedNode');
        this.domService.tabIndex($event, 'next');
        if (document.activeElement.classList.contains('tree-node-wrapper')) {
          document.activeElement.classList.add('lastSelectedNode');
        }
        break;
      case 'ArrowUp':
        document.activeElement.classList.remove('lastSelectedNode');
        const elementBeforeTab = document.activeElement;
        this.domService.tabIndex($event, 'previous');
        if (document.activeElement.classList.contains('tree-node-wrapper')) {
          document.activeElement.classList.add('lastSelectedNode');
        } else {
          elementBeforeTab.classList.add('lastSelectedNode');
        }
        break;
      case 'ArrowLeft':
        this.switchToAreaFilter($event);
        break;
      case 'Enter':
        this.onClick(node, $event);
        break;
    }
  }

  onMouseUp($event: MouseEvent) {
    if ($event.button === 0) {
      document.activeElement.classList.add('lastSelectedNode');
    }
  }

  onMouseDown($event: MouseEvent) {
    if ($event.button === 0) {
      const lastSelectedNode = this.treeComponent.viewportComponent['elementRef'].nativeElement.querySelector('.lastSelectedNode');
      lastSelectedNode?.classList.remove('lastSelectedNode');
    }
  }

  /**
   * Updates the state of the selected program.
   *
   * @param unset - If true, unsets the selected program state.
   */
  public updateSelectedProgramState(unset = false) {
    if (unset) {
      this.selectedProgram = new Program();
      this.selectedProgram.programCode = '';
      this.formGroup.get('program').setValue('');
    } else {
      this.formGroup.get('program').setValue(this.selectedProgram.programCode);
    }
    this.cdr.detectChanges();
    this.emitSelectedProgram.emit(this.selectedProgram);
  }

  /**
   * Extracts the name from an HTML element representing a tree node.
   *
   * @param element - The HTML element to extract the name from.
   * @returns The name of the tree node as extracted from the HTML element.
   */
  public getNodeNameFromHTMLElement(element: Element): string {
    return element.querySelector('tree-node-wrapper div.column-name').attributes['title'].nodeValue;
  }

  /**
   * Extracts the degree code from an HTML element representing a tree node.
   *
   * @param element - The HTML element to extract the degree code from.
   * @returns The degree code of the tree node as extracted from the HTML element.
   */
  public getNodeDegreeCodeFromHTMLElement(element: Element): string {
    return element.querySelector('tree-node-wrapper div.column-degreecode').attributes['title'].nodeValue;
  }

  /**
   * Determines the 'aria-expanded' attribute value based on the type and state of the tree node.
   *
   * @param node - The tree node for which to determine the 'aria-expanded' attribute value.
   * @returns The appropriate 'aria-expanded' attribute value ('true', 'false', or null).
   */
  calculateAriaExpanded(node: TreeNode): string {
    // if root or leaf, its always expanded and should not be annouced as expandable
    if (node.data.isRoot || node.data.isLeaf) {
      return null;
    } else {
      // if branch, then announce either state, and if undefined, it hasnt been touched so its false.
      return node.isExpanded?.toString() ?? 'false';
    }
  }

  /**
   * Highlights the search string within the given name.
   *
   * @param name - The original name to be highlighted.
   * @param searchString - The search string used for highlighting.
   * @returns The name with the matched portion highlighted.
   */
  protected highlightSearchString(name: string, searchString: string) {
    if (typeof searchString !== 'string') {
      return name;
    }
    const lowerCaseName = name.toLowerCase();
    const lowerCaseSearchString = searchString.toLowerCase();

    const startIndex = lowerCaseName.indexOf(lowerCaseSearchString);

    if (startIndex !== -1) {
      const highlightedPart = name.substring(startIndex, startIndex + searchString.length);
      const beforeHighlighted = name.substring(0, startIndex);
      const afterHighlighted = name.substring(startIndex + searchString.length);

      return `${beforeHighlighted}<span class="highlight">${highlightedPart}</span>${afterHighlighted}`;
    } else {
      return name;
    }
  }

  /**
   * Handles the click event on a tree node.
   *
   * @param node - The tree node that was clicked.
   * @param ev - The click event.
   */
  private onClick(node: TreeNode, ev) {
    if (!node.data.isRoot) {
      if (node.data.isLeaf) {
        this.chooseProgram(node.data.programCode, node.data.name, ev);
      } else {
        node.toggleExpanded();
      }
    }
  }

  /**
   * Switches to the area filter based on the event triggered.
   *
   * @param event - The event that triggered the switch.
   */
  private switchToAreaFilter(event: Event) {
    const areaToFocus = this.domService.selectElementAsRoot(
      !this.domService.selectElementAsRoot('.lastSelectedArea')
        ? '.degree-picker-container .area-filters > .area-filter'
        : '.lastSelectedArea'
    ) as HTMLElement;

    areaToFocus.focus();
  }

  /**
   * Handles the selection of a program and updates the active state.
   *
   * @param selectedProgramCode - The program Code that is being selected.
   * @param selectedName - The program Name that is being selected.
   * @param event - (Optional) The event that triggered the selection.
   *                If provided, stops event propagation to prevent triggering other actions.
   */
  private async chooseProgram(selectedProgramCode: string, selectedName: string, event?: Event) {
    if (event) {
      event.stopPropagation(); // prevents clicking on a program triggering toggleArea()
    }


    if (this.selectedProgram.programCode === selectedProgramCode && (this.selectedProgram.cognate ?? this.selectedProgram.majorGroup) === selectedName) { // same program, unset the active
      this.updateSelectedProgramState(true);
    } else { // new active program
      // gather the selected program object
      this.selectedProgram = this.programService.getProgramByProgramCodeAndName(selectedProgramCode, selectedName);
      this.updateSelectedProgramState();
    }

    setTimeout(() => {
      // there's a lot going on when a program is selected, preferrable to pause for a moment before attempting to scroll
      // things can finish so that this way the animation is much smoother
      this.domService.scrollIntoView(this.domService.selectElementAsRoot('#picker-anchor'));
    }, 320);

  }

  /**
   * Expands the tree branch associated with the specified HTML element.
   *
   * @param element - The HTML element whose associated tree branch should be expanded.
   */
  private expandElement(element: Element) {
    this.getTreeBranchFromHTMLElement(element).expand();
  }

  /**
   * Collapses the tree branch associated with the specified HTML element.
   *
   * @param element - The HTML element whose associated tree branch should be collapsed.
   */
  private collapseElement(element: Element) {
    this.getTreeBranchFromHTMLElement(element).collapse();
  }

  /**
   * Retrieves a tree branch based on the information extracted from an HTML element.
   *
   * @param element - The HTML element associated with the tree branch.
   * @returns The tree branch that matches the information extracted from the HTML element.
   */
  private getTreeBranchFromHTMLElement(element: Element) {
    const nodeName = this.getNodeNameFromHTMLElement(element);
    const nodeDegreeCode = this.getNodeDegreeCodeFromHTMLElement(element);
    return this.getTreeBranchByNameAndDegreeCode(nodeName, nodeDegreeCode);
  }

  /**
   * Retrieves a tree branch with a specific name and degree code.
   *
   * @param nodeName - The name of the branch to retrieve.
   * @param degreeCode - The degree code associated with the branch.
   * @returns The tree branch that matches the criteria.
   */
  private getTreeBranchByNameAndDegreeCode(nodeName: string, degreeCode: string): TreeNode {
    return this.treeComponent.treeModel.getNodeBy(
      (node: TreeNode) =>
        node.data.name.toLowerCase() === nodeName.toLowerCase()
        && !node.data.isRoot
        && !node.data.isLeaf
        && node.data.shortCode.toLowerCase() === degreeCode.toLowerCase());
  }

  private collapseOrExpandGroups() {
    const selectContainer = this.treeComponent?.viewportComponent['elementRef'].nativeElement;
    if (!selectContainer) {
      console.error('Viewport element is not available');
      return;
    }
    const areas = selectContainer.querySelectorAll('.root');
    areas.forEach((area: HTMLElement) => {
      const groups = area.querySelectorAll('.branch');
      groups.forEach((group: HTMLElement) => {
        this.programSearchString === '' ? this.collapseElement(group) : this.expandElement(group);
      });
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EIconType } from 'src/app/shared/component/icon/icon.component';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CookieService } from 'src/app/shared/service/cookie.service';
import { FormService } from 'src/app/shared/service/form.service';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss']
})
export class LoginBannerComponent implements OnInit, OnDestroy {

  public env = environment;
  public eIconTypes: typeof EIconType = EIconType;

  public shouldDisplay = true;

  private endSubs = new Subject();

  constructor(
    public authenticationService: AuthenticationService,
    public cookieService: CookieService,
    public navigationService: NavigationService,
    private applicationService: ApplicationService,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.applicationService.updateSent.pipe(takeUntil(this.endSubs)).subscribe(application => {
      this.shouldDisplay = this.navigationService.mainForm?.get('data_fields')?.get('studentHasLoggedIn')?.value === false;
    });

    this.formService.prefillForm.pipe(takeUntil(this.endSubs)).subscribe(prefillFormObj => {
      const hasStudentLoggedIn = prefillFormObj.formData['studentHasLoggedIn'];

      if (hasStudentLoggedIn  !== undefined) {
        this.shouldDisplay = hasStudentLoggedIn === false;
      }

    });
  }

  ngOnDestroy() {
    this.endSubs.next(null);
    this.endSubs.complete();
  }

  triggerLogin() {
    this.authenticationService.initStudentLoginProcess();
  }

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ProgramService} from '../../../shared/service/program.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Program} from '../../../shared/model/program.model';
import {Subject} from 'rxjs';
import {FormService} from 'src/app/shared/service/form.service';
import {DomService} from 'src/app/shared/service/dom.service';
import {TermService} from 'src/app/shared/service/term.service';
import {ModalService} from 'src/app/shared/service/modal.service';
import {DegreePickerSelectComponent} from '../degree-picker-select/degree-picker-select.component';
import {environment} from 'src/environments/environment';
import {EIconType} from 'src/app/shared/component/icon/icon.component';
import {take, takeUntil} from 'rxjs/operators';
import {TreeDataService} from '../../../shared/service/tree-data-service';
import {PickerNode} from '../../../shared/model/picker-node.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-degree-picker',
  templateUrl: './degree-picker.component.html',
  styleUrls: ['./degree-picker.component.scss'],
})
export class DegreePickerComponent implements OnInit, OnDestroy {


  @ViewChild(DegreePickerSelectComponent) picker: DegreePickerSelectComponent;
  @ViewChildren('degreeSearches') degreeSearches!: QueryList<ElementRef>;
  @Input() formGroup: FormGroup = new FormGroup({});
  public eIconTypes: typeof EIconType = EIconType;
  selectedProgram = new Program();
  endSubscriptions = new Subject<void>();
  scrollSubscription = new Subject<void>();
  programsDisplayed = false;
  infoBoxHeader: string;
  showInfoBox = false;
  searchStatusText = '';
  emptyProgram = new Program();
  showMobileVersionOnDesktop = false;
  areaOfStudies: string[] = [];

  pickerTree: Array<PickerNode>;

  public currentCampus: string = this.formService.getCurrentCampus();
  public currentLevel: string = this.formService.getCurrentLevel();
  private allAreas: string[];

  constructor(
    public programService: ProgramService,
    public cdr: ChangeDetectorRef,
    public formService: FormService,
    public domService: DomService,
    public termService: TermService,
    private modalService: ModalService,
    private treeDataService: TreeDataService
  ) {
  }

  get degreePickerContainerElement(): HTMLElement {
    return this.domService.selectElementAsRoot('.degree-picker-container');
  }

  get areaFiltersElement(): HTMLElement {
    return this.degreePickerContainerElement.querySelector('.area-filters');
  }

  get viewPortElement(): HTMLElement {
    return this.degreePickerContainerElement.querySelector('app-degree-picker-select');
  }

  ngOnInit(): void {

    this.currentCampus = this.formService.currentCampus;
    this.currentLevel = this.formService.currentLevel;


    this.treeDataService.getCurrentPickerTreeSub().pipe(takeUntil(this.endSubscriptions)).subscribe((newTree: PickerNode[]) => {

      this.currentCampus = this.formService.currentCampus;
      this.currentLevel = this.formService.currentLevel;

      if (this.selectedProgram.programCode) {
        this.onProgramChosen(this.emptyProgram);
      }

      this.regeneratePicker(newTree);
    });


    // still keeping track of current campus/level in the component just for a couple pieces of data
    this.formService.updatedCampus.pipe(takeUntil(this.endSubscriptions)).subscribe(newCampus => {
      if (newCampus === this.currentCampus) { // dont do anything if value is the same
        return;
      }

      this.currentCampus = newCampus;
    });

    this.formService.updatedLevel.pipe(takeUntil(this.endSubscriptions)).subscribe(newLevel => {
      if (newLevel === this.currentLevel) { // dont do anything if value is the same
        return;
      }

      this.currentLevel = newLevel;
      if (newLevel === '') {
        this.showMobileVersionOnDesktop = false;
      }
    });


    this.programService.getPickerSelectedSub().pipe(takeUntil(this.endSubscriptions)).subscribe((program: Program) => {
      this.onProgramChosen(program, false);

      this.formGroup.controls['program'].setValue(program.programCode, {emitEvent: false});
    });

    this.formGroup.addControl('programSearchString', new FormControl<string>('')) ;
  }

  ngOnDestroy() {
    this.formGroup.removeControl('program');
    this.formGroup.removeControl('programSearchString');
    this.scrollSubscription.unsubscribe();
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  public switchToDegreeSelector($event: KeyboardEvent) {

    const lastDegreeElementFocused = this.viewPortElement.querySelector(
      !this.viewPortElement.querySelector('.lastSelectedNode')
        ? 'tree-node > .tree-node-level-2 > tree-node-wrapper > .tree-node-wrapper'
        : '.lastSelectedNode'
    ) as HTMLElement;

    lastDegreeElementFocused.tabIndex = 0;
    lastDegreeElementFocused.focus();
  }

  public onProgramChosen(program: Program, emit = true) {

    if (program.programCode && program.programCode !== '') {
      program = this.programService.checkForRequiredProps(program);
    }
    this.selectedProgram = program;

    if (!this.selectedProgram.programCode) { // set value of program field to blank as well
      this.formGroup.controls['program'].setValue('');
    }

    this.showInfoBox = this.selectedProgram.programCode !== '';

    if (this.selectedProgram.programCode) {
      const degreeCode = this.selectedProgram.degreeCode;
      const majorGroup = this.selectedProgram.majorGroup;

      if (majorGroup.includes('Executive') && this.selectedProgram.level === 'Doctorate') {
        this.infoBoxHeader = 'Executive Certificate';
      } else if (degreeCode && degreeCode.includes('CRT')) {
        this.infoBoxHeader = 'Undergrad Certificate';
      } else {
        this.infoBoxHeader = this.selectedProgram.degree;
      }


      // *Fill Hidden Fields
      if (program.displayDescription.includes('CRT:')) {

        this.formGroup.controls['program_type'].setValue('CRT');
      } else if (program.displayDescription.includes('CTG:')) {
        this.formGroup.controls['program_type'].setValue('CTG');
      } else if (program.displayDescription.includes('THM:')) {
        this.formGroup.controls['program_type'].setValue('THM');
      } else {
        this.formGroup.controls['program_type'].setValue('');
      }
      this.formGroup.controls['degree_level'].setValue(program.degreeLevel);
      this.formGroup.controls['level'].setValue(program.level);
      this.formGroup.controls['level_code'].setValue(program.levelCode);
      this.formGroup.controls['wappCode'].setValue(program.wappCode);

      this.cdr.detectChanges();


    }

    this.programService.setCurrentProgram(this.selectedProgram);
    this.cdr.detectChanges();
    if (emit) {
      this.programService.programSubNext(this.selectedProgram);
    }

  }

  public scrollToArea(area: string) {
    const areaElement = this.degreePickerContainerElement.querySelector('.root.tree-node-level-1 > tree-node-wrapper .tree-column[title="' + area + '"]')
    if (!areaElement) {
      this.onSearchInput('');
      this.cdr.detectChanges();


      this.scrollSubscription.pipe(takeUntil(this.endSubscriptions), take(1)).subscribe(() => {
        const updatedAreaElement = this.degreePickerContainerElement.querySelector('.root.tree-node-level-1 > tree-node-wrapper .tree-column[title="' + area + '"]');
        if (updatedAreaElement) {
          this.scrollToElement(updatedAreaElement);
        }
      });
    }
    else {
      this.scrollToElement(areaElement);
    }

  }

  private scrollToElement(element: Element) {
    const gatherAreaCell = element.parentElement;
    const areaCellOffsetTop = gatherAreaCell.offsetTop;

    this.domService.scrollIt(this.viewPortElement, areaCellOffsetTop - 125, 800);
  }

  onMouseUp($event: MouseEvent) {
    if ($event.button === 0) {
      document.activeElement.classList.add('lastSelectedArea');
    }
  }

  onMouseDown($event: MouseEvent) {
    if ($event.button === 0) {
      const lastSelectedArea = this.areaFiltersElement.querySelector('.lastSelectedArea');
      lastSelectedArea?.classList.remove('lastSelectedArea');
    }
  }

  resetSearch() {
      this.degreeSearches?.forEach(search => {
        search.nativeElement.value = '';
      });
      this.onSearchInput('');

  }

  onSearchInput(input: string) {
    this.formGroup?.controls['programSearchString']?.setValue(input);
  }

  triggerMobileDegreePicker() {
    if (this.showMobileVersionOnDesktop) {
      this.showMobileVersionOnDesktop = false;
    } else {
      this.modalService.triggerDegreePickerModal.next(true);
    }
    if (this.selectedProgram.programCode) {
      this.onProgramChosen(this.emptyProgram);
    }
  }

  onKeyDown(majorArea: string, $event: KeyboardEvent) {
    switch ($event.key) {
      case 'ArrowDown':
        document.activeElement.classList.remove('lastSelectedArea');
        const elementBeforeTab = document.activeElement;
        this.domService.tabIndex($event, 'next');
        if (document.activeElement.classList.contains('area-filter')) {
          document.activeElement.classList.add('lastSelectedArea');
        } else {
          elementBeforeTab.classList.add('lastSelectedArea');
        }
        break;
      case 'ArrowUp':
        document.activeElement.classList.remove('lastSelectedArea');
        this.domService.tabIndex($event, 'previous');
        if (document.activeElement.classList.contains('area-filter')) {
          document.activeElement.classList.add('lastSelectedArea');
        }
        break;
      case 'ArrowRight':
        this.switchToDegreeSelector($event);
        break;
      case 'Enter':
        this.scrollToArea(majorArea);
        break;
    }
  }

  private regeneratePicker(newTree: PickerNode[]) {
    this.pickerTree = newTree;
    this.allAreas = this.pickerTree.map(node => node.name);
    // also reset degree search
    this.resetSearch();

    if (this.pickerTree.length !== 0) {
      this.areaOfStudies = this.pickerTree.map(branch => branch.name);
      this.programsDisplayed = true;
    } else {
      this.programsDisplayed = false;
    }

    // performance optimization problem zone, onProgramChosen needs to happen but then we try to scroll the page down and there is so much going on under the hood that the screen encounters studdering
    // don't auto select UNDE-BS-R program if user comes in with a p param
    const noPParam = window.location.href.indexOf('?p=') === -1 && window.location.href.indexOf('&p=') === -1;

    if (!environment.isAgent && this.currentCampus === 'R' && this.currentLevel === 'Bachelor' && noPParam && !this.formGroup.get('program').value) {

      const undecidedProgram = this.programService.getProgramByProgramCode('UNDE-BS-R');
      this.programService.pickerSelectedSubNext(undecidedProgram);

      this.onProgramChosen(undecidedProgram);
    } else if (this.formGroup.get('program').value) {
      const currentProgram = this.programService.getProgramByProgramCode(this.formGroup.get('program').value);
      if (currentProgram) {

        this.onProgramChosen(currentProgram);
      }
    }
    this.cdr.detectChanges();

    setTimeout(() => {
      this.domService.selectElementAsRoot('#picker-anchor').scrollIntoView({behavior: 'smooth'});
    }, 300);

  }
  protected hasSearched(val: string) {
    if(this.scrollSubscription) {
      this.scrollSubscription.next();
    }
    this.setAreaOfStudy(val);
  }

  private setAreaOfStudy(val: string) {
    // next gather all areas that have been left visible in the selectContainer, iterate and remove hidden class from those left over
    if (this.viewPortElement) {
      const currentAreas = Array.from(this.viewPortElement.querySelectorAll('.root')).map(area => this.picker.getNodeNameFromHTMLElement(area));
      this.areaOfStudies = currentAreas.length > 0 ? currentAreas : this.allAreas;

      const howManyAreas = Array.from(this.viewPortElement.querySelectorAll('.root:not(.hidden)')).length;
      const howManyPrograms = Array.from(this.viewPortElement.querySelectorAll('.leaf:not(.hidden)')).length;
      // announce search results for SR
      this.searchStatusText = `We found ${howManyPrograms} degree programs in ${howManyAreas} area${howManyAreas > 1 ? 's' : ''} of study for ${val}`;
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormError } from 'src/app/shared/model/form-error.model';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  @Input() formGroup = new FormGroup({});
  @Input() activateTrigger = false;
  @Input() preStageErrors = false;

  sectionErrors: FormError[] = [];
  formGroupSub = new Subscription();

  constructor(
    public formService: FormService,
  ) { }

  ngOnInit(): void {

    if (this.formService.submitted) {
      this.sectionErrors = this.formService.gatherFormErrors(this.formGroup as FormGroup);
    }
    if (this.preStageErrors) {
      this.sectionErrors = this.formService.gatherFormErrors(this.formGroup as FormGroup);
    }

    this.formGroupSub = this.formGroup.valueChanges.subscribe(() => {
        this.formGroup.markAllAsTouched();
        this.sectionErrors = this.formService.gatherFormErrors(this.formGroup as FormGroup);
    });

  }

}

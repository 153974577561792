import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-drop-down',
  templateUrl: './select-drop-down.component.html'
})
export class SelectDropDownComponent<T> {

  @Input() public itemList = new Array<T>();
  @Input() public itemFormControl: FormControl;
  @Input() public displayProperty: string;
  @Input() public optionValueProperty: string;
  @Input() public placeholder: string;

  @Output() outputtedOption = new EventEmitter<T>();

  constructor() { }

  public getFormControlName(): string {
    const formGroup = this.itemFormControl.parent.controls;
    return Object.keys(formGroup).find(name => this.itemFormControl === formGroup[name]);
  }

  public getFormControlParent(): FormGroup {
    return this.itemFormControl.parent as FormGroup;
  }

  selectionMade(selection) {
    this.outputtedOption.emit(selection);
  }

}

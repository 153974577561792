import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Visa} from 'src/app/shared/model/visa.model';
import {Term} from 'src/app/shared/model/term.model';
import {FormGroup, Validators} from '@angular/forms';
import {Program} from 'src/app/shared/model/program.model';
import {Subject} from 'rxjs';
import {FormService} from 'src/app/shared/service/form.service';
import {NavigationService} from 'src/app/shared/service/navigation.service';
import {Nation} from 'src/app/shared/model/nation.model';
import {ProgramService} from 'src/app/shared/service/program.service';
import {
  SearchableSelectDropDownComponent
} from '../../generic/searchable-select-drop-down/searchable-select-drop-down.component';
import {takeUntil} from 'rxjs/operators';
import {DomService} from 'src/app/shared/service/dom.service';

@Component({
  selector: 'app-visa-status-select',
  templateUrl: './visa-status-select.component.html',
  styles: ['.visa-search {max-width: 257px; display: block;} .newMajor { display: inline; color: #0059C0; font-weight: bold; cursor: pointer; }']
})
export class VisaStatusSelectComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({});
  @ViewChild(SearchableSelectDropDownComponent) visaDropdown: SearchableSelectDropDownComponent<Visa>;

  endSubscriptions = new Subject<void>();
  hasPrefilled = false;

  showResideInternationalDisclaimer = false;
  showNonResideInternationalDisclaimer = false;
  getResideInUSRadio = true;

  currentVisa: Visa = new Visa();
  currentBirthCountry = new Nation();
  currentProgram = new Program();

  allVisas: Term[] = [
    {name: 'Adjudication Status', value: 'AS'},
    {name: 'Asylum Status', value: 'AY'},
    {name: 'Business Temporary', value: 'B1'},
    {name: 'Canadian/Mexican Professional', value: 'TN'},
    {name: 'Child or Fiancé of US Citizen', value: 'K2'},
    {name: 'Deferred Action for Child Arrivals', value: 'DA'},
    {name: 'Dependent of Canadian/Mexican Professional', value: 'TD'},
    {name: 'Dependent of L1 Intracompany Transeree', value: 'L2'},
    {name: 'Dependent of M-1 Visa Holder', value: 'M2'},
    {name: 'Dependent of R2 Religious Worker', value: 'R2'},
    {name: 'Diplomatic', value: 'A1'},
    {name: 'Diplomatic Staff', value: 'A3'},
    {name: 'Exchange Visitor', value: 'J1'},
    {name: 'F1 Student', value: 'F1'},
    {name: 'F2 Family of Student', value: 'F2'},
    {name: 'Family of Exchange Visitor', value: 'J2'},
    {name: 'Fiancee of U.S. Citizen', value: 'K1'},
    {name: 'H4 Spouse or Child of H1 - H3', value: 'H4'},
    {name: 'Immigration Card Holder', value: 'IM'},
    {name: 'International Organization Officer & Family', value: 'G4'},
    {name: 'Intra-company Transferree', value: 'L1'},
    {name: 'Jay Treaty', value: 'JT'},
    {name: 'Non-US Citizen No Visa Given', value: 'N'},
    {name: 'North Atlantic Treaty Organization 1-7', value: 'NT'},
    {name: 'Other Foreign Goverment Officer', value: 'A2'},
    {name: 'Other Representative of Foreign Government', value: 'G2'},
    {name: 'Other or Former Visa Holder', value: 'OT'},
    {name: 'Out of Status', value: 'OS'},
    {name: 'Principle Representative of Foreign Government', value: 'G1'},
    {name: 'Refugee', value: 'RF'},
    {name: 'Religious Worker', value: 'R1'},
    {name: 'Representative of Non-Recognized Foreign Government', value: 'G3'},
    {name: 'Spouse of US Citizen', value: 'K3'},
    {name: 'Spouse of child of L1', value: 'L2'},
    {name: 'Staff of Alien G1 - G4 & Family', value: 'G5'},
    {name: 'Temporary Worker of Distinguished Merit', value: 'H1'},
    {name: 'Temporary Protected', value: 'TP'},
    {name: 'Temporary Worker of Unavailable Services', value: 'H2'},
    {name: 'Trainee', value: 'H3'},
    {name: 'Treaty Investor & Wife', value: 'E2'},
    {name: 'Treaty Trader & Wife', value: 'E1'},
    {name: 'Very Distinguished Vistor', value: 'H'},
    {name: 'Vocational Student', value: 'M1'}
  ];

  readonly ORIGINAL_INTL_DISCLAIMER = `The selected program is currently not approved by SEVIS for F-1 VISA students at Liberty University.
  We are diligently working to add this program to our approved list. Based on this information, you may
  continue with your application or select a`;
  readonly SECONDARY_INTL_DISCLAIMER = `Due to limited residential classes on this degree plan, this program is not offered to International
   students on F-1 visa/pursuing F-1 visa. Please select a`;

  internationalDisclaimer = this.ORIGINAL_INTL_DISCLAIMER;

  constructor(
    public formService: FormService,
    public navigationService: NavigationService,
    public cdr: ChangeDetectorRef,
    private programService: ProgramService,
    private domService: DomService
  ) {
    this.currentProgram.campCode = '';
    this.currentBirthCountry.code = '';
  }

  ngOnInit(): void {

    // these other formControls will trigger valueChanges when they are actually interracted with
    // we have prevented them from emitting valueChanges in their own prefill listeners in the us-citizen-questions file
    this.formGroup.get('us_citizen_radio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnableVisaSearch();
    });
    this.formService.UpdatedAddress.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnableVisaSearch();
    });
    this.formGroup.get('green_card_radio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnableVisaSearch();
    });
    this.formService.birthCountryUpdated.pipe(takeUntil(this.endSubscriptions)).subscribe(nation => {
      this.currentBirthCountry = nation;
      this.maybeEnableVisaSearch();
    });
    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.currentProgram = program;
      this.maybeEnableVisaSearch();
    });

    this.formService.residesInUs.pipe(takeUntil(this.endSubscriptions)).subscribe(isUsResident => {
      this.getResideInUSRadio = isUsResident;
      this.maybeEnableVisaSearch(true);
    });

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(formObject => {
      setTimeout(() => {
      // Need to set the visa back on prefill with a timeout so it runs after the above subscription clears it out. I don't like it.
      const visaCode = formObject.formData['personalInformation'].citizenshipGroup.visa_search_code;
      const visaName = formObject.formData['personalInformation'].citizenshipGroup.visa_search;
      const visa = new Term();
      visa.name = visaName;
      visa.value = visaCode;

      this.formGroup.controls['visa_search_code'].setValue(visaCode);
      this.formGroup.controls['visa_search'].setValue(visaName);
      this.currentVisa.type = visaCode;
      this.visaDropdown.selectItem(visa);
    }, 1000);
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeEnableVisaSearch(onResidentRadioChange: boolean = false) {
    if (
      !this.isUsCitizen()
      && this.getResideInUSRadio
      && this.greenCardRadio() === 'No'
      && this.currentProgram.campCode === 'R'
    ) {
      const previousSelectedVisa = this.formGroup.get('visa_search').value;
      if (previousSelectedVisa) {
        const foundVisaObject: Term = this.allVisas.find(visa => visa.name === previousSelectedVisa);
        if (onResidentRadioChange) {
          this.currentVisa.type = '';
          this.formGroup.controls['visa_search_code'].setValue('');
          this.formGroup.controls['visa_search'].setValue('');
          this.formGroup.controls['visa_search'].setValidators([]);
        } else {
          this.currentVisa.type = foundVisaObject.value;
        }
      }
      this.formGroup.get('visa_search').enable();
      this.formGroup.get('visa_search').setValidators([Validators.required]);
    } else if (
      !this.isUsCitizen()
      && !this.getResideInUSRadio
      && this.greenCardRadio() === 'No'
      && this.currentProgram.campCode === 'R'
    ) {
      this.formGroup.controls['visa_search_code'].setValue('F1');
      this.formGroup.get('visa_search').disable();
      const autoVisa = new Term();
      autoVisa.name = 'F1 Student';
      autoVisa.value = 'F1';

      this.visaDropdown.selectItem(autoVisa);
    } else {
      this.formGroup.controls['visa_search_code'].setValue('');
      this.formGroup.controls['visa_search'].setValue('');
      this.formGroup.get('visa_search').disable();
      this.formGroup.get('visa_search').setValidators([]);
    }
    this.navigationService.validateSection('personalInformation');
    this.maybeShowResideInternationalDisclaimer();
    this.maybeShowNonResideInternationalDisclaimer();
    this.cdr.detectChanges();
  }

  onVisaSelect(visa: Term) {
    this.currentVisa.type = visa.value;
    this.formGroup.controls['visa_search_code'].setValue(visa.value);
    this.navigationService.validateSection('personalInformation');
    this.maybeShowResideInternationalDisclaimer();
    this.maybeShowNonResideInternationalDisclaimer();
    this.cdr.detectChanges();
  }

  maybeShowResideInternationalDisclaimer() {
    this.showResideInternationalDisclaimer = this.currentProgram.internationalBanned
      && this.currentVisa.type
      && this.currentVisa.type === 'F1'
      && !this.isUsCitizen()
      && this.greenCardRadio() === 'No'
      && this.getResideInUSRadio
      && this.currentProgram.campCode !== 'D';

    if (this.showResideInternationalDisclaimer && (this.currentProgram.programCode === 'ITSD-MS-R' || this.currentProgram.programCode === 'MCYS-MS-R')) {
      this.internationalDisclaimer = this.SECONDARY_INTL_DISCLAIMER;
    } else if (!this.showNonResideInternationalDisclaimer) {
      this.internationalDisclaimer = this.ORIGINAL_INTL_DISCLAIMER;
    }
    this.cdr.detectChanges();
  }

  maybeShowNonResideInternationalDisclaimer() {

    this.showNonResideInternationalDisclaimer = this.currentProgram.internationalBanned
      && !this.isUsCitizen()
      && this.greenCardRadio() === 'No'
      && !this.getResideInUSRadio
      && this.currentProgram.campCode === 'R';

    if (this.showNonResideInternationalDisclaimer && (this.currentProgram.programCode === 'ITSD-MS-R' || this.currentProgram.programCode === 'MCYS-MS-R')) {
      this.internationalDisclaimer = this.SECONDARY_INTL_DISCLAIMER;
    } else if (!this.showResideInternationalDisclaimer) {
      this.internationalDisclaimer = this.ORIGINAL_INTL_DISCLAIMER;
    }
  }

  navigateToNewProgramSelection() {
    this.navigationService.emitActiveSectionNext('yourDegree');

    const anchorEl = this.domService.selectElementAsRoot('#degree-picker-anchor');
    this.domService.scrollIntoView(anchorEl, 30);

  }

  isUsCitizen(): boolean {
    return this.formGroup.get('citizenship').value === 'US';
  }

  greenCardRadio(): string {
    return this.formGroup.get('green_card_radio').value;
  }


}

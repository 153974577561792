import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Lead } from '../shared/model/lead';
import { Application } from '../shared/model/application.model';
import { ApplicationService } from '../shared/service/application.service';
import { LeadService } from '../shared/service/lead.service';
import { AcodeService } from '../shared/service/acode.service';
import { AnalyticsService } from '../shared/service/analytics.service';
import { environment } from 'src/environments/environment';
import { map, takeUntil } from 'rxjs/operators';
import { CookieService } from '../shared/service/cookie.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [
    '#main-content {background-color: #fff;}'
  ]
})
export class MainComponent implements OnInit, OnDestroy, AfterContentInit {

  endSubscriptions = new Subject<void>();

  constructor(
    public applicationService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private leadService: LeadService,
    private acodeService: AcodeService,
    private analyticsService: AnalyticsService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {

    const regressionParam = this.route.snapshot.queryParams.regression;
    if (!regressionParam || environment.envType === 'prod') {
      this.analyticsService.initAnalytics();
    }

    /* --- URL params that should do things just in the application object: --- */
    let currentUrl = window.location.href.split('#id_token')[0];
    currentUrl = currentUrl.replace('https://', '');
    currentUrl = currentUrl.replace('http://', '');
    this.applicationService.currentUrl = currentUrl + ' '; // causing weird formatting in server logs, add extra blank space to avoid

    // always runs even when no params are present
    this.route.queryParams.pipe(
      takeUntil(this.endSubscriptions),
      map(params => {
        // as of now we dont want any duplicates here (example: 2 tid params in URL)
        // If there are duplicates it will return an array instead of a string, which can break the code in subscribe()
        // iterate over each and if any are arrays, take the value of the first item and convert to that string

        // have to make a new one because we cannot modify the original
        const convertedParams: Params = {};

        Object.keys(params).map(key => {
          if (Array.isArray(params[key])) {
            convertedParams[key] = params[key][0];
          } else {
            convertedParams[key] = params[key];
          }
        });
        return convertedParams;

      })
    ).subscribe(params => {
      this.checkParamsAndCookies(params);
    });

    this.applicationService.prefillApplication.pipe(takeUntil(this.endSubscriptions)).subscribe(application => {
      const receivedApp = new Application(application);

      const answer244 = receivedApp.getCustomAnswer('244');
      if (answer244) {
        const prefillParams = this.getParamsFromUrl(answer244);
        this.acodesToAppService(prefillParams);
      }
    });

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  acodesToAppService(urlSearchParams: URLSearchParams) {
    if (urlSearchParams.has('acode') && urlSearchParams.get('acode') !== '') {
      this.applicationService.addAcode(urlSearchParams.get('acode'));
      this.addToCurrentQueryParams({ acode: urlSearchParams.get('acode') });
    }
  }

  checkParamsAndCookies(params?: Params) {
    const Q204UrlParams = {};
    if (!params) {
      params = {};
    }

    // about to check all params, but first check for any that are provided as a cookie, the value would take precedence over whatever the param val would originally be
    // this also will catch users that come in with a cookie in the browser and no URL param for it

    // process q204 cookies first
    const q204CookieNames = [
      'vwo_id',
      'lp',
      'c',
      'l',
      'p',
      'eventzip',
      '_ga',
      'network',
      'extension',
      'page_placement',
      'recruiter',
      'aud_id',
      'fb_ad_id',
      'fb_platform',
      'fbclid',
      'kwid',
      'sv',
      'g_kw_id',
      'b_kw_id',
      'utm_content',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'adobe_campaign_id',
      'adobe_placement_id',
      'adobe_creative_id',
      'g_location',
      'b_location',
      'msclkid',
      'ad_group_id',
      'account_id',
      'platform',
      'campaign_id'
    ];

    q204CookieNames.forEach(name => {
      // foreach cookie name, see if cookie exists, but dont overwrite url parameter if present
      if (!environment.isAgent && !params[name] && this.cookieService.getCookie(name)) {
        params[name] = this.cookieService.getCookie(name);
      }

      if (params[name]) {
        if (name === 'p') { // helps avoid critical bug
          Q204UrlParams[name] = params.p.toUpperCase();
        } else {
          Q204UrlParams[name] = params[name];
        }
      }
    });

    if (Object.keys(Q204UrlParams).length > 0) {
      this.applicationService.Q204UrlParams = JSON.stringify(Q204UrlParams);
    }

    // do things with other cookies as needed
    const otherCookieNames = [
      'tid',
      'ceeb',
      'acode',
      'psmt',
      'refer_id',
      'crmid',
      '_vis_test_id',
      'test',
      '_gtm_referral_channel'
    ];

    otherCookieNames.forEach(name => {
      if (!environment.isAgent && !params[name] && this.cookieService.getCookie(name)) {
        params[name] = this.cookieService.getCookie(name);
      }
    });

    if (params.tid) {
      Q204UrlParams['tid'] = params.tid;
      this.applicationService.tid = params.tid;
      this.leadService.leadByTIDSearch(params.tid).then((result: Observable<Lead>) => {

        result.pipe(takeUntil(this.endSubscriptions)).subscribe(
          (returnedLead: Lead) => {
          this.applicationService.prefillLead.next(returnedLead);
        });

      });
    }
    if (params.ceeb) {
      this.applicationService.ceeb = params.ceeb;
    }
    if (params.psmt) {
      this.applicationService.psmt = params.psmt;
    }
    if (params.refer_id) {
      this.applicationService.refereesAidm = params.refer_id;
    }
    if (params.crmid) {
      this.applicationService.crmid = params.crmid;
    }
    if (params._vis_test_id) {
      this.cookieService._vis_test_id = params._vis_test_id;
    }
    if (params._vis_opt_preview_combination) {
      this.cookieService._vis_opt_preview_combination = params._vis_opt_preview_combination;
    }
    if (params._gtm_referral_channel) {
      this.applicationService.gtmReferralChannel = params._gtm_referral_channel;
    }

    if (params.test) {
      this.applicationService.isTest = true;
    }
    if (params.acode) {
      this.applicationService.addAcode(params.acode);
    }

  }

  addToCurrentQueryParams(params: Params) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  getParamsFromUrl(url: string): URLSearchParams {
    let returnedUrl: string;
    const splitByQuery = url.split('?');
    if (splitByQuery[1]) {
      returnedUrl = splitByQuery[1].replace(' ', '');
    } else {
      returnedUrl = '';
    }
    return new URLSearchParams('?' + returnedUrl);
  }


  async ngAfterContentInit(): Promise<void> {

    // add any provided acodes to Sources array. As of now, these should not be able to be removed at a later time
    if (environment.isAgent) {
      this.acodeService.generateAcodes();
    }
    this.acodesToAppService(new URLSearchParams(window.location.search));

  }

}

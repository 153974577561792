<ng-container *ngIf="isModalDisplayed">
  <div class="modal-container" #modalContainer tabindex="0" (keydown.escape)="closeGetHelpModal()">
    <a class="modalClose" aria-label="Close" (click)="closeGetHelpModal()" (keyup.enter)="closeGetHelpModal()" tabindex="0"></a>
    <div class="modalContent">

      <div class="form-control">
        <fieldset class="styled-radio-container mb-0" role="radiogroup" tabindex="0">
          <legend class="row">
            <h2 class="mb-2 mb-md-5 col-auto mr-auto">Need help?</h2>
            <h3 class="col-12 col-md-auto mb-4 mb-md-2 mt-md-3 ml-md-auto">App ID: {{ appIdService.getAppId() || 'Loading'}}</h3>
          </legend>

          <div class="row styled-radio-row" [formGroup]="formGroup">

            <div class="col-4">
              <label class="styled-radio-label styled-radio-stacked text-center" for="help_radio_phone" tabindex="0" role="radio"
                (keydown.arrowup)="domService.tabIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
                (keydown.arrowdown)="domService.tabIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
                (keydown.arrowleft)="switchFocusAndEnter($event.target,$event.key)"
                (keydown.arrowright)="switchFocusAndEnter($event.target,$event.key)"
                [attr.aria-checked]="formGroup.get('help_radio').value === 'call' ? 'true' : 'false'"
                (keyup.enter)="formGroup.get('help_radio').setValue('call'); onHelpSelectionMade('call')"
                [ngClass]="{'active': formGroup.get('help_radio').value === 'call'}">

                <input type="radio" tabindex="-1" id="help_radio_phone" name="help_radio" formControlName="help_radio"
                  value="call" (change)="onHelpSelectionMade($event.target.value)">

                <div class="radio-icon-container">
                  <app-icon class="radio-icon" aria-label="Call icon" [icon]="'call'"></app-icon>
                </div>

                <h3>Call</h3>
                <h4>For Residential or Online</h4>
              </label>
            </div>

            <div class="col-4">
              <label class="styled-radio-label styled-radio-stacked text-center" for="help_radio_chat" tabindex="0" role="radio"
                (keydown.arrowup)="domService.tabIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
                (keydown.arrowdown)="domService.tabIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
                (keydown.arrowleft)="switchFocusAndEnter($event.target,$event.key)"
                (keydown.arrowright)="switchFocusAndEnter($event.target,$event.key)"
                [attr.aria-checked]="formGroup.get('help_radio').value === 'chat' ? 'true' : 'false'"
                (keyup.enter)="formGroup.get('help_radio').setValue('chat'); onHelpSelectionMade('chat')"
                [ngClass]="{'active': formGroup.get('help_radio').value === 'chat'}">

                <input type="radio" tabindex="-1" id="help_radio_chat" name="help_radio" formControlName="help_radio"
                  value="chat" (change)="onHelpSelectionMade($event.target.value)">

                <div class="radio-icon-container">
                  <app-icon class="radio-icon" aria-label="Call icon" [icon]="'chat'"></app-icon>
                </div>

                <h3 aria-label="Llive Chat">Live Chat</h3>
                <h4>Talk to an agent over chat</h4>
              </label>
            </div>

            <div class="col-4">
              <label class="styled-radio-label styled-radio-stacked text-center" for="help_radio_email" tabindex="0" role="radio"
                (keydown.arrowup)="domService.tabIndex($event, 'previous', $event.target.parentElement.parentElement.parentElement)"
                (keydown.arrowdown)="domService.tabIndex($event, 'next', $event.target.parentElement.parentElement.parentElement)"
                (keydown.arrowleft)="switchFocusAndEnter($event.target,$event.key)"
                (keydown.arrowright)="switchFocusAndEnter($event.target,$event.key)"
                [attr.aria-checked]="formGroup.get('help_radio').value === 'email' ? 'true' : 'false'"
                (keyup.enter)="formGroup.get('help_radio').setValue('email'); onHelpSelectionMade('email')"
                [ngClass]="{'active': formGroup.get('help_radio').value === 'email'}">

                <input type="radio" tabindex="-1" id="help_radio_email" name="help_radio" formControlName="help_radio"
                  value="email" (change)="onHelpSelectionMade($event.target.value)">

                <div class="radio-icon-container">
                  <app-icon class="radio-icon" style="fill: #4D00A2;" aria-label="Envelope icon" [icon]="'envelope'"></app-icon>
                </div>

                <h3 class="medium-hide">Email/Report Issue</h3>
                <h3 class="medium-show">Email</h3>
                <h4>Ask a question</h4>
              </label>
            </div>

          </div>
        </fieldset>
      </div>

      <div *ngIf="currentSelection === 'call'">
        <div class="row">
          <div class="col">
            <h3 class="mb-2">Residential</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Residential Undergraduate: <a href="tel:8005435317">(800) 543-5317</a><br>
              Mon., Tues., Thurs., and Fri. | 10am-9pm EST<br>
              Wed. | 9am - 6pm EST<br>
              Sat. | 10am- 6pm EST</p>
          </div>
          <div class="col-md-6">
            <p>Residential Graduate: <a href="tel:8772989617">(877) 298-9617</a><br>
              Mon., Tues., Thurs., and Fri. | 10am-9pm EST<br>
              Wed. | 9am - 6pm EST<br>
              Sat. | 10am- 6pm EST</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3 class="mb-2">Online</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p>Online Admissions: <a href="tel:8004249595">(800) 424-9595</a><br>
                Mon., Tues., Thurs., and Fri. | 8am-8pm EST<br>
                Wed. | 8am - 6pm EST<br>
                Sat. | 10am- 6pm EST</p>
          </div>
        </div>
      </div>

      <div class="live-chat" *ngIf="currentSelection === 'chat'">
        <div class="residential-row">
          <div class="row">
            <div class="col">
              <h3 class="mb-2">Residential</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <button (click)="openResidentialChat()" class="btn btn-live-chat">Chat Live</button>
            </div>
            <div class="col">
              <p>Monday - Friday | 8am - 5pm EST<br>
                Sat. | 10am- 6pm EST</p>
            </div>
          </div>
        </div>
        <div class="online-row">
          <div class="row">
            <div class="col">
              <h3 class="mb-2">Online</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <button (click)="openOnlineChat()" class="btn btn-live-chat">Chat Live</button>
            </div>
            <div class="col">
              <p>Monday - Friday | 8am - 5pm EST<br>
                Sat. | 10am- 6pm EST</p>
            </div>
          </div>
        </div>

      </div>

      <div [formGroup]="formGroup" *ngIf="currentSelection === 'email'">
        <app-get-help-email-form></app-get-help-email-form>
      </div>
    </div>


  </div>
  <div class="modalOverlay" (click)="closeGetHelpModal()" (keyup.enter)="closeGetHelpModal()" tabindex="-1"></div>
</ng-container>

<ng-container *ngIf="formGroup.get('tshirtSelect').enabled" [formGroup]="formGroup">
  <div class="tshirt-container form-control">
    <div class="validation-error-bar"
        *ngIf="formService.hasErrors(formGroup.get('tshirtSelect'))">
      </div>
    <label for="tshirtSelect">
      <span class="input-label-text">For first time accepted students, what unisex size LU shirt can we send?</span>
    </label>
    <div class="select-input-wrap">
      <select
        tabindex="0"
        name="tshirtSelect"
        id="tshirtSelect"
        formControlName="tshirtSelect"
        [attr.aria-invalid]="formService.hasErrors(formGroup.get('tshirtSelect')) ? 'true' : null"
        [attr.aria-describedby]="'tshirt-error'"
      >
        <option value="" disabled="true" selected="true">Select</option>
        <option *ngFor="let shirt of shirtSizes" value="{{shirt.value}}">{{shirt.name}}</option>
      </select>
      <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
    </div>
    <span class="error" role="dialog" id="tshirt-error" arial-live="polite" *ngIf="formService.hasErrors(formGroup.get('tshirtSelect'))">Select a shirt size</span>
  </div>
</ng-container>

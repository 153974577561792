import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { DataFieldsComponent } from './data-fields/data-fields.component';
import { SearchableSelectDropDownModule } from './searchable-select-drop-down/searchable-select-drop-down.module';
import { SelectDropDownModule } from './select-drop-down/select-drop-down.module';
import { YearComponent } from './year/year.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SearchableSelectDropDownComponent } from './searchable-select-drop-down/searchable-select-drop-down.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { IconModule } from 'src/app/shared/component/icon/icon.module';
import { MultiSearchableSelectDropdownComponent } from './multi-searchable-select-dropdown/multi-searchable-select-dropdown.component';
import { StateSearchComponent } from './state-search/state-search.component';
import { RadioModule } from './radio/radio.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { StateSearchModule } from './state-search/state-search.module';
import { TextboxModule } from './textbox/textbox.module';
import { TextboxComponent } from './textbox/textbox.component';
import { DirectivesModule } from 'src/app/shared/directive/directives.module';
import { RadioComponent } from './radio/radio.component';


@NgModule({
  declarations: [
    DataFieldsComponent,
    YearComponent,
    FileUploadComponent,
    MultiSearchableSelectDropdownComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TextboxModule,
    CheckboxModule,
    ReactiveFormsModule,
    SharedModule,
    RadioModule,
    StateSearchModule,
    SelectDropDownModule,
    DirectivesModule,
    IconModule,
    StateSearchModule,
    SearchableSelectDropDownModule,
    RadioModule
  ],
  exports: [
    DataFieldsComponent,
    YearComponent,
    RadioComponent,
    CheckboxComponent,
    TextboxComponent,
    StateSearchComponent,
    FileUploadComponent,
    MultiSearchableSelectDropdownComponent,
    SearchableSelectDropDownComponent,
  ]
})
export class GenericInputModule { }

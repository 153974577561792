import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDropDownComponent } from './select-drop-down.component';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from 'src/app/shared/component/icon/icon.module';

@NgModule({
  declarations: [
    SelectDropDownComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    IconModule
  ],
  exports: [
    SelectDropDownComponent
  ]
})
export class SelectDropDownModule { }

// tslint:disable: variable-name
export class ApplicationRequest {
  id: number;
  email: string;
  phone: string;
  appJson: string;
  formObject: string;
  agentUsername: string;
  pidm: string;
  ipAddress: string;
  testApp: string;
  festivalApp: string;
}

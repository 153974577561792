import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormService} from '../../../../shared/service/form.service';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SchoolSearchInputComponent} from '../school-search-input/school-search-input.component';
import {FormControlCustom} from 'src/app/shared/form-control-custom';
import {AppFormBuilder} from 'src/app/shared/model/app-form-builder.model';
import {SchoolSearchFieldsetComponent, SchoolType} from './school-search-fieldset/school-search-fieldset.component';

@Component({
  selector: 'app-school-search',
  templateUrl: './school-search.component.html',
  styleUrls: ['./school-search.component.scss']
})
export class SchoolSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() formGroup: FormGroup<any>;
  @Input() schoolType: SchoolType;
  @Input() multiSelect = false;
  @Input() required = true;
  @Input() searchLabelText = '';
  @Input() defaultLevel = '';
  @Input() errorText = '';

  @Input() ariaLabelMonth = 'Month Input';
  @Input() ariaLabelYear = 'Year Input';
  @Input() ariaLabelPrefix = '';

  currentFormGroupSub = new Subscription();
  @ViewChild(SchoolSearchInputComponent) schoolSearchInputComponent: SchoolSearchInputComponent;

  endSubscriptions = new Subject<void>();

  generateDidGraduateRadio: boolean;

  @ViewChild(SchoolSearchFieldsetComponent) schoolFieldsetComponent: SchoolSearchFieldsetComponent;

  degreeTypes = {
    '000000': {name: 'None or Undeclared'},
    BS: {name: 'Bachelor\'s Degree'},
    MA: {name: 'Master\'s Degree'},
    D: {name: 'Doctorate Degree'},
    AA: {name: 'Associates Degree'},
    CRT: {name: 'Certificate'}
  };

  searchInputName = 'school_search';

  constructor(
    public formService: FormService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required) {
      this.setupValidation(changes.required.currentValue);
    }
  }

  ngOnInit(): void {

    if (this.schoolType === SchoolType.Highschool) {
      this.searchInputName = 'highschool_school_search';
    } else if (this.schoolType === SchoolType.College) {
      this.searchInputName = 'college_school_search';
    }

    this.generateDidGraduateRadio = this.schoolType === SchoolType.College && this.defaultLevel === '';

    if (this.formGroup.get('done_entering_multi_school')) {
      (this.formGroup.get('done_entering_multi_school').get('checkbox_values').get('done_entering_multi_school') as FormControlCustom<string>).label = 'All attended Colleges/Universities have been added.'; // add it quick n dirty here, the constructor doesn't have an easy option to add it yet
    }

    if (this.schoolArray().value.length === 0) {
      this.schoolArray().push(AppFormBuilder.generateSchoolFields(this.defaultLevel, this.schoolType, this.generateDidGraduateRadio));
    }



  }

  ngAfterViewInit() {

    this.initializeSetup();

    // set child component properties after it has initialized
    this.schoolFieldsetComponent.ariaLabelMonth = this.ariaLabelMonth;
    this.schoolFieldsetComponent.ariaLabelYear = this.ariaLabelYear;
    this.schoolFieldsetComponent.ariaLabelPrefix = this.ariaLabelPrefix;
    this.schoolFieldsetComponent.errorText = this.errorText;
    this.schoolFieldsetComponent.searchLabelText = this.searchLabelText;

    this.cdr.detectChanges();

  }

  ngOnDestroy() {

    this.schoolArray().clear();

    this.endSubscriptions.next();
    this.endSubscriptions.complete();

  }


  setupValidation(required: boolean) {

    if (this.schoolFieldsetComponent) {

      if (required === false) {
        this.schoolFieldsetComponent.clearRequiredValidators();
      } else {
        this.schoolFieldsetComponent.setRequiredValidators();
      }
    }
    this.formGroup.updateValueAndValidity({emitEvent: false});
  }

  initializeSetup() {

    this.subscribeToCurrentGroup();
    this.addOrRemoveValidationToCurrentGroup();
    this.maybeEnableDoneEnteringMultiSchool(this.getCurrentFieldset().valid);

    this.setupValidation(this.required);

  }

  subscribeToCurrentGroup() {
    this.currentFormGroupSub.unsubscribe();

    this.currentFormGroupSub = this.getCurrentFieldset().valueChanges
      .pipe(
        takeUntil(this.endSubscriptions)
      )
      .subscribe(values => {
        if (values.length <= 1) {
          return;
        }

        this.maybeEnableDoneEnteringMultiSchool(this.getCurrentFieldset().valid);
      });
  }

  getCurrentFieldset(): FormGroup {
    return this.schoolArray().at(this.schoolArray().length - 1) as FormGroup;
  }

  currentSchoolIsComplete(): boolean {
    let isComplete = false;
    const currentGroup = this.getCurrentFieldset();
    if (
      currentGroup
      && this.isTotallyValid(currentGroup.get(this.searchInputName))
      && this.isTotallyValid(currentGroup.get('grad_year'))
      && this.isTotallyValid(currentGroup.get('last_month_attended'))
      && (this.schoolType === SchoolType.Highschool || (this.schoolType === SchoolType.College && currentGroup.get('degree_level_received').value !== ''))
    ) {
      isComplete = true;
    }

    return isComplete;
  }

  isTotallyValid(formControl: AbstractControl): boolean {
    return formControl && formControl.value !== '' && formControl.valid;
  }

  collapseSelectedSchoolIfComplete() {

    this.markFormGroupTouched(this.getCurrentFieldset());

    if (this.getCurrentFieldset().valid && this.currentSchoolIsComplete()) {

      this.schoolArray().push(AppFormBuilder.generateSchoolFields(this.defaultLevel, this.schoolType, this.generateDidGraduateRadio));

      this.subscribeToCurrentGroup();

    }

    this.addOrRemoveValidationToCurrentGroup();


  }

  removeCollapsedSchoolByIndex(index: number) {
    this.schoolArray().removeAt(index);

    const schoolArrayLength = this.schoolArray().length;

    if (schoolArrayLength > 1) {
      this.schoolFieldsetComponent.clearRequiredValidators();
    } else {
      this.schoolFieldsetComponent.setRequiredValidators();
    }

    this.subscribeToCurrentGroup();

    this.addOrRemoveValidationToCurrentGroup();

    if (schoolArrayLength < 2 && !this.currentSchoolIsComplete()) {
      this.maybeEnableDoneEnteringMultiSchool(false);
    }

  }

  addOrRemoveValidationToCurrentGroup() {
    if (this.schoolArray().value.length > 1) {
      this.schoolFieldsetComponent.clearRequiredValidators(this.getCurrentFieldset());
    } else {
      this.schoolFieldsetComponent.setRequiredValidators(this.getCurrentFieldset());
    }
    this.getCurrentFieldset().updateValueAndValidity();
  }

  maybeEnableDoneEnteringMultiSchool(enable = false) {
    if (!this.formGroup.get('done_entering_multi_school')) {
      return;
    }
    if (
      this.multiSelect && enable
      && (
        this.schoolArray().length > 1
        || this.schoolArray().length === 1 && this.currentSchoolIsComplete()
      )
    ) {
      this.formGroup.get('done_entering_multi_school').enable();
    } else {
      this.formGroup.get('done_entering_multi_school').disable();
      this.getDoneEnteringMultiCheckbox().setValue(false);
    }
    this.cdr.detectChanges();
  }


  getDoneEnteringMultiCheckbox(): FormControl {
    return this.formGroup.get('done_entering_multi_school').get('checkbox_values').get('done_entering_multi_school') as FormControl;
  }

  schoolArray(): FormArray {
    return this.formGroup.get('selected_schools') as FormArray;
  }

  // used in the template, settle down
  public getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


}

export class CampusLevelProgramArray {
   // all programs prepared for a degree picker
  D: {
    Bachelor: [],
    Master: [],
    Doctorate: [],
    Associate: [],
    Certificate: []
  };
  R: {
    Bachelor: [],
    Master: [],
    Doctorate: [],
    Associate: [],
    Certificate: []
  };

  constructor() {
    this.D = {
      Bachelor: [],
      Master: [],
      Doctorate: [],
      Associate: [],
      Certificate: []
    };
    this.R = {
      Bachelor: [],
      Master: [],
      Doctorate: [],
      Associate: [],
      Certificate: []
    };
  }

  isEmpty(): boolean {
    if (Array.isArray(this.D['Bachelor'])) {
      return this.D['Bachelor'].length === 0;
    } else {
      return Object.keys(this.D['Bachelor']).length === 0;
    }
  }
}

<div [formGroup]="formGroup" class="{{getControlName(formGroup)}}">

  <div class="row mb-3" *ngIf="schoolArray().length > 0">
    <div class="col">
      <ng-container *ngFor="let schoolFormGroup of schoolArray().controls; let i = index; let count = count">
        <div class="school-collapsed mb-2" *ngIf="i !== schoolArray().controls.length - 1">
          <span *ngIf="schoolFormGroup.get('degree_level_received').value"><b>{{
              degreeTypes[schoolFormGroup.get('degree_level_received').value].name }}</b> from </span><b>{{
            schoolFormGroup.get(this.searchInputName).value }}</b>
          <div class="remove-school" (click)="removeCollapsedSchoolByIndex(i)"></div>
        </div>
      </ng-container>

    </div>
  </div>

  <ng-container *ngFor="let thisSchoolGroup of schoolArray().controls; let i = index">

    <ng-container *ngIf="i === schoolArray().controls.length - 1">

      <app-school-search-fieldset
        [searchLabelText]="searchLabelText"
        [searchErrorText]="errorText"
        [formGroup]="thisSchoolGroup"
        [searchInputName]="searchInputName"
        [searchLabelText]="searchLabelText"
        [schoolType]="schoolType"
        [defaultLevel]="defaultLevel"
      ></app-school-search-fieldset>

    </ng-container>

  </ng-container>

  <button *ngIf="multiSelect && currentSchoolIsComplete()" tabindex="0" type="button" class="button button-small button-ghost mt-3" (click)="collapseSelectedSchoolIfComplete()">+ Add Another College or University</button>

  <fieldset *ngIf="multiSelect && formGroup.get('done_entering_multi_school') && formGroup.get('done_entering_multi_school').enabled" class="form-control mt-4" role="checkbox">
    <div class="form-control">

      <app-checkbox
        (outputtedVals)="formGroup.get('done_entering_multi_school').markAsTouched()"
        [itemFormGroup]="this.formGroup.get('done_entering_multi_school').get('checkbox_values')"
      ></app-checkbox>

    </div>
  </fieldset>

</div>

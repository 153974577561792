<div class="form-control" *ngIf="formGroup.get('dismissalRadio').enabled" [formGroup]="formGroup">

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('dismissalRadio')"
    [label]="'Have you ever been dismissed or left an institution while under investigation for a serious offense (e.g., theft, assault, threatening language/behavior, etc.)?'"
    [errorMessage]="'Indicate if you were dismissed from your last institution'"
  >
  </app-radio>

  <app-textbox
    *ngIf="formGroup.get('dismissalRadio').value === 'Yes'"
    [formGroup]="formGroup"
    [nameForControl]="'dismissal_textbox'"
    [requiredErrorMessage]="'Provide a reason for your dismissal/investigation'"
    [required]="true"
    [textboxHeader]="'Please provide the following details: Name of institution, reason for dismissal/investigation, how long ago it happened, and a statement that you will comply with the Liberty Way. (2,000 characters max)'"
    [textboxError]="'Provide the details asked for above regarding dismissal/investigation'"
  ></app-textbox>

  <app-school-search-input
    *ngIf="formGroup.get('dismissalRadio').value === 'Yes'"
    [formGroup]="formGroup"
    [inputFormControl]="formGroup.get('dismissal_school_search')"
    [searchInputName]="'dismissal_school_search'"
    [searchLabelText]="'Name of school that dismissed/investigated you'"
    (outputtedSchool)="emittedSchool($event)"
    [schoolType]=SchoolType.All
    [searchErrorText]="'Provide the last school that dismissed/investigated you'"
  ></app-school-search-input>
</div>

<fieldset>
  <legend>
    <h2 class="large-header d-inline-block">Personal Information</h2>
  </legend>

  <div class="row less-padding">

    <app-email-address class="col-sm-7" [formGroup]="formGroup"
      [triggerDupChecker]="true" (emailAddressNuVal)="maybeCheckBlockedEmail($event)"></app-email-address>

    <app-phone-number class="col-sm-5" [formGroup]="formGroup.get('phone_fields')">
    </app-phone-number>

  </div>

  <app-cell-phone-radio [formGroup]="formGroup.get('phone_fields')"></app-cell-phone-radio>

  <app-name-fields [formGroup]="formGroup.get('name_fields')"
    [fieldsetLegend]="'Full legal name'" [displayIdChangeAlert]="displayNameFieldsMiniAlert"></app-name-fields>

  <hr>
</fieldset>

<app-full-address [formGroup]="formGroup.get('address_fields')">
</app-full-address>


<app-program-state-disclosure [formGroup]="formGroup.get('state_disclosure_group')">
</app-program-state-disclosure>


<fieldset>
  <ng-container>
    <hr style="margin-top: 0;">
  </ng-container>
  <app-gender-select [formGroup]="formGroup"></app-gender-select>

  <app-birth-date [formGroup]="formGroup.get('birth_date')"></app-birth-date>

  <app-mini-alert *ngIf="!env.isAgent && authenticationService.isLoggedIn() && displayBdayFieldsMiniAlert">In order to properly update this information, you will be asked to submit two forms of identification after you have submitted your application.</app-mini-alert>

  <app-marital-status *ngIf="currentCampus === 'R'" [formGroup]="formGroup"></app-marital-status>

  <app-religion-select *ngIf="currentCampus === 'R'" [formGroup]="formGroup"></app-religion-select>

  <app-race-select [formGroup]="formGroup"></app-race-select>
  <hr>

  <app-us-citizen-questions [formGroup]="formGroup.get('citizenshipGroup')"></app-us-citizen-questions>

  <app-financial-aid *ngIf="!applicationService.spcApp" [formGroup]="formGroup">
  </app-financial-aid>

  <app-ssn [formGroup]="formGroup"></app-ssn>

  <hr>
  <app-military-questions [formGroup]="formGroup.get('military')">
  </app-military-questions>


  <app-college-credits-radio [formGroup]="formGroup"></app-college-credits-radio>

  <app-college-certs-radio [formGroup]="formGroup.get('college_certs_group')">
  </app-college-certs-radio>

  <app-relative-info *ngIf="!applicationService.spcApp && currentCampus === 'R' && levelCategory === 'Undergrad'" [formGroup]="formGroup.get('relative_info')">
  </app-relative-info>
</fieldset>

<ng-container [formGroup]="formGroup">
  <div [ngClass]="{'hidden' : formGroup.get('visa_search').disabled}">

    <app-searchable-select-drop-down
      class="visa-search"
      [placeholder]="'Select'"
      [formGroup]="formGroup"
      [itemFormControl]="formGroup.get('visa_search')"
      [displayProperty]="'name'"
      [optionValueProperty]="'value'"
      [itemList]="allVisas"
      [inputLabelText]="'Visa Status'"
      (emitedItem)="onVisaSelect($event)"
    ></app-searchable-select-drop-down>
  </div>

  <app-alert *ngIf="showResideInternationalDisclaimer || showNonResideInternationalDisclaimer">
    {{internationalDisclaimer}}
    <div (click)="navigateToNewProgramSelection()" class="newMajor">
      new major
    </div>
    before continuing.
  </app-alert>

</ng-container>

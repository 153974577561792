import { Injectable } from '@angular/core';
import { Acode } from 'src/app/shared/model/applyLUApiObjects/acode.model';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AcodeService {
    private static readonly ACODE_BASE_URL = '/rest/sources';

    private _allAcodes = new Array<Acode>();
    public acodeAddedToApp = new Subject<boolean>();

    constructor(private http: HttpClient) { }

    public generateAcodes() {
        if (this.allAcodes.length) {
            return of<Array<Acode>>(this.allAcodes);
        }

        this.http.get<Array<Acode>>(AcodeService.ACODE_BASE_URL)
            .pipe(
                tap<Array<Acode>>(response => {

                    this._allAcodes = response;
                })
            ).subscribe();
    }

    public returnAcodes(): Array<Acode> {
        return this._allAcodes;
    }

    private get allAcodes(): Array<Acode> {
        return this._allAcodes;
    }

}

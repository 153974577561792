import { FormArray, FormGroup, Validators } from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Program } from 'src/app/shared/model/program.model';
import { FormService } from 'src/app/shared/service/form.service';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { Subject } from 'rxjs';
import { ProgramService } from 'src/app/shared/service/program.service';
import { State } from 'src/app/shared/model/state.model';
import { DomService } from 'src/app/shared/service/dom.service';
import { takeUntil } from 'rxjs/operators';
import { SchoolService } from 'src/app/shared/service/school.service';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { gradYearMax, gradYearMin } from 'src/app/shared/model/app-form-builder.model';
import { SchoolSearchComponent } from '../../input/generic/school-search/school-search.component';
import { SchoolType } from '../../input/generic/school-search/school-search-fieldset/school-search-fieldset.component';
import { CookieService } from '../../../shared/service/cookie.service';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { College } from 'src/app/shared/model/application/college.model';

@Component({
  selector: 'app-previous-education',
  templateUrl: './previous-education.component.html',
  styleUrls: ['./previous-education.component.scss']
})
export class PreviousEducationComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly CANADA_STATE_CODES_THAT_NEED_PREFIX = ['BC', 'MB', 'NB', 'NF', 'NT', 'NS', 'NU', 'ON', 'PE', 'PQ', 'SK', 'YT'];
  readonly MILITARY_STATE_CODES_THAT_NEED_PREFIX = ['AA', 'AE', 'AP'];
  certifyDegreeText: string;

  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() showErrors = false;
  @Input() hidden = true;

  @Output() emitHsDiplomaFName = new EventEmitter<string>();
  @Output() emitHsDiplomaLName = new EventEmitter<string>();

  @ViewChild('bachelorsSearchComponent') bachelorsSearchComponent: SchoolSearchComponent;
  @ViewChild('mastersSearchComponent') mastersSearchComponent: SchoolSearchComponent;

  diplomaRadioOptions: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];

  hsTypeRadioOptions: RadioOpts[] = [
    { value: 'High School', label: 'High School' },
    { value: 'Home School', label: 'Home School' },
    { value: 'GED', label: 'GED' }
  ];

  endSubscriptions = new Subject<void>();
  schoolType = SchoolType;
  isUndergrad = false;
  isGraduateAndRequiresBachelors = false;
  isGraduateOrDoctorateAndRequiresMasters = false;
  isGed = false;
  isHighSchool = false;
  isExecutiveDoctoral = false;
  isPHD = false;
  showCertQuestion = false;
  assertGpaStoredValue = '';

  selectedProgram = new Program();
  gedState: State;

  possibleHsGradYears = this.formService.generateGradYears();
  gedCeebCode = '';

  displayMultiCollege = false;

  bachelorsComplete = false;
  mastersComplete = false;

  masterSearchRequired = true;
  bachelorSearchRequired = true;
  masterAndBachelorRequired = false;

  masterOrBachelorRequired = false;

  minYear = gradYearMin;
  maxYear = gradYearMax;

  hsLabelText = '';
  headerText = '';

  currentCampus: string = this.formService.getCurrentCampus();
  currentLevel: string = this.formService.getCurrentLevel();
  schoolTypes = SchoolType;

  constructor(
    public formService: FormService,
    public applicationService: ApplicationService,
    public programService: ProgramService,
    public domService: DomService,
    public schoolService: SchoolService,
    private cdr: ChangeDetectorRef,
    public cookieService: CookieService,
    public navigationService: NavigationService,
  ) {
  }

  ngOnInit(): void {

    if (!this.applicationService.festivalsApp) { // festivals shows high school fields but none are required
      this.formGroup.addControl('schoolSelectionActive', new FormControlCustom({ required: 'Fill out all required fields' }, false, Validators.requiredTrue));
    }

    if (this.applicationService.spcApp) {
      this.formGroup.get('previously_attended_college').clearValidators();
      this.formGroup.get('previously_attended_college').updateValueAndValidity();
    }

    this.cdr.detectChanges();

    this.formGroup.valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(values => {

      // need to gather and emit schools immediately for login split test so that the login box appears right away,
      // rather than after 1 second debounce after waiting for app update
      // gather up colleges in all college searches
      let allColleges = [];
      this.schoolService.collegeGroups.forEach(collegeGroupName => {
        if (values[collegeGroupName] && values[collegeGroupName].selected_schools) {
          const theseColleges: College[] = values[collegeGroupName].selected_schools.map(schoolFields => schoolFields.selected_school);
          allColleges = allColleges.concat(theseColleges);
        }
      });

      this.checkForMastersAndBachelors();

      if (!this.applicationService.festivalsApp) {
        this.isSchoolSelectionActive();
      }
    });

    this.formGroup.get('hs_diploma_type').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      if (this.formGroup.get('hs_diploma_type').disabled) {
        val = '';
      }
      if (val === 'High School' || val === 'Home School') {
        this.activateHighSchool();
        if (val === 'Home School') {
          this.schoolService.isHomeSchool.next(true);
        } else {
          this.schoolService.isHomeSchool.next(false);
        }
      } else if (val === 'GED') {
        this.activateGed();
      }
    });

    this.formGroup.get('previously_attended_college').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe((val: string) => {
      if (this.formGroup.get('previously_attended_college').enabled && val === 'Yes') {
        this.formGroup.get('multiCollegeSearch').enable();
      } else {
        this.formGroup.get('multiCollegeSearch').disable();
      }
    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe((program: Program) => {
      this.showProperRadiosAndSchoolSearchBasedOnWapp(program);
    });

    this.formService.getUpdatedCampusSub().pipe(takeUntil(this.endSubscriptions)).subscribe(campus => {
      this.currentCampus = campus;
    });

    this.formService.getUpdatedLevelSub().pipe(takeUntil(this.endSubscriptions)).subscribe(level => {
      this.currentLevel = level;
    });

    this.formGroup.get('hs_diploma_type').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      this.hsLabelText = val === 'Home School' ? 'Name of Home School' : 'Name of High School';
      this.maybeEnableAssertGPA();
    });

    this.formGroup.get('certify_degree_radio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeEnableAssertGPA();
    });

  }

  ngAfterViewInit() {
    this.schoolService.generateSchools();

    if (this.applicationService.festivalsApp) {

      for (const field in (this.formGroup.get('ged_fields') as FormGroup).controls) {
        if (this.formGroup.get('ged_fields').get(field)) {
          this.formGroup.get('ged_fields').get(field).clearValidators();
          this.formGroup.get('ged_fields').get(field).updateValueAndValidity();
        }
      }

      const otherFields = ['hs_diploma_type', 'hs_diploma_firstName', 'hs_diploma_lastName', 'previously_attended_college', 'previous_balance'];
      otherFields.forEach(fieldName => {

        this.formGroup.get(fieldName).clearValidators();
        this.formGroup.get(fieldName).updateValueAndValidity();
      });

    }
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  clearSchoolSearch(schoolSearch: string[]) {
    schoolSearch.forEach(c => {
      const formArray = this.formGroup.get(c).get('selected_schools') as FormArray;
      while (formArray.length !== 0) {
        formArray.removeAt(0);
      }
    });
  }

  checkForMastersAndBachelors() {

    if (this.masterOrBachelorRequired) {

      this.masterSearchRequired = true;
      this.bachelorSearchRequired = true;

      // determine if either one has been completed
      const bachelorsIsComplete = this.bachelorsSearchComponent && this.bachelorsSearchComponent.currentSchoolIsComplete();
      const mastersIsComplete = this.mastersSearchComponent && this.mastersSearchComponent.currentSchoolIsComplete();

      this.masterSearchRequired = !bachelorsIsComplete;
      this.bachelorSearchRequired = !mastersIsComplete;

    } else {
      this.masterSearchRequired = true;
      this.bachelorSearchRequired = true;
    }

    this.cdr.detectChanges();

  }

  maybeEnableAssertGPA() {
    /* Since prefill fires this method around four times, each while building the form, store the assert_gpa value
       into a component property. Once it does return something, set that form-control so that setting the blank value
       in the else block doesn't permanently erase that value.*/
    const gpa = this.formGroup.get('assert_gpa').value;
    if (gpa === 'Yes' || gpa === 'No') {
      this.assertGpaStoredValue = gpa;
    }
    if (
      this.isUndergrad
      && this.formGroup.get('certify_degree_radio').enabled
      && this.formGroup.get('certify_degree_radio').value === 'Yes'
      && this.formGroup.get('hs_diploma_type').value !== 'GED'
    ) {
      this.formGroup.get('assert_gpa').enable();
      this.formGroup.get('assert_gpa').setValidators([Validators.required]);
      if (this.assertGpaStoredValue !== '') {
        this.formGroup.get('assert_gpa').setValue(this.assertGpaStoredValue);
      }
    } else {
      this.formGroup.get('assert_gpa').disable();
      this.formGroup.get('assert_gpa').setValidators([]);
      this.formGroup.get('assert_gpa').setValue('');
    }
    this.formGroup.get('assert_gpa').updateValueAndValidity();
  }

  shouldDisplayAdditionalRadios(): boolean {
    const shouldDisplay = !this.applicationService.spcApp &&
      (this.formGroup.get('previously_attended_college').enabled && this.formGroup.get('previously_attended_college').value === 'Yes')
      || this.isGraduateAndRequiresBachelors || this.isGraduateOrDoctorateAndRequiresMasters || this.isPHD || this.masterOrBachelorRequired || this.masterAndBachelorRequired;

    return shouldDisplay;
  }

  enableUndergrad() {
    this.formGroup.get('hs_diploma_type').enable();
  }

  disableUndergrad() {
    this.formGroup.get('hs_diploma_type').setValue('');
    this.formGroup.get('hs_diploma_type').disable();
    this.formGroup.get('hs_diploma_firstName').disable();
    this.formGroup.get('hs_diploma_lastName').disable();
    this.formGroup.get('previously_attended_college').disable();
    this.formGroup.get('previously_attended_college').setValue('');
    this.disableGed();
    this.isUndergrad = false;
    this.isHighSchool = false;
    this.isGed = false;
  }

  activateHighSchool() {
    this.disableGed();
    this.activateSharedHsFields();
    this.isHighSchool = true;
    this.isGed = false;
  }

  activateGed() {
    this.activateSharedHsFields();
    this.isHighSchool = false;
    this.isGed = true;
    this.formGroup.get('ged_fields').get('ged_diploma_month').enable();
    this.formGroup.get('ged_fields').get('ged_diploma_year').enable();
    this.formGroup.get('ged_fields').get('ged_state').enable();
    this.formGroup.get('ged_fields').get('ged_state').markAsUntouched();
    this.formGroup.get('ged_fields').get('ged_state').markAsPristine();
    this.formGroup.get('ged_fields').get('ged_diploma_month').markAsUntouched();
    this.formGroup.get('ged_fields').get('ged_diploma_year').markAsUntouched();
    this.formGroup.get('ged_fields').get('ged_diploma_month').markAsPristine();
    this.formGroup.get('ged_fields').get('ged_diploma_year').markAsPristine();
  }

  disableGed() {
    this.isGed = false;
    this.formGroup.get('ged_fields').get('ged_diploma_month').disable();
    this.formGroup.get('ged_fields').get('ged_diploma_month').setValue('');
    this.formGroup.get('ged_fields').get('ged_diploma_year').disable();
    this.formGroup.get('ged_fields').get('ged_diploma_year').setValue('');
    this.formGroup.get('ged_fields').get('ged_state').disable();
    this.formGroup.get('ged_fields').get('ged_state').setValue('');
    this.formGroup.get('ged_fields').get('ged_ceeb_code').disable();
    this.formGroup.get('ged_fields').get('ged_ceeb_code').setValue('');
    this.formGroup.get('ged_fields').get('ged_hs_name').disable();
    this.formGroup.get('ged_fields').get('ged_hs_name').setValue('');
  }

  public updateGedState(specifiedState: State) {

    if (this.CANADA_STATE_CODES_THAT_NEED_PREFIX.includes(specifiedState.code)) {
      this.gedCeebCode = 'G00C' + specifiedState.code;

    } else if (this.MILITARY_STATE_CODES_THAT_NEED_PREFIX.includes(specifiedState.code)) {
      this.gedCeebCode = 'G00MIL';
    } else {
      this.gedCeebCode = 'G000' + specifiedState.code;
    }
    this.gedState = specifiedState;

    this.formGroup.get('ged_fields').get('ged_ceeb_code').setValue(this.gedCeebCode);

    this.formGroup.get('ged_fields').get('ged_state').setValue(this.gedState.code);

    this.formGroup.get('ged_fields').get('ged_hs_name').setValue('GED from state: ' + this.gedState.code);

  }

  public updateGedDate(): void {
    if (this.gedCeebCode === '' && this.gedState) {
      this.gedCeebCode = 'G000' + this.gedState.code;
    }
  }

  isSchoolSelectionActive() {
    // the reason this all exists is because the previous education section dynamically adds school fields
    // (specifically for high school and all college searches)
    // during section validation, while the school search fields do not exist yet, and under certain conditions
    // the section can be triggered as valid at the wrong time, when no school selections have yet been made
    // this ensures that the section cannot become valid until school fields are also active
    let isActive = false;

    // if hs_diploma_type has been selected then school fields are activated
    if (this.formGroup.get('hs_diploma_type').value !== '') {
      isActive = true;
    }

    // hs_diploma_type radio might not be active for higher program levels, but if any of these have
    // any school fields generated then we are also considered active
    const collegeGroupNames = ['multiCollegeSearch', 'bachelorsSearch', 'mastersSearch', 'doctoralSearch'];
    collegeGroupNames.forEach(name => {
      if (this.formGroup.get(name).get('selected_schools').value.length > 0) {
        isActive = true;
      }
    });


    if (!this.selectedProgram.programCode || this.selectedProgram.programCode === '') {
      isActive = false;
    }

    this.formGroup.get('schoolSelectionActive').setValue(isActive, { emitEvent: false });
  }

  trackByFn(index, item) {
    return item.name; // or item.id
  }

  maybeEnableMultiCollege(val: string) {
    this.displayMultiCollege = this.isUndergrad && val === 'Yes';
  }

  private activateSharedHsFields() {
    this.formGroup.get('hs_diploma_firstName').enable();
    this.formGroup.get('hs_diploma_lastName').enable();
    this.formGroup.get('previously_attended_college').enable();
  }

  public showProperRadiosAndSchoolSearchBasedOnWapp(program: Program) {
    this.selectedProgram = program;
    this.displayMultiCollege = false;

    this.displayMultiCollege = false;
    this.isUndergrad = this.programService.isUndergrad(this.selectedProgram);
    this.isGraduateAndRequiresBachelors = this.programService.isGraduate(this.selectedProgram);
    this.isGraduateOrDoctorateAndRequiresMasters = this.programService.isGraduateOrDoctorateAndRequiresMasters(this.selectedProgram);
    this.isExecutiveDoctoral = this.programService.isExecutiveDoctoral(this.selectedProgram);
    this.isPHD = this.programService.isPHD(this.selectedProgram);
    this.masterAndBachelorRequired = this.programService.requiresMasterAndBachelor(this.selectedProgram);
    this.masterOrBachelorRequired = this.programService.requiresMasterOrBachelor(this.selectedProgram);

    // Do not show the cert question if the program is residential and undergraduate
    this.showCertQuestion = !(this.isUndergrad && program.campCode === 'R');
    // These two are determined by being undergrads and a radio formControl the user selects
    this.isGed = this.isUndergrad && this.formGroup.get('hs_diploma_type').value === 'GED';
    this.isHighSchool = this.isUndergrad && (this.formGroup.get('hs_diploma_type').value === 'High School' || this.formGroup.get('hs_diploma_type').value === 'Home School');

    // If a residential undergrad, don't show question at all
    if (this.showCertQuestion) {
      this.formGroup.get('certify_degree_radio').enable();
      this.formGroup.get('certify_degree_radio').setValidators([Validators.required]);
    } else {
      this.formGroup.get('certify_degree_radio').disable();
      this.formGroup.get('certify_degree_radio').setValidators([]);
      this.formGroup.get('certify_degree_radio').setValue('');
    }

    if (this.isUndergrad) {
      this.enableUndergrad();
      this.clearSchoolSearch(['bachelorsSearch', 'mastersSearch', 'doctoralSearch']);

      if (this.showCertQuestion) {
        this.certifyDegreeText = 'Do you certify that you have (or will obtain) a standard or advanced High School Diploma or GED prior to enrolling?';
      }
    }


    if (this.isGraduateAndRequiresBachelors) {
      this.certifyDegreeText = 'Do you certify that you have (or will obtain) a Bachelor\'s Degree prior to enrolling?';
    }

    if (this.isGraduateOrDoctorateAndRequiresMasters) {
      this.certifyDegreeText = 'Do you certify that you have (or will obtain) a Master\'s Degree prior to enrolling?';
    }

    if (this.masterOrBachelorRequired) {
      this.certifyDegreeText = 'Do you certify that you have (or will obtain) a Bachelor\'s or Master\'s Degree prior to enrolling?';
    }

    if (this.masterAndBachelorRequired) {
      this.certifyDegreeText = 'Do you certify that you have (or will obtain) a Bachelor\'s and Master\'s Degree prior to enrolling?';
      this.masterSearchRequired = true;
    }

    if (this.isPHD) {
      this.certifyDegreeText = 'Do you certify that you have (or will obtain) a Doctor of Education Degree prior to enrolling?';
    }

    if (this.isGraduateAndRequiresBachelors || this.isGraduateOrDoctorateAndRequiresMasters) {
      this.disableUndergrad();
      this.clearSchoolSearch(['highschoolSearch']);
    }

    if (this.isGed) {
      this.activateGed();
    }
    if (this.isHighSchool) {
      this.activateHighSchool();
    }

    if (program) {
      if (program.levelCode === 'GR' || program.levelCode === 'DR') {
        this.headerText = 'Where did/will you get your degree?';
      } else {
        this.headerText = 'Where did/will you get your High School Diploma?';
      }
    }

    this.cdr.detectChanges();
  }

}

<h1 tabindex="0" class="previousEducation screen-reader-only">Previous Education</h1>

<button *ngIf="programService.getCurrentProgram().programCode === ''" (click)="navigationService.emitActiveSectionNext('yourDegree')" type="button">Please Choose a Program First!</button>

<div [formGroup]="formGroup" [hidden]="!selectedProgram.programCode">

  <app-form-error *ngIf="showErrors" [preStageErrors]="true" [formGroup]="formGroup"></app-form-error>

  <div class="row">
    <div class="col">
      <h2 id="previous_ed_info_header" class="large-header">Previous Education</h2>

    </div>
  </div>

  <div class="form-control">

    <app-school-search
      [defaultLevel]="'BS'"
      *ngIf="isExecutiveDoctoral || isGraduateAndRequiresBachelors || masterOrBachelorRequired || masterAndBachelorRequired"
      [required]="bachelorSearchRequired"
      [searchLabelText]="'Where did you receive your Bachelor\'s Degree:'"
      [schoolType]=schoolType.College
      [formGroup]="formGroup.get('bachelorsSearch')"
      #bachelorsSearchComponent
      [errorText]="'Provide where you received your Bachelor\'s Degree'"
      [ariaLabelMonth]="'Last Month Attended'"
      [ariaLabelYear]="'Last Year Attended'"
      [ariaLabelPrefix]="'Bachelor Degree'"
    >
    </app-school-search>
    <app-school-search
      *ngIf="isGraduateOrDoctorateAndRequiresMasters || masterAndBachelorRequired || masterOrBachelorRequired || isExecutiveDoctoral"
      [required]="masterSearchRequired"
      [defaultLevel]="'MA'"
      [searchLabelText]="'Where did you receive your Master\'s Degree:'"
      [schoolType]=schoolType.College
      [formGroup]="formGroup.get('mastersSearch')"
      #mastersSearchComponent
      [errorText]="'Provide where you received your Master\'s Degree'"
      [ariaLabelMonth]="'Last Month Attended'"
      [ariaLabelYear]="'Last Year Attended'"
      [ariaLabelPrefix]="'Masters Degree'"
    >
    </app-school-search>

    <app-school-search
      *ngIf="isPHD"
      [defaultLevel]="'D'"
      [searchLabelText]="'Where did you receive your Doctoral Degree:'"
      [schoolType]=schoolType.College
      [formGroup]="formGroup.get('doctoralSearch')"
      [errorText]="'Provide where you received your Doctoral Degree'"
      [ariaLabelMonth]="'Last Month Attended'"
      [ariaLabelYear]="'Last Year Attended'"
      [ariaLabelPrefix]="'Doctoral Degree'"
    >
    </app-school-search>

  </div>


  <fieldset *ngIf="isUndergrad">
    <legend class="bold-label mb-3">{{ headerText }}</legend>
    <legend class="screen-reader-only">Where did or will you get your High School Diploma?</legend>
    <app-radio
      [radioOpts]="hsTypeRadioOptions"
      [radioFormControl]="formGroup.get('hs_diploma_type')"
    ></app-radio>
  </fieldset>

  <hr *ngIf="isUndergrad">

  <div class="form-control mb-0" [ngClass]="{'hidden' : !isGed}" [formGroup]="formGroup.get('ged_fields')">

    <div class="row relative">
      <app-state-search [inputLabelText]="'G.E.D. award State'"
                        [itemFormControl]="formGroup.get('ged_fields').get('ged_state')" [placeholder]="'State'"
                        class="col-sm-6"
                        (emitedState)="updateGedState($event)"></app-state-search>
    </div>


    <fieldset class="form-control">
      <div class="relative">
        <div class="validation-error-bar"
             *ngIf="formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_month')) ||
            formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_year'))"></div>
        <fieldset class="row">
          <legend class="col-12 input-label-text grad-date">
            Graduation Date
          </legend>

          <div class="col-6 col-sm-5">
            <div class="select-input-wrap">
              <label for="ged_diploma_month" class="screen-reader-only">Graduation Month</label>
              <select
                name="ged_diploma_month"
                id="ged_diploma_month"
                formControlName="ged_diploma_month"
                (change)="updateGedDate()"
                [attr.aria-invalid]="formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_month')) ? 'true' : null"
                [attr.aria-describedby]="formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_month')) ? 'ged-diploma-month-error' : null"
              >
                <option value="" selected disabled>Month</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
              <span class="error" id="ged-diploma-month-error"
                    *ngIf="formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_month'))">Select a graduation month</span>

              <span class="error" id="{{formGroup.get('ged_fields').get('ged_diploma_year')}}-error" role="dialog"
                    *ngIf="formService.hasRequiredError(formGroup.get('ged_fields').get('ged_diploma_year'))">A year is required</span>

              <span class="error" id="{{formGroup.get('ged_fields').get('ged_diploma_year')}}-error" role="dialog"
                    *ngIf="formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_year')) &&
                    formGroup.get('ged_fields').get('ged_diploma_year').errors.min">Enter a valid graduation year after {{minYear - 1}}</span>

              <span class="error" id="{{formGroup.get('ged_fields').get('ged_diploma_year')}}-error" role="dialog"
                    *ngIf="formService.hasErrors(formGroup.get('ged_fields').get('ged_diploma_year')) &&
                    formGroup.get('ged_fields').get('ged_diploma_year').errors.max">Enter a valid graduation year before {{maxYear + 1}}</span>

            </div>
          </div>

          <label for="ged_diploma_year" class="screen-reader-only">Graduation Year</label>

          <app-year
            class="col-5 mb-2 mb-sm-0 col-sm-3"
            [itemFormControl]="formGroup.get('ged_fields').get('ged_diploma_year')"
            [minYear]="minYear"
            [maxYear]="maxYear"
            (keydown.space)="$event.preventDefault()"
            (emitYear)="updateGedDate()"
            id="ged_diploma_year"
            [ariaLabel]="'Diploma Year'"
          >
          </app-year>
        </fieldset>
      </div>

    </fieldset>

  </div>

  <div class="form-control" *ngIf="isUndergrad && (isGed || isHighSchool)">

    <div class="row" *ngIf="isHighSchool">
      <app-school-search
        class="col"
        [schoolType]=schoolType.Highschool
        [searchLabelText]='hsLabelText'
        [formGroup]="formGroup.get('highschoolSearch')"
        [errorText]="'Provide a school name'"
        [required]="!applicationService.festivalsApp"
        [ariaLabelMonth]="'Graduation Month'"
        [ariaLabelYear]="'Graduation Year'"
      ></app-school-search>
    </div>

    <div class="row hs-diploma-row">

      <div class="col-sm-6 form-control">
        <div style="position: relative">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('hs_diploma_firstName'))"></div>
          <span class="input-label-text">First Name on Diploma</span>
          <input
            type="text"
            name="hs_diploma_firstName"
            id="hs_diploma_firstName"
            formControlName="hs_diploma_firstName"
            (blur)="emitHsDiplomaFName.emit($event.target.value);"
            aria-label="First Name on Diploma"
            appTrim
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('hs_diploma_firstName')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('hs_diploma_firstName')) ? 'hs-diploma-firstname-error' : null"
          >
          <span class="error" id="hs-diploma-firstname-error"
                *ngIf="formService.hasErrors(formGroup.get('hs_diploma_firstName'))">Enter your first name as it appears on your diploma</span>
        </div>

      </div>

      <div class="col-sm-6 form-control">
        <div style="position: relative">
          <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('hs_diploma_lastName'))"></div>
          <span class="input-label-text">Last Name on Diploma</span>
          <input
            type="text"
            name="hs_diploma_lastName"
            id="hs_diploma_lastName"
            formControlName="hs_diploma_lastName"
            appTrim
            (blur)="emitHsDiplomaLName.emit($event.target.value);"
            aria-label="Last Name on Diploma"
            [attr.aria-invalid]="formService.hasErrors(formGroup.get('hs_diploma_lastName')) ? 'true' : null"
            [attr.aria-describedby]="formService.hasErrors(formGroup.get('hs_diploma_lastName')) ? 'hs-diploma-lastname-error' : null"
          >
          <span class="error" id="hs-diploma-lastname-error"
                *ngIf="formService.hasErrors(formGroup.get('hs_diploma_lastName'))">Enter your last name as it appears on your diploma</span>
        </div>
      </div>

    </div>
  </div>

  <span id="did_graduate_radio" *ngIf="isUndergrad"></span>


  <hr *ngIf="formGroup.get('previously_attended_college').enabled">

  <app-radio
    *ngIf="formGroup.get('certify_degree_radio').enabled"
    [label]="certifyDegreeText"
    [radioOpts]="diplomaRadioOptions"
    [radioFormControl]="formGroup.get('certify_degree_radio')"
    [errorMessage]="'Indicate if you will receive a diploma prior to enrolling'"
  ></app-radio>

  <app-radio
    *ngIf="formGroup.get('assert_gpa').enabled"
    [label]="'Do you certify that your final high school GPA was (or will be) at least a 1.5 GPA?'"
    [radioOpts]="diplomaRadioOptions"
    [radioFormControl]="formGroup.get('assert_gpa')"
    [errorMessage]="'You must certify your GPA'"
  ></app-radio>

  <app-radio
    *ngIf="!applicationService.spcApp"
    [ngClass]="{'hidden' : !formGroup.get('previously_attended_college').enabled}"
    [label]="'Have you attended Colleges, Universities or Dual Enrollment previously?'"
    [radioOpts]="diplomaRadioOptions"
    [radioFormControl]="formGroup.get('previously_attended_college')"
    [errorMessage]="'Indicate if you have attended Colleges, Universities or Dual Enrollment previously'"
  ></app-radio>

  <div *ngIf="!applicationService.spcApp && isUndergrad && formGroup.get('previously_attended_college').value === 'Yes'" class="form-control mb-0">
    <app-school-search
      [schoolType]=schoolType.College
      [multiSelect]="true"
      [formGroup]="formGroup.get('multiCollegeSearch')"
      [errorText]="'Provide a college name'"
      [ariaLabelMonth]="'Last Month Attended'"
      [ariaLabelYear]="'Last Year Attended'"
    ></app-school-search>
  </div>

  <app-expelled-radio [formGroup]="formGroup.get('expelled_group')"></app-expelled-radio>
  <app-previous-balance [formGroup]="formGroup"></app-previous-balance>

</div>

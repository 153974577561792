import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {EIconType} from '../../../../shared/component/icon/icon.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() approvedFileTypes: string[] = [];
  @Input() approvedFileTypesName: string[] = [];
  @Input() uploadType = '';

  @Output() filesEmitted = new EventEmitter<File[]>();
  fileNames: string[] = [];
  fileList: File[] = [];

  errorFileType = false;
  errorFileNameLength = false;
  errorFileSize = false;
  errorFileDuplicateName = false;
  showLoadingAnimation = false;

  public showSendButton = false;
  public eIconTypes: typeof EIconType = EIconType;

  constructor() { }

  ngOnInit(): void {
  }

  removeFile(fileName: string) {
    const index = this.fileNames.indexOf(fileName);
    if (index > -1) {
      this.fileNames.splice(index, 1);
    }
    this.fileList = this.fileList.filter(file => {
      return file.name !== fileName;
    });

    this.maybeShowSendButton();
  }

  maybeShowSendButton() {
    this.showSendButton = this.fileNames.length > 0;
  }

  onFileSelected(item) {
    const file: File = item.target.files[0];
    this.errorFileType = false;
    this.errorFileNameLength = false;
    this.errorFileSize = false;
    this.errorFileDuplicateName = false;

    if (!this.approvedFileTypes.includes(file.type)) {
      this.errorFileType = true;

    } else if (file.name.length > 100) {
      this.errorFileNameLength = true;

    } else if (file.size > 5000000) {
      this.errorFileSize = true;

    } else if (this.fileNames.includes(file.name)) {
      this.errorFileDuplicateName = true;
    }
    if (!this.errorFileType && !this.errorFileNameLength && !this.errorFileSize && !this.errorFileDuplicateName) {
      this.fileNames.push(file.name);
      this.fileList.push(file);
    }


    this.maybeShowSendButton();
  }

  filesSubmitted() {
    this.filesEmitted.next(this.fileList);

    this.fileList = [];
    this.fileNames = [];
  }
}

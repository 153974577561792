import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { Program } from 'src/app/shared/model/program.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { Subject } from 'rxjs';
import { MultiSearchableSelectDropdownComponent } from '../generic/multi-searchable-select-dropdown/multi-searchable-select-dropdown.component';
import { takeUntil } from 'rxjs/operators';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-name-fields',
  templateUrl: './name-fields.component.html',
  styles: ['.suffix { max-width: 105px; }']
})
export class NameFieldsComponent implements OnInit, OnDestroy {
  constructor(
    public formService: FormService,
    private programService: ProgramService,
    public authenticationService: AuthenticationService
  ) { }

  suffixs = ['', 'II', 'III', 'IV', 'J.D.', 'Jr.', 'LL.D.', 'M.D.', 'Ph.D.', 'R.N.', 'Sr.'];
  @Input() formGroup: FormGroup<any> = new FormGroup({});
  @Input() fieldsetLegend = '';

  // for vwo split test
  env = environment;
  @Input() displayIdChangeAlert = false;

  lastName: string;
  @Output() firstNameNuVal = new EventEmitter<string>();
  @Output() lastNameNuVal = new EventEmitter<string>();

  @ViewChild(MultiSearchableSelectDropdownComponent) multiLastNameSearch: MultiSearchableSelectDropdownComponent<string>;

  currentProgram = new Program();
  multiLastName = false;
  hsDiplomaLastName = '';

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  ngOnInit(): void {

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.currentProgram = program;

      if (this.formGroup.get('lastNameChange').value === 'Yes') {
        this.maybeSetLastName();
      }
      if (this.currentProgram.programCode === '') { // if empty program
        this.formGroup.get('lastNameChange').setValue('No'); // set last name change radio back to No
        this.multiLastName = false;
      }
    });

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.maybeSetLastName();
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  updatedLastNameRadio(val: string) {
    if (val === 'Yes') {
      this.maybeSetLastName();
    } else {
      this.maybeSetLastName();
    }
  }

  public maybeSetLastName() {
    this.lastName = this.formGroup.get('lastName').value;

    if (this.formGroup.get('lastNameChange').value === 'Yes' && this.currentProgram.programCode) {
      this.multiLastName = true;

      if (
        this.programService.isUndergrad(this.currentProgram)
        && this.hsDiplomaLastName !== ''
        && this.hsDiplomaLastName !== this.lastName
      ) {
        this.multiLastNameSearch.searchString = this.hsDiplomaLastName;
        this.multiLastNameSearch.addItem(false);
      }

    } else {
      this.multiLastName = false;
    }
  }

}

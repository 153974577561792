<ng-container [formGroup]="formGroup">
  <div class="form-control discovery_goals_container">

    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('goals_search'))"></div>

    <app-multi-searchable-select-dropdown
      [inputtedObjectList]="allGoals"
      [notListedText]="''"
      [itemFormControl]="formGroup.get('goals_search')"
      [inputLabelText]="'Applicant Goals'"
      (outputtedObjectList)="addGoals($event)"
      [smallerLabel]="true"
      [errorText]="'Select at least one goal'"
    >
    </app-multi-searchable-select-dropdown>

  </div>
</ng-container>

<ng-container [formGroup]="formGroup">

  <span id="picker-anchor" style="display: block;"></span>
  <div
    class="form-control degree-picker-container"
    [ngClass]="showMobileVersionOnDesktop ? 'show-mobile-version' : ''"
    [attr.data-campus]="this.currentCampus"
    [attr.data-level]="this.currentLevel"
  >
    <span class="large-question mb-3">What are you interested in studying?</span>
    <div class="program-tree">
      <app-alert *ngIf="selectedProgram.programCode === 'UNDE-BS-R'" alertStyle="" clean=true goFullWidth=true>
        <div style="display: flex;">
          <img src="assets/icons/lightbulb.svg">
          <p class="unknown-study-text">Picking a major can be a big decision - good thing you don't have to make it
            today. Just keep filling out your application and exploring all your options.</p>
        </div>
      </app-alert>

      <div
        class="mobile-show mobile-picker"
        *ngIf="pickerTree && pickerTree.length !== 0 && (!selectedProgram.programCode || selectedProgram.programCode === '')"
      >
        <button
          class="button button-primary button-large"
          type="button"
          (click)="triggerMobileDegreePicker()"
          (keyup.enter)="triggerMobileDegreePicker()"
          [attr.disabled]="!programsDisplayed ? 'true' : null"
        >

          <app-icon class="icon" aria-label="Search icon" [icon]="'search'"></app-icon>
          {{ programsDisplayed ? 'Browse Degree Programs' : 'Loading Programs' }}

        </button>
      </div>

      <!--SEARCH FOR DEGREE-->
      <div class="search-wrap mobile-hide">
        <form [formGroup]="formGroup">
          <app-icon class="icon" aria-label="Search icon" [icon]="'search'"></app-icon>

          <div class="degree_search_info" class="screen-reader-only">
            By default, all programs are listed below and are filterable by area of study. As you type, the results will
            update to match your search criteria.
          </div>

          <input
            formControlName="programSearchString"
            (input)="onSearchInput($event.target.value)"
            type="search"
            value=""
            name="degree-search"
            class="degree-search"
            #degreeSearches
            placeholder="Search Degree Programs"
            tabindex="0"
            aria-describedby="degree_search_info"
          >

          <app-icon
            *ngIf="this.formGroup.controls['programSearchString'] && this.formGroup.controls['programSearchString'].value.length > 0"
            (click)="resetSearch()"
            class="close-icon"
            [icon]="eIconTypes.x"
            tabindex="0"
            aria-label="Click to reset program search"
          ></app-icon>
        </form>
      </div>

      <app-loading-ring [circleColor]="'#0059C0'" *ngIf="!pickerTree || pickerTree.length == 0"></app-loading-ring>

      <div *ngIf="pickerTree && pickerTree.length !== 0" class="degree-picker mobile-hide"
           [ngClass]="{'no-active-program': selectedProgram.programCode === null}">
        <div class="degree-picker-areas">
          <h3>AREAS OF STUDY</h3>
          <ul aria-label="filter by area of study" class="area-filters">
            <li class="area-filter"
                *ngFor="let majorArea of areaOfStudies; let first = first"
                [class.lastSelectedArea]="first"
                tabindex="0"
                (click)="scrollToArea(majorArea)"
                (mouseup)="onMouseUp($event)"
                (mousedown)="onMouseDown($event)"
                (keydown)="onKeyDown(majorArea, $event)"
                attr.data-area="{{majorArea}}">{{ majorArea }}
            </li>
          </ul>
        </div>

        <app-degree-picker-select [formGroup]="formGroup"
                                  (emitSelectedProgram)="onProgramChosen($event)"
                                  (hasSearched)="hasSearched($event)"
                                  [tree]="pickerTree"
                                  [selectedProgram]="selectedProgram">
        </app-degree-picker-select>

        <div class="degree-picker--search_status screen-reader-only" role="alert"
             aria-live="assertive">{{ searchStatusText }}
        </div>

      </div>

      <div class="info-box" id="info-box" *ngIf="showInfoBox && selectedProgram.programCode">
        <div (click)="triggerMobileDegreePicker()" (keyup.enter)="triggerMobileDegreePicker()" class="edit mobile-show"
             tabindex="0">
          <app-icon class="icon" aria-label="Edit icon" [icon]="'edit'"></app-icon>
          <span>Edit</span>
        </div>

        <h4>{{ infoBoxHeader }}</h4>
        <h3 *ngIf="selectedProgram.cognate !== '' && selectedProgram.cognate !== null"
            class="program-name">{{ selectedProgram.majorGroup + ': ' + selectedProgram.cognate }}</h3>
        <h3 *ngIf="selectedProgram.cognate === '' || selectedProgram.cognate === null"
            class="program-name">{{ selectedProgram.majorGroup }}</h3>
      </div>

    </div>
  </div>

  <input id="program" name="program" formControlName="program" type="hidden" value="">

</ng-container>

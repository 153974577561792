import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-guardian-email',
  templateUrl: './guardian-email.component.html',
})
export class GuardianEmailComponent {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

}

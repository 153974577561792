<fieldset [formGroup]="formGroup">

  <app-name-fields-first-mid-last [fieldsetLegend]="fieldsetLegend" [formGroup]="formGroup"></app-name-fields-first-mid-last>
  <app-mini-alert *ngIf="!env.isAgent && authenticationService.isLoggedIn() && displayIdChangeAlert">In order to properly update this information, you will be asked to submit two forms of identification after you have submitted your application.</app-mini-alert>

  <div class="row">
    <div class="col-xl-2 form-control">
      <label for="suffix">
        <span class="input-label-text">Suffix</span>
      </label>
      <div class="suffix select-input-wrap">
        <select name="suffix" id="suffix" formControlName="suffix">
          <option selected disabled value="">Select</option>
          <option *ngFor="let suffix of suffixs" value="{{ suffix }}">{{ suffix }}</option>
        </select>
        <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
      </div>
    </div>
  </div>

  <app-radio
    *ngIf="formGroup.get('lastNameChange')"
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('lastNameChange')"
    [label]="'Has your last name changed since your previous degree?'"
    [errorMessage]="'This field is required'"
    (radioChanged)="updatedLastNameRadio($event)"
  >
  </app-radio>

  <div *ngIf="formGroup.get('multiLastName')" class="row" [ngClass]="{'hidden' : !multiLastName}">
    <div class="col-sm-5">
      <app-multi-searchable-select-dropdown
        [notListedText]="''"
        [inputLabelText]="'All last names on your degrees'"
        [maxSelect]="5"
        [itemFormControl]="formGroup.get('multiLastName')"
        [allowCustom]=true
      ></app-multi-searchable-select-dropdown>
    </div>
  </div>
</fieldset>

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchService } from '../../../../shared/service/search.service';
import { FormService } from '../../../../shared/service/form.service';

@Component({
  selector: 'app-searchable-select-drop-down',
  templateUrl: './searchable-select-drop-down.component.html'
})
export class SearchableSelectDropDownComponent<T> implements OnInit, OnChanges {

  constructor(
    public formService: FormService,
    private searchService: SearchService,
    private elm: ElementRef
  ) { }

  @Input() public itemList = new Array<any>();
  @Input() public formGroup: FormGroup;
  @Input() public itemFormControl: FormControl;
  @Input() public displayProperty: string;
  @Input() public optionValueProperty: string;
  @Input() public placeholder: string;
  @Input() public inputLabelText: string;
  @Input() public autoComplete = 'no';
  @Input() public initItem: string;
  @Input() public allowCustom = false;
  @Input() public floatingResults = true;
  @Input() public errorMessage = 'This field is required';
  @Input() public canSearchByValues = false;

  @Output() emitedItem = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  displayItems = false;
  highlightedItem: T;
  itemSearch = '';
  filteredItems = this.itemList;
  selectedItem: T;

  tooManyResults = false;

  searchElement;

  private static toTitleCase(phrase: string): string {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemList && !changes.itemList.firstChange) {
      this.itemList = changes.itemList.currentValue;
      if (this.initItem) {
        this.selectInitItem(this.initItem);
      }
    }

  }

  ngOnInit(): void {
    if (this.initItem) {
      this.selectInitItem(this.initItem);
    }

  }

  public searchItems(value: string) {
    this.itemSearch = value;
    if (this.itemSearch !== '') {
        this.displayItems = true;
       }
    if (this.itemSearch) {
        this.filteredItems = this.itemList.filter((item: T) => {
          if (item[this.displayProperty].toLowerCase().includes(value.toLowerCase())
            || (this.canSearchByValues && item[this.optionValueProperty].toLowerCase().includes(value.toLowerCase()))) {
            return item[this.displayProperty];
          }
        });
        this.tooManyResults = this.filteredItems.length >= 70;
      } else {
        this.filteredItems = this.itemList;
        this.displayItems = true;
        this.highlightedItem = null;
      }

    this.searchElement = this.elm.nativeElement.querySelector('#' + this.getFormControlName());

  }

  public selectInitItem(initItem: string) {
    const findItem = this.itemList.find((thisItem: T) => thisItem[this.optionValueProperty] === initItem);
    if (findItem) {
        this.selectItem(findItem, false);
      }
  }

  public showAllItems() {
    if (this.selectedItem) {
      this.itemFormControl.setValue('', {emitEvent: false});
    }
    // This line shows all items, not appropriate for keying down after searching
    this.filteredItems = this.itemList;
    this.displayItems = true;
  }

  public showSearchedItems() {
    // This pulls the current filtered items and displays them
    this.displayItems = true;
  }

  public resetSearch() {
    if (this.selectedItem && this.selectedItem[this.displayProperty] !== this.itemFormControl.value && this.selectedItem[this.optionValueProperty]) {
      this.itemFormControl.setValue(this.selectedItem[this.displayProperty]);
    } else if (!this.selectedItem || !this.selectedItem[this.optionValueProperty]) {
      this.itemFormControl.setValue('');
    }
    this.highlightedItem = null;
    this.displayItems = false;
    this.itemSearch = '';
  }

  customAddItem(itemName: string) {
    const formattedName = SearchableSelectDropDownComponent.toTitleCase(itemName);
    const selectedItem = {};
    selectedItem[this.displayProperty] = formattedName;
    selectedItem[this.optionValueProperty] = formattedName;
    this.selectItem(selectedItem);
  }

  public selectItem(item, event?) {
    if (event) {
      event.preventDefault(); // prevents form submission in the case of hitting enter on the search input
    }
    this.itemFormControl.markAsPristine();

    if ((!item || !item[this.displayProperty]) && this.filteredItems.length === 1) {
      item = this.filteredItems[0];
    } else if (!item || !item[this.displayProperty] && this.highlightedItem) {
      item = this.highlightedItem;
    }

    if (!item) {
      return false;
    }

    this.selectedItem = item;

    const inputEl = this.elm.nativeElement.querySelector('#' + this.getFormControlName());

    this.itemFormControl.setValue(item[this.displayProperty], {emitEvent: false});
    this.displayItems = false;
    this.emitedItem.emit(item);
    this.highlightedItem = null;

    if (inputEl && inputEl) {
      inputEl.blur();
    }
  }

  public clearSelection() {
    this.selectedItem = null;
    this.itemFormControl.setValue('');
    this.emitedItem.emit(null);
  }

  public changeHighlightedItem(direction: 'UP' | 'DOWN') {
    if (direction === 'UP') {
      this.highlightedItem = this.searchService.findPrev(this.filteredItems, this.highlightedItem);
    } else if (direction === 'DOWN') {
      this.highlightedItem = this.searchService.findNext(this.filteredItems, this.highlightedItem);
    }

    // this is a safe way of using document as body is read-only HTMLElement being used only to scroll into view
    const searchResultElement = document.body.querySelector('#search-input-results li[data-code="' + this.highlightedItem[this.optionValueProperty] + '"]');
    if (searchResultElement) {
      searchResultElement.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
    }
  }

  public getFormControlName(): string {
    const formGroup = this.itemFormControl.parent.controls;
    return Object.keys(formGroup).find(name => this.itemFormControl === formGroup[name]);
  }

  public getFormControlParent(): FormGroup {
    return this.itemFormControl.parent as FormGroup;
  }

  trackByFn(index, item) {
    return item[this.displayProperty];
  }

}

<ng-container [formGroup]="formGroup">
  <div [hidden]="!displayInput" class="startTerm-select-container form-control">
    <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('start_term'))"></div>
    <label for="startTerm">
      <span class="input-label-text">Desired Start Term</span>
    </label>
    <div class="select-input-wrap">
      <select
        formControlName="start_term"
        name="startTerm"
        id="startTerm"
        (change)="updateTermSelect($event.target.value)"
        [attr.disabled]="displayedTerms.length === 0 ? 'disabled' : null"
        [attr.aria-invalid]="formService.hasErrors(formGroup.get('start_term')) ? 'true' : null"
        [attr.aria-describedby]="formService.hasErrors(formGroup.get('start_term')) ? 'start-term-error' : null"
      >
        <option value="" selected disabled>Select</option>
        <option *ngFor="let option of displayedTerms" value="{{ option.termCode }}">{{ option.termName }}</option>
      </select>
      <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'"></app-icon>
    </div>

    <span class="error" id="start-term-error" role="alert" aria-live="polite" *ngIf="formService.hasErrors(formGroup.get('start_term'))">Select your desired start term</span>

  </div>
</ng-container>

<ng-container *ngIf="showHearAboutUs" [formGroup]="formGroup">
  <div *ngIf="formGroup.get('hear_about_us_dropdown').enabled" class="hear_about_us_dropdown form-control">
    <label for="hear_about_us_dropdown">
      <h4 class="input-label-text">How did you hear about us?</h4>
    </label>
    <div class="select-input-wrap">
      <select name="hear_about_us_dropdown" id="hear_about_us_dropdown" formControlName="hear_about_us_dropdown">
        <option value="" disabled="true" selected="true">Select</option>
        <option *ngFor="let choice of choices" value="{{ choice }}">{{ choice }}</option>
      </select>

      <app-icon class="icon" aria-label="Search icon" [icon]="'search'"></app-icon>

    </div>
  </div>
</ng-container>

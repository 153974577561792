import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { FormService } from 'src/app/shared/service/form.service';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styles: ['']
})
export class TextboxComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() textboxHeader: string;
  @Input() textboxExample: string;
  @Input() textboxError = '';
  @Input() nameForControl: string;
  @Input() showAlerts: boolean;
  @Input() prompt = '';
  @Input() answerlength = 2000;
  @Input() minWords: number;
  @Input() required = false;
  @Input() requiredErrorMessage = 'This input is required';
  @Output() textboxEmmitter = new EventEmitter<string>();

  textboxLength = 0;
  wordLength = 0;
  minWordCount = 0;
  wordsUsed = 0;
  constructor(
    public formService: FormService,
  ) { }

  ngOnInit(): void {
    const newControl = new FormControlCustom<string>({ required: this.requiredErrorMessage});

    if (this.required) {
      newControl.setValidators([Validators.required]);
    }

    this.formGroup.addControl(this.nameForControl, newControl);
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      this.formGroup.removeControl(this.nameForControl);
    }
  }

  updateLength(val: string) {
    this.textboxLength = val.length;
    if (this.minWords) {
      this.wordLength = this.countWords(val);
    }
  }

  updateTextbox(val: string) {
    this.formGroup.get(this.nameForControl).markAsDirty();
    this.formGroup.get(this.nameForControl).markAsTouched();
    this.textboxEmmitter.emit(val);
    if (this.minWords && this.showAlerts) {
      this.validateLength();
    }
  }

  countWords(s){
    if (s && s.length > 0) {
    s = s.replace(/(^\s*)|(\s*$)/gi, ''); // exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' '); // 2 or more space to 1
    s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
    return s.split(' ').filter(str => str !== '').length;
    }
    // return s.split(' ').filter(String).length; - this can also be used
  }

  validateLength() {
    this.wordsUsed = this.countWords(this.formGroup.get(this.nameForControl).value);
    if (this.countWords(this.formGroup.get(this.nameForControl).value) > 150 || this.countWords(this.formGroup.get(this.nameForControl).value) < 100) {
      this.formGroup.get(this.nameForControl).setValidators([Validators.requiredTrue]);
      this.formGroup.get(this.nameForControl).setErrors({required: true});
    } else {
      this.formGroup.get(this.nameForControl).clearValidators();
      this.formGroup.get(this.nameForControl).setErrors(null);
    }
    this.formGroup.updateValueAndValidity();
  }
}

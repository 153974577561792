<fieldset
  role="radiogroup"
  [attr.aria-describedby]="getFormControlName()+'-description'"
  [formGroup]="getFormControlParent()"
  id="{{getFormControlName()}}"
>
  <div class="relative">
    <div class="validation-error-bar" *ngIf="getRadioControl().touched && getRadioControl().errors"></div>

    <legend class="bold-label mb-3" *ngIf="label" id="{{getFormControlName()}}-description">
      {{label}}
    </legend>

    <div #radioContainer class="radio-container">
      <label
        *ngFor="let option of radioOpts; let i = index"
        class="radio-input"
        [ngClass]="{
          'selected' : getRadioControl().value === option.value
        }"
        for="{{getFormControlName()}}_{{option.value}}"
        tabindex="{{ getRadioControl().value === option.value || getRadioControl().value === '' && i === 0 ? '0' : '-1' }}"
        role="radio"
        (keydown.arrowup)="focusRadio($event, i, 'prev')"
        (keydown.arrowleft)="focusRadio($event, i, 'prev')"
        (keydown.arrowdown)="focusRadio($event, i, 'next')"
        (keydown.arrowright)="focusRadio($event, i, 'next')"
        (keydown.enter)="onClick($event)"
        (keydown.space)="onClick($event)"
        (click)="onClick($event)"
      >
        <input
          value="{{option.value}}"
          type="radio"
          formControlName="{{getFormControlName()}}"
          [attr.aria-label]="option.label"
          [attr.data-val]="option.value"
          name="{{getFormControlName()}}"
          id="{{getFormControlName()}}_{{option.value}}"
          tabindex="-1"
          [attr.aria-invalid]="getRadioControl().touched && getRadioControl().errors ? 'true' : null"
          [attr.aria-describedby]="getRadioControl().touched && getRadioControl().errors ? getFormControlName()+'-error' : null"
          (change)="radioChanged.next(option.value)"
        >
        {{option.label}}
      </label>
    </div>

    <ng-container *ngIf="!radioControlIsCustom(getRadioControl())">
      <span
        class="error"
        id="{{getFormControlName()}}-error"
        role="dialog"
        *ngIf="getRadioControl().touched && getRadioControl().errors"
      >{{errorMessage}}</span>
    </ng-container>

    <ng-container *ngIf="radioControlIsCustom(getRadioControl()) && getRadioControl().touched">

      <span
        *ngIf="getCustomErrorMessages() && getCustomErrorMessages().required && getRadioControl().errors && getRadioControl().errors.required"
        class="error" id="{{getFormControlName()}}-error" role="dialog" aria-live="polite">{{getCustomErrorMessages().required}}</span>

      <span
        *ngIf="getCustomErrorMessages() && getCustomErrorMessages().pattern && getRadioControl().errors && getRadioControl().errors.pattern"
        class="error" id="{{getFormControlName()}}-error" role="dialog" aria-live="polite">{{getCustomErrorMessages().pattern}}</span>

    </ng-container>
  </div>
</fieldset>

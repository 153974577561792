import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EndSessionRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RouterService } from './router.service';
import { environment } from '../../../environments/environment';
import { CookieService } from './cookie.service';

/* event message from msal-angular:
https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
*/

export enum ADRoles {
  SAT_LU_AGENT = 'SAT_LU_Agent',
  SAT_APP_DEV = 'SAT_App_Dev'
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  endSubscriptions = new Subject<void>();
  username = '';
  sessionExpirationDate: Date;

  readonly EMPLOYEE_START_HOUR = 7;  // 7AM
  readonly EMPLOYEE_END_HOUR = 20;  // 8PM

  constructor(
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private routerService: RouterService,
    private cookieService: CookieService
  ) {

    this.msalService.initialize().pipe(takeUntil(this.endSubscriptions)).subscribe();

    // set up subscription, fires after acquiring any token
    // fires after initial login as well
    this.broadcastService.msalSubject$
      .pipe(
        takeUntil(this.endSubscriptions),
        filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END)
      )
      .subscribe(async () => {

        const account = this.getActiveAccount();
        this.sessionExpirationDate = new Date(account.idTokenClaims?.exp * 1000);   // Convert to milliseconds then to Date Object
        const username = account.username;
        if (username) {
          this.username = stripEmail(username);
        }

      });


    // set up subscription, fires if getting Azure token fails
    this.broadcastService.msalSubject$
      .pipe(
        takeUntil(this.endSubscriptions),
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
      )
      .subscribe(async () => {

        localStorage.clear();

      });


  } // end constructor

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  async initStudentLoginProcess() {
    this.cookieService.addCookie('student_login_in_process', 'true');
    await this.msalService.instance.handleRedirectPromise();
    this.routerService.navigate('/login')
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  public getUsername() {
    return stripEmail(this.username);
  }

  logout() {
    this.msalService.logout();
  }

  private getActiveAccount() {
    return this.msalService.instance.getAllAccounts().length > 0 ? this.msalService.instance.getAllAccounts()[0] : null;
  }

  logoutOfCurrentAccount() {

    const logoutRequest: EndSessionRequest = {
      postLogoutRedirectUri : window.location.href,
      authority: `https://login.microsoftonline.com/${environment.azureTenantId}`,
      account: this.getActiveAccount(),
    }

    this.msalService.logout(logoutRequest)
  }

  /**
   * Check if user is authorized. If account has SuperApplyLu, they have all permissions
   *
   * (roles) is what roles are authorized. Method uses what roles are associated with the account
   *
   * @param roles - AD Roles allowed
   * @returns true if account is authorized
   */
  public isAuthorised(roles: ADRoles[]): boolean {
    // Make sure that the token in the current account is valid
    // If NOT refresh it
    this.validateToken();

    const account = this.getActiveAccount();
    const userRoles = account.idTokenClaims?.roles;
    if (!userRoles) { return false; }
    // true if account contains the role SAT_App_Dev, or has a authorized role
    return (userRoles.indexOf(ADRoles.SAT_APP_DEV) >= 0) || roles.some(r => userRoles.indexOf(r) >= 0);
  }

  private validateToken() {
    // This will make sure that the token is valid
    // This needs to run after HANDLE_REDIRECT_END Subscription
    if (environment.isAgent) {
      const tokenFromToday = this.isTokenFromToday();
      if (this.isBusinessHours() && tokenFromToday) {
        // Refresh silently during business hours
        this.getTokenSilent();
      } else {
        // Force login otherwise
        this.getTokenRedirect(!tokenFromToday);
      }
    }
  }

  public getTokenSilent(): void {
    // Refresh token behind the scenes if session is expired
    if (new Date() > this.sessionExpirationDate) {
      this.msalService.acquireTokenSilent({
        scopes: [
          'user.read'
        ],
        redirectUri: window.location.origin + '/agent',
        authority: `https://login.microsoftonline.com/${environment.azureTenantId}`,
        account: {
          username: this.getUsername(),
          homeAccountId: undefined,
          environment: undefined,
          tenantId: environment.azureTenantId,
          localAccountId: undefined
        },
        forceRefresh: true
      }).subscribe();
    }
  }

  public getTokenRedirect(forceRedirect = false) {
    // Redirect user to login if session is expired
    if (new Date() > this.sessionExpirationDate || forceRedirect) {
      this.msalService.acquireTokenRedirect({
        scopes: [
          'user.read',
        ]
      }).subscribe();
    }
  }

  public isTokenFromToday() {
    const today = new Date();
    return this.sessionExpirationDate.getDay() === today.getDay()
      && this.sessionExpirationDate.getMonth() === today.getMonth()
      && this.sessionExpirationDate.getFullYear() === today.getFullYear();
  }

  isBusinessHours(): boolean {
    return new Date().getHours() >= this.EMPLOYEE_START_HOUR && new Date().getHours() < this.EMPLOYEE_END_HOUR;
  }
}

function stripEmail(str: string): string {
  return str.replace(/@.*$/, '');
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { FormService } from 'src/app/shared/service/form.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import {SchoolType} from '../generic/school-search/school-search-fieldset/school-search-fieldset.component';
import {College} from '../../../shared/model/application/college.model';
import {HighSchool} from '../../../shared/model/application/highschool.model';

@Component({
  selector: 'app-dismissal-radio',
  templateUrl: './dismissal-radio.component.html',
  styles: [
  ]
})

export class DismissalRadioComponent implements OnInit {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  currentCampus: string;

  protected readonly SchoolType = SchoolType;

  constructor(
    public formService: FormService,
    public programService: ProgramService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('dismissalRadio', new FormControlCustom<string>({ required: 'Indicate if you were dismissed from your last institution'}, {value: '', disabled: true} ) );
    this.formGroup.addControl('dismissal_school', new FormControlCustom<string>({}, {value: '', disabled: false} ) );
    this.formGroup.addControl('dismissal_school_search', new FormControlCustom<string>({}, {value: '', disabled: false} , [Validators.required]) );
    this.formService.updatedCampus.pipe(takeUntil(this.endSubscriptions)).subscribe(val => {
      this.currentCampus = val;
      if (this.currentCampus === 'R') {
        this.formGroup.get('dismissal_school_search').enable();
        this.formGroup.get('dismissal_school_search').setValidators([Validators.required]);
        this.formGroup.get('dismissalRadio').enable();
        this.formGroup.get('dismissalRadio').setValidators([Validators.required]);
      } else {
        this.formGroup.get('dismissal_school_search').setValue('');
        this.formGroup.get('dismissal_school_search').clearValidators();
        this.formGroup.get('dismissal_school_search').disable();
        this.formGroup.get('dismissalRadio').setValue('');
        this.formGroup.get('dismissalRadio').disable();
        this.formGroup.get('dismissalRadio').clearValidators();
      }
    });

    this.formGroup.get('dismissalRadio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      if (this.formGroup.get('dismissalRadio').value === 'Yes') {
        this.formGroup.get('dismissal_school_search').enable();
        this.formGroup.get('dismissal_school_search').setValidators([Validators.required]);
      } else if (this.formGroup.get('dismissalRadio').value === 'No') {
        this.formGroup.get('dismissal_school_search').setValue('');
        this.formGroup.get('dismissal_school_search').clearValidators();
        this.formGroup.get('dismissal_school_search').disable();
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  emittedSchool(emittedSchool: (College|HighSchool)) {
    if (emittedSchool.ceebCode) {
      this.formGroup.get('dismissal_school').setValue(emittedSchool.ceebCode);
    } else if (emittedSchool.description) {
      this.formGroup.get('dismissal_school').setValue(emittedSchool.description);
    }
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/service/form.service';
import { Program } from '../../../shared/model/program.model';
import { Term } from 'src/app/shared/model/term.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-college-certs-radio',
  templateUrl: './college-certs-radio.component.html',
  styles: ['.certs-input-dropdown{ max-width: 345px}']
})
export class CollegeCertsRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({});

  selectedProgram: Program;
  endSubscriptions = new Subject<void>();

  certsSearch = '';
  allCerts: Term[] = [
    { name: 'Nursing', value: 'Nursing' },
    { name: 'Aviation', value: 'Aviation' },
    { name: 'EMT', value: 'EMT' },
    { name: 'Early Childhood Education', value: 'Early Childhood Education' },
    { name: 'Microsoft, CompTIA, or Cisco Certified', value: 'Microsoft, CompTIA, or Cisco Certified' },
    { name: 'Law Enforcement, Corrections, Border Patrol', value: 'Law Enforcement, Corrections, Border Patrol' },
    { name: 'Homeland Security or FEMA Training', value: 'Homeland Security or FEMA Training' },
  ];

  selectedCerts = [];

  constructor(
    public formService: FormService,
    private programService: ProgramService
  ) { }

  ngOnInit() {

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      this.selectedProgram = program;

      if ((this.selectedProgram.degreeLevel === 'Bachelor' || this.selectedProgram.degreeLevel === 'Associate') && this.selectedProgram.campCode === 'D') {
        this.formGroup.get('college_certs_select').get('certs').enable();

      } else {
        this.formGroup.get('college_certs_select').get('certs').disable();
      }

    });

    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      const items: Term[] = this.formGroup.get('college_certs_select').get('certs_selected_options').value;
      if (items.length > 0) {
        this.formGroup.get('college_certs_select').get('certs').enable();
        this.addCerts(items);
      }
    });

  }

  ngOnDestroy() {

    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  addCerts(items: Term[]) {
    this.selectedCerts = [];
    items.forEach(cert => {
      this.selectedCerts.push(cert.value);
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Lead } from '../model/lead';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  private leadBaseURL = '/rest/lead/';

  constructor(
    private http: HttpClient,
  ) { }

  public async leadByTIDSearch(tid: string): Promise<Observable<Lead>> {
    return this.http.get<Lead>(this.leadBaseURL + tid);
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Program } from 'src/app/shared/model/program.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { BlockedApplicationService } from 'src/app/shared/service/blocked-application.service';
import { FormService } from 'src/app/shared/service/form.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { environment } from 'src/environments/environment';
import { NameFieldsComponent } from '../../input/name-fields/name-fields.component';
import { FullAddressComponent } from '../../input/personal-info/full-address/full-address.component';
import { PhoneNumberComponent } from '../../input/phone-number/phone-number.component';
import { CookieService } from "../../../shared/service/cookie.service";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html'
})
export class PersonalInformationComponent implements OnInit, OnDestroy {

  constructor(
    public applicationService: ApplicationService,
    private blockedAppService: BlockedApplicationService,
    private programService: ProgramService,
    public formService: FormService,
    public cookieService: CookieService,
    public authenticationService: AuthenticationService
  ) {
  }

  @ViewChild(NameFieldsComponent) nameFieldsComponent: NameFieldsComponent;
  @ViewChild(FullAddressComponent) fullAddressComponent: FullAddressComponent;
  @ViewChild(PhoneNumberComponent) phoneNumberComponent: PhoneNumberComponent;

  @Input() formGroup: FormGroup;

  endSubscriptions = new Subject<void>();

  currentProgram = this.programService.getCurrentProgram();
  currentCampus = this.formService.getCurrentCampus();
  levelCategory = this.programService.getCurrentLevelCategory();

  // for student login functionality
  alertDisplayed = new Subject<void>();
  displayNameFieldsMiniAlert = false;
  displayBdayFieldsMiniAlert = false;
  env = environment;

  ngOnInit() {
    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe((program: Program) => {
      this.currentProgram = program;
      this.currentCampus = this.formService.getCurrentCampus();
      this.levelCategory = this.programService.getCurrentLevelCategory();
    });


    this.formService.prefillForm.pipe(takeUntil(this.endSubscriptions)).subscribe(prefillObj => {

      // check to see if they have previously changed their info in order to display alert
      if (
        prefillObj.formData['data_fields']['shouldDisplayIdUpload'] && prefillObj.formData['data_fields']['shouldDisplayIdUpload'] === true
      ) {
        this.displayNameFieldsMiniAlert = true;
      }

    });


    if (this.authenticationService.isLoggedIn
      && !this.applicationService.spcApp && !this.applicationService.festivalsApp
    ) {

      this.formService.getStudentDataImportedSub().pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
        this.setupIdChangeSubs();
      });

    }

  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

  maybeCheckBlockedEmail(email: string) {
    if (!environment.isAgent) {
      this.blockedAppService.checkBlockedEmail(email);
    }
  }

  setupIdChangeSubs() {
    const bdateFields = this.formGroup.get('birth_date');
    const nameFields = this.formGroup.get('name_fields');
    // now set up subscriptions to fields that if changed will trigger the mini alert and the id upload component on the /thank-you screen
    merge(
      nameFields.get('firstName').valueChanges,
      nameFields.get('lastName').valueChanges,
      nameFields.get('middleName').valueChanges,
    ).pipe(takeUntil(this.alertDisplayed)).subscribe(value => {
      this.displayNameFieldsMiniAlert = true;
      this.formService.setShouldDisplayIdUploadSub(true);
      this.alertDisplayed.next();
      this.alertDisplayed.complete();
    });

    merge(
      bdateFields.get('birth_day').valueChanges,
      bdateFields.get('birth_month').valueChanges,
      bdateFields.get('birth_year').valueChanges,
    ).pipe(takeUntil(this.alertDisplayed)).subscribe(value => {
      this.displayBdayFieldsMiniAlert = true;
      this.formService.setShouldDisplayIdUploadSub(true);
      this.alertDisplayed.next();
      this.alertDisplayed.complete();
    });
  }

}

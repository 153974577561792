<fieldset class="form-control" [formGroup]="formGroup">
<div class="relative ">


  <div class="validation-error-bar"
  *ngIf="
    formService.hasErrors(formGroup.get('birth_month'))
    || formService.hasErrors(formGroup.get('birth_day'))
    || formService.hasErrors(formGroup.get('birth_year'))
    || formService.hasErrors(formGroup.get('birth_date_is_valid'))
  "
  ></div>


  <legend>
    <label for="birth_month">
      <span class="input-label-text">Birthdate</span>
    </label>
  </legend>

  <span id="birth_date_is_valid"></span>

  <div class="row">
    <div class="col-auto birth_month">
      <div class="">

        <div class="select-input-wrap">
          <label for="birth_month">
            <select
              (change)="getBirthMonth($event.target.value)"
              name="birth_month"
              id="birth_month"
              aria-label="Birth month"
              formControlName="birth_month"
              [attr.aria-invalid]="formService.hasErrors(formGroup.get('birth_month')) ? 'true' : null"
              aria-describedby="birth-month-error"
            >
              <option selected disabled value="">MM</option>
              <option *ngFor="let month of months" value="{{ month.code }}">{{ month.code + ' - ' + month.name }}</option>
            </select>
          </label>

          <app-icon class="icon" aria-label="Dropdown Arrow icon" [icon]="'dropdown-arrow'" aria-label="Dropdown Arrow icon"></app-icon>

        </div>
      </div>
    </div>
    <div class="col-auto birth_day">
      <div class="">
        <div class="select-input-wrap">
            <input
              (blur)="getBirthDay($event.target.value)"
              appNumbersOnly
              placeholder="DD"
              aria-label="Day of Birth"
              type="text"
              inputmode="numeric"
              autocomplete="no"
              name="birth_day"
              id="birth_day"
              formControlName="birth_day"
              maxlength="2"
              [attr.aria-invalid]="formService.hasErrors(formGroup.get('birth_day')) ? 'true' : null"
              aria-describedby="birth-day-error"
            >

        </div>

      </div>
    </div>
    <app-year
    class="col-auto"
    [itemFormControl]="formGroup.get('birth_year')"
    [minYear]="minimumYear"
    [maxYear]="maximumYear"
    [ariaLabel]="'Birth Year'"
    >
    </app-year>

  </div>

  <input type="hidden" formControlName="birth_date_is_valid">

  <div class="errors">

    <span class="error" role="dialog" aria-live="polite" id="birth-month-error"
            *ngIf="formService.hasErrors(formGroup.get('birth_month'))">Select a birth month</span>

    <span class="error" *ngIf="formService.hasRequiredError(formGroup.get('birth_day'))">Enter a birth day</span>
    <span class="error" *ngIf="formService.hasPatternError(formGroup.get('birth_day'))">Enter a day between 1 and
      {{maxDays}}</span>

    <span class="error" id="birth_year-error" role="dialog"
      *ngIf="formService.hasRequiredError(formGroup.get('birth_year'))">Enter a valid year</span>
    <span class="error" id="birth_year-error" role="dialog"
      *ngIf="formService.hasPatternError(formGroup.get('birth_year')) || (formGroup.get('birth_year').touched && formGroup.get('birth_year').errors && (formGroup.get('birth_year').errors.min || formGroup.get('birth_year').errors.max))">A
      valid year is required</span>

    <span class="error" *ngIf="formService.hasPatternError(formGroup.get('birth_date_is_valid'))">Birth date cannot be today or later</span>

  </div>

</div>
</fieldset>

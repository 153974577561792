import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from 'src/app/form/input/input.module';
import {OtpModalComponent} from './otp-modal.component';
import {IconModule} from 'src/app/shared/component/icon/icon.module';
import {MiniAlertModule} from 'src/app/alert/mini-alert/mini-alert.module';
import {DirectivesModule} from "../../shared/directive/directives.module";
import {StyledRadioModule} from "../../form/input/generic/styled-radio/styled-radio.module";
import {RadioModule} from "../../form/input/generic/radio/radio.module";
import {VerticalRadioModule} from "../../form/input/generic/vertical-radio/vertical-radio.module";

@NgModule({
  declarations: [
    OtpModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    MiniAlertModule,
    IconModule,
    StyledRadioModule,
    DirectivesModule,
    RadioModule,
    VerticalRadioModule,

  ],
  exports: [
    OtpModalComponent
  ]
})
export class OtpModalModule {
}

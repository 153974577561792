<ng-container *ngIf="isModalDisplayed">
  <dialog class="modal-container" aria-modal="true" role='alertdialog' #modalContainer>

    <a class="modalClose" aria-label="Close" (click)="cancelOtpModal()" (keyup.enter)="cancelOtpModal()" tabindex="0"></a>

    <div class="modalContent">

      <div *ngIf="!codeIsSent">
        <div class="mb-4">
          <h2>We need to verify your contact information to complete your submission. </h2>
          <p class="otp-sub">{{ promptText }}</p>
        </div>

        <form>
          <div class="row less-padding">
            <app-vertical-radio
              [radioFormControl]="formGroup.get('otp_options_radio')"
              [radioOpts]="radioOpts"
            ></app-vertical-radio>
          </div>
        </form>

        <span class="error"
              *ngIf="isSendInErrorState">
          There was an issue with sending your code, please wait a few minutes and then <span class="link"
                                                                                              (click)="SendOtpCode($event)">resend code</span>
        </span>
        <div class="row mt-5">
          <p class="otp-disclaimer">This code will be valid for 10 minutes. </p>
          <div class="btn-container text-center">
            <button class="btn" (click)="SendOtpCode($event)"> Continue
              <app-icon class="continueArrow" aria-hidden="true" [icon]="'side-white-arrow'"></app-icon>
            </button>
          </div>
        </div>
        <p class="otp-optin-disclaimer">By selecting a verification method, you give us consent to send you a one time
          passcode.</p>
      </div>

      <div *ngIf="codeIsSent && !codeIsVerified">
        <div class="mb-4">
          <h2>We need to verify your contact information to complete your submission. </h2>
          <p class="p-please-review">{{ hasBeenSentMessage }}</p>
        </div>
        <div class="form-control">
          <div class="relative">
            <div class="validation-error-bar "
                 *ngIf="formService.hasErrors(formGroup.get('otp_verify')) || isVerifyInErrorState"></div>
            <form [formGroup]="formGroup">
              <label class="col-md-5 mb-4 otp-code">
                <span class="input-label-text">Enter Code:</span>
                <input appNumbersOnly
                       aria-label="OTP Code"
                       type="text"
                       inputmode="numeric"
                       autocomplete="one-time-code"
                       name="otp_input"
                       id="otp_input"
                       formControlName="otp_verify"
                       maxlength="10">
              </label>
              <span class="otp-resend input-label-text">Didn't receive code? <span class="link"
                                                                                   (click)="SendOtpCode($event)">Resend Code</span></span>

              <span
                class="error"
                id="otp-verification-error"
                *ngIf="formService.hasErrors(formGroup.get('otp_verify')) || isVerifyInErrorState">
                One Time Passcode is invalid, please try again or <span class="link" (click)="SendOtpCode($event)">resend code</span>
              </span>


            </form>
          </div>
        </div>
        <div class="btn-container row">
          <span class="otp-change link" (click)="changeVerficationMethod($event)"><span class="caret"></span> Change Verification Method</span>
          <button class="btn" (click)="verifyOtpCode($event)" [disabled]="!isVerifyButtonEnabled"> Continue
            <app-icon class="continueArrow" aria-hidden="true" [icon]="'side-white-arrow'"></app-icon>
          </button>
        </div>
      </div>

    </div>
  </dialog>
  <div class="modalOverlay" tabindex="-1"></div>
</ng-container>

<ng-container [formGroup]="formGroup">

  <app-radio
    [radioOpts]="yesNoRadioOpts"
    [radioFormControl]="formGroup.get('expelled_radio_question')"
    [label]="'Were you expelled or dismissed from your last Educational Institution?'"
    [errorMessage]="'You must indicate if you were expelled or dismissed from your last Educational Institution'"
    [ngClass]="{'hidden' : justSearch}"
  ></app-radio>

  <div class="form-control" *ngIf="formGroup.get('expelled_radio_question').value === 'Yes' || justSearch">
    <app-school-search-input
      [formGroup]="formGroup"
      [inputFormControl]="formGroup.get('school_search')"
      [searchInputName]="'school_search'"
      [searchLabelText]="'Name of last school that dismissed you'"
      (outputtedSchool)="emittedSchool($event)"
      [schoolType]=schoolType.All
      [searchErrorText]="'Provide the last school that dismissed you'"
    ></app-school-search-input>

    <app-alert class="mb-3" type="generic" *ngIf="formGroup.get('expelled_radio_question').value === 'Yes'">All students who intend to gain admission to Liberty must be eligible for readmission at their last institution attended.</app-alert>
  </div>

</ng-container>

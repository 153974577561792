import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlCustom } from 'src/app/shared/form-control-custom';
import { RadioOpts } from 'src/app/shared/model/radio-options.model';
import { FormService } from 'src/app/shared/service/form.service';
import { ProgramService } from 'src/app/shared/service/program.service';

@Component({
  selector: 'app-probation-radio',
  templateUrl: './probation-radio.component.html',
  styles: [
  ]
})
export class ProbationRadioComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup<any> = new FormGroup({});

  textboxHeader = 'Please provide the following details: Name of institution, reason for dismissal or disciplinary probation, if you got off of disciplinary probation, how long ago it happened, and a statement that you will comply with the Liberty Way. (2,000 characters max)';

  endSubscriptions = new Subject<void>();

  yesNoRadioOpts: RadioOpts[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  constructor(
    public formService: FormService,
    public programService: ProgramService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.formGroup.addControl('probationRadio', new FormControlCustom<string>({ required: 'Indicate if you have been dismissed or placed on probation'}, { value: '', disabled: true }));

    this.formGroup.get('probationRadio').valueChanges.pipe(takeUntil(this.endSubscriptions)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.programService.getProgramSub().pipe(takeUntil(this.endSubscriptions)).subscribe(program => {
      if (
        program.level === 'Undergraduate' && program.campCode === 'R'
      ) {
        this.formGroup.get('probationRadio').setValidators([Validators.required]);
        this.formGroup.get('probationRadio').enable();

      } else {
        this.formGroup.get('probationRadio').clearValidators();
        this.formGroup.get('probationRadio').setValue('');
        this.formGroup.get('probationRadio').disable();
      }
    });
  }

  ngOnDestroy() {
    this.endSubscriptions.next();
    this.endSubscriptions.complete();
  }

}

export abstract class School {
  public sequenceNumber: string; // (required for each record in this array)
  public ceebCode: string; // (optional; must be a value in saturn.stvsbgi.stvsbgi_code)
  public city: string;
  public state: string;
  public nationCode: string;

  public description: string;
  public degree: string;
  public schoolSearchString: string;

  public abstract setDate(date: string): void;

  public abstract getName(): string;

  public abstract setName(name: string): void;

  hasName() {
    return (this.description !== undefined && this.description !== '');
  }

}

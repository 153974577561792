<div [ngClass]="{'hidden': !isModalDisplayed}">
  <div class="modal-container picker-modal" #modalContainer tabindex="0">
    <a class="modalClose" (click)="shutDownThisModal()" (keyup.enter)="shutDownThisModal()" tabindex="0"></a>
    <div class="modalContent">

      <div class="p-3 pt-4 text-center">

        <div class="search-wrap">
          <form [formGroup]="formGroup">
          <app-icon class="icon" aria-label="Search icon" [icon]="'search'"></app-icon>
          <input formControlName="programSearchString" autocomplete="off" (input)="onSearchInput($event.target.value)" type="search" value=""
            name="degree-search" id="degree-search" #degreeSearch placeholder="Search" tabindex="0">

          <app-icon *ngIf="this.formGroup.controls['programSearchString'] && this.formGroup.controls['programSearchString'].value.length > 0" (click)="resetSearch()"
            class="close-icon" [icon]="eIconTypes.x"></app-icon>
          </form>
        </div>
      </div>

      <app-degree-picker-select *ngIf="isModalDisplayed" [formGroup]="formGroup" (emitSelectedProgram)="onProgramSelected($event)"
        [tree]="pickerTree"></app-degree-picker-select>


      <div class="program-selection text-center" [ngClass]="{'show': showInfoBox}">
        <p>{{infoBoxHeader}}</p>
        <h3 class="program-name">{{selectedProgram.displayDescription }}</h3>
        <button class="button button-primary" (click)="onProgramChosen()"
          [attr.disabled]="selectedProgram.programCode ? null : 'disabled'">
          Choose this Program
        </button>
      </div>

    </div>
  </div>

  <div class="modalOverlay" (click)="shutDownThisModal()" (keyup.enter)="shutDownThisModal()" tabindex="-1"></div>
</div>

<ng-container *ngIf="formGroup.get('felonRadio').enabled" [formGroup]="formGroup">
  <fieldset class="form-control" role="radiogroup">

    <div class="relative">

      <div class="validation-error-bar" *ngIf="formService.hasErrors(formGroup.get('felonRadio'))"></div>

      <legend>
        <span class="bold-label mb-2">Have you ever been convicted of a felony or misdemeanor?</span>
        <p class="mb-2">Under Virginia law, when answering you may disregard <b>Virginia</b> convictions for simple marijuana possession without intent to distribute. Liberty University encourages complete disclosure.</p>
      </legend>

      <app-radio
      [radioOpts]="radioOpts" [radioFormControl]="formGroup.get('felonRadio')"
        [errorMessage]="'Indicate if you have ever been convicted of a felony or misdemeanor'"
      ></app-radio>


      <app-textbox *ngIf="formGroup.get('felonRadio').value === 'Yes'"
      [formGroup]="formGroup"
      [nameForControl]="'textbox_felon'"
      [requiredErrorMessage]="'Provide a reason for your felony/misdemeanor'"
      [required]="true"
      [textboxHeader] ="textboxHeader"
      [textboxError]="'Provide the details asked for above regarding having been convicted of a felony or misdemeanor'"
      ></app-textbox>


    </div>
  </fieldset>
</ng-container>

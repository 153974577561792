<div
  class="login-banner-container"
  *ngIf="
  shouldDisplay
  && !env.isAgent
  && !authenticationService.isLoggedIn()
  && navigationService.sectionIsActive('yourDegree')"
>
  <div class="mobile-hide">
    <app-icon [icon]="eIconTypes.Lightbulb" aria-hidden="true"></app-icon>
  </div>

  <div class="login-text">
    <b>Do you have a Liberty account?</b> Log in with your Liberty email and password and we'll prefill the information we already have.
  </div>

  <div class="ml-2">
    <button class="button button-rounded button-ghost" (click)="triggerLogin()">Log in</button>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SchoolSearchComponent} from './school-search.component';
import {SchoolSearchFieldsetModule} from './school-search-fieldset/school-search-fieldset.module';
import {CheckboxModule} from '../checkbox/checkbox.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    SchoolSearchComponent
  ],
  imports: [
    CommonModule,
    SchoolSearchFieldsetModule,
    ReactiveFormsModule,
    CheckboxModule
  ],
  exports: [
    SchoolSearchComponent
  ]
})
export class SchoolSearchModule {
}

export class MajorFoses {
  public etrySequenceNumber: string; // (optional for each record in this array) Entry sequence number (saturn.saretry)
  public sareFosSequenceNumber: string; // (optional for each record in this array) field of study sequence number (saturn.sarefos)
  public programCode: string; // (optional) must be a value in saturn.sobcurr.sobcurr_program

  constructor(etrySeqNum?: string, sareFosSeqNum?: string, progCode?: string) {
    this.etrySequenceNumber = etrySeqNum;
    this.sareFosSequenceNumber = sareFosSeqNum;
    this.programCode = progCode;
  }
}

interface Programs {
  name: string;
  nineCode?: string;
  twelveCode?: string;
  eighteenCode?: string;
  disabledFor9?: boolean;
  disabledFor12?: boolean;
  disabledFor18?: boolean;

}

export interface DisciplineGroup {
  discipline?: string;
  programs: Programs[];
  disabledFor9?: boolean;
  disabledFor12?: boolean;
  disabledFor18?: boolean;
}

export function getMaisAllDisciplineOptions(): DisciplineGroup[] {
  return sortDisciplineAndPrograms(maisAllDisciplineOptions);
}

export function getIndrAllDisciplines(): DisciplineGroup[] {
  return sortDisciplineAndPrograms(indrAllDisciplineOptions);
}

function sortDisciplineAndPrograms(array: DisciplineGroup[]): DisciplineGroup[] {
  array.sort((a, b) => stringCompare(a.discipline, b.discipline)); // Sort the top-level discipline groups first
  array.forEach((group) => group.programs.sort((a, b) => stringCompare(a.name, b.name))); // Sort the nested program names next
  return array;
}

function stringCompare(a: string, b: string) {
  return a.toUpperCase().localeCompare(b.toUpperCase());
}

const maisAllDisciplineOptions: DisciplineGroup[] = [
  {
    discipline: 'Business',
    programs: [
      { name: 'Executive Leadership', nineCode: 'GU09', twelveCode: 'GU12' },
      { name: 'Non-Profit Organization', nineCode: 'G09E', twelveCode: 'G12E' },
      { name: 'Project Management', nineCode: 'G09D', twelveCode: 'G12D' },
      { name: 'Supply Chain Logistics & Geography', nineCode: 'G09C', twelveCode: 'G12C' }
    ]
  },
  {
    discipline: 'Communication',
    programs: [
      { name: 'Communication', nineCode: 'GC09', twelveCode: 'GC12' }
    ]
  },
  {
    discipline: 'Counseling',
    programs: [
      { name: 'Crisis Counseling', nineCode: 'GG09', twelveCode: 'GG12' },
      { name: 'Life Coaching', nineCode: 'GL09', twelveCode: 'GL12' },
      { name: 'Marriage & Family', nineCode: 'GM09', twelveCode: 'GM12' }, // Corrected alphabetical order
      { name: 'Military Resilience', nineCode: 'GR09', twelveCode: 'GR12' },
      { name: 'Pastoral Counseling', nineCode: 'GK09', twelveCode: 'GK12' }
    ]
  },
  {
    discipline: 'Education',
    programs: [
      { name: 'Education', nineCode: 'GE09', twelveCode: 'GE12' }
    ]
  },
  {
    discipline: 'English & Writing',
    programs: [
      { name: 'Composition', nineCode: 'G09N', twelveCode: 'G12N' },
      { name: 'English Literature', nineCode: 'G09O', twelveCode: 'G12O' },
      { name: 'Research Administration and Sponsored Programs', nineCode: 'G09I', twelveCode: 'G12I' },
      { name: 'Writing', nineCode: 'G09H', twelveCode: 'G12H' }
    ]
  },
  {
    discipline: 'Ethnomusicology',
    programs: [
      { name: 'Ethnomusicology', nineCode: 'GV09', twelveCode: 'GV12' }
    ]
  },
  {
    discipline: 'Exercise Science',
    programs: [
      { name: 'Exercise Science', nineCode: 'GX09', twelveCode: 'GX12' }
    ]
  },
  {
    discipline: 'Family Studies',
    programs: [
      { name: 'Family and Consumer Sciences', nineCode: 'G09G', twelveCode: 'G12G' },
      { name: 'Family Studies', nineCode: 'G09M', twelveCode: 'G12M' }
    ]
  },
  {
    discipline: 'Geographic Sciences',
    programs: [
      { name: 'Geographic Information Systems', nineCode: 'G09K', twelveCode: 'G12K' },
      { name: 'Geography', nineCode: 'G09B', twelveCode: 'G12B' } // this will disable Supply Chain Logistics & Geography 12
    ]
  },
  {
    discipline: 'Government',
    programs: [
      { name: 'American Government', nineCode: 'GA09', twelveCode: 'GA12' },
      { name: 'Chinese Affairs', twelveCode: 'G12R' },
      { name: 'Criminal Justice', nineCode: 'GJ09', twelveCode: 'GJ12' },
      { name: 'International Relations', nineCode: 'GI09', twelveCode: 'GI12' },
      { name: 'Public Policy', nineCode: 'G09Q', twelveCode: 'G12Q' }
    ]
  },
  {
    discipline: 'History and Public History',
    programs: [
      { name: 'History', nineCode: 'GH09', twelveCode: 'GH12' },
      { name: 'Public History', nineCode: 'G09F', twelveCode: 'G12F' }
    ]
  },
  {
    discipline: 'Judicial Studies',
    programs: [
      { name: 'Judicial Studies', nineCode: 'G09T', twelveCode: 'G12T' },
    ]
  },
  {
    discipline: 'Linguistics and Teaching English as a Second Language',
    programs: [
      { name: 'Applied Linguistics', nineCode: 'G09P', twelveCode: 'G12P' },
      { name: 'Linguistics', nineCode: 'G09L', twelveCode: 'G12L' },
      { name: 'Teaching English as a Second Language', nineCode: 'G09S', twelveCode: 'G12S' }
    ]
  },
  {
    discipline: 'Mathematics',
    programs: [
      { name: 'Mathematics', nineCode: 'GZ09', twelveCode: 'GZ12' }
    ]
  },
  {
    discipline: 'Music & Worship Studies',
    programs: [
      { name: 'Music & Worship Studies', nineCode: 'GW09', twelveCode: 'GW12' }
    ]
  },
  {
    discipline: 'Public & Community Health',
    programs: [
      { name: 'Public & Community Health', nineCode: 'GQ09', twelveCode: 'GQ12' }
    ]
  },
  {
    discipline: 'Psychology',
    programs: [
      { name: 'Psychology', nineCode: 'GP09', twelveCode: 'GP12' }
    ]
  },
  {
    discipline: 'Religion',
    programs: [
      { name: 'Discipleship & Evangelism', nineCode: 'GD09', twelveCode: 'GD12' },
      { name: 'Global Studies', nineCode: 'GB09', twelveCode: 'GB12' },
      { name: 'New Testament', nineCode: 'GT09', twelveCode: 'GT12' },
      { name: 'Old Testament', nineCode: 'GO09', twelveCode: 'GO12' },
      { name: 'Theology & Apologetics', nineCode: 'GY09', twelveCode: 'GY12' }
    ]
  },
  {
    discipline: 'Spanish',
    programs: [
      { name: 'Spanish', nineCode: 'G09J' }
    ]
  },
  {
    discipline: 'Sports Management',
    programs: [
      { name: 'Outdoor Adventure Sport & Tourism Management', nineCode: 'GF09', twelveCode: 'GF12' },
      { name: 'Sport Business & Leadership', nineCode: 'GS09', twelveCode: 'GS12' }
    ]
  }
];

const indrAllDisciplineOptions: DisciplineGroup[] = [
  {
    discipline: 'Criminal Justice',
    programs: [
      { name: 'Criminal Justice', twelveCode: 'RD12', eighteenCode: 'RD18' }
    ]
  },
  {
    discipline: 'Economics',
    programs: [
      { name: 'Economics', twelveCode: 'RE12', eighteenCode: 'RE18' }
    ]
  },
  {
    discipline: 'English',
    programs: [
      { name: 'English', twelveCode: 'RF12', eighteenCode: 'RF18' }
    ]
  },
  {
    discipline: 'Ethnomusicology',
    programs: [
      { name: 'Ethnomusicology', twelveCode: 'RG12', eighteenCode: 'RG18' }
    ]
  },
  {
    discipline: 'Geographic Sciences',
    programs: [
      { name: 'Geographic Information Systems', twelveCode: 'RI12', eighteenCode: 'RI18' },
      { name: 'Geography', twelveCode: 'RH12', eighteenCode: 'RH18' }
    ]
  },
  {
    discipline: 'Government and International Relations',
    programs: [
      { name: 'Government', twelveCode: 'RJ12', eighteenCode: 'RJ18' },
      { name: 'International Relations', twelveCode: 'RM12', eighteenCode: 'RM18' }
    ]
  },
  {
    discipline: 'History',
    programs: [
      { name: 'History', twelveCode: 'RK12', eighteenCode: 'RK18' }
    ]
  },
  {
    discipline: 'Legal Studies',
    programs: [
      { name: 'American Legal Studies', twelveCode: 'RA12', eighteenCode: 'RA18' },
      { name: 'International Legal Studies', twelveCode: 'RW12', eighteenCode: 'RW18' }
    ]
  },
  {
    discipline: 'Linguistics',
    programs: [
      { name: 'Linguistics', twelveCode: 'RN12', eighteenCode: 'RN18' }
    ]
  },
  {
    discipline: 'Military and National Security',
    programs: [
      { name: 'Military and National Security', twelveCode: 'RO12', eighteenCode: 'RO18' }
    ]
  },
  {
    discipline: 'Nutrition',
    programs: [
      { name: 'Nutrition', twelveCode: 'RQ12', eighteenCode: 'RQ18' }
    ]
  },
  {
    discipline: 'Psychology',
    programs: [
      { name: 'Psychology', twelveCode: 'RS12', eighteenCode: 'RS18' }
    ]
  },
  {
    discipline: 'Public Administration',
    programs: [
      { name: 'Public Administration', twelveCode: 'RT12', eighteenCode: 'RT18' }
    ]
  },
  {
    discipline: 'Religion',
    programs: [
      { name: 'Apologetics', twelveCode: 'RB12', eighteenCode: 'RB18' },
      { name: 'Church History', twelveCode: 'RC12', eighteenCode: 'RC18' },
      { name: 'New Testament', twelveCode: 'RP12', eighteenCode: 'RP18' },
      { name: 'Old Testament', twelveCode: 'RR12', eighteenCode: 'RR18' },
      { name: 'Theology', twelveCode: 'RV12', eighteenCode: 'RV18' }
    ]
  },
  {
    discipline: 'Strategic Communication',
    programs: [
      { name: 'Strategic Communication', twelveCode: 'RU12', eighteenCode: 'RU18' }
    ]
  }
];


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpelledRadioComponent } from './expelled-radio.component';
import { AlertModule } from 'src/app/alert/alert.module';
import { SchoolSearchInputModule } from '../generic/school-search-input/school-search-input.module';
import { RadioModule } from '../generic/radio/radio.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ExpelledRadioComponent
  ],
  imports: [
    CommonModule,
    AlertModule,
    ReactiveFormsModule,
    RadioModule,
    SchoolSearchInputModule
  ],
  exports: [
    ExpelledRadioComponent
  ]
})
export class ExpelledRadioModule { }
